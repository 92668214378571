import diacritics from 'diacritics'
import Fuse from 'fuse.js'

import { MileageRateResponse } from '../../client'

/**
 * Creates an index to look for mileage rates
 * @param mileageRates
 */
export function createMileageRatesIndex(mileageRates: MileageRateResponse[]) {
  return new Fuse(mileageRates, {
    keys: ['name'],
    threshold: 0.25,
    getFn(object: any, path: string | string[]) {
      path = Array.isArray(path) ? path[0] : path
      const value = object[path]
      if (!value) {
        return ''
      }

      return diacritics.remove(object[path])
    },
  })
}
