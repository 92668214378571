import { createContext, useCallback, useContext, useMemo, useReducer } from 'react'

import { RepositoryStore } from '../../interfaces/repository.types'
import { Entity, EntityReducer, entityReducer } from '../store'

export function useRepositoryStore<TEntity extends Entity<string>>(): RepositoryStore<TEntity> {
  // State

  const [itemsMap, dispatchItems] = useReducer(entityReducer as EntityReducer<string, TEntity>, {})

  // Getters

  const items = useMemo(() => Object.values(itemsMap), [itemsMap])
  const itemById = useCallback((id?: string) => (id ? itemsMap[id] : undefined), [items])
  const itemsByIds = useCallback(
    (ids: string[]) => ids.map(itemById).filter(Boolean) as TEntity[],
    [itemById],
  )

  // Actions

  // Adds one item
  const addOne = useCallback(
    (item: TEntity) => dispatchItems({ type: 'addOne', item }),
    [dispatchItems],
  )

  // Adds many items
  const addMany = useCallback(
    (items: TEntity[] | Record<string, TEntity>, clear = false) =>
      dispatchItems({ type: 'addMany', items, clear }),
    [dispatchItems],
  )

  // Removes one item
  const removeOne = useCallback(
    (id: string) => dispatchItems({ type: 'removeOne', id }),
    [dispatchItems],
  )

  // Clears the map
  const clear = useCallback(() => dispatchItems({ type: 'clear' }), [dispatchItems])

  return {
    items,
    itemById,
    itemsByIds,

    addOne,
    addMany,
    removeOne,
    clear,
  }
}

export const RepositoryStoreContext = createContext<RepositoryStore<any>>({
  isLoading: true,
} as any)

export function getRepositoryStore() {
  return useContext(RepositoryStoreContext)
}
