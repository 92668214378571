import { createContext, useContext, useEffect, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AppStore } from '../app/app.store'
import { PopulatedTask } from '../tasks/task.interface'
import { TaskColumnStore } from '../tasks/task-column.store'
import { getWorkspaceParamsFromTemplate } from './workspace.factory'
import {
  Workspace,
  WorkspaceColumnParams,
  WorkspaceParams,
  WorkspaceTemplate,
} from './workspace.interface'
import {
  transformToCreateWorkspaceRequest,
  transformToUpdateWorkspaceRequest,
  transformToWorkspaceColumnRequest,
  transformWorkspaceResponse,
} from './workspace.transformer'

export function useWorkspacesStore(app: AppStore, taskColumns: TaskColumnStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const [workspacesMap, dispatchWorkspaces] = useReducer(
    entityReducer as EntityReducer<string, Workspace>,
    {},
  )

  // Getters

  const workspaces = Object.values(workspacesMap)
  const getWorkspaceById = (id?: string): Workspace | undefined =>
    id ? workspacesMap[id] : undefined

  // Actions

  const getWorkspaces = operation(async () => {
    setReady(false)

    try {
      const response = await api.workspaces.getMyWorkspaces()

      dispatchWorkspaces({ type: 'addMany', items: response.data.map(transformWorkspaceResponse) })
    } finally {
      setReady(true)
    }
  })

  const createWorkspace = async (params: WorkspaceParams): Promise<Workspace | undefined> => {
    const response = await api.workspaces.createWorkspace({
      createWorkspaceRequest: transformToCreateWorkspaceRequest(params),
    })
    const workspace = transformWorkspaceResponse(response.data)
    dispatchWorkspaces({ type: 'addOne', item: workspace })

    return workspace
  }

  const createWorkspaceFromTemplate = async (
    title: string,
    template: WorkspaceTemplate,
    color?: string,
  ) => {
    const params = getWorkspaceParamsFromTemplate(app, title, template)

    return await createWorkspace({ ...params, color })
  }

  const updateWorkspace = async (id: string, params: WorkspaceParams) => {
    const response = await api.workspaces.updateWorkspace({
      id,
      updateWorkspaceRequest: transformToUpdateWorkspaceRequest(params),
    })
    const workspace = transformWorkspaceResponse(response.data)
    dispatchWorkspaces({ type: 'addOne', item: workspace })

    return workspace
  }

  const createWorkspaceColumn = async (workspaceId: string, params: WorkspaceColumnParams) => {
    const response = await api.workspaces.createColumn({
      id: workspaceId,
      columnRequest: transformToWorkspaceColumnRequest(params),
    })
    const workspace = transformWorkspaceResponse(response.data)
    dispatchWorkspaces({ type: 'addOne', item: workspace })

    return workspace
  }

  const updateWorkspaceColumn = async (
    workspaceId: string,
    columnId: string,
    params: WorkspaceColumnParams,
  ) => {
    const response = await api.workspaces.updateColumn({
      id: workspaceId,
      columnId,
      columnRequest: transformToWorkspaceColumnRequest(params),
    })
    const workspace = transformWorkspaceResponse(response.data)
    dispatchWorkspaces({ type: 'addOne', item: workspace })

    return workspace
  }

  const deleteWorkspaceColumn = async (workspaceId: string, columnId: string) => {
    const response = await api.workspaces.deleteColumn({
      id: workspaceId,
      columnId,
    })
    const workspace = transformWorkspaceResponse(response.data)
    dispatchWorkspaces({ type: 'addOne', item: workspace })

    return workspace
  }

  function updateColumnsWithLocalTask(workspace: Workspace, newTask: PopulatedTask) {
    for (const column of workspace.columns) {
      taskColumns.updateLocalTask(column, newTask)
    }
  }

  const deleteWorkspace = async (id: string) => {
    await api.workspaces.deleteWorkspace({ id })

    dispatchWorkspaces({ type: 'removeOne', id })
  }

  // Effects

  useEffect(() => {
    if (app.isReady) {
      if (app.auth.isAuthenticated) {
        getWorkspaces()
      } else {
        dispatchWorkspaces({ type: 'clear' })
        setReady(true)
      }
    }
  }, [app.isReady, app.auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    workspaces,
    getWorkspaceById,

    getWorkspaces,
    createWorkspace,
    createWorkspaceFromTemplate,
    createWorkspaceColumn,
    updateWorkspaceColumn,
    deleteWorkspaceColumn,
    updateWorkspace,
    deleteWorkspace,

    updateColumnsWithLocalTask,
  }
}

export type WorkspacesStore = ReturnType<typeof useWorkspacesStore>

export const WorkspacesStoreContext = createContext<WorkspacesStore>({
  isReady: false,
  isLoading: true,
  workspaces: [],
} as any)

export function getWorkspaceStore() {
  return useContext(WorkspacesStoreContext)
}
