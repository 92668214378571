import { ReportRateRequest, ReportRateResponse } from '../../client'
import { transformRate, transformRateRequest } from './rate.transformer'

/**
 * Transforms a report rate. Transforms perKm rate
 * @param rate
 * @returns transformed rate
 */
export function transformReportRate(rate: ReportRateResponse): ReportRateResponse {
  return {
    ...rate,
    perReport: transformRate(rate.perReport),
    perReopen: transformRate(rate.perReopen),
  }
}

/**
 * Transforms a report rate request. Transforms perKm rate
 * @param rate
 * @returns transformed rate
 */
export function transformReportRateRequest(request: ReportRateRequest): ReportRateRequest {
  if (request.perReport) {
    request.perReport = transformRateRequest(request.perReport)
  }
  if (request.perReopen) {
    request.perReopen = transformRateRequest(request.perReopen)
  }

  return request
}
