/**
 * Sets a router query, in case it's not set already
 * @param router
 * @param key
 * @param value
 */
export function setRouterQuery(router, key, value) {
  if (router.query[key] !== value) {
    router.query[key] = value === null || value === void 0 ? void 0 : value.toString();
    router.push(router, undefined, {
      shallow: true
    });
  }
}