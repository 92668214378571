import { createContext, useContext, useEffect, useRef, useState } from 'react'

import { api } from '../../api/clients'
import { RouteResponse, RouteSortType, SortDir } from '../../client'
import { RepositoryStore } from '../../interfaces/repository.types'
import { usePaginationStore } from '../../utils/stores/pagination.store'

export function useRoutePaginationStore(repository: RepositoryStore<RouteResponse>) {
  const sortBy = useRef<RouteSortType>()
  const sortDir = useRef<SortDir>()
  const [filters, setFilters] = useState<any>({})

  const pagination = usePaginationStore<RouteResponse>({
    repository,
    fetchItems: async (page: number, pageSize: number) => {
      const response = await api.routes.searchRoutes({
        ...filters,
        sortBy: sortBy.current,
        sortDir: sortDir.current,
        page,
        limit: pageSize,
      })

      return response.data
    },
  })

  const clear = () => {
    setFilters({})
    pagination.clear()
  }

  useEffect(() => {
    if (pagination.active) {
      pagination.fetchPage(0)
    }
  }, [filters])

  return {
    ...pagination,

    filters,
    setFilters,
    setSortBy: (value: RouteSortType) => (sortBy.current = value),
    setSortDir: (value: SortDir) => (sortDir.current = value),
    clear,
  }
}

export type RoutePaginationStore = ReturnType<typeof useRoutePaginationStore>

export const RoutePaginationStoreContext = createContext<RoutePaginationStore>({
  isLoading: true,
} as any)

export function getRoutePaginationStore() {
  return useContext(RoutePaginationStoreContext)
}
