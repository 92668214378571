import _slicedToArray from "/app/web/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { deepCopy } from 'core/utils/object';
/**
 * Transforms the raw response from the server into the maps directions.
 * This is a bit of a hack because the server API for directions is not fully
 * compatible with the one in the frontend. Not my fault, is google's
 *
 * https://stackoverflow.com/questions/47168200/google-maps-display-route-from-json
 *
 * @param directions
 * @returns
 */

export function transformRawRouteResponseToDirections(directions) {
  var result = deepCopy(directions.googleMaps);
  var bounds = new google.maps.LatLngBounds(result.routes[0].bounds.southwest, result.routes[0].bounds.northeast);
  result.routes[0].bounds = bounds;
  result.routes[0].overview_path = google.maps.geometry.encoding.decodePath(result.routes[0].overview_polyline.points);
  result.routes[0].legs = result.routes[0].legs.map(function (leg) {
    leg.start_location = new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng);
    leg.end_location = new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng);
    leg.steps = leg.steps.map(function (step) {
      step.path = google.maps.geometry.encoding.decodePath(step.polyline.points);
      step.start_location = new google.maps.LatLng(step.start_location.lat, step.start_location.lng);
      step.end_location = new google.maps.LatLng(step.end_location.lat, step.end_location.lng);
      return step;
    });
    return leg;
  });
  result.request = {
    travelMode: 'DRIVING'
  };
  return result;
}
export function transformStringLatLngToCoords(literal, increaseIndex) {
  var _literal$split = literal.split(','),
      _literal$split2 = _slicedToArray(_literal$split, 2),
      lat = _literal$split2[0],
      lng = _literal$split2[1];

  var latNumber = Number(lat);
  var lngNumber = Number(lng);

  if (increaseIndex) {
    lngNumber += 0.001 * increaseIndex;
  }

  return {
    lat: latNumber,
    lng: lngNumber
  };
}