import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '../../api/clients'
import { RateStatusType, ReportRateRequest, ReportRateResponse } from '../../client'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { useRepositoryStore } from '../../utils/stores/repository.store'
import { AuthStore } from '../auth/auth.store'
import { createReportRatesIndex } from './rates-report.filter'
import { transformReportRate, transformReportRateRequest } from './rates-reportmileage.transformer'

export function useReportRatesStore(auth: AuthStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const repository = useRepositoryStore<ReportRateResponse>()

  // Getters

  const reportRatesIndex = useMemo(
    () => createReportRatesIndex(repository.items),
    [repository.items],
  )

  const activeReportRates = useMemo(
    () => repository.items.filter((it) => it.status === RateStatusType.Active),
    [repository.items],
  )

  // Actions

  // Get report rates for the current user
  const getReportRates = operation(async () => {
    setReady(false)

    try {
      const response = await api.rates.getAllReportRates()
      repository.addMany(response.data.map(transformReportRate))
    } finally {
      setReady(true)
    }
  })

  const createReportRate = operation(async (request: ReportRateRequest) => {
    const response = await api.rates.createReportRate({
      reportRateRequest: transformReportRateRequest(request),
    })
    repository.addOne(transformReportRate(response.data))

    return response.data
  })

  const updateReportRate = operation(async (id: string, request: ReportRateRequest) => {
    const response = await api.rates.updateReportRate({
      id,
      reportRateRequest: transformReportRateRequest(request),
    })
    repository.addOne(transformReportRate(response.data))

    return response.data
  })

  const deleteReportRate = operation(async (id: string) => {
    await api.rates.deleteReportRate({ id })
    repository.removeOne(id)
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getReportRates()
    } else {
      repository.clear()
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    repository: repository,
    reportRatesIndex,
    reportRates: repository.items,
    activeReportRates,
    getReportRateById: repository.itemById,
    reportRatesByIds: repository.itemsByIds,

    createReportRate,
    updateReportRate,
    deleteReportRate,
  }
}

export type ReportRatesStore = ReturnType<typeof useReportRatesStore>

export const ReportRatesStoreContext = createContext<ReportRatesStore>({
  isReady: false,
  isLoading: true,
  reportRates: [],
} as any)

export function getReportRateStore() {
  return useContext(ReportRatesStoreContext)
}
