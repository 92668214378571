import {
  ClaimType,
  CreateUserRequest,
  UpdateSelfUserRequest,
  UpdateUserRequest,
  UserResponse,
} from '../../client'
import { User, UserParams } from './user.interface'

/**
 * Transforms response from the api
 * @param response
 */
export function transformUserResponse(response: UserResponse): User {
  return {
    id: response.id,
    disabled: response.disabled ?? false,
    name: response.name,
    email: response.username,
    phone: response.phone,
    language: response.language as any,
    role: response.role,
    isGod: (response.role as any) === 'god',
    contract: response.contract,
    hasAvatar: !!response.avatar?.fileId,
    claims: response.claims,
    location: response.location,
    locationName: response.locationName,
    avatar: response.avatar,
    claimsMap: {
      [ClaimType.ManageUsers]: response.claims.includes(ClaimType.ManageUsers),
      [ClaimType.ManageSettings]: response.claims.includes(ClaimType.ManageSettings),
      [ClaimType.ManageTasks]: response.claims.includes(ClaimType.ManageTasks),
      [ClaimType.SeeAllTasks]: response.claims.includes(ClaimType.SeeAllTasks),
      [ClaimType.ManageFinances]: response.claims.includes(ClaimType.ManageFinances),
    },
  }
}

/**
 * Transform into update request
 * @param params
 */
export function transformToUpdateUserRequest(params: UserParams): UpdateUserRequest {
  const claims = Object.entries(params.claimsMap ?? {}).reduce((result, [claim, isEnabled]) => {
    if (isEnabled) {
      result.push(claim as any as ClaimType)
    }

    return result
  }, [] as ClaimType[])

  return {
    disabled: params.disabled,
    name: params.name,
    phone: params.phone,
    language: params.language,
    contract: params.contract,
    role: params.role as any,
    claims,
    location: params.location,
    locationName: params.locationName,
  }
}

export function transformToUpdateMeRequest(params: UserParams): UpdateSelfUserRequest {
  return {
    name: params.name,
    language: params.language,
    phone: params.phone,
    location: params.location,
    locationName: params.locationName,
  }
}

/**
 * Transforms into create request
 * @param params
 */
export function transformToCreateUserRequest(params: UserParams): CreateUserRequest {
  const claims = Object.entries(params.claimsMap || {}).reduce((result, [claim, isEnabled]) => {
    if (isEnabled) {
      result.push(claim as ClaimType)
    }

    return result
  }, [] as ClaimType[])

  return {
    username: params.email,
    name: params.name,
    phone: params.phone,
    language: params.language,
    contract: params.contract,
    role: params.role as any,
    claims: claims,
    location: params.location,
    locationName: params.locationName,
  }
}
