import {
  ColumnFiltersDtoStatesEnum,
  ColumnFiltersDtoTypesEnum,
  ColumnSortType,
  SortDir,
} from '../../client'
import { TaskStateColorDark, TaskTypeColor } from '../../interfaces/variables.types'
import { AppStore } from '../app/app.store'
import { TaskState, TaskType } from '../tasks/task.interface'
import { WorkspaceColumnParams, WorkspaceParams, WorkspaceTemplate } from './workspace.interface'

const WORKSPACE_TEMPLATE_FACTORY: Record<
  WorkspaceTemplate,
  (app: AppStore) => WorkspaceColumnParams[]
> = {
  [WorkspaceTemplate.Custom]: getWorkspaceColumnParamsForCustomTemplate,
  [WorkspaceTemplate.ByType]: getWorkspaceColumnParamsForByTypeTemplate,
  [WorkspaceTemplate.ByState]: getWorkspaceColumnParamsForByStateTemplate,
  [WorkspaceTemplate.ByCompany]: getWorkspaceColumnParamsForByColumnTemplate,
  [WorkspaceTemplate.ByClient]: getWorkspaceColumnParamsForByClientTemplate,
  [WorkspaceTemplate.ByZone]: getWorkspaceColumnParamsForByZoneTemplate,
  [WorkspaceTemplate.ByAssignee]: getWorkspaceColumnParamsForByAssigneeTemplate,
  [WorkspaceTemplate.ByLabel]: getWorkspaceColumnParamsForByLabelTemplate,
}

export function getWorkspaceParamsFromTemplate(
  app: AppStore,
  title: string,
  template: WorkspaceTemplate,
): WorkspaceParams {
  const factory = WORKSPACE_TEMPLATE_FACTORY[template]
  const columns = factory(app)

  return {
    title,
    columns,
  }
}

export function getWorkspaceColumnParamsForCustomTemplate(): WorkspaceColumnParams[] {
  return []
}

/**
 * Gets all the columns for the type template.
 * Each column gets tasks in the given type.
 */
export function getWorkspaceColumnParamsForByTypeTemplate(app: AppStore): WorkspaceColumnParams[] {
  return Object.values(TaskType).map(
    (type): WorkspaceColumnParams => ({
      title: app.t(`form.task.type.values.${type}`),
      color: TaskTypeColor[type],
      filters: { types: [type as unknown as ColumnFiltersDtoTypesEnum] },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}

/**
 * Gets all the columns for the state template.
 * Each column gets tasks in the given state.
 */
export function getWorkspaceColumnParamsForByStateTemplate(app: AppStore): WorkspaceColumnParams[] {
  return Object.values(TaskState).map(
    (state): WorkspaceColumnParams => ({
      title: app.t(`tasks.state.${state}`),
      color: TaskStateColorDark[state],
      filters: { states: [state as unknown as ColumnFiltersDtoStatesEnum] },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}

/**
 * Gets all the columns for the companies template.
 * Each column gets tasks in the given company.
 */
export function getWorkspaceColumnParamsForByColumnTemplate(
  app: AppStore,
): WorkspaceColumnParams[] {
  return app.companies.companies.map(
    (company): WorkspaceColumnParams => ({
      title: company.name,
      filters: { companyIds: [company.id] },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}

/**
 * Gets all the columns for the clients template.
 * Each column gets tasks in the given client.
 */
export function getWorkspaceColumnParamsForByClientTemplate(
  app: AppStore,
): WorkspaceColumnParams[] {
  return app.clients.clients.map(
    (client): WorkspaceColumnParams => ({
      title: client.name,
      filters: {
        // Not ready yet
        // clientIds: [client.id]
      },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}

/**
 * Gets all the columns for the zones template.
 * Each column gets tasks in the given zone.
 */
export function getWorkspaceColumnParamsForByZoneTemplate(app: AppStore): WorkspaceColumnParams[] {
  return app.zones.zones.map(
    (zone): WorkspaceColumnParams => ({
      title: zone.name,
      filters: { zoneIds: [zone.id] },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}

/**
 * Gets all the columns for the users template.
 * Each column gets tasks assigned to the given user.
 */
export function getWorkspaceColumnParamsForByAssigneeTemplate(
  app: AppStore,
): WorkspaceColumnParams[] {
  return app.users.users.map(
    (user): WorkspaceColumnParams => ({
      title: user.name,
      filters: { assigneeIds: [user.id] },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}

/**
 * Gets all the columns for the labels template.
 * Each column gets tasks with the given label.
 */
export function getWorkspaceColumnParamsForByLabelTemplate(app: AppStore): WorkspaceColumnParams[] {
  return app.labels.labels.map(
    (label): WorkspaceColumnParams => ({
      title: label.name,
      color: label.color,
      filters: { labelIds: [label.id] },
      sort: {
        sortBy: ColumnSortType.Deadline,
        sortDir: SortDir.Desc,
      },
    }),
  )
}
