import moment from 'moment'

import { ZoneRequest, ZoneResponse } from '../../client'
import { Zone, ZoneParams } from './zone.interface'

export function transformZoneResponse(response: ZoneResponse): Zone {
  return {
    id: response.id,
    name: response.name,
    description: response.description,
    coordinates: response.coordinates,
    createdAt: moment(response.createdAt).toDate(),
    updatedAt: moment(response.updatedAt).toDate(),
  }
}

export function transformToZoneRequest(params: ZoneParams): ZoneRequest {
  return {
    name: params.name,
    description: params.description,
    coordinates: params.coordinates,
  }
}
