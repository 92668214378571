import { ReportTaskContentDto, TaskIncidenceState, TaskVersionDto } from '../../client'
import { Client } from '../clients/client.interface'
import { Company } from '../companies/company.interface'
import { Label } from '../labels/label.interface'
import { User } from '../users/user.interface'
import { Zone } from '../zones/zone.interface'

export enum TaskType {
  Report = 'report',
  Homologation = 'homologation',
  Recognition = 'recognition',
}

export enum TaskState {
  // Initial

  // Task is just a draft for now.
  Draft = 'draft',

  // Task has not started yet.
  Open = 'open',

  // Intermediate

  // Client is expecting contact from the expert
  Contact = 'contact',
  // Client is expecting a visit
  Visit = 'visit',
  // Expert should build the report
  BuildReport = 'buildReport',
  // Report should be reviewed
  ReviewReport = 'reviewReport',
  // Homologation is in the laboratory
  InLaboratory = 'inLaboratory',
  // Homologation is accepted
  Accepted = 'accepted',
  // Homologation is in inspection
  InInspection = 'inInspection',

  // Final

  // Task has finished.
  Close = 'close',

  // Task was cancelled
  Cancelled = 'cancelled',
}

export enum TaskStateKind {
  Open = 'open',
  InProgress = 'inProgress',
  Finished = 'finished',
}

export enum TaskDocumentType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
}

export interface TaskDocument {
  /** ID of the document */
  readonly id: string

  /** File's mime-type */
  readonly mimeType: string

  /** File's type */
  readonly type: TaskDocumentType

  /** The metadata of the file. */
  readonly metadata: any

  /** The user that uploaded the file. */
  readonly uploadedBy: string

  /** The time at which the file was uploaded. */
  readonly uploadedAt: string

  /** The tags of the application for the file. */
  readonly tags: string[]

  /** Tags */

  readonly isDocument?: boolean
  readonly isPhoto?: boolean
  readonly isReport?: boolean
}

export interface TaskIncidence {
  /** Identifier of the incidence entry */
  readonly id: string

  /** ID of the task */
  readonly taskId?: string

  /** The incidence title */
  readonly title: string

  /** The state of the incidence */
  readonly state: TaskIncidenceState

  /** The incidence description */
  readonly description?: string

  /** The deadline of the incidence */
  readonly deadline?: Date

  /** The close date of the incidence */
  readonly closedAt?: Date

  /** The author of the incidence */
  readonly authorId: string

  /** Author of the incidence */
  readonly author?: User

  /** The creation date of the incidence */
  readonly createdAt: Date

  /** The update date of the incidence */
  readonly updatedAt: Date
}

export type TaskIncidenceParams = Pick<TaskIncidence, 'title' | 'description' | 'deadline'>

export interface Task {
  /** ID of the task. */
  readonly id: string

  /** Type of task. */
  readonly type: TaskType

  /** Current status of the task. */
  readonly state: TaskState

  /** Reference of the task. */
  readonly reference: string

  /** Current version of the task. */
  readonly version: number

  /** Title of the task. */
  title?: string

  /** Description of the task. */
  description?: string

  /** ID of the user who created the task. */
  createdByUserId: string

  /** ID of the user who is assigned to finish the task. */
  assignedToUserId?: string

  /** ID of the user who is assigned to review the task. */
  reviewerId?: string

  /** IDs of the labels attached to the task. */
  labelIds: string[]

  /** Date when the task was created. */
  createdAt: Date

  /** Last date when the task was updated. */
  updatedAt: Date

  /** Date when the task was closed */
  closedAt?: Date

  /** Deadline for the task .*/
  deadline?: Date

  /** Content of the task, depends on the type. */
  readonly content: TaskContent

  /** Task comments. */
  readonly comments: TaskComment[]

  /** Incidences in the task */
  readonly incidences: TaskIncidence[]

  /** Task activity items */
  readonly activity: TaskActivity[]

  /** History of all the versions. */
  readonly versions: TaskVersionDto[]

  /** Documents attached to the task. */
  readonly documents: TaskDocument[]
}

export interface ReportTaskContent
  extends Pick<ReportTaskContentDto, 'appraisalType' | 'rate' | 'price'> {
  readonly type: TaskType.Report

  /** ID of the company that relates to this task. */
  readonly companyId: string

  /** ID of the base in the company */
  readonly baseId: string

  /** ID of the zone that relates to this task. */
  readonly zoneId: string
}

/** Details to identify a car. */
export interface CarDetails {
  readonly brand?: string
  readonly model?: string
  readonly commercialName?: string
  readonly variant?: string
  readonly plateNumber?: string
  readonly chassisNumber?: string
}

export interface HomologationTaskContent extends CarDetails {
  readonly type: TaskType.Homologation

  /** ID of the client. */
  readonly clientId: string

  /** ID of the laboratory company. */
  readonly laboratoryId: string

  /** ID of the laboratory company. */
  readonly companyId: string
}

export interface RecognitionTaskContent extends CarDetails {
  readonly type: TaskType.Recognition

  /** ID of the client. */
  readonly clientId: string
}

export interface PopulatedReportTaskContent extends ReportTaskContent {
  /** Populated company */
  readonly company?: Company

  /** Populated zone */
  readonly zone?: Zone
}

export interface PopulatedHomologationTaskContent extends HomologationTaskContent {
  /** Populated client */
  readonly client?: Client

  /** Populated laboratory */
  readonly laboratory?: Company

  /** Populated laboratory */
  readonly company?: Company
}

export interface PopulatedRecognitionTaskContent extends RecognitionTaskContent {
  /** Populated client */
  readonly client?: Client
}

export type TaskContent = ReportTaskContent | HomologationTaskContent | RecognitionTaskContent
export type PopulatedTaskContent =
  | PopulatedReportTaskContent
  | PopulatedHomologationTaskContent
  | PopulatedRecognitionTaskContent

export interface PopulatedTask extends Task {
  /** User who created the task. */
  createdBy?: User

  /** User who is assigned to finish the task.  */
  assignedTo?: User

  /** Reviewer of the task. */
  reviewer?: User

  /** Labels attached to the task. */
  labels: Label[]

  /** Populated content */
  content: PopulatedTaskContent

  readonly numDocuments: number
  readonly numPhotos: number
  readonly numReports: number
}

export interface TaskComment {
  /** ID of the comment */
  readonly id: string

  /** ID of the author. */
  readonly authorId: string

  /** Text of the comment */
  readonly text: string

  /** Date when the comment was created. */
  readonly createdAt: Date
}

export enum TaskActivityType {
  // Lifecycle
  Created = 'created',
  Deleted = 'deleted',

  // Data changed
  AssigneeChanged = 'assigneeChanged',
  StateChanged = 'stateChanged',
}

/** The data related to the assigned task activity. */
export interface AssignedTaskActivityData {
  readonly previous?: string
  readonly current: string
}

/** The data related to the state changed task activity. */
export interface StateChangedTaskActivityData {
  readonly previous: TaskState
  readonly current: TaskState
}

/** The data of the activities. */
export type TaskActivityData = AssignedTaskActivityData | StateChangedTaskActivityData

export interface TaskActivity {
  readonly type: TaskActivityType
  readonly data?: TaskActivityData
  readonly authorId: string
  readonly createdAt: Date
}

export interface TaskFilters {
  ids?: string[]

  /** Filter by type of task. */
  types?: TaskType[]

  /** Filter by status of the task. */
  states?: TaskState[]

  /** Filter tasks that contain the given string in the reference. */
  reference?: string

  /** Filter the tasks which text fields matches the given terms. */
  readonly contains?: string

  /** Filter tasks that contain the given string in the title. */
  title?: string

  /** Filter tasks that contain the given string in the description. */
  description?: string

  /** Filter tasks that have been created by the given users. */
  createdByUserIds?: string[]

  /** Filter by whether the task has been assigned or not. */
  isAssigned?: boolean

  /** Filter tasks assigned to any user in the list. */
  assigneeIds?: string[]

  /** Filter by whether the task has reviewer or not. */
  hasReviewer?: boolean

  /** Filter tasks with the given reviewers. */
  reviewerIds?: string[]

  /** Filter by whether the task has a company associated. */
  hasCompany?: boolean

  /** Filter by the company associated. */
  companyIds?: string[]

  /** Filter the tasks where zone ID matches one of the given IDs. */
  readonly clientIds?: string[]

  /** Filter by whether the task has a zone associated. */
  hasZone?: boolean

  /** Filter by the zone associated. */
  zoneIds?: string[]

  /** Filter by whether the task has any label attached. */
  hasLabels?: boolean

  /** Filter by the label ids. */
  labelIds?: string[]

  /** Filter the tasks that were created from the given date onwards. */
  readonly createdFrom?: string

  /** Filter the tasks that were created until the given date. */
  readonly createdTo?: string

  /** Filter the tasks that were updated from the given date onwards. */
  readonly updatedFrom?: string

  /** Filter the tasks that were updated until the given date. */
  readonly updatedTo?: string

  /** Filter the tasks that were closed from the given date onwards. */
  readonly closedFrom?: string

  /** Filter the tasks that were closed until the given date. */
  readonly closedTo?: string

  /** Filter by whether the task has a deadline set  or not.*/
  hasDeadline?: boolean

  /** Filter by deadline of the task, from this date. */
  deadlineFrom?: string

  /** Filter by deadline of the task, until this date. */
  deadlineTo?: string
}

export type TaskParams = Omit<Task, 'id' | 'comments' | 'activity'>

export type ReportTask = Task & { type: TaskType.Report }
export type ReportPopulatedTask = PopulatedTask & { type: TaskType.Report }

/** Visible task columns */
export enum TaskColumn {
  Type = 'type',
  State = 'state',
  Reference = 'reference',
  Title = 'title',
  Description = 'description',
  Assignee = 'assignee',
  Reviewer = 'reviewer',
  Company = 'company',
  Labels = 'labels',
  Documents = 'documents',
  Comments = 'comments',
  Incidences = 'incidences',
  Deadline = 'deadline',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ClosedAt = 'closedAt',

  // Types
  ReportZone = 'reportZone',
  HomologationPlateNumber = 'homologationPlateNumber',
  HomologationChassisNumber = 'homologationChassisNumber',
  HomologationClient = 'homologationClient',
  RecognitionPlateNumber = 'recognitionPlateNumber',
  RecognitionChassisNumber = 'recognitionChassisNumber',
  RecognitionClient = 'recognitionClient',
}

export const TASK_STATUS_ORDER: Record<TaskState, number> = {
  // Initial
  [TaskState.Draft]: 1,
  [TaskState.Open]: 2,

  // Intermediate
  [TaskState.Contact]: 3,
  [TaskState.Visit]: 4,
  [TaskState.BuildReport]: 5,
  [TaskState.ReviewReport]: 6,
  [TaskState.InLaboratory]: 7,
  [TaskState.Accepted]: 8,
  [TaskState.InInspection]: 9,

  // Final
  [TaskState.Close]: 10,
  [TaskState.Cancelled]: 11,
}

/**
 * Information about the current page loaded
 */
export interface TaskPageInfo {
  /** IDs of all the tasks in the page */
  taskIds: string[]

  /** Page number */
  readonly page: number

  /** Limit of tasks per page */
  readonly limit: number

  /** Total number of pages */
  readonly pages: number

  /** Whether there are more pages */
  readonly hasMore: boolean

  /** Total number of tasks */
  readonly total: number
}

export type TaskSortBy =
  | 'id'
  | 'reference'
  | 'state'
  | 'deadline'
  | 'assignee'
  | 'reviewer'
  | 'labels'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'company'
  | 'zone'

export const TASK_STATE_TRANSITIONS: Record<TaskType, Partial<Record<TaskState, TaskState[]>>> = {
  [TaskType.Report]: {
    [TaskState.Draft]: [TaskState.Open, TaskState.Contact],
    [TaskState.Open]: [
      TaskState.Contact,
      TaskState.Visit,
      TaskState.BuildReport,
      TaskState.Cancelled,
    ],
    [TaskState.Contact]: [TaskState.Open, TaskState.Visit, TaskState.Cancelled],
    [TaskState.Visit]: [
      TaskState.Open,
      TaskState.Contact,
      TaskState.BuildReport,
      TaskState.Cancelled,
    ],
    [TaskState.BuildReport]: [TaskState.Open, TaskState.ReviewReport, TaskState.Cancelled],
    [TaskState.ReviewReport]: [
      TaskState.Open,
      TaskState.BuildReport,
      TaskState.Close,
      TaskState.Cancelled,
    ],
    [TaskState.Close]: [TaskState.Open],
    [TaskState.Cancelled]: [TaskState.Open],
  },
  [TaskType.Homologation]: {
    [TaskState.Draft]: [TaskState.Open, TaskState.Contact],
    [TaskState.Open]: [
      TaskState.Contact,
      TaskState.BuildReport,
      TaskState.InLaboratory,
      TaskState.Cancelled,
    ],
    [TaskState.Contact]: [TaskState.Open, TaskState.BuildReport, TaskState.Cancelled],
    [TaskState.BuildReport]: [
      TaskState.Open,
      TaskState.ReviewReport,
      TaskState.InLaboratory,
      TaskState.Cancelled,
    ],
    [TaskState.ReviewReport]: [
      TaskState.Open,
      TaskState.BuildReport,
      TaskState.InLaboratory,
      TaskState.InInspection,
      TaskState.Cancelled,
    ],
    [TaskState.InLaboratory]: [
      TaskState.Open,
      TaskState.BuildReport,
      TaskState.Accepted,
      TaskState.Cancelled,
    ],
    [TaskState.Accepted]: [TaskState.Open, TaskState.InInspection, TaskState.Cancelled],
    [TaskState.InInspection]: [TaskState.Open, TaskState.Close, TaskState.Cancelled],
    [TaskState.Close]: [TaskState.Open],
    [TaskState.Cancelled]: [TaskState.Open],
  },
  [TaskType.Recognition]: {
    [TaskState.Draft]: [TaskState.Open, TaskState.BuildReport],
    [TaskState.Open]: [TaskState.BuildReport, TaskState.Cancelled],
    [TaskState.BuildReport]: [
      TaskState.Open,
      TaskState.ReviewReport,
      TaskState.Accepted,
      TaskState.Cancelled,
    ],
    [TaskState.ReviewReport]: [
      TaskState.Open,
      TaskState.BuildReport,
      TaskState.Accepted,
      TaskState.Cancelled,
    ],
    [TaskState.Accepted]: [TaskState.Open, TaskState.InInspection, TaskState.Cancelled],
    [TaskState.InInspection]: [TaskState.Open, TaskState.Close, TaskState.Cancelled],
    [TaskState.Close]: [TaskState.Open],
    [TaskState.Cancelled]: [TaskState.Open],
  },
}

export const TASK_STATE_KIND: Record<TaskState, TaskStateKind> = {
  // Initial
  [TaskState.Draft]: TaskStateKind.Open,
  [TaskState.Open]: TaskStateKind.Open,

  // Intermediate
  [TaskState.Contact]: TaskStateKind.InProgress,
  [TaskState.Visit]: TaskStateKind.InProgress,
  [TaskState.BuildReport]: TaskStateKind.InProgress,
  [TaskState.ReviewReport]: TaskStateKind.InProgress,
  [TaskState.InLaboratory]: TaskStateKind.InProgress,
  [TaskState.InInspection]: TaskStateKind.InProgress,
  [TaskState.Accepted]: TaskStateKind.InProgress,

  // Final
  [TaskState.Close]: TaskStateKind.Finished,
  [TaskState.Cancelled]: TaskStateKind.Finished,
}
