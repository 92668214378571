import { useMemo, useReducer } from 'react'

import { removeNullish } from '../array'
import { Logger } from '../logger'
import { MapReducer, mapReducer } from '../store'

// eslint-disable-next-line @typescript-eslint/ban-types
export type LoaderID = string | number | Function | { code: string }

const logger = new Logger('loading')

/**
 * Store to keep loaders.
 */
export function useLoadingStore() {
  // State

  const [loadersMap, dispatchLoader] = useReducer(mapReducer as MapReducer<string, boolean>, {})

  // Getters

  const loaders = useMemo(
    () =>
      Object.entries(loadersMap)
        .filter(([, value]) => value)
        .map((key) => key),
    [loadersMap],
  )
  /** Whether any loader is active. */
  const isLoading = useMemo(() => loaders.length > 0, [loaders])
  function isLoadingKey(id: LoaderID): boolean {
    return loadersMap[getID(id)] ?? false
  }

  // Actions

  function startLoading(id: LoaderID) {
    dispatchLoader({ type: 'addOne', key: getID(id), value: true })
  }
  function stopLoading(id: LoaderID) {
    dispatchLoader({ type: 'removeOne', key: getID(id) })
  }
  function clearLoaders() {
    dispatchLoader({ type: 'clear' })
  }

  function getID(id: LoaderID): string {
    if (!id) {
      return 'unknown'
    }
    if (typeof id === 'string') {
      return id
    }
    if (typeof id === 'function') {
      if ((id as any).code) {
        return (id as any).code
      }
      if (id.name) {
        return id.name
      }
    }

    return id?.toString()
  }

  const getIdsFromArgs = (args: unknown[]): string[] => {
    return removeNullish(args.map(getIdFromArg))
  }

  const getIdFromArg = (arg: unknown): string | undefined => {
    if (typeof arg === 'string') {
      return arg
    }
    if (arg && typeof arg === 'object' && (arg as any).id && typeof (arg as any).id === 'string') {
      return (arg as any).id
    }
  }

  function operation<TArgs extends any[], TResult>(
    fn: Func<TArgs, Promise<TResult>>,
  ): (...args: TArgs) => Promise<TResult> {
    const id = getID(fn)
    const returnFn = async (...args: TArgs): Promise<TResult> => {
      startLoading(id)
      const argIds = getIdsFromArgs(args)
      for (const argId of argIds) {
        startLoading(argId)
      }

      try {
        return await fn(...args)
      } catch (error) {
        logger.error(error)
        throw error
      } finally {
        stopLoading(id)
        for (const argId of argIds) {
          stopLoading(argId)
        }
      }
    }

    returnFn.code = id
    return returnFn
  }

  return {
    isLoading,
    isLoadingKey,
    loaders,
    startLoading,
    stopLoading,
    clearLoaders,

    operation,
  }
}

export type LoadingStore = ReturnType<typeof useLoadingStore>

type Func<TArgs extends any[], TResult> = (...args: TArgs) => TResult
