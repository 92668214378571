import moment from 'moment'

import { LabelRequest, LabelResponse } from '../../client'
import { Label, LabelParams } from './label.interface'

export function transformLabelResponse(response: LabelResponse): Label {
  return {
    id: response.id,
    name: response.name,
    color: response.color,
    description: response.description,
    createdAt: moment(response.createdAt).toDate(),
    updatedAt: moment(response.updatedAt).toDate(),
  }
}

export function transformToLabelRequest(params: LabelParams): LabelRequest {
  return {
    name: params.name,
    color: params.color,
    description: params.description,
  }
}
