import { FixedRateDto, PorcentualRateDto, RateType } from '../../client'

/**
 * Gets the value out of a rate
 * @param rate
 * @returns value
 */
export function getRateValue(rate?: FixedRateDto | PorcentualRateDto): number {
  if (rate?.type === RateType.Fixed) {
    return Number((rate as FixedRateDto)?.rate?.amount ?? 0)
  }
  if (rate?.type === RateType.Porcentual) {
    return Number((rate as PorcentualRateDto)?.factor ?? 0)
  }

  return 0
}

/**
 * Formats a rate
 * @param rate
 * @returns formatted rate
 */
export function formatRate(rate?: FixedRateDto | PorcentualRateDto): string {
  if (rate?.type == RateType.Fixed) {
    return `${getRateValue(rate).toFixed(2)}€`
  }
  if (rate?.type == RateType.Porcentual) {
    return `${getRateValue(rate)}%`
  }

  return '-'
}
