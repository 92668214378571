import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'
import { createZonesIndex } from './zone.filter'
import { Zone, ZoneParams } from './zone.interface'
import { transformToZoneRequest, transformZoneResponse } from './zones.transformer'

export function useZonesStore(auth: AuthStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const [zonesMap, dispatchZones] = useReducer(entityReducer as EntityReducer<string, Zone>, {})

  // Getters

  const zones = Object.values(zonesMap)
  const getZoneById = (id?: string): Zone | undefined => (id ? zonesMap[id] : undefined)

  const zonesIndex = useMemo(() => createZonesIndex(zones), [zones])

  // Actions

  const getZones = operation(async () => {
    setReady(false)

    try {
      const response = await api.zones.getAllZones()

      dispatchZones({ type: 'addMany', items: response.data.map(transformZoneResponse) })
    } finally {
      setReady(true)
    }
  })

  const createZone = operation(async (params: ZoneParams): Promise<Zone | undefined> => {
    const response = await api.zones.createZone({ zoneRequest: transformToZoneRequest(params) })
    const zone = transformZoneResponse(response.data)
    dispatchZones({ type: 'addOne', item: zone })

    return zone
  })

  const updateZone = operation(
    async (id: string, params: ZoneParams): Promise<Zone | undefined> => {
      const response = await api.zones.updateZone({
        id,
        zoneRequest: transformToZoneRequest(params),
      })
      const zone = transformZoneResponse(response.data)
      dispatchZones({ type: 'addOne', item: zone })

      return zone
    },
  )

  const deleteZone = operation(async (id: string) => {
    await api.zones.deleteZone({ id })

    dispatchZones({ type: 'removeOne', id })
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getZones()
    } else {
      dispatchZones({ type: 'clear' })
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    zones,
    getZoneById,
    zonesIndex,

    getZones,
    createZone,
    updateZone,
    deleteZone,
  }
}

export type ZonesStore = ReturnType<typeof useZonesStore>

export const ZonesStoreContext = createContext<ZonesStore>({
  isLoading: true,
  zones: [],
} as any)

export function getZoneStore() {
  return useContext(ZonesStoreContext)
}
