import Fuse from 'fuse.js'

import { PopulatedTask, TaskFilters, TaskType } from './task.interface'

/**
 * Filters the incoming tasks using the given filters
 * @param tasks
 * @param filters
 */
export function filterTasks(tasks: PopulatedTask[], filters: TaskFilters): PopulatedTask[] {
  return tasks.filter((task) => filterTask(task, filters))
}

export function filterTask(task: PopulatedTask, filters: TaskFilters): boolean {
  // Status
  if (filters.states && !filters.states.includes(task.state)) {
    return false
  }

  // Assigned
  if (filters.isAssigned !== undefined && filters.isAssigned !== !!task.assignedToUserId) {
    return false
  }
  if (
    filters.assigneeIds &&
    (!task.assignedToUserId || !filters.assigneeIds.includes(task.assignedToUserId))
  ) {
    return false
  }

  // Reviewer
  if (filters.hasReviewer !== undefined && filters.hasReviewer !== !!task.reviewerId) {
    return false
  }
  if (filters.reviewerIds && (!task.reviewerId || !filters.reviewerIds.includes(task.reviewerId))) {
    return false
  }

  // Labels
  if (filters.hasLabels !== undefined && filters.hasLabels !== task.labelIds.length > 0) {
    return false
  }
  if (
    filters.labelIds?.length &&
    (task.labelIds.length === 0 || !filters.labelIds.some((id) => task.labelIds.includes(id)))
  ) {
    return false
  }

  // Company
  if (
    filters.hasCompany !== undefined &&
    (task.content.type !== TaskType.Report || filters.hasCompany !== !!task.content.companyId)
  ) {
    return false
  }
  if (
    filters.companyIds &&
    (task.content.type !== TaskType.Report ||
      !task.content.companyId ||
      !filters.companyIds.includes(task.content.companyId))
  ) {
    return false
  }

  // Zone
  if (
    filters.hasZone !== undefined &&
    (task.content.type !== TaskType.Report || filters.hasZone !== !!task.content.zoneId)
  ) {
    return false
  }
  if (
    filters.zoneIds &&
    (task.content.type !== TaskType.Report ||
      !task.content.zoneId ||
      !filters.zoneIds.includes(task.content.zoneId))
  ) {
    return false
  }

  return true
}

/**
 * Creates an index to look for tasks
 * @param tasks
 */
export function createTasksIndex(tasks: PopulatedTask[]) {
  return new Fuse(tasks, {
    keys: [
      { name: 'type', weight: 0.1 },
      { name: 'status', weight: 0.1 },
      { name: 'title', weight: 0.3 },
      { name: 'description', weight: 0.1 },
      { name: 'createdBy.name', weight: 0.05 },
      { name: 'createdBy.email', weight: 0.05 },
      { name: 'assignedTo.name', weight: 0.05 },
      { name: 'assignedTo.email', weight: 0.05 },
      { name: 'company.name', weight: 0.05 },
      { name: 'company.email', weight: 0.05 },
      { name: 'zone.name', weight: 0.05 },
      { name: 'labels.name', weight: 0.05 },
    ],
    threshold: 0.25,
  })
}
