import { createContext, useContext } from 'react'

import { TranslateFunction } from '../../interfaces/translate.interface'
import { AuthStore } from '../auth/auth.store'
import { ClientsStore } from '../clients/client.store'
import { CompaniesStore } from '../companies/company.store'
import { LabelsStore } from '../labels/label.store'
import { PreferencesStore } from '../preferences/preferences.store'
import { MileageRatesStore } from '../rates/rates-mileage.store'
import { ReportRatesStore } from '../rates/rates-report.store'
import { SettingsStore } from '../settings/settings.store'
import { UsersStore } from '../users/user.store'
import { ZonesStore } from '../zones/zone.store'

interface Props {
  readonly settings: SettingsStore
  readonly auth: AuthStore
  readonly preferences: PreferencesStore
  readonly users: UsersStore
  readonly companies: CompaniesStore
  readonly reportRates: ReportRatesStore
  readonly mileageRates: MileageRatesStore
  readonly clients: ClientsStore
  readonly zones: ZonesStore
  readonly labels: LabelsStore
  readonly t: TranslateFunction
}

export function useAppStore({
  settings,
  auth,
  preferences,
  users,
  companies,
  reportRates,
  mileageRates,
  clients,
  zones,
  labels,
  t,
}: Props) {
  const isReady =
    settings.isReady &&
    auth.isReady &&
    preferences.isReady &&
    users.isReady &&
    companies.isReady &&
    reportRates.isReady &&
    mileageRates.isReady &&
    clients.isReady &&
    zones.isReady &&
    labels.isReady

  return {
    isReady,
    settings,
    auth,
    users,
    companies,
    reportRates,
    mileageRates,
    clients,
    zones,
    labels,
    t,
  }
}

export type AppStore = ReturnType<typeof useAppStore>

export const AppStoreContext = createContext<AppStore>({
  isReady: false,
} as any)

export function getAppStore() {
  return useContext(AppStoreContext)
}
