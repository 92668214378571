import {
  CompanyResponse,
  CompanyType,
  CreateCompanyRequest,
  UpdateCompanyRequest,
} from '../../client'
import { Company, CompanyParams } from './company.interface'

export function transformCompanyResponse(response: CompanyResponse): Company {
  return {
    id: response.id,
    type: (response.type ?? CompanyType.Insurance) as any,
    name: response.name,
    email: response.email,
    phone: response.phone,
    hasLogo: !!response.logo?.fileId,
    note: response.note,
    taxName: response.taxName,
    taxNumber: response.taxNumber,
    taxAddress: response.taxAddress,
    bases: response.bases,
    settings: response.settings,
  }
}

export function transformToCreateCompanyRequest(params: CompanyParams): CreateCompanyRequest {
  return {
    name: params.name,
    type: params.type as any,
    email: params.email,
    phone: params.phone,
    note: params.note,
    taxName: params.taxName,
    taxNumber: params.taxNumber,
    taxAddress: params.taxAddress,
    settings: params.settings,
  }
}

export function transformToUpdateCompanyRequest(params: CompanyParams): UpdateCompanyRequest {
  return {
    name: params.name,
    type: params.type as any,
    email: params.email,
    phone: params.phone,
    note: params.note,
    taxName: params.taxName,
    taxNumber: params.taxNumber,
    taxAddress: params.taxAddress,
    settings: params.settings,
  }
}
