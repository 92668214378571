import { ColumnResponse } from '../../client'

export interface WorkspaceColumn extends Omit<ColumnResponse, 'createdAt' | 'updatedAt'> {
  /** The creation date of the column */
  readonly createdAt: Date

  /** The update date of the column */
  readonly updatedAt: Date
}

export interface Workspace {
  /** ID of the workspace. */
  readonly id: string

  /** Title of the workspace. */
  readonly title: string

  /** Color of the workspace */
  readonly color?: string

  /** IDs of the columns attached to the workspace. */
  readonly columns: WorkspaceColumn[]

  /** The creation date of the workspace */
  readonly createdAt: Date

  /** The update date of the workspace */
  readonly updatedAt: Date
}

export type WorkspaceParams = Omit<Workspace, 'id' | 'createdAt' | 'updatedAt' | 'columns'> & {
  columns: WorkspaceColumnParams[]
}

export type WorkspaceColumnParams = Omit<WorkspaceColumn, 'id' | 'createdAt' | 'updatedAt'>

export enum WorkspaceTemplate {
  // Empty
  Custom = 'custom',

  // By a field
  ByType = 'byType',
  ByState = 'byState',
  ByCompany = 'byCompany',
  ByClient = 'byClient',
  ByZone = 'byZone',
  ByAssignee = 'ByAssignee',
  ByLabel = 'ByLabel',
}

export type WorkspaceColumnExtraTasks = Record<string, boolean>
