import moment from 'moment'

import { NotificationResponse } from '../../client'
import { Notification } from './notifications.types'

export function transformNotificationResponse(response: NotificationResponse): Notification {
  return {
    ...response,
    seenAt: response.seenAt ? moment(response.seenAt).toDate() : undefined,
    readAt: response.readAt ? moment(response.readAt).toDate() : undefined,
    createdAt: moment(response.createdAt).toDate(),
    updatedAt: moment(response.updatedAt).toDate(),
  }
}
