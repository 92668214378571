import diacritics from 'diacritics'
import Fuse from 'fuse.js'

import { Zone } from './zone.interface'

/**
 * Creates an index to look for zones
 * @param zones
 */
export function createZonesIndex(zones: Zone[]) {
  return new Fuse(zones, {
    keys: ['name', 'description'],
    threshold: 0.25,
    getFn(object: any, path: string | string[]) {
      path = Array.isArray(path) ? path[0] : path
      const value = object[path]
      if (!value) {
        return ''
      }

      return diacritics.remove(object[path])
    },
  })
}
