import { AxiosResponse } from 'axios'

import {
  AuthApi,
  ClientsApi,
  CompaniesApi,
  Configuration,
  LabelsApi,
  MeApi,
  NotificationsApi,
  PreferencesApi,
  RatesApi,
  RoutesApi,
  TasksApi,
  UsersApi,
  WorkspacesApi,
  ZonesApi,
} from '../client'
import { Config } from '../interfaces/config.interface'
import { generateHttpClient } from './http'

const defaultHttp = generateHttpClient()

const API_CLIENTS = {
  authAPI: AuthApi,
  meAPI: MeApi,
  usersAPI: UsersApi,
  tasksAPI: TasksApi,
  routesAPI: RoutesApi,
  zonesAPI: ZonesApi,
  labelsAPI: LabelsApi,
  companiesAPI: CompaniesApi,
  ratesAPI: RatesApi,
  clientsAPI: ClientsApi,
  workspacesAPI: WorkspacesApi,
  notificationsAPI: NotificationsApi,
  preferencesAPI: PreferencesApi,
}

type Apis = {
  [K in keyof typeof API_CLIENTS]: InstanceType<typeof API_CLIENTS[K]>
}

class ClientsManager {
  protected apis = Object.entries(API_CLIENTS).reduce((result, [name, ApiClass]) => {
    result[name as keyof typeof API_CLIENTS] = new ApiClass(
      undefined,
      undefined,
      defaultHttp,
    ) as any

    return result
  }, {} as Apis)

  /**
   * Initializes the clients using a configuration and JWT.
   * @param config
   * @param jwt
   * @param onUnauthorized fn to call when a request ends in non
   * authenticated response
   */
  init(config: Config, jwt?: string, onUnauthorized?: () => void) {
    const http = generateHttpClient(config)

    if (jwt) {
      http.interceptors.response.use(
        (value: AxiosResponse) => value,
        (error: any) => {
          if (jwt && onUnauthorized && error?.response?.status === 401) {
            onUnauthorized()
          }

          throw error
        },
      )
    }

    const accessToken = jwt

    this.apis = Object.entries(API_CLIENTS).reduce((result, [name, ApiClass]) => {
      result[name as keyof typeof API_CLIENTS] = new ApiClass(
        new Configuration({ accessToken }),
        config?.api.baseUrl,
        http,
      ) as any

      return result
    }, {} as Apis)
  }

  get auth() {
    return this.apis.authAPI
  }

  get users() {
    return this.apis.usersAPI
  }

  get me() {
    return this.apis.meAPI
  }

  get zones() {
    return this.apis.zonesAPI
  }

  get labels() {
    return this.apis.labelsAPI
  }

  get companies() {
    return this.apis.companiesAPI
  }

  get rates() {
    return this.apis.ratesAPI
  }

  get clients() {
    return this.apis.clientsAPI
  }

  get tasks() {
    return this.apis.tasksAPI
  }

  get routes() {
    return this.apis.routesAPI
  }

  get workspaces() {
    return this.apis.workspacesAPI
  }

  get notifications() {
    return this.apis.notificationsAPI
  }

  get preferences() {
    return this.apis.preferencesAPI
  }
}

export const api = new ClientsManager()
