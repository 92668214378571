import axios from 'axios'

import { Config } from '../interfaces/config.interface'

/**
 * Generates an http client with the given configuration
 * @param config
 * @returns http client
 */
export function generateHttpClient(config?: Config) {
  const http = axios.create({
    baseURL: config?.api.baseUrl,
  })

  // TODO - Add interceptors

  return http
}
