import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'
import { createClientsIndex } from './client.filter'
import { Client, ClientParams } from './client.interface'
import {
  transformClientResponse,
  transformToCreateClientRequest,
  transformToUpdateClientRequest,
} from './client.transformer'

export function useClientsStore(auth: AuthStore) {
  // State

  const { isLoading, isLoadingKey, operation } = useLoadingStore()
  const [isReady, setReady] = useState(false)
  const [clientsMap, dispatchClients] = useReducer(
    entityReducer as EntityReducer<string, Client>,
    {},
  )

  // Getters

  const clients = Object.values(clientsMap)
  const getClientById = (id?: string): Client | undefined => (id ? clientsMap[id] : undefined)

  const clientsIndex = useMemo(() => createClientsIndex(clients), [clients])

  // Actions

  const getClients = operation(async () => {
    setReady(false)

    try {
      const response = await api.clients.getAllClients()
      const newClients = response.data.map(transformClientResponse)

      dispatchClients({ type: 'addMany', items: newClients })
    } finally {
      setReady(true)
    }
  })

  const createClient = operation(async (params: ClientParams): Promise<Client | undefined> => {
    if (params.email === '') {
      params.email = undefined
    }

    const response = await api.clients.createClient({
      clientRequest: transformToCreateClientRequest(params),
    })
    const createdClient = transformClientResponse(response.data)
    dispatchClients({ type: 'addOne', item: createdClient })

    return createdClient
  })

  const updateClient = operation(async (id: string, params: ClientParams) => {
    if (params.email === '') {
      params.email = undefined
    }

    const response = await api.clients.updateClient({
      id,
      clientRequest: transformToUpdateClientRequest(params),
    })
    const updatedClient = transformClientResponse(response.data)
    dispatchClients({ type: 'addOne', item: updatedClient })

    return updatedClient
  })

  const deleteClient = async (id: string) => {
    await api.clients.deleteClient({ id })

    dispatchClients({ type: 'removeOne', id })
  }

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getClients()
    } else {
      dispatchClients({ type: 'clear' })
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    clients,
    getClientById,
    clientsIndex,

    getClients,
    createClient,
    updateClient,
    deleteClient,
  }
}

export type ClientsStore = ReturnType<typeof useClientsStore>

export const ClientsStoreContext = createContext<ClientsStore>({
  isReady: false,
  isLoading: true,
  clients: [],
} as any)

export function getClientStore() {
  return useContext(ClientsStoreContext)
}
