import { createContext, useContext, useReducer } from 'react'

import { TaskSortType } from '../../client'
import { MapReducer, mapReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { WorkspaceColumn } from '../workspaces/workspace.interface'
import { filterTask } from './task.filter'
import { PopulatedTask, TaskPageInfo, TaskState, TaskType } from './task.interface'
import { TasksStore } from './task.store'

export enum TaskColumnLoader {
  FetchTasks = 'fetchTasks',
}

export function useTaskColumnStore(tasks: TasksStore) {
  const { isLoading, isLoadingKey, startLoading, stopLoading } = useLoadingStore()

  const [columnTaskIdsMap, dispatchColumnTaskIds] = useReducer(
    mapReducer as MapReducer<string, TaskPageInfo>,
    {},
  )

  const isLoadingColumn = (id: string) =>
    isLoadingKey(id) || getTaskIdsForColumn(id).some(tasks.isLoadingKey)
  const getTaskPageInfoForColumn = (id: string): TaskPageInfo | undefined => columnTaskIdsMap[id]
  const getTaskIdsForColumn = (id: string): string[] => getTaskPageInfoForColumn(id)?.taskIds ?? []
  const getTasksForColumn = (id: string): PopulatedTask[] =>
    getTaskIdsForColumn(id).map(tasks.populateTask).filter(Boolean) as PopulatedTask[]

  const fetchTasks = async (column: WorkspaceColumn, page = 0) => {
    startLoading(TaskColumnLoader.FetchTasks)
    startLoading(column.id)

    try {
      const prevTaskIds = page === 0 ? [] : getTaskIdsForColumn(column.id)
      const pageInfo = await tasks.getTasks(
        page,
        {
          types:
            (column.filters.types?.length ?? 0) > 0
              ? (column.filters.types as unknown as TaskType[])
              : undefined,
          states:
            (column.filters.states?.length ?? 0) > 0
              ? (column.filters.states as unknown as TaskState[])
              : undefined,
          assigneeIds:
            (column.filters.assigneeIds?.length ?? 0) > 0 ? column.filters.assigneeIds : undefined,
          reviewerIds:
            (column.filters.reviewerIds?.length ?? 0) > 0 ? column.filters.reviewerIds : undefined,
          labelIds:
            (column.filters.labelIds?.length ?? 0) > 0 ? column.filters.labelIds : undefined,
          companyIds:
            (column.filters.companyIds?.length ?? 0) > 0 ? column.filters.companyIds : undefined,
          clientIds:
            (column.filters.clientIds?.length ?? 0) > 0 ? column.filters.clientIds : undefined,
          zoneIds: (column.filters.zoneIds?.length ?? 0) > 0 ? column.filters.zoneIds : undefined,
        },
        undefined,
        column.sort.sortBy as unknown as TaskSortType,
        column.sort.sortDir,
      )

      pageInfo.taskIds = [...prevTaskIds, ...pageInfo.taskIds]
      dispatchColumnTaskIds({
        type: 'addOne',
        key: column.id,
        value: pageInfo,
      })
    } finally {
      stopLoading(TaskColumnLoader.FetchTasks)
      stopLoading(column.id)
    }
  }

  /**
   * Updates the list of tasks for a given column, to include/exclude a task
   * that has been modified.
   */
  const updateLocalTask = (column: WorkspaceColumn, task: PopulatedTask) => {
    const shouldAppear = filterTask(task, column.filters as any)
    const pageInfo = getTaskPageInfoForColumn(column.id)
    if (!pageInfo) {
      return
    }

    const taskIds = [...pageInfo.taskIds]
    const isIncluded = taskIds.includes(task.id)

    if (!shouldAppear && isIncluded) {
      taskIds.splice(taskIds.indexOf(task.id), 1)
      dispatchColumnTaskIds({ type: 'addOne', key: column.id, value: { ...pageInfo, taskIds } })
    } else if (shouldAppear && !isIncluded) {
      taskIds.unshift(task.id)
      dispatchColumnTaskIds({ type: 'addOne', key: column.id, value: { ...pageInfo, taskIds } })
    }
  }

  return {
    isLoading,
    isLoadingColumn,
    getTaskPageInfoForColumn,
    getTaskIdsForColumn,
    getTasksForColumn,

    fetchTasks,
    updateLocalTask,
  }
}

export type TaskColumnStore = ReturnType<typeof useTaskColumnStore>

export const TaskColumnStoreContext = createContext<TaskColumnStore>({
  isLoading: true,
} as any)

export function getTaskColumnStore() {
  return useContext(TaskColumnStoreContext)
}
