import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { PaginatedResult } from '../../interfaces/pagination.types'
import { RepositoryStore } from '../../interfaces/repository.types'
import { removeDuplicates } from '../array'
import { Entity } from '../store'
import { useLoadingStore } from './loading.store'

interface Props<TEntity extends Entity<string>> {
  readonly repository: RepositoryStore<TEntity>

  fetchItems: (page: number, take: number) => Promise<PaginatedResult<TEntity>>
}

export function usePaginationStore<TEntity extends Entity<string>>({
  repository,
  fetchItems,
}: Props<TEntity>) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [active, setActive] = useState(false)
  const [ids, setIds] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)

  // Getters

  const items = useMemo(() => repository.itemsByIds(ids), [ids, repository.itemsByIds])

  // Actions

  const fetchPage = operation(async (page: number, newPageSize?: number) => {
    setActive(true)
    setPage(page)
    if (newPageSize) {
      setPageSize(newPageSize)
    }

    const response = await fetchItems(page, newPageSize ?? pageSize)

    setIds(response.items.map((item) => item.id))
    setTotal(response.total)
    repository.addMany(response.items)
  })

  // Adds new ides
  const addIds = useCallback(
    (...newIds: string[]) => {
      setIds((current) => removeDuplicates([...newIds, ...current]))
      setTotal((current) => current + newIds.length)
    },
    [setIds, setTotal],
  )

  // Remove ids
  const removeIds = useCallback(
    (...idsToRemove: string[]) => {
      setIds((current) => current.filter((id) => !idsToRemove.includes(id)))
      setTotal((current) => current - idsToRemove.length)
    },
    [setIds, setTotal],
  )

  // Clears the pagination
  const clear = useCallback(() => {
    if (!active) {
      return
    }

    setIds([])
    setPage(1)
    setTotal(0)
    setActive(false)
  }, [active])

  return {
    isLoading,
    isLoadingKey,
    active,
    items,
    page,
    pageSize,
    total,

    setActive,
    fetchPage,
    addIds,
    removeIds,
    clear,
  }
}

export type PaginationStore = ReturnType<typeof usePaginationStore>

export const PaginationStoreContext = createContext<PaginationStore>({
  isLoading: true,
} as any)

export function getPaginationStore() {
  return useContext(PaginationStoreContext)
}
