/**
 * Converts record of class -> condition into list of classes
 * @param dynamic
 * @param classes
 */
export function classes(dynamic) {
  var classes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.entries(dynamic).filter(function (entry) {
    return entry[1];
  }).map(function (entry) {
    return entry[0];
  }).join(' ').concat(' ').concat(classes);
}