import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'

import { api } from '../../api/clients'
import {
  CompleteRouteRequest,
  CreateRouteRequest,
  RawRouteResponse,
  RouteResponse,
  RouteStatusType,
  UpdateRouteRequest,
  UpdateRouteTaskRequest,
  UpdateRouteTasksRequest,
} from '../../client'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { useRepositoryStore } from '../../utils/stores/repository.store'
import { AuthStore } from '../auth/auth.store'
import { TasksStore } from '../tasks/task.store'
import { TaskWithRoute } from './route.types'

export function useRouteStore(auth: AuthStore, tasks: TasksStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const repository = useRepositoryStore<RouteResponse>()
  const rawDataRepository = useRepositoryStore<RawRouteResponse>()

  // Getters

  // Routes that are in pending status
  const pendingRoutes = useMemo(
    () => repository.items.filter((it) => it.status === RouteStatusType.PendingApproval),
    [repository.items],
  )

  // Gets the tasks with the route info
  const getTasksFromRoute = useCallback(
    (route: RouteResponse): TaskWithRoute[] =>
      route.tasks
        .map((it) => {
          const task = tasks.taskById(it.id)
          if (!task) {
            return
          }

          return {
            ...tasks.populateTask(task),
            routeInfo: it,
          }
        })
        .filter(Boolean) as TaskWithRoute[],
    [tasks.taskById, tasks.populateTask],
  )

  // Actions

  // Fetches the routes that are pending
  const fetchPendingRoutes = operation(async () => {
    const response = await api.routes.searchRoutes({
      status: [RouteStatusType.PendingApproval],
      limit: 100,
    })

    repository.addMany(response.data.items)
  })

  // Fetches a route by its id
  const fetchRoute = operation(async (id: string) => {
    const response = await api.routes.getRoute({ id })
    repository.addOne(response.data)
  })

  // Fetches a route if it's not locally
  const assertRoute = operation(async (id: string) => {
    if (repository.itemById(id)) {
      return
    }

    const response = await api.routes.getRoute({ id })
    repository.addOne(response.data)
  })

  // Fetches the raw route data if is not present
  const assertGoogleMapsId = operation(async (googleMapsId: string) => {
    if (rawDataRepository.itemById(googleMapsId)) {
      return
    }

    const response = await api.routes.getRawRouteById({ id: googleMapsId })
    rawDataRepository.addOne(response.data)
  })

  // Creates a new route
  const createRoute = operation(async (params: CreateRouteRequest) => {
    const response = await api.routes.createRoute({ createRouteRequest: params })
    repository.addOne(response.data)

    return response.data
  })

  // Approves a route
  const approveRoute = operation(async (id: string) => {
    const response = await api.routes.approveRoute({ id })
    repository.addOne(response.data)
  })

  // Updates a route
  const updateRoute = operation(async (id: string, params: UpdateRouteRequest) => {
    const response = await api.routes.updateRoute({ id, updateRouteRequest: params })
    repository.addOne(response.data)
  })

  // Updates a task in a route
  const updateRouteTask = operation(
    async (id: string, taskId: string, params: UpdateRouteTaskRequest) => {
      const response = await api.routes.updateRouteTask({
        id,
        taskId,
        updateRouteTaskRequest: params,
      })
      repository.addOne(response.data)
    },
  )

  // Updates the tasks in a route
  const updateRouteTasks = operation(async (id: string, params: UpdateRouteTasksRequest) => {
    const response = await api.routes.updateRouteTasks({ id, updateRouteTasksRequest: params })
    repository.addOne(response.data)
  })

  // Completes a route
  const completeRoute = operation(async (id: string, params: CompleteRouteRequest) => {
    const response = await api.routes.completeRoute({ id, completeRouteRequest: params })
    repository.addOne(response.data)
  })

  // Cancels a route
  const cancelRoute = operation(async (id: string) => {
    const response = await api.routes.cancelRoute({ id })
    repository.addOne(response.data)
  })

  // Reopens a route
  const reopenRoute = operation(async (id: string) => {
    const response = await api.routes.reopenRoute({ id })
    repository.addOne(response.data)
  })

  // Deletes a route
  const deleteRoute = operation(async (id: string) => {
    await api.routes.deleteRoute({ id })
    repository.removeOne(id)
  })

  // Effects

  useEffect(() => {
    if (!auth.isAuthenticated) {
      repository.clear()
    }

    if (auth.isAuthenticated && auth.canManageRoutes) {
      fetchPendingRoutes()
    }
  }, [auth.isAuthenticated])

  return {
    isLoading,
    isLoadingKey,
    repository,
    routes: repository.items,
    routeById: repository.itemById,
    routesByIds: repository.itemsByIds,
    rawData: rawDataRepository.items,
    rawDataById: rawDataRepository.itemById,
    rawDataByIds: rawDataRepository.itemsByIds,
    pendingRoutes,
    getTasksFromRoute,

    fetchRoute,
    assertRoute,
    createRoute,
    approveRoute,
    assertGoogleMapsId,
    updateRoute,
    updateRouteTask,
    updateRouteTasks,
    completeRoute,
    cancelRoute,
    reopenRoute,
    deleteRoute,
  }
}

export type RouteStore = ReturnType<typeof useRouteStore>

export const RouteStoreContext = createContext<RouteStore>({
  isLoading: true,
} as any)

export function getRouteStore() {
  return useContext(RouteStoreContext)
}
