import {
  AppraisalType,
  ClaimType,
  CompanyType,
  ContractType,
  NotificationType,
  RateStatusType,
  RouteStatusType,
  RouteTaskStatusType,
  TaskNotificationsReceiverPreferenceType,
} from '../../client'
import { TaskState, TaskType } from '../../modules/tasks/task.interface'

export const esTranslations = {
  changesSaved: 'Los cambios se han guardado correctamente.',
  unknownError: 'Ha ocurrido un error, por favor reintenta más tarde.',

  copy: 'Copiar',
  create: 'Crear',
  modify: 'Modificar',
  delete: 'Eliminar',
  remove: 'Quitar',
  clear: 'Restablecer',
  search: 'Buscar',

  continue: 'Continuar',
  confirm: 'Confirmar',
  close: 'Cerrar',
  cancel: 'Cancelar',
  edit: 'Editar',
  quit: 'Salir',
  pass: 'Saltar',
  save: 'Guardar',
  download: 'Descargar',
  submit: 'Enviar',
  yes: 'Sí',
  no: 'No',

  areYouSure: '¿Estás seguro?',
  cannotBeUndone: 'Esta acción no puede deshacerse.',

  warning: '¡Atención!',
  createMore: {
    label: 'Crear otra',
    help: 'Marca esta casilla para crear múltiples elementos a la vez.',
  },

  copiedToClipboard: 'Texto copiado al portapapeles.',

  language: {
    es: 'Castellano',
    en: 'Inglés',
  },

  map: {
    search: 'Buscar',
    sectionTitle: 'Localización',
    coordinates: { label: 'Coordenadas', placeholder: 'Coordenadas en el mapa...' },
    address: { label: 'Dirección', placeholder: 'Dirección en el mapa...' },
    error: {
      emptyCoordinates: 'Las coordenadas son obligatorias',
      invalidCoordinates: 'Las coordenadas son inválidas. Ejemplo: 38.597222,-0.195833',
    },
  },

  form: {
    user: {
      disabled: {
        label: 'Deshabilitado',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Introduce tu e-mail...',
        error: {
          required: 'El e-mail es obligatorio',
          invalid: 'Introduce un e-mail válido',
        },
      },
      name: {
        label: 'Nombre',
        placeholder: 'Introduce tu nombre...',
        error: {
          required: 'El nombre es obligatorio',
        },
      },
      language: {
        label: 'Idioma',
        placeholder: 'Escoge tu idioma',
        error: {
          required: 'El idioma es obligatorio',
        },
      },
      phone: {
        label: 'Teléfono',
        placeholder: 'Introduce tu teléfono...',
        error: {
          invalid: 'El teléfono intorducido es inválido',
        },
      },
      password: {
        label: 'Contraseña',
        placeholder: 'Introduce tu contraseña...',
        error: {
          required: 'La contraseaña es obligatoria',
          invalidLength: 'La contraseña debe tener al menos 8 caracteres.',
          incorrect: 'La contraseña es incorrecta.',
        },
      },
      newPassword: {
        label: 'Nueva contraseña',
        placeholder: 'Introduce tu nueva contraseña...',
        error: {
          required: 'La nueva contraseaña es obligatoria',
        },
      },
      confirmation: {
        label: 'Confirmación',
        placeholder: 'Confirma tu contraseña...',
        error: {
          incorrect: 'Las contraseñas no coinciden',
        },
      },
      contractType: {
        label: 'Tipo de contrato',
        placeholder: 'Elige el tipo de contrato...',
        values: {
          [ContractType.Employee]: 'Empleado',
          [ContractType.Contractor]: 'Autónomo',
        },
      },
    },

    company: {
      name: {
        label: 'Nombre',
        placeholder: 'Introduce el nombre de la empresa...',
        error: {
          required: 'El nombre de la empresa es obligatorio',
        },
      },
      type: {
        label: 'Tipo',
        placeholder: 'Introduce el tipo de la empresa...',
        error: {
          required: 'El tipo de la empresa es obligatorio',
        },
        values: {
          [CompanyType.Insurance]: 'Aseguradora',
          [CompanyType.Laboratory]: 'Laboratorio',
        },
      },
      phone: {
        label: 'Teléfono',
        placeholder: 'Introduce el teléfono de la empresa...',
        error: {
          invalid: 'El teléfono intorducido es inválido',
        },
      },
      email: {
        label: 'E-mail',
        placeholder: 'Introduce el e-mail de la empresa...',
        error: {
          invalid: 'Introduce un e-mail válido',
        },
      },
      logo: {
        label: 'Logo',
        message: {
          uploaded: 'Logo de la empresa actualizado correctamente.',
        },
      },
      taxName: {
        label: 'Nombre fiscal',
        labelShort: 'Nombre',
        placeholder: 'Introduce el nombre fiscal de la empresa...',
        error: {
          required: 'El nombre fiscal de la empresa es obligatorio',
          max: 'El nombre fiscal no pueden superar los 500 caracteres.',
        },
      },
      taxNumber: {
        label: 'Nº fiscal',
        labelShort: 'Nº',
        placeholder: 'Introduce el número fiscal de la empresa...',
        error: {
          required: 'El número fiscal de la empresa es obligatorio',
          max: 'El número fiscal no pueden superar los 500 caracteres.',
        },
      },
      taxAddress: {
        label: 'Dirección fiscal',
        labelShort: 'Dirección',
        placeholder: 'Introduce la dirección fiscal de la empresa...',
        error: {
          required: 'La dirección fiscal de la empresa es obligatoria',
          max: 'La dirección fiscal no pueden superar los 500 caracteres.',
        },
      },
      note: {
        label: 'Notas',
        placeholder: 'Añade notas sobre la empresa...',
        error: {
          max: 'Las notas no pueden superar los 10000 caracteres.',
        },
      },
    },

    client: {
      name: {
        label: 'Nombre',
        placeholder: 'Introduce el nombre del cliente...',
        error: {
          required: 'El nombre del cliente es obligatorio',
        },
      },
      phone: {
        label: 'Teléfono',
        placeholder: 'Introduce el teléfono del cliente...',
        error: {
          invalid: 'El teléfono introducido es inválido',
        },
      },
      email: {
        label: 'E-mail',
        placeholder: 'Introduce el e-mail del cliente...',
        error: {
          invalid: 'Introduce un e-mail válido',
        },
      },
      note: {
        label: 'Notas',
        placeholder: 'Añade notas sobre el cliente...',
        error: {
          max: 'Las notas no pueden superar los 10000 caracteres.',
        },
      },
      taxName: {
        label: 'Nombre fiscal',
        labelShort: 'Nombre',
        placeholder: 'Introduce el nombre fiscal del cliente...',
        error: {
          required: 'El nombre fiscal del cliente es obligatorio',
          max: 'El nombre fiscal no pueden superar los 500 caracteres.',
        },
      },
      taxNumber: {
        label: 'Nº fiscal',
        labelShort: 'Nº',
        placeholder: 'Introduce el número fiscal del cliente...',
        error: {
          required: 'El número fiscal del cliente es obligatorio',
          max: 'El número fiscal no pueden superar los 500 caracteres.',
        },
      },
      taxAddress: {
        label: 'Dirección fiscal',
        labelShort: 'Dirección',
        placeholder: 'Introduce la dirección fiscal del cliente...',
        error: {
          required: 'La dirección fiscal del cliente es obligatoria',
          max: 'La dirección fiscal no pueden superar los 500 caracteres.',
        },
      },
    },

    rate: {
      name: {
        label: 'Nombre',
        placeholder: 'Introduce el nombre de la tarifa...',
        error: {
          required: 'El nombre es obligatorio',
        },
      },
      status: {
        label: 'Estado',
        placeholder: 'Selecciona el estado...',
        error: {
          required: 'El estado es obligatorio',
        },
        values: {
          [RateStatusType.Draft]: 'Borrador',
          [RateStatusType.Active]: 'Activa',
          [RateStatusType.Decommissioned]: 'Retirada',
        },
      },
      description: {
        label: 'Descripción',
        placeholder: 'Introduce la descripción de la tarifa...',
        error: {
          max: 'La descripcioón no puede superar los 1000 caracteres.',
        },
      },
    },

    reportRate: {
      perReport: {
        label: 'Por parte',
        placeholder: 'Introduce la tarifa por parte...',
        error: {
          required: 'La tarifa por parte es obligatoria',
        },
      },
      perReopen: {
        label: 'Por reapertura',
        placeholder: 'Introduce la tarifa por reapertura...',
        error: {
          required: 'La tarifa por reapertura es obligatoria',
        },
      },
    },

    mileageRate: {
      perKm: {
        label: 'Por KM.',
        placeholder: 'Introduce la tarifa por KM....',
        error: {
          required: 'La tarifa por KM. es obligatoria',
        },
      },
    },

    task: {
      type: {
        label: 'Tipo',
        placeholder: 'Introduce el tipo de tarea...',
        filter: 'Filtra por tipo...',
        error: {
          required: 'El tipo de tarea es obligatorio',
        },
        values: {
          [TaskType.Report]: 'Parte',
          [TaskType.Homologation]: 'Homologación',
          [TaskType.Recognition]: 'Ficha reducida',
        },
      },
      state: {
        label: 'Estado',
        filter: 'Filtra por estado...',
      },
      title: {
        label: 'Título',
        placeholder: 'Introduce el título...',
        empty: 'Sin título',
        error: {
          required: 'El título de la tarea es obligatorio',
        },
      },
      description: {
        label: 'Descripción',
        placeholder: 'Escribe una descripción para la tarea...',
        empty: 'Sin descripción',
      },
      deadline: {
        label: 'Deadline',
        placeholder: 'Selecciona el deadline...',
        placeholderFrom: 'Deadline desde',
        placeholderTo: 'Deadline hasta',
        filter: 'Filtra por deadline...',
        empty: 'Sin deadline',
        with: 'Con deadline',
        without: 'Sin deadline',
      },
      assignee: {
        label: 'Experto asignado',
        placeholder: 'Asigna a un exprto...',
        filter: 'Filtra por experto asignado...',
        empty: 'Sin experto asignado',
      },
      reviewer: {
        label: 'Revisor',
        placeholder: 'Asigna a un exprto para revisar la tarea...',
        filter: 'Filtra por revisor...',
        empty: 'Sin revisor',
      },
      labels: {
        label: 'Etiquetas',
        placeholder: 'Añade etiquetas a la tarea...',
        filter: 'Filtra por etiquetas...',
        empty: 'Sin etiquetas',
      },
      reference: {
        label: 'Referencia',
        placeholder: 'Añade la referencia...',
        filter: 'Filtra por la referencia...',
        error: {
          required: 'La referencia es obligatoria',
        },
      },
      content: {
        label: 'Contenido',
        filter: 'Filtra por contenido...',
      },
      company: {
        label: 'Empresa',
        placeholder: 'Selecciona la empresa...',
        filter: 'Filtra por empresas...',
        empty: 'Sin empresa',
        error: {
          required: 'La empresa es obligatoria',
        },
      },
      base: {
        label: 'Base',
        placeholder: 'Selecciona la base de la empresa...',
        filter: 'Filtra por bases...',
        empty: 'Sin base',
        error: {
          required: 'La base es obligatoria',
        },
      },
      client: {
        label: 'Cliente',
        placeholder: 'Selecciona el cliente...',
        filter: 'Filtra por clientes...',
        empty: 'Sin cliente',
        error: {
          required: 'El cliente es obligatorio',
        },
      },
      laboratory: {
        label: 'Laboratorio',
        placeholder: 'Selecciona el laboratorio...',
        error: {
          required: 'El laboratorio es obligatorio',
        },
      },
      zone: {
        label: 'Zona',
        placeholder: 'Selecciona la zona...',
        filter: 'Filtra por zonas...',
        error: {
          required: 'La zona es obligatoria',
        },
      },
      rate: {
        label: 'Tarifa',
        placeholder: 'Selecciona la tarifa del parte...',
        error: {
          required: 'La tarifa es obligatoria',
        },
      },
      appraisalType: {
        type: {
          [AppraisalType.InPerson]: 'Presencial',
          [AppraisalType.Remote]: 'Videoperitación',
        },
        label: 'Tipo de contacto',
        placeholder: 'Selecciona el tipo de contacto',
        empty: 'Sin tipo de contacto',
        error: {
          required: 'El tipo de contacto es obligatorio',
        },
      },
      price: {
        label: 'Precio',
        placeholder: 'Inserta el precio del parte...',
      },
      documents: {
        empty: 'Sin archivos',
      },
      createdAt: {
        label: 'Fecha Creación',
        placeholderFrom: 'Creada desde',
        placeholderTo: 'Creada hasta',
      },
      updatedAt: {
        label: 'Fecha Actualización',
        placeholderFrom: 'Actualizada desde',
        placeholderTo: 'Actualizada hasta',
      },
      closedAt: {
        label: 'Cerrada desde',
        placeholderFrom: 'Cerrada desde',
        placeholderTo: 'Cerrada hasta',
      },
    },

    incidence: {
      title: {
        label: 'Título',
        placeholder: 'Introduce el título...',
        empty: 'Sin título',
        error: {
          required: 'El título de la tarea es obligatorio',
        },
      },
      description: {
        label: 'Descripción',
        placeholder: 'Escribe una descripción para la tarea...',
        empty: 'Sin descripción',
      },
      deadline: {
        label: 'Deadline',
        placeholder: 'Selecciona el deadline...',
        empty: 'Sin deadline',
      },
      solved: {
        label: 'Solucionada',
      },
    },

    route: {
      title: { label: 'Título', placeholder: 'Introduce un título para la ruta...' },
      notes: {
        label: 'Notas',
        placeholder: 'Añade notas a la ruta...',
      },
      status: { label: 'Estado', filter: 'Filtra por el estado...' },
      date: {
        label: 'Fecha',
        placeholder: 'Introduce la fecha de la ruta...',
        filter: 'Filtra por la fecha de la ruta...',
      },
      assignee: {
        label: 'Experto asignado',
        placeholder: 'Elige el experto asignado...',
        filter: 'Filtra por el experto asignado...',
      },
      rate: {
        label: 'Tarifa',
        placeholder: 'Selecciona la tarifa...',
        error: {
          required: 'La tarifa es obligatoria',
        },
      },
      tasks: {
        status: { label: 'Estado' },
        notes: {
          label: 'Nota',
          placeholder: 'Añade notas para explicar el estado de la visita...',
        },
      },

      preferences: {
        defaultReportRate: {
          label: 'Tarifa para los partes por defecto',
          placeholder: 'Selecciona la tarifa para los partes por defecto...',
          error: {
            required: 'La tarifa para los partes por defecto es obligatoria',
          },
        },
        defaultMileageRate: {
          label: 'Tarifa por kilometraje por defecto',
          placeholder: 'Selecciona la tarifa por kilometraje por defecto...',
          error: {
            required: 'La tarifa por kilometraje por defecto es obligatoria',
          },
        },
        billingDay: {
          label: 'Día de facturación',
          placeholder: '1, 2, 3... 31',
          error: {
            required: 'El día de facturación es obligatorio',
          },
        },
      },
    },

    carDetails: {
      brand: {
        label: 'Marca',
        placeholder: 'Introduce la marca...',
        empty: 'Sin marca',
      },
      model: {
        label: 'Modelo',
        placeholder: 'Introduce el modelo...',
        empty: 'Sin modelo',
      },
      commercialName: {
        label: 'Nombre comercial',
        placeholder: 'Introduce el nombre comercial...',
        empty: 'Sin nombre comercial',
      },
      variant: {
        label: 'Variante',
        placeholder: 'Introduce la variante...',
        empty: 'Sin variante',
      },
      plateNumber: {
        label: 'Matrícula',
        placeholder: 'Introduce la matrícula...',
        empty: 'Sin matrícula',
      },
      chassisNumber: {
        label: 'Número de bastidor',
        placeholder: 'Introduce el número de bastidor...',
        empty: 'Sin número de bastidor',
      },
    },

    workspace: {
      title: {
        label: 'Título',
        placeholder: 'Introduce el título...',
        error: {
          required: 'El título es obligatorio',
        },
      },
      color: {
        label: 'Color',
      },
    },

    column: {
      title: {
        label: 'Título',
        placeholder: 'Introduce el título...',
        error: {
          required: 'El título es obligatorio',
        },
      },
      color: {
        label: 'Color',
      },
      types: {
        label: 'Tipos',
        placeholder: 'Filtra por tipos...',
      },
      states: {
        label: 'Estados',
        placeholder: 'Filtra por estados...',
      },
      assignees: {
        label: 'Experto asignado',
        placeholder: 'Filtra por expertos...',
      },
      reviewers: {
        label: 'Revisores asignados',
        placeholder: 'Filtra por revisores...',
      },
      labels: {
        label: 'Etiquetas',
        placeholder: 'Filtra por etiquetas...',
      },
      companies: {
        label: 'Empresas',
        placeholder: 'Filtra por empresas...',
      },
      clients: {
        label: 'Clientes',
        placeholder: 'Filtra por clientes...',
      },
      zones: {
        label: 'Zonas',
        placeholder: 'Filtra por zonas...',
      },

      sortBy: {
        title: 'Orden',
        label: 'Campo',
        placeholder: 'Elige el campo por el que ordenar...',
        state: 'Estado',
        deadline: 'Deadline',
        createdAt: 'Fecha creación',
        error: {
          required: 'El campo es obligatorio...',
        },
      },
      sortDir: {
        label: 'Dirección',
        asc: 'Ascendente',
        desc: 'Descendente',
        error: {
          required: 'La dirección es obligatoria...',
        },
      },
    },
  },

  auth: {
    login: {
      title: 'Conectarse',
      subtitle: 'Conéctate a tu perfil',
      submit: 'Entrar',
    },

    forgotPassword: {
      title: 'Recordar contraseña',
      cta: 'Recordar contraseña',
      alert: 'Intrudce tu correo y te mandaremos las instrucciones para recupear tu contraseña.',
      submit: 'Enviar',
      success: 'Mensaje enviado correctamente. ¡Comprueba tu correo!',
    },

    recover: {
      title: 'Recuperar contraseña',
      change: 'Cambiar contraseña',

      email: 'E-mail',
      password: 'Contraseña',
      confirmation: 'Confirmación',
      submit: 'Cambiar contraseña',
      success: 'Contraseña cambaida correctamente.',

      errors: {
        confirmationMatch: 'Las contraseñas no coinciden',
      },
    },
  },

  // Sections
  work: {
    new: {
      title: 'Nuevo espacio de trabajo',
      subtitle: '',
      submit: 'Crear espacio de trabajo',

      custom: {
        title: 'Personalizado',
        subtitle: 'Crea un espacio de trabajo desde cero.',
        create: 'Personalizado',
      },

      template: {
        title: 'Plantilla',
        subtitle: 'Crea un espacio de trabajo a partir de una plantilla',

        byType: {
          title: 'Por tipo',
        },
        byState: {
          title: 'Por estado',
        },
        byAssigned: {
          title: 'Por asignado',
        },
        byCompany: {
          title: 'Por empresa',
        },
        byClient: {
          title: 'Por cliente',
        },
        byZone: {
          title: 'Por zona',
        },
        byLabel: {
          title: 'Por etiqueta',
        },
      },
    },

    settings: {
      filters: 'Filtros',
    },
  },

  column: {
    new: 'Nueva columna',
    created: 'Columna creada correctamente.',
    updated: 'Columna actualizada correctamente.',
  },

  tasksList: {
    title: 'Tareas',
    filter: 'Filtrar referencia...',
    settings: 'Configurar filtros',
    add: 'Añadir tarea',

    changeOrder: 'Reordenar filtros',
    columnsSettings: 'Gestiona las columnas visibles',
    noColumnsError: 'Debes seleccionar al menos una columna',

    filtersSettings: 'Gestiona los filtros activos',
    filtersTitlePlaceholder: 'Introduce el nombre del filtro...',

    columns: {
      id: 'ID',
      type: 'Tipo',
      state: 'Estado',
      reference: 'Referencia',
      title: 'Título',
      description: 'Descripción',
      creator: 'Creador',
      assignee: 'Asignado',
      reviewer: 'Revisor',
      company: 'Empresa',
      labels: 'Etiquetas',
      documents: 'Documentos',
      comments: 'Comentarios',
      incidences: 'Incidencias',
      zone: 'Zona',
      deadline: 'Deadline',
      details: 'Detalles',
      plateNumber: 'Matrícula',
      chassisNumber: 'N. Bastidor',
      client: 'Cliente',
      createdAt: 'Creada',
      updatedAt: 'Actualizada',
      closedAt: 'Cerrada',
    },

    documentsTooltip:
      'Documentos: {{numDocuments}}. Fotos: {{numPhotos}}. Informes: {{numReports}}',
    commentsTooltip: '{{numComments}} comentario(s)',
  },

  tasks: {
    title: 'Tareas',
    filter: 'Filtrar tareas...',
    new: 'Nueva tarea',
    open: 'Abrir tarea {{reference}}',

    filters: 'Filtros',
    created: 'Tarea creada correctamente.',
    updated: 'Tarea actualizada correctamente.',
    newComment: 'Cometario añadido correctamente.',

    newIncidence: 'Incidencia añadida correctamente.',
    incidenceUpdated: 'Incidencia actualizada correctamente.',
    incidenceDeleted: 'Incidencia borrada correctamente.',

    references: {
      new: 'Nueva referencia',
      newHelp: 'No hay otras tareas que usen esta referencia.',
      existingHelp: 'Existe otra tarea con esta referencia. Pincha para ver esa tarea.',
    },

    state: {
      [TaskState.Draft]: 'Borrador',
      [TaskState.Open]: 'Abierta',
      [TaskState.Contact]: 'Pendiente contacto',
      [TaskState.Visit]: 'Pendiente visita',
      [TaskState.BuildReport]: 'Preparar informe',
      [TaskState.ReviewReport]: 'Revisar informe',
      [TaskState.InLaboratory]: 'Laboratorio',
      [TaskState.InInspection]: 'Inspección',
      [TaskState.Accepted]: 'Aprobada',
      [TaskState.Close]: 'Terminada',
      [TaskState.Cancelled]: 'Cancelada',
    },

    changeState: {
      message: '¿Quieres cambiar el estado a {{state}}?',
      deadlineAlert:
        'La empresa tiene un deadline asociado a este estado. El deadline de la tarea se actualizará al cambiar a este estado.',

      error: {
        requirePrice: 'Debes establecer el precio del parte',
        requireAssignee: 'Debes asignar la tarea a un experto',
      },
    },

    sections: {
      general: 'General',
      report: 'Parte',
      homologation: 'Homologación',
      recognition: 'Ficha reducida',
      versions: 'Versionado',
      comments: 'Comentarios',
      incidences: 'Incidencias',
      activity: 'Actividad',
      documents: 'Documentación',
      photos: 'Fotos',
      reports: 'Informes',
    },

    multiple: {
      delete: {
        title: 'Borrar',
      },
      labels: {
        title: 'Cambiar etiquetas',
        cta: 'Cambiar',
      },
      state: {
        label: 'Cambiar estado',
        title: 'Cambiar el estado de {{ quantity }} tareas a {{ state }}',
        cta: 'Cambiar el estado de {{ quantity }} tareas',
        info: 'Estás a punto de cambiar el estado de <1>{{ quantity }}</1> tareas al nuevo estado <2>{{ state }}</2>.',
        warning:
          'Hay tareas que no pueden cambiar al estado que has seleccionado. Solo se modificarán las tareas que sí pueden cambiar a dicho estado.',
        success: 'Las tareas se han actualizado correctamente.',
        error: 'No se han podido actualizar todas las tareas.',
      },
    },
  },

  task: {
    form: {
      submit: 'Guardar',
    },

    reopen: 'Esta tarea ha sido cerrada previamente.',
    reopenLabel: 'Reapertura',

    version: 'Versión',
    versionNumber: 'Versión #{{version}}',
    createdAt: 'Creada',
    updatedAt: 'Actualizada',
    closedAt: 'Cerrada',
    invoices: 'Facturas',
    versions: {
      invoice: {
        confirmCheck: '¿Quieres marcar la factura para la versión {{version}} como pagada?',
        confirmUncheck: '¿Quieres marcar la factura para la versión {{version}} como pendiente?',
        collected: 'Cobrado',
        pending: 'Pendiente',
      },
    },

    progress: {
      assignee: 'Experto asignado',
      created: 'Tarea creada',
      deleted: 'Tarea borrada',
      newState: 'Nuevo estado',
    },

    documents: {
      title: 'Documentos',
      addSuccess: 'Documento añadido correctamente',
      deleteSuccess: 'Documento borrado correctamente.',
    },

    comments: {
      add: 'Añadir comentario',
      text: {
        placeholder: 'Añade un comentario...',
        error: {
          required: 'El comentario no puede estar vacío',
        },
      },
    },
  },

  incidences: {
    title: 'Incidencias pendientes',
    tooltip: '{{ count }} incidencias pendientes',
  },

  route: {
    filters: 'Filtros',
    form: 'Detalles de ruta',
    pendingTooltip: '{{ count }} rutas pendientes',
    status: {
      types: {
        [RouteStatusType.PendingApproval]: 'Pendiente de aprobación',
        [RouteStatusType.Ready]: 'En progreso',
        [RouteStatusType.Completed]: 'Completada',
        [RouteStatusType.Cancelled]: 'Cancelado',
      },
    },

    summary: {
      title: 'Resumen de la ruta',
      expertDistance: 'Distancia para el experto',
      companiesDistance: 'Distancia para las empresas',

      profitable: {
        message: 'La ruta es rentable.',
        description:
          'La distancia facturable para las empresas es mayor que la distancia para el experto asginado.',
      },
      notProfitable: {
        message: 'La ruta podría no ser rentable.',
        description:
          'La distancia facturable para las empresas es menor que la distancia para el experto asginado. Revisa que las tareas sean las adecuadas y que el orden sea el correcto.',
      },
      finalDistanceWarning: {
        notProfitable:
          'Esta ruta ha requerido más kilómetros de los estimados.\nEs posible que la ruta sea revisada. Añade las notas que consideres oportunas para explicar el motivo.',
      },
    },

    maps: {
      title: 'Mapas de la ruta',
      expert: 'Experto',
      distance: 'Distancia',
      finalDistance: 'Distancia recorrida',
      duration: 'Duración',
      open: 'Abrir ruta',
      copy: 'Copiar ruta',
    },

    tasks: {
      status: {
        label: 'Estado',
        types: {
          [RouteTaskStatusType.Pending]: {
            title: 'Pendiente',
            description: 'La visita no se ha realizado aún',
          },
          [RouteTaskStatusType.Cancelled]: {
            title: 'Cancelado',
            description: 'El asegurado ha cancelado la cita',
          },
          [RouteTaskStatusType.Failed]: {
            title: 'Fallado',
            description: 'El asegurado no se ha presentado',
          },
          [RouteTaskStatusType.Visited]: {
            title: 'Visitado',
            description: 'Visita realizada con éxito',
          },
        },
      },
      notes: 'Notas',
      actions: 'Acciones',
      changeStatus: 'Marcar como...',
      rearrange: {
        cta: 'Modificar tareas',
        title: 'Modificar tareas',
        addMore: 'Añadir más tareas',
        addMoreReassign:
          'No puedes añadir las tareas seleccionadas porque no están asignadas al experto que va a realizar la ruta.\nPor favor, reasigna las tareas al experto del a ruta usando el botón en la tabla.',
        reassign: 'Reasignar',
        help: 'Ordena las tareas de la ruta.',
        helpDescription:
          'Ten en cuenta que el orden que elijas se utilizará para generar la ruta en coche.',
      },

      addNote: {
        cta: 'Añadir nota',
        title: 'Añadir nota',
        template: 'Nota en ruta "{{- routeTitle }}" el {{- date }}:\n\n{{ note }}',
      },
    },
    approve: 'Aprobar ruta',
    approveTip: 'Esta ruta tiene que ser aprobada por un administrador.',
    restore: 'Restaurar ruta',
    needsApproval: {
      message: 'Esta ruta está pendiente de aprobadación.',
      description: 'La ruta debe ser aceptada por un administrador antes de poder ser iniciada.',
      descriptionAdmin: 'Comprueba que todos los datos son correctos y apruébala usando el botón.',
    },

    cancelled: {
      message: 'Esta ruta ha sido cancelada.',
      description: 'Mira las notas o consulta con un administrador para saber más.',
      descriptionAdmin: 'El experto asignado no podrá realizar esta ruta.',
    },

    complete: {
      cta: 'Completar ruta',
      title: 'Completar ruta',
      message: 'Por favor introduce la distancia recorrida.',
      description: 'Ten en cuenta que la distancia no podrá ser modificada más adelante.',
    },

    create: {
      title: 'Crear nueva ruta',
      selectTasks: 'Selecciona las tareas de la ruta',
      addMore: 'Añadir más tareas',
    },
  },

  settings: {
    users: {
      title: 'Usuarios',
      filter: 'Filtrar usuarios...',
      add: 'Añadir usuario',
      deleteConfirmation: '¿Seguro que quieres borrar este usuario?',
      deleteMany: {
        title: 'Borrar {{quantity}} usuarios',
        warning:
          'Estás a punto de borrar los siguientes usuarios, esta acción no puede deshacerse:',
        success: 'Los usuarios se han borrado correctamente.',
        error: 'No se han podido borrar todos los usuarios.',
      },

      roles: {
        user: 'Usuario',
        admin: 'Admin',
        god: '> root',
      },
      claims: {
        [ClaimType.ManageUsers]: {
          label: 'Usuarios',
          tip: 'Permite gestionar otros usuarios (crear, editar, borrar).',
        },
        [ClaimType.ManageSettings]: {
          label: 'Configuración',
          tip: 'Permite gestionar la configuración del sistema (etiqutas, zonas, empresas, tarifas y clientes).',
        },
        [ClaimType.ManageTasks]: {
          label: 'Gestionar Partes',
          tip: 'Permite gestionar partes (crear, modificar, asignar).',
        },
        [ClaimType.SeeAllTasks]: {
          label: 'Ver todos los partes',
          tip: 'Permite ver todos los partes.',
        },
        [ClaimType.ManageFinances]: {
          label: 'Facturas',
          tip: 'Permite marcar versones de partes como cobrados.',
        },
      },

      form: {
        create: 'Crear usuario',
        edit: 'Editar usuario',

        name: 'Nombre',
        namePlaceholder: 'Nombre del usuario...',
        avatar: 'Avatar',
        email: 'E-mail',
        emailPlaceholder: 'E-mail del usuario...',
        phone: 'Teléfono',
        phonePlaceholder: 'Teléfono del usuario...',
        language: 'Idioma',
        role: 'Rol',
        admin: 'Admin',
        claims: 'Permisos',
        claimsInfo: 'Gestiona los las secciones a las que el usuario tiene acceso.',

        submit: 'Guardar',

        updated: 'Usuario actualizado correctamente.',
        created: 'Usuario creado correctamente.',
        avatarUpdated: 'Avatar actualizado correctamente.',
      },
    },

    labels: {
      title: 'Etiquetas',
      filter: 'Filtrar etiquetas...',
      add: 'Añadir etiqueta',
      deleteConfirmation: '¿Seguro que quieres borrar esta etiqueta?',
      deleteMany: {
        title: 'Borrar {{quantity}} etiquetas',
        warning:
          'Estás a punto de borrar las siguientes etiquetas, esta acción no puede deshacerse:',
        success: 'Las etiquetas se han borrado correctamente.',
        error: 'No se han podido borrar todas las etiquetas.',
      },

      form: {
        create: 'Crear etiqueta',
        edit: 'Editar etiqueta',

        name: 'Nombre',
        namePlaceholder: 'Nombre de la etiqueta...',
        nameError: 'El nombre de la etiqueta es obligatorio.',
        color: 'Color',
        colorPlaceholder: 'Color de la etiqueta...',
        description: 'Descripción',
        descriptionPlaceholder: 'Descripción de la etiqueta...',

        submit: 'Guardar',

        updated: 'Etiqueta actualizada correctamente.',
        created: 'Etiqueta creada correctamente.',
      },
    },

    zones: {
      title: 'Zonas',
      filter: 'Filtrar zones...',
      add: 'Añadir zona',
      deleteConfirmation: '¿Seguro que quieres borrar esta zona?',
      deleteMany: {
        title: 'Borrar {{quantity}} zonas',
        warning: 'Estás a punto de borrar las siguientes zonas, esta acción no puede deshacerse:',
        success: 'Las zonas se han borrado correctamente.',
        error: 'No se han podido borrar todas las zonas.',
      },

      form: {
        create: 'Crear zona',
        edit: 'Editar zona',

        name: 'Nombre',
        namePlaceholder: 'Nombre de la zona...',
        nameError: 'El nombre de la zona es obligatorio.',
        description: 'Descripción',
        descriptionPlaceholder: 'Descripción de la zona...',
        coordinates: { label: 'Coordenadas', placeholder: 'Coordenadas en el mapa...' },

        submit: 'Guardar',

        updated: 'Zona actualizada correctamente.',
        created: 'Zona creada correctamente.',
      },
    },

    companies: {
      title: 'Empresas',
      filter: 'Filtrar empresas...',
      add: 'Añadir empresa',

      deleteConfirmation: '¿Seguro que quieres borrar esta empresa?',
      deleteMany: {
        title: 'Borrar {{quantity}} empresas',
        warning:
          'Estás a punto de borrar las siguientes empresas, esta acción no puede deshacerse:',
        success: 'Las empresas se han borrado correctamente.',
        error: 'No se han podido borrar todas las empresas.',
      },

      tax: 'Informacion fiscal',
      taxInfoCopied: 'Información fiscal copiada correctamente',

      form: {
        create: 'Crear empresa',
        edit: 'Editar empresa',

        bases: {
          title: 'Bases',
          info: 'Gestiona las bases ',

          add: 'Añadir nueva base',
          new: 'Nueva base',
        },

        settings: {
          deadlines: {
            add: 'Añadir deadline',

            unit: {
              hour: 'horas',
              day: 'días',
              week: 'semanas',
              month: 'meses',
            },

            info: 'Gestiona el deadline para cada uno de los estados en los que puede pasar un parte hecho para esta compañía',
            invalid:
              'Los deadlines son inválidos. No pueden haber dos deadlines para el mismo estado.',
          },
        },

        submit: 'Guardar',

        created: 'Empresa creada correctamente.',
        updated: 'Empresa actualizada correctamente.',
      },
    },

    reportRates: {
      title: 'Tarifas para los partes',
      filter: 'Filtrar tarifas...',
      add: 'Añadir tarifa',
      unknown: 'Tarifa desconocida',

      deleteConfirmation: '¿Seguro que quieres borrar esta tarifa?',
      deleteMany: {
        title: 'Borrar {{quantity}} tarifas',
        warning: 'Estás a punto de borrar las siguientes tarifas, esta acción no puede deshacerse:',
        success: 'Las tarfias se han borrado correctamente.',
        error: 'No se han podido borrar todas las tarifas.',
      },
      form: {
        create: 'Crear tarfia',
        edit: 'Editar tarfia',
        submit: 'Guardar',
        created: 'Tarfia creada correctamente.',
        updated: 'Tarfia actualizada correctamente.',
      },
    },

    mileageRates: {
      title: 'Tarifas por kilometraje',
      filter: 'Filtrar tarifas...',
      add: 'Añadir tarifa',
      unknown: 'Tarifa desconocida',

      deleteConfirmation: '¿Seguro que quieres borrar esta tarifa?',
      deleteMany: {
        title: 'Borrar {{quantity}} tarifas',
        warning: 'Estás a punto de borrar las siguientes tarifas, esta acción no puede deshacerse:',
        success: 'Las tarfias se han borrado correctamente.',
        error: 'No se han podido borrar todas las tarifas.',
      },
      form: {
        create: 'Crear tarfia',
        edit: 'Editar tarfia',
        submit: 'Guardar',
        created: 'Tarfia creada correctamente.',
        updated: 'Tarfia actualizada correctamente.',
      },
    },

    clients: {
      title: 'Clientes',
      filter: 'Filtrar clientes...',
      add: 'Añadir cliente',

      deleteConfirmation: '¿Seguro que quieres borrar esta cliente?',
      deleteMany: {
        title: 'Borrar {{quantity}} clientes',
        warning:
          'Estás a punto de borrar las siguientes clientes, esta acción no puede deshacerse:',
        success: 'Las clientes se han borrado correctamente.',
        error: 'No se han podido borrar todas las clientes.',
      },

      tax: 'Informacion fiscal',
      taxInfoCopied: 'Información fiscal copiada correctamente',

      form: {
        create: 'Crear cliente',
        edit: 'Editar empresa',

        submit: 'Guardar',

        created: 'Cliente creado correctamente.',
        updated: 'Cliente actualizado correctamente.',
      },
    },

    preferences: {
      title: 'Preferencias',
      general: 'General',
      submit: 'Guardar',
      success: 'Preferencias guardadas correctamente',
    },

    task: {
      deleteMany: {
        title: 'Borrar {{quantity}} tareas',
        warning: 'Estás a punto de borrar las siguientes tareas, esta acción no puede deshacerse:',
        success: 'Las tareas se han borrado correctamente.',
        error: 'No se han podido borrar todas las tareas.',
      },
    },
  },

  userProfile: {
    title: 'Perfil de usuario',

    update: {
      title: 'Cambiar datos',
      submit: 'Actualizar datos',
      success: 'Los datos de tu usuario se han actualizado correctamente.',
    },

    notifications: {
      title: 'Notificaciones',
    },

    changePassword: {
      title: 'Cambiar contraseña',
      submit: 'Actualizar contraseña',
      success: 'La contraseña se ha actualizado correctamente.',
    },
  },

  nav: {
    home: 'Home',

    // Work pages
    work: 'Trabajo',
    workNew: 'Nuevo',

    // Tasks pages
    tasks: 'Tareas',
    tasksList: 'Lista',
    tasksColumns: 'Columnas',
    tasksCalendar: 'Calendario',
    tasksAssignees: 'Asignación',
    routes: 'Rutas',
    incidences: 'Incidencias',

    invoices: 'Facturas',

    settings: 'Configuración',
    // Settings pages
    users: 'Usuarios',
    labels: 'Etiquetas',
    zones: 'Zonas',
    companies: 'Empresas',
    insurances: 'Aseguradoras',
    laboratories: 'Laboratorios',
    rates: 'Tarifas',
    reportRates: 'Partes',
    mileageRates: 'Kilometraje',
    clients: 'Clientes',
    preferences: 'Preferencias',

    profile: 'Perfil',
    logout: 'Desconectar',
  },

  notifications: {
    label: 'Notificaciones',
    tooltip: '{{ count }} notificaciones pendientes',
    read: 'Leída',
    empty: 'No tienes notificationces',
    markAllAsRead: 'Marcar todas como leídas',
    deleteAll: 'Borrar todas',

    receiver: {
      [TaskNotificationsReceiverPreferenceType.Always]: 'Siempre',
      [TaskNotificationsReceiverPreferenceType.IfAssignee]: 'Mis tareas',
      [TaskNotificationsReceiverPreferenceType.IfReviewer]: 'Tareas que reviso',
      [TaskNotificationsReceiverPreferenceType.Never]: 'Nunca',
    },

    [NotificationType.TaskAssigned]: {
      title: 'Te han asignado una tarea',
      details: '<1>{{ author }}</1> te ha asignado la tarea <2>{{ task }}</2>.',
    },
    [NotificationType.TaskReviewAssigned]: {
      title: 'Eres el revisor de una tarea',
      details: '<1>{{ author }}</1> te ha asignado como revisor de la tarea <2>{{ task }}</2>',
    },
    [NotificationType.TaskDocumentAdded]: {
      title: 'Nuevo documento',
      details: '<1>{{ author }}</1> ha añadido un nuevo documento a la tarea <2>{{ task }}</2>',
    },
    [NotificationType.TaskIncidenceAdded]: {
      title: 'Nueva incidencia',
      details: '<1>{{ author }}</1> ha añadido una nueva incidencia a la tarea <2>{{ task }}</2>',
    },
    [NotificationType.TaskCommentAdded]: {
      title: 'Nuevo comentario',
      details: '<1>{{ author }}</1> ha comentado la tarea <2>{{ task }}</2>',
    },
  },

  confirmClose: {
    title: '¡Atención!',
    description:
      'Si cierras el panel, los cambios que no hayas guardado se perderán. ¿Seguro que quieres continuar?',
    discard: 'Decartar cambios',
  },

  errors: {
    403: 'No tienes acceso a esta sección',
    404: 'La sección que buscas no está disponible',
    500: 'Oh no, ha ocurrido un error inesperado',
    sessionExpired: 'La sesión ha expirado, por favor conectate de nuevo.',

    reload: 'Recargar',
    goBack: 'Volver al inicio',
  },
}

export default esTranslations
