import { useEffect, useState } from 'react';
export function useKeyPress(targetKey, ref) {
  var _ref$current;

  var target = (_ref$current = ref === null || ref === void 0 ? void 0 : ref.current) !== null && _ref$current !== void 0 ? _ref$current : window;

  var _useState = useState(false),
      keyPressed = _useState[0],
      setKeyPressed = _useState[1];

  function downHandler(_ref) {
    var key = _ref.key;

    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  var upHandler = function upHandler(_ref2) {
    var key = _ref2.key;

    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(function () {
    target === null || target === void 0 ? void 0 : target.addEventListener('keydown', downHandler);
    target === null || target === void 0 ? void 0 : target.addEventListener('keyup', upHandler);
    return function () {
      target === null || target === void 0 ? void 0 : target.removeEventListener('keydown', downHandler);
      target === null || target === void 0 ? void 0 : target.removeEventListener('keyup', upHandler);
    };
  });
  return keyPressed;
}