import moment from 'moment'
import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { UpdateOperationType } from '../../client'
import { Logger } from '../../utils/logger'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'
import { TasksStore } from '../tasks/task.store'
import { transformNotificationResponse } from './notification.transformer'
import { Notification } from './notifications.types'

export enum NotificationLoader {
  AddNotification = 'addNotification',
  FetchNotifications = 'fetchNotifications',
  MarkNotificationsAsSeen = 'markNotificationsAsSeen',
  MarkAllNotificationsAsRead = 'markAllNotificationsAsRead',
  SetActiveNotification = 'setActiveNotification',
  DeleteAllNotifications = 'deleteAllNotifications',
}

const logger = new Logger('notification.store')

export function useNotificationStore(auth: AuthStore, tasks: TasksStore) {
  const { isLoading, isLoadingKey, startLoading, stopLoading } = useLoadingStore()

  // State

  const [notificationsMap, dispatchNotifications] = useReducer(
    entityReducer as EntityReducer<string, Notification>,
    {},
  )
  const [activeNotificationId, setActiveNotificationId] = useState<string>()

  // Getters

  const notifications = useMemo<Notification[]>(
    () =>
      Object.values(notificationsMap).sort((notificationA, notificationB) =>
        moment(notificationA.createdAt).isSameOrBefore(moment(notificationB.createdAt)) ? 1 : -1,
      ),
    [notificationsMap],
  )
  const unreadNotifications = useMemo(
    () => notifications.filter((notification) => !notification.isRead).length,
    [notifications],
  )

  function notificationById(id?: string): Notification | undefined {
    return id ? notificationsMap[id] : undefined
  }
  const activeNotification = useMemo(
    () => notificationById(activeNotificationId),
    [notifications, activeNotificationId],
  )
  const activeTask = useMemo(
    () => tasks.getTaskById(activeNotification?.content.task),
    [activeNotification],
  )

  // Actions

  const addNotification = async (notification: Notification) => {
    startLoading(NotificationLoader.AddNotification)
    startLoading(notification.id)

    try {
      await tasks.getTaskByIds([notification.content.task])
      dispatchNotifications({ type: 'addOne', item: notification })
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(NotificationLoader.AddNotification)
      stopLoading(notification.id)
    }
  }

  const fetchNotifications = async () => {
    startLoading(NotificationLoader.FetchNotifications)

    try {
      const response = await api.notifications.getNotifications()
      const transformedNotifications = response.data.map(transformNotificationResponse)

      const newNotifications = transformedNotifications.filter(
        (notification) => !notificationById(notification.id),
      )
      const taskIds = newNotifications.map((notification) => notification.content.task)
      const uniqueTaskIds = new Set(taskIds)
      if (taskIds.length > 0) {
        await tasks.getTaskByIds(Array.from(uniqueTaskIds))
      }

      dispatchNotifications({ type: 'clear' })
      dispatchNotifications({ type: 'addMany', items: transformedNotifications })
    } catch (error) {
      logger.error(error)
      throw error
    }

    stopLoading(NotificationLoader.FetchNotifications)
  }

  const markNotificationsAsSeen = async (notifications: Notification[]) => {
    if (notifications.length === 0) {
      return
    }

    startLoading(NotificationLoader.MarkNotificationsAsSeen)

    try {
      await api.notifications.updateNotifications({
        updateNotificationsRequest: {
          ids: notifications.map((notification) => notification.id),
          operation: UpdateOperationType.Seen,
        },
      })

      const updatedNotifications = notifications.map((notification) => ({
        ...notification,
        seenAt: new Date(),
        isSeen: true,
      }))

      dispatchNotifications({ type: 'addMany', items: updatedNotifications })
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(NotificationLoader.MarkNotificationsAsSeen)
    }
  }

  const setActiveNotification = async (id?: string) => {
    if (!id) {
      setActiveNotificationId(undefined)
      return
    }

    startLoading(NotificationLoader.SetActiveNotification)
    startLoading(id)

    try {
      const notification = notificationById(id)
      if (!notification) {
        return
      }

      if (notification.content.task) {
        await tasks.getTaskByIds([notification.content.task])
      }

      // Mark as read
      await api.notifications.updateNotifications({
        updateNotificationsRequest: {
          ids: [id],
          operation: UpdateOperationType.Read,
        },
      })

      setActiveNotificationId(id)
      dispatchNotifications({
        type: 'addOne',
        item: {
          ...notification,
          readAt: new Date(),
          isRead: true,
          isSeen: true,
        },
      })
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(NotificationLoader.SetActiveNotification)
      stopLoading(id)
    }
  }

  const markAllNotificationsAsRead = async () => {
    if (notifications.length === 0) {
      return
    }

    startLoading(NotificationLoader.MarkAllNotificationsAsRead)

    try {
      await api.notifications.updateNotifications({
        updateNotificationsRequest: {
          ids: notifications.map((notification) => notification.id),
          operation: UpdateOperationType.Read,
        },
      })

      fetchNotifications()
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(NotificationLoader.MarkAllNotificationsAsRead)
    }
  }

  const deleteAllNotifications = async () => {
    if (notifications.length === 0) {
      return
    }

    startLoading(NotificationLoader.DeleteAllNotifications)

    try {
      await api.notifications.deleteNotifications({
        deleteNotificationsRequest: {
          ids: notifications.map((notification) => notification.id),
        },
      })

      dispatchNotifications({ type: 'clear' })
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(NotificationLoader.DeleteAllNotifications)
    }
  }

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchNotifications()
    } else {
      dispatchNotifications({ type: 'clear' })
    }
  }, [auth.isAuthenticated])

  return {
    isLoading,
    isLoadingKey,
    notifications,
    unreadNotifications,
    activeNotification,
    activeTask,

    fetchNotifications,
    addNotification,
    setActiveNotification,
    markNotificationsAsSeen,
    markAllNotificationsAsRead,
    deleteAllNotifications,
  }
}

export type NotificationStore = ReturnType<typeof useNotificationStore>

export const NotificationStoreContext = createContext<NotificationStore>({
  isLoading: true,
} as any)

export function getNotificationStore() {
  return useContext(NotificationStoreContext)
}
