import diacritics from 'diacritics'
import Fuse from 'fuse.js'

import { User } from './user.interface'

/**
 * Creates an index to look for users
 * @param users
 */
export function createUsersIndex(users: User[]) {
  return new Fuse(users, {
    keys: ['name', 'email', 'phone', 'roles'],
    threshold: 0.25,
    getFn(user: any, fullPath) {
      const path = Array.isArray(fullPath) ? fullPath[0] : fullPath
      const value = user[path]
      if (!value) {
        return ''
      }

      return diacritics.remove(user[path])
    },
  })
}
