import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '../../api/clients'
import { MileageRateRequest, MileageRateResponse, RateStatusType } from '../../client'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { useRepositoryStore } from '../../utils/stores/repository.store'
import { AuthStore } from '../auth/auth.store'
import { createMileageRatesIndex } from './rates-mileage.filter'
import { transformMileageRate, transformMileageRateRequest } from './rates-mileage.transformer'

export function useMileageRatesStore(auth: AuthStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const repository = useRepositoryStore<MileageRateResponse>()

  // Getters

  const mileageRatesIndex = useMemo(
    () => createMileageRatesIndex(repository.items),
    [repository.items],
  )

  const activeMileageRates = useMemo(
    () => repository.items.filter((it) => it.status === RateStatusType.Active),
    [repository.items],
  )

  // Actions

  // Get report rates for the current user
  const getMileageRates = operation(async () => {
    setReady(false)

    try {
      const response = await api.rates.getAllMileageRates()
      repository.addMany(response.data.map(transformMileageRate))
    } finally {
      setReady(true)
    }
  })

  const createMileageRate = operation(async (request: MileageRateRequest) => {
    const response = await api.rates.createMileageRate({
      mileageRateRequest: transformMileageRateRequest(request),
    })
    repository.addOne(transformMileageRate(response.data))

    return response.data
  })

  const updateMileageRate = operation(async (id: string, request: MileageRateRequest) => {
    const response = await api.rates.updateMileageRate({
      id,
      mileageRateRequest: transformMileageRateRequest(request),
    })
    repository.addOne(transformMileageRate(response.data))

    return response.data
  })

  const deleteMileageRate = operation(async (id: string) => {
    await api.rates.deleteMileageRate({ id })
    repository.removeOne(id)
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getMileageRates()
    } else {
      repository.clear()
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    repository: repository,
    mileageRatesIndex,
    mileageRates: repository.items,
    activeMileageRates,
    getMileageRateById: repository.itemById,
    mileageRatesByIds: repository.itemsByIds,

    createMileageRate,
    updateMileageRate,
    deleteMileageRate,
  }
}

export type MileageRatesStore = ReturnType<typeof useMileageRatesStore>

export const MileageRatesStoreContext = createContext<MileageRatesStore>({
  isReady: false,
  isLoading: true,
  mileageRates: [],
} as any)

export function getMileageRatesStore() {
  return useContext(MileageRatesStoreContext)
}
