import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '../../api/clients'
import { PreferencesRequest, PreferencesResponse } from '../../client'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'

interface Props {
  readonly auth: AuthStore
}

export function usePreferencesStore({ auth }: Props) {
  const { isLoading, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const [preferences, setPreferences] = useState<PreferencesResponse>({})

  // Actions

  const getPreferences = operation(async () => {
    setReady(true)

    try {
      const response = await api.preferences.getGlobalPreferences()
      setPreferences(response.data)
    } finally {
      setReady(true)
    }
  })

  const updatePreferences = operation(async (value: PreferencesRequest) => {
    const response = await api.preferences.updateGlobalPreferences({ preferencesRequest: value })
    setPreferences(response.data)
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getPreferences()
    } else {
      setPreferences({})
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isLoading,
    isReady,
    preferences,
    updatePreferences,
  }
}

export type PreferencesStore = ReturnType<typeof usePreferencesStore>

export const PreferencesStoreContext = createContext<PreferencesStore>({
  isLoading: true,
} as any)

export function getPreferencesStore() {
  return useContext(PreferencesStoreContext)
}
