import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { AddTaskDocumentResponse, SortDir, TaskIncidenceState, TaskSortType } from '../../client'
import { Logger } from '../../utils/logger'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { useRepositoryStore } from '../../utils/stores/repository.store'
import { AuthStore } from '../auth/auth.store'
import { ClientsStore } from '../clients/client.store'
import { CompaniesStore } from '../companies/company.store'
import { Label } from '../labels/label.interface'
import { LabelsStore } from '../labels/label.store'
import { MediaStore } from '../media/media.store'
import { UsersStore } from '../users/user.store'
import { ZonesStore } from '../zones/zone.store'
import { createTasksIndex } from './task.filter'
import {
  PopulatedHomologationTaskContent,
  PopulatedRecognitionTaskContent,
  PopulatedReportTaskContent,
  PopulatedTask,
  Task,
  TaskContent,
  TaskFilters,
  TaskIncidence,
  TaskIncidenceParams,
  TaskPageInfo,
  TaskParams,
  TaskState,
  TaskType,
} from './task.interface'
import {
  transformTask,
  transformTaskIncidence,
  transformToSearchRequest,
  transformToTaskCreateRequest,
  transformToTaskUpdateRequest,
} from './task.transformer'

const PAGINATION_LIMIT = 15

const logger = new Logger('task.store')

export enum TaskLoader {
  Fetch,
  FetchByIds,
  FetchByReference,
  FetchPendingIncidences,
  Create,
  CreateComment,
  CreateIncidence,
  Update,
  UpdateState,
  UpdateComment,
  UpdateIncidence,
  UpdateVersion,
  Delete,
  DeleteDocument,
  DeleteComment,
  DeleteIncidence,
}

export function useTasksStore(
  auth: AuthStore,
  media: MediaStore,
  users: UsersStore,
  companies: CompaniesStore,
  clients: ClientsStore,
  zones: ZonesStore,
  labels: LabelsStore,
) {
  // State

  const { isLoading, isLoadingKey, startLoading, stopLoading, operation } = useLoadingStore()
  const repository = useRepositoryStore<Task>()
  // const [tasksMap, dispatchTasks] = useReducer(entityReducer as EntityReducer<string, Task>, {})
  const [incidencesMap, dispatchIncidences] = useReducer(
    entityReducer as EntityReducer<string, TaskIncidence>,
    {},
  )
  const [tasksPage, setTasksPage] = useState<TaskPageInfo>({
    taskIds: [],
    page: 0,
    pages: 0,
    limit: 0,
    hasMore: true,
    total: 0,
  })

  // Getters

  const populateTask = (task: Task | string | undefined): PopulatedTask | undefined => {
    if (typeof task === 'string') {
      task = repository.itemById(task)
    }
    if (!task) {
      return
    }

    const documents = task.documents.filter((file) => file.isDocument)
    const photos = task.documents.filter((file) => file.isPhoto)
    const reports = task.documents.filter((file) => file.isReport)

    return {
      ...task,
      createdBy: users.getUserById(task.createdByUserId),
      assignedTo: users.getUserById(task.assignedToUserId),
      reviewer: users.getUserById(task.reviewerId),
      labels: task.labelIds.map(labels.getLabelById).filter(Boolean) as Label[],
      content: populateTaskContent(task),
      numDocuments: documents.length,
      numPhotos: photos.length,
      numReports: reports.length,
    }
  }

  const populateTaskContent = (
    task: Task,
  ):
    | PopulatedReportTaskContent
    | PopulatedHomologationTaskContent
    | PopulatedRecognitionTaskContent => {
    if (task.content.type === TaskType.Report) {
      return {
        ...task.content,
        company: companies.getCompanyById(task.content.companyId),
        zone: zones.getZoneById(task.content.zoneId),
      }
    }

    if (task.content.type === TaskType.Homologation) {
      return {
        ...task.content,
        company: companies.getCompanyById(task.content.companyId),
        laboratory: companies.getCompanyById(task.content.laboratoryId),
        client: clients.getClientById(task.content.clientId),
      }
    }

    return {
      ...task.content,
      client: clients.getClientById(task.content.clientId),
    }
  }

  const rawTasks = repository.items
  const tasks = rawTasks.map(populateTask).filter(Boolean) as PopulatedTask[]

  const getTaskById = repository.itemById

  const taskByReference = (reference: string): Task | undefined => {
    if (!reference) {
      return
    }

    return tasks.find((task) => task.reference.trim() === reference.trim())
  }

  const incidences = useMemo(() => Object.values(incidencesMap), [incidencesMap])
  const pendingIncidences = useMemo(
    () => incidences.filter((incidence) => incidence.state === TaskIncidenceState.Pending),
    [incidences],
  )
  const getIncidenceById = (id?: string): TaskIncidence | undefined =>
    id ? incidencesMap[id] : undefined

  const tasksIndex = useMemo(() => createTasksIndex(tasks), [tasks])
  const populatedTasksPage = useMemo(
    () => ({
      ...tasksPage,
      tasks: repository.itemsByIds(tasksPage.taskIds).map(populateTask),
    }),
    [repository.itemsByIds, tasksPage],
  )

  // Actions

  const getTasks = async (
    page: number,
    filters: TaskFilters,
    limit: number = PAGINATION_LIMIT,
    sortBy?: TaskSortType,
    sortDir?: SortDir,
  ): Promise<TaskPageInfo> => {
    startLoading(TaskLoader.Fetch)

    try {
      const response = await api.tasks.searchTasks(
        transformToSearchRequest(page, limit, filters, sortBy, sortDir),
      )
      const tasks = response.data.items.map(transformTask)
      const pageInfo: TaskPageInfo = {
        taskIds: response.data.items.map((item) => item.id),
        page: response.data.page,
        limit,
        pages: response.data.pages,
        hasMore: response.data.page + 1 < response.data.pages,
        total: response.data.total,
      }
      repository.addMany(tasks)
      for (const task of tasks) {
        dispatchIncidences({ type: 'addMany', items: task.incidences })
      }

      setTasksPage(pageInfo)

      return pageInfo
    } catch (error) {
      logger.error(error)
      setTasksPage({
        ...tasksPage,
        taskIds: [],
      })

      throw error
    } finally {
      stopLoading(TaskLoader.Fetch)
    }
  }

  const fetchTaskWithReference = async (reference: string) => {
    startLoading(TaskLoader.FetchByReference)

    try {
      const response = await api.tasks.searchTasks({ reference })
      const tasks = response.data.items.map(transformTask)

      repository.addMany(tasks)
      for (const task of tasks) {
        dispatchIncidences({ type: 'addMany', items: task.incidences })
      }
    } catch (error) {
      logger.error(error)
    }

    stopLoading(TaskLoader.FetchByReference)
  }

  const getTaskByIds = async (ids: string[]) => {
    startLoading(TaskLoader.FetchByIds)

    try {
      const response = await api.tasks.searchTasks({ ids: ids })
      const tasks = response.data.items.map(transformTask)

      repository.addMany(tasks)
      for (const task of tasks) {
        dispatchIncidences({ type: 'addMany', items: task.incidences })
      }
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.FetchByIds)
    }
  }

  // Fetches all the pending incidences
  const fetchPendingIncidences = async () => {
    startLoading(TaskLoader.FetchPendingIncidences)

    try {
      const response = await api.tasks.getPendingIncidences()
      const tasks: Task[] = []
      const incidences: TaskIncidence[] = []
      for (const item of response.data.items) {
        const task = transformTask(item.task)
        const incidence = transformTaskIncidence(item.incidence, task.id)

        tasks.push(task)
        incidences.push(incidence)
      }

      repository.addMany(tasks)
      dispatchIncidences({ type: 'addMany', items: incidences })
    } catch (error) {
      logger.error(error)
    }

    stopLoading(TaskLoader.FetchPendingIncidences)
  }

  const createTask = async (params: TaskParams): Promise<Task> => {
    startLoading(TaskLoader.Create)

    try {
      const response = await api.tasks.createTask({
        createTaskRequest: transformToTaskCreateRequest(params),
      })
      const task = transformTask(response.data)

      repository.addOne(task)
      dispatchIncidences({ type: 'addMany', items: task.incidences })

      if (tasksPage) {
        setTasksPage({
          ...tasksPage,
          taskIds: [task.id, ...tasksPage.taskIds],
        })
      }

      return task
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.Create)
    }
  }

  const updateTask = operation(async (id: string, params: TaskParams) => {
    const response = await api.tasks.updateTask({
      id,
      updateTaskRequest: transformToTaskUpdateRequest(params),
    })
    const task = transformTask(response.data)

    repository.addOne(task)
    dispatchIncidences({ type: 'addMany', items: task.incidences })
  })

  const deleteTaskDocument = async (taskId: string, documentId: string) => {
    startLoading(TaskLoader.DeleteDocument)
    startLoading(taskId)

    try {
      const response = await api.tasks.deleteTaskDocument({ id: taskId, documentId })
      const task = transformTask(response.data)

      repository.addOne(task)
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.DeleteDocument)
      startLoading(taskId)
    }
  }

  const onTaskDocumentsUpdated = (response: AddTaskDocumentResponse) => {
    const task = transformTask(response.task)
    repository.addOne(task)

    loadTaskMedia(task)
  }

  const updateTaskState = async (
    id: string,
    newStatus: TaskState,
    newDeadline?: Date,
    newAssignee?: string,
    newContent?: TaskContent,
  ) => {
    const task = getTaskById(id)
    if (!task) {
      return
    }

    startLoading(TaskLoader.UpdateState)
    startLoading(id)

    try {
      const response = await api.tasks.updateTask({
        id,
        updateTaskRequest: transformToTaskUpdateRequest({
          ...task,
          content: newContent ?? task.content,
          assignedToUserId: newAssignee ?? task.assignedToUserId,
          state: newStatus,
          deadline: newDeadline ?? task.deadline,
        }),
      })

      repository.addOne(transformTask(response.data))
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.UpdateState)
      stopLoading(id)
    }
  }

  // Comments

  const addTaskComment = async (id: string, comment: string) => {
    startLoading(TaskLoader.CreateComment)
    startLoading(id)

    try {
      const response = await api.tasks.addTaskComment({ id, addTaskCommentRequest: { comment } })

      repository.addOne(transformTask(response.data.task))
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.CreateComment)
      stopLoading(id)
    }
  }

  const deleteTaskComment = async (id: string, commentId: string) => {
    startLoading(TaskLoader.DeleteComment)
    startLoading(id)

    try {
      const response = await api.tasks.deleteTaskComment({ id, commentId })

      repository.addOne(transformTask(response.data))
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.DeleteDocument)
      stopLoading(id)
    }
  }

  // Incidences

  const addTaskIncidence = async (id: string, params: TaskIncidenceParams) => {
    startLoading(TaskLoader.CreateIncidence)
    startLoading(id)

    try {
      const response = await api.tasks.addTaskIncidence({
        id,
        addTaskIncidenceRequest: {
          title: params.title,
          description: params.description,
          deadline: params.deadline?.toISOString(),
        },
      })
      const task = transformTask(response.data.task)

      repository.addOne(task)
      dispatchIncidences({ type: 'addMany', items: task.incidences })
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.CreateIncidence)
      stopLoading(id)
    }
  }

  const updateTaskIncidence = async (
    taskId: string,
    id: string,
    params: TaskIncidenceParams,
    state: TaskIncidenceState,
  ) => {
    startLoading(TaskLoader.UpdateIncidence)
    startLoading(id)

    try {
      const response = await api.tasks.updateTaskIncidence({
        id: taskId,
        incidenceId: id,
        updateTaskIncidenceRequest: {
          title: params.title,
          description: params.description,
          deadline: params.deadline?.toISOString(),
          state,
        },
      })
      const task = transformTask(response.data.task)

      repository.addOne(task)
      dispatchIncidences({ type: 'addMany', items: task.incidences })
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.UpdateIncidence)
      stopLoading(id)
    }
  }

  const deleteTaskIncidence = async (taskId: string, incidenceId: string) => {
    startLoading(TaskLoader.DeleteIncidence)
    startLoading(taskId)

    try {
      const response = await api.tasks.deleteTaskIncidence({ id: taskId, incidenceId })

      repository.addOne(transformTask(response.data))
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      startLoading(TaskLoader.DeleteIncidence)
      stopLoading(taskId)
    }
  }

  // Versions

  const setTaskVersionInvoiceCollected = async (
    task: Task,
    version: number,
    isCollected: boolean,
  ) => {
    startLoading(TaskLoader.UpdateVersion)
    startLoading(task.id)

    try {
      const response = await api.tasks.collectTaskVersion({
        id: task.id,
        collectTaskVersionRequest: {
          version,
          collect: isCollected,
        },
      })

      repository.addOne(transformTask(response.data))
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      stopLoading(TaskLoader.UpdateVersion)
      stopLoading(task.id)
    }
  }

  const deleteTask = async (id: string) => {
    startLoading(TaskLoader.Delete)
    startLoading(id)

    try {
      await api.tasks.deleteTask({ id })

      repository.removeOne(id)
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      startLoading(TaskLoader.Delete)
      stopLoading(id)
    }
  }

  const loadTaskMedia = async (task: Task) => {
    await media.fetchTaskDocumentThumbnails(task)
  }

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchPendingIncidences()
    } else {
      repository.clear()
      dispatchIncidences({ type: 'clear' })
    }
  }, [auth.isAuthenticated])

  return {
    isLoading,
    isLoadingKey: isLoadingKey,
    tasks,
    populateTask,
    repository,
    taskById: repository.itemById,
    tasksByIds: repository.itemsByIds,
    getTaskById,
    taskByReference,
    incidences,
    pendingIncidences,
    getIncidenceById,
    tasksIndex,
    tasksPage,
    populatedTasksPage,

    getTasks,
    fetchTaskWithReference,
    getTaskByIds,
    createTask,
    updateTask,
    loadTaskMedia,
    onTaskDocumentsUpdated,
    deleteTaskDocument,
    updateTaskState,
    addTaskComment,
    addTaskIncidence,
    updateTaskIncidence,
    deleteTaskIncidence,
    deleteTaskComment,
    setTaskVersionInvoiceCollected,
    deleteTask,
  }
}

export type TasksStore = ReturnType<typeof useTasksStore>

export const TasksStoreContext = createContext<TasksStore>({
  isLoading: true,
  getTaskById: () => undefined,
  tasks: [],
} as any)

export function getTaskStore() {
  return useContext(TasksStoreContext)
}
