import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'
import { createLabelsIndex } from './label.filter'
import { Label, LabelParams } from './label.interface'
import { transformLabelResponse, transformToLabelRequest } from './label.transformer'

export function useLabelsStore(auth: AuthStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const [labelsMap, dispatchLabels] = useReducer(entityReducer as EntityReducer<string, Label>, {})

  // Getters

  const labels = Object.values(labelsMap)
  const getLabelById = (id?: string): Label | undefined => (id ? labelsMap[id] : undefined)

  const labelsIndex = useMemo(() => createLabelsIndex(labels), [labels])

  // Actions

  const getLabels = operation(async () => {
    setReady(false)

    try {
      const response = await api.labels.getAllLabels()

      dispatchLabels({ type: 'addMany', items: response.data.map(transformLabelResponse) })
    } finally {
      setReady(true)
    }
  })

  const createLabel = operation(async (params: LabelParams): Promise<Label | undefined> => {
    const response = await api.labels.createLabel({ labelRequest: transformToLabelRequest(params) })
    const label = transformLabelResponse(response.data)

    dispatchLabels({ type: 'addOne', item: label })

    return label
  })

  const updateLabel = operation(async (id: string, params: LabelParams) => {
    const response = await api.labels.updateLabel({
      id,
      labelRequest: transformToLabelRequest(params),
    })
    const label = transformLabelResponse(response.data)
    dispatchLabels({ type: 'addOne', item: label })

    return label
  })

  const deleteLabel = operation(async (id: string) => {
    await api.labels.deleteLabel({ id })

    dispatchLabels({ type: 'removeOne', id })
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getLabels()
    } else {
      dispatchLabels({ type: 'clear' })
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    labels,
    getLabelById,
    labelsIndex,

    getLabels,
    createLabel,
    updateLabel,
    deleteLabel,
  }
}

export type LabelsStore = ReturnType<typeof useLabelsStore>

export const LabelsStoreContext = createContext<LabelsStore>({
  isReady: false,
  isLoading: true,
  labels: [],
} as any)

export function getLabelStore() {
  return useContext(LabelsStoreContext)
}
