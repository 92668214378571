import Big from 'big.js'

import { FixedRateDto, PorcentualRateDto, RateType } from '../../client'

/**
 * Transforms a rate response so it can be used in the app.
 * Percentage rates are multiplied by 100 (0.5 -> 50%).
 * @param rate
 * @returns transformed rate
 */
export function transformRate(
  rate: FixedRateDto | PorcentualRateDto,
): FixedRateDto | PorcentualRateDto {
  if (rate.type === RateType.Porcentual) {
    const value = (rate as PorcentualRateDto).factor

    return { ...rate, factor: Big(value).mul(100).toString() }
  }

  return rate
}

/**
 * Transforms a rate request so it can be used in the app.
 * Percentage rates are divided by 100 (50% -> 0.5).
 * @param rate
 * @returns transformed rate
 */
export function transformRateRequest(
  rate: FixedRateDto | PorcentualRateDto,
): FixedRateDto | PorcentualRateDto {
  if (rate.type === RateType.Porcentual) {
    const value = (rate as PorcentualRateDto).factor

    return { ...rate, factor: Big(value).div(100).toString() }
  }

  return rate
}
