/* tslint:disable */
/* eslint-disable */
/**
 * Jukuren API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddTaskCommentRequest
 */
export interface AddTaskCommentRequest {
    /**
     * The comment
     * @type {string}
     * @memberof AddTaskCommentRequest
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface AddTaskCommentResponse
 */
export interface AddTaskCommentResponse {
    /**
     * The task updated with the new comment
     * @type {TaskResponse}
     * @memberof AddTaskCommentResponse
     */
    'task': TaskResponse;
    /**
     * The new comment
     * @type {TaskCommentEntryDto}
     * @memberof AddTaskCommentResponse
     */
    'comment': TaskCommentEntryDto;
}
/**
 * 
 * @export
 * @interface AddTaskDocumentResponse
 */
export interface AddTaskDocumentResponse {
    /**
     * The task updated with the new document
     * @type {TaskResponse}
     * @memberof AddTaskDocumentResponse
     */
    'task': TaskResponse;
    /**
     * The new document
     * @type {MediaResponse}
     * @memberof AddTaskDocumentResponse
     */
    'document': MediaResponse;
}
/**
 * 
 * @export
 * @interface AddTaskIncidenceRequest
 */
export interface AddTaskIncidenceRequest {
    /**
     * The incidence title
     * @type {string}
     * @memberof AddTaskIncidenceRequest
     */
    'title': string;
    /**
     * The incidence description
     * @type {string}
     * @memberof AddTaskIncidenceRequest
     */
    'description'?: string;
    /**
     * The deadline of the incidence
     * @type {string}
     * @memberof AddTaskIncidenceRequest
     */
    'deadline'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AppraisalType {
    InPerson = 'inPerson',
    Remote = 'remote'
}

/**
 * 
 * @export
 * @interface AssignedTaskActivityDataDto
 */
export interface AssignedTaskActivityDataDto {
    /**
     * The previous assignee user ID
     * @type {string}
     * @memberof AssignedTaskActivityDataDto
     */
    'previous'?: string;
    /**
     * The current assignee user ID
     * @type {string}
     * @memberof AssignedTaskActivityDataDto
     */
    'current': string;
}
/**
 * 
 * @export
 * @interface BaseRequest
 */
export interface BaseRequest {
    /**
     * Name of the base
     * @type {string}
     * @memberof BaseRequest
     */
    'name': string;
    /**
     * Description of the base
     * @type {string}
     * @memberof BaseRequest
     */
    'description'?: string;
    /**
     * Address of the base
     * @type {string}
     * @memberof BaseRequest
     */
    'address'?: string;
    /**
     * Coordinates of the base
     * @type {string}
     * @memberof BaseRequest
     */
    'coordinates': string;
}
/**
 * 
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
    /**
     * Identifier of the base
     * @type {string}
     * @memberof BaseResponse
     */
    'id': string;
    /**
     * Name of the base
     * @type {string}
     * @memberof BaseResponse
     */
    'name': string;
    /**
     * Description of the base
     * @type {string}
     * @memberof BaseResponse
     */
    'description'?: string;
    /**
     * Address of the base
     * @type {string}
     * @memberof BaseResponse
     */
    'address'?: string;
    /**
     * Coordinates of the base
     * @type {string}
     * @memberof BaseResponse
     */
    'coordinates': string;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * Previous password of the user
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'previousPassword': string;
    /**
     * New password of the user
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ClaimType {
    ManageUsers = 'manage-users',
    ManageSettings = 'manage-settings',
    ManageTasks = 'manage-tasks',
    SeeAllTasks = 'see-all-tasks',
    ManageFinances = 'manage-finances'
}

/**
 * 
 * @export
 * @interface ClientDeletedResponse
 */
export interface ClientDeletedResponse {
    /**
     * Identifier of the client
     * @type {string}
     * @memberof ClientDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof ClientDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface ClientRequest
 */
export interface ClientRequest {
    /**
     * Name of the client
     * @type {string}
     * @memberof ClientRequest
     */
    'name': string;
    /**
     * Email of the client
     * @type {string}
     * @memberof ClientRequest
     */
    'email'?: string;
    /**
     * Phone of the client
     * @type {string}
     * @memberof ClientRequest
     */
    'phone'?: string;
    /**
     * Note for the client
     * @type {string}
     * @memberof ClientRequest
     */
    'note'?: string;
    /**
     * Tax name of the client
     * @type {string}
     * @memberof ClientRequest
     */
    'taxName'?: string;
    /**
     * Tax name of the client
     * @type {string}
     * @memberof ClientRequest
     */
    'taxNumber'?: string;
    /**
     * Tax name of the client
     * @type {string}
     * @memberof ClientRequest
     */
    'taxAddress'?: string;
}
/**
 * 
 * @export
 * @interface ClientResponse
 */
export interface ClientResponse {
    /**
     * Identifier of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'id': string;
    /**
     * Name of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'name': string;
    /**
     * The logo of the client
     * @type {MediaResponse}
     * @memberof ClientResponse
     */
    'logo'?: MediaResponse;
    /**
     * Email of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'email'?: string;
    /**
     * Phone of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'phone'?: string;
    /**
     * Note for the client
     * @type {string}
     * @memberof ClientResponse
     */
    'note'?: string;
    /**
     * Tax name of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'taxName'?: string;
    /**
     * Tax name of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'taxNumber'?: string;
    /**
     * Tax name of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'taxAddress'?: string;
    /**
     * The creation date of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'createdAt': string;
    /**
     * The update date of the client
     * @type {string}
     * @memberof ClientResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CollectTaskVersionRequest
 */
export interface CollectTaskVersionRequest {
    /**
     * The version to collect
     * @type {number}
     * @memberof CollectTaskVersionRequest
     */
    'version': number;
    /**
     * The collect value
     * @type {boolean}
     * @memberof CollectTaskVersionRequest
     */
    'collect': boolean;
}
/**
 * 
 * @export
 * @interface ColumnFiltersDto
 */
export interface ColumnFiltersDto {
    /**
     * Filter the tasks that match any of the give types
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'types'?: Array<ColumnFiltersDtoTypesEnum>;
    /**
     * Filter the tasks that match any of the give states
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'states'?: Array<ColumnFiltersDtoStatesEnum>;
    /**
     * Filter the tasks that have an assigned user
     * @type {boolean}
     * @memberof ColumnFiltersDto
     */
    'isAssigned'?: boolean;
    /**
     * Filter the tasks that have an assigned which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'assigneeIds'?: Array<string>;
    /**
     * Filter the tasks that have a reviewer user
     * @type {boolean}
     * @memberof ColumnFiltersDto
     */
    'hasReviewer'?: boolean;
    /**
     * Filter the tasks that have a reviewer which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'reviewerIds'?: Array<string>;
    /**
     * Filter the tasks that have a label which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'labelIds'?: Array<string>;
    /**
     * Filter the tasks that have any company associated
     * @type {boolean}
     * @memberof ColumnFiltersDto
     */
    'hasCompany'?: boolean;
    /**
     * Filter the tasks that have a company which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'companyIds'?: Array<string>;
    /**
     * Filter the tasks that have a base which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'baseIds'?: Array<string>;
    /**
     * Filter the tasks that have any zone associated
     * @type {boolean}
     * @memberof ColumnFiltersDto
     */
    'hasZone'?: boolean;
    /**
     * Filter the tasks that have a zone which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'zoneIds'?: Array<string>;
    /**
     * Filter the tasks that match any of the give appraisal types
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'appraisalTypes'?: Array<ColumnFiltersDtoAppraisalTypesEnum>;
    /**
     * Filter the tasks that have any client associated
     * @type {boolean}
     * @memberof ColumnFiltersDto
     */
    'hasClient'?: boolean;
    /**
     * Filter the tasks that have a client which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof ColumnFiltersDto
     */
    'clientIds'?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum ColumnFiltersDtoTypesEnum {
    Report = 'report',
    Homologation = 'homologation',
    Recognition = 'recognition'
}
/**
    * @export
    * @enum {string}
    */
export enum ColumnFiltersDtoStatesEnum {
    Draft = 'draft',
    Open = 'open',
    Contact = 'contact',
    Visit = 'visit',
    BuildReport = 'buildReport',
    ReviewReport = 'reviewReport',
    InLaboratory = 'inLaboratory',
    Accepted = 'accepted',
    InInspection = 'inInspection',
    Close = 'close',
    Cancelled = 'cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum ColumnFiltersDtoAppraisalTypesEnum {
    InPerson = 'inPerson',
    Remote = 'remote'
}

/**
 * 
 * @export
 * @interface ColumnOrderDto
 */
export interface ColumnOrderDto {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof ColumnOrderDto
     */
    'id': string;
    /**
     * The position of the column in the workspace
     * @type {number}
     * @memberof ColumnOrderDto
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface ColumnRequest
 */
export interface ColumnRequest {
    /**
     * Title of the column
     * @type {string}
     * @memberof ColumnRequest
     */
    'title': string;
    /**
     * Color of the column
     * @type {string}
     * @memberof ColumnRequest
     */
    'color'?: string;
    /**
     * Filters of the column
     * @type {ColumnFiltersDto}
     * @memberof ColumnRequest
     */
    'filters': ColumnFiltersDto;
    /**
     * Sort of the column
     * @type {ColumnSortDto}
     * @memberof ColumnRequest
     */
    'sort': ColumnSortDto;
}
/**
 * 
 * @export
 * @interface ColumnResponse
 */
export interface ColumnResponse {
    /**
     * Identifier of the column
     * @type {string}
     * @memberof ColumnResponse
     */
    'id': string;
    /**
     * Title of the column
     * @type {string}
     * @memberof ColumnResponse
     */
    'title': string;
    /**
     * Color of the column
     * @type {string}
     * @memberof ColumnResponse
     */
    'color'?: string;
    /**
     * Filters of the column
     * @type {ColumnFiltersDto}
     * @memberof ColumnResponse
     */
    'filters': ColumnFiltersDto;
    /**
     * Sort of the column
     * @type {ColumnSortDto}
     * @memberof ColumnResponse
     */
    'sort': ColumnSortDto;
    /**
     * The creation date of the column
     * @type {string}
     * @memberof ColumnResponse
     */
    'createdAt': string;
    /**
     * The update date of the column
     * @type {string}
     * @memberof ColumnResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ColumnSortDto
 */
export interface ColumnSortDto {
    /**
     * 
     * @type {ColumnSortType}
     * @memberof ColumnSortDto
     */
    'sortBy': ColumnSortType;
    /**
     * 
     * @type {SortDir}
     * @memberof ColumnSortDto
     */
    'sortDir': SortDir;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ColumnSortType {
    State = 'state',
    Deadline = 'deadline',
    CreatedAt = 'createdAt'
}

/**
 * 
 * @export
 * @interface CompanyBaseResponse
 */
export interface CompanyBaseResponse {
    /**
     * The company updated with the new base
     * @type {CompanyResponse}
     * @memberof CompanyBaseResponse
     */
    'company': CompanyResponse;
    /**
     * The new base
     * @type {BaseResponse}
     * @memberof CompanyBaseResponse
     */
    'base': BaseResponse;
}
/**
 * 
 * @export
 * @interface CompanyDeletedResponse
 */
export interface CompanyDeletedResponse {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompanyDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof CompanyDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'id': string;
    /**
     * Name of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'name': string;
    /**
     * 
     * @type {CompanyType}
     * @memberof CompanyResponse
     */
    'type': CompanyType;
    /**
     * The logo of the company
     * @type {MediaResponse}
     * @memberof CompanyResponse
     */
    'logo'?: MediaResponse;
    /**
     * Email of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'email'?: string;
    /**
     * Phone of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'phone'?: string;
    /**
     * Note for the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'note'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'taxName'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'taxNumber'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof CompanyResponse
     */
    'taxAddress'?: string;
    /**
     * The settings of the company
     * @type {InsuranceCompanySettingsResponse | CompanySettingsResponse}
     * @memberof CompanyResponse
     */
    'settings': InsuranceCompanySettingsResponse | CompanySettingsResponse;
    /**
     * The bases of the company
     * @type {Array<BaseResponse>}
     * @memberof CompanyResponse
     */
    'bases'?: Array<BaseResponse>;
}
/**
 * 
 * @export
 * @interface CompanySettingsRequest
 */
export interface CompanySettingsRequest {
    /**
     * The deadlines for the task states
     * @type {Array<DeadlineDto>}
     * @memberof CompanySettingsRequest
     */
    'deadlines'?: Array<DeadlineDto>;
}
/**
 * 
 * @export
 * @interface CompanySettingsResponse
 */
export interface CompanySettingsResponse {
    /**
     * The deadlines for the task states
     * @type {Array<DeadlineDto>}
     * @memberof CompanySettingsResponse
     */
    'deadlines'?: Array<DeadlineDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CompanyType {
    Insurance = 'insurance',
    Laboratory = 'laboratory'
}

/**
 * 
 * @export
 * @interface CompleteRouteRequest
 */
export interface CompleteRouteRequest {
    /**
     * The total distance done for the route in km
     * @type {number}
     * @memberof CompleteRouteRequest
     */
    'distance': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ContractType {
    Employee = 'employee',
    Contractor = 'contractor'
}

/**
 * 
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
    /**
     * Name of the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {CompanyType}
     * @memberof CreateCompanyRequest
     */
    'type': CompanyType;
    /**
     * Email of the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'email'?: string;
    /**
     * Phone of the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'phone'?: string;
    /**
     * Note for the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'note'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'taxName'?: string;
    /**
     * Tax number of the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'taxNumber'?: string;
    /**
     * Tax address of the company
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'taxAddress'?: string;
    /**
     * The settings of the company
     * @type {InsuranceCompanySettingsRequest | CompanySettingsRequest}
     * @memberof CreateCompanyRequest
     */
    'settings': InsuranceCompanySettingsRequest | CompanySettingsRequest;
}
/**
 * 
 * @export
 * @interface CreateRouteRequest
 */
export interface CreateRouteRequest {
    /**
     * The scheduled date of the route
     * @type {string}
     * @memberof CreateRouteRequest
     */
    'date': string;
    /**
     * Assignee of the route
     * @type {string}
     * @memberof CreateRouteRequest
     */
    'assignee': string;
    /**
     * Tasks for the route
     * @type {Array<string>}
     * @memberof CreateRouteRequest
     */
    'tasks': Array<string>;
    /**
     * Rate to apply to the route
     * @type {string}
     * @memberof CreateRouteRequest
     */
    'rate': string;
    /**
     * Title of the route
     * @type {string}
     * @memberof CreateRouteRequest
     */
    'title'?: string;
    /**
     * Notes of the route
     * @type {string}
     * @memberof CreateRouteRequest
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface CreateTaskRequest
 */
export interface CreateTaskRequest {
    /**
     * The reference of the task
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'reference': string;
    /**
     * Title of the task
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'title'?: string;
    /**
     * The description of the task
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'description'?: string;
    /**
     * The deadline of the task
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'deadline'?: string;
    /**
     * The assignee of the task
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'assignee'?: string;
    /**
     * The reviewer of the task
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'reviewer'?: string;
    /**
     * The labels of the task
     * @type {Array<string>}
     * @memberof CreateTaskRequest
     */
    'labels'?: Array<string>;
    /**
     * The content of the task
     * @type {ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto}
     * @memberof CreateTaskRequest
     */
    'content': ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * The name of the user for login purposes. Unique across the application
     * @type {string}
     * @memberof CreateUserRequest
     */
    'username': string;
    /**
     * Name of the user
     * @type {string}
     * @memberof CreateUserRequest
     */
    'name': string;
    /**
     * 
     * @type {RoleType}
     * @memberof CreateUserRequest
     */
    'role': RoleType;
    /**
     * Claims of the user
     * @type {Array<ClaimType>}
     * @memberof CreateUserRequest
     */
    'claims': Array<ClaimType>;
    /**
     * Language of the user
     * @type {string}
     * @memberof CreateUserRequest
     */
    'language': string;
    /**
     * Location of the user. Used to calculate the distance to the incidences.
     * @type {string}
     * @memberof CreateUserRequest
     */
    'location': string;
    /**
     * Location name of the user.
     * @type {string}
     * @memberof CreateUserRequest
     */
    'locationName': string;
    /**
     * 
     * @type {ContractType}
     * @memberof CreateUserRequest
     */
    'contract': ContractType;
    /**
     * Phone of the user
     * @type {string}
     * @memberof CreateUserRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface CreateWorkspaceRequest
 */
export interface CreateWorkspaceRequest {
    /**
     * Title of the workspace
     * @type {string}
     * @memberof CreateWorkspaceRequest
     */
    'title': string;
    /**
     * Color of the workspace
     * @type {string}
     * @memberof CreateWorkspaceRequest
     */
    'color'?: string;
    /**
     * Columns of the workspace
     * @type {Array<ColumnRequest>}
     * @memberof CreateWorkspaceRequest
     */
    'columns': Array<ColumnRequest>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DashboardType {
    DailySummary = 'dailySummary'
}

/**
 * 
 * @export
 * @interface DeadlineDto
 */
export interface DeadlineDto {
    /**
     * 
     * @type {TaskState}
     * @memberof DeadlineDto
     */
    'state': TaskState;
    /**
     * The amount of time to add
     * @type {number}
     * @memberof DeadlineDto
     */
    'amount': number;
    /**
     * 
     * @type {DeadlineUnit}
     * @memberof DeadlineDto
     */
    'unit': DeadlineUnit;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DeadlineUnit {
    Hour = 'hour',
    Day = 'day',
    Week = 'week',
    Month = 'month'
}

/**
 * 
 * @export
 * @interface DeleteNotificationsRequest
 */
export interface DeleteNotificationsRequest {
    /**
     * Identifiers of the notifications
     * @type {Array<string>}
     * @memberof DeleteNotificationsRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface DeletedMediaResponse
 */
export interface DeletedMediaResponse {
    /**
     * Identifier of the media in the storage
     * @type {string}
     * @memberof DeletedMediaResponse
     */
    'fileId': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof DeletedMediaResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface FixedRateDto
 */
export interface FixedRateDto {
    /**
     * 
     * @type {RateType}
     * @memberof FixedRateDto
     */
    'type': RateType;
    /**
     * The fixed rate
     * @type {MoneyDto}
     * @memberof FixedRateDto
     */
    'rate': MoneyDto;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * The email of the user to recover the password.
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface HomologationTaskContentDto
 */
export interface HomologationTaskContentDto {
    /**
     * 
     * @type {TaskContentType}
     * @memberof HomologationTaskContentDto
     */
    'type': TaskContentType;
    /**
     * The client of the homologation
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'client': string;
    /**
     * The company of the homologation
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'company': string;
    /**
     * The brand of the car
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'brand'?: string;
    /**
     * The model of the car
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'model'?: string;
    /**
     * The commercial name of the car
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'commercialName'?: string;
    /**
     * The variant of the car
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'variant'?: string;
    /**
     * The license plate number of the car
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'plateNumber'?: string;
    /**
     * The chassis number of the car
     * @type {string}
     * @memberof HomologationTaskContentDto
     */
    'chassisNumber'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InsuranceCompanySettingsRequest
 */
export interface InsuranceCompanySettingsRequest {
    /**
     * The deadlines for the task states
     * @type {Array<DeadlineDto>}
     * @memberof InsuranceCompanySettingsRequest
     */
    'deadlines'?: Array<DeadlineDto>;
}
/**
 * 
 * @export
 * @interface InsuranceCompanySettingsResponse
 */
export interface InsuranceCompanySettingsResponse {
    /**
     * The deadlines for the task states
     * @type {Array<DeadlineDto>}
     * @memberof InsuranceCompanySettingsResponse
     */
    'deadlines'?: Array<DeadlineDto>;
}
/**
 * 
 * @export
 * @interface LabelDeletedResponse
 */
export interface LabelDeletedResponse {
    /**
     * Identifier of the label
     * @type {string}
     * @memberof LabelDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof LabelDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface LabelRequest
 */
export interface LabelRequest {
    /**
     * Name of the label
     * @type {string}
     * @memberof LabelRequest
     */
    'name': string;
    /**
     * Color of the label
     * @type {string}
     * @memberof LabelRequest
     */
    'color': string;
    /**
     * Description of the label
     * @type {string}
     * @memberof LabelRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface LabelResponse
 */
export interface LabelResponse {
    /**
     * Identifier of the label
     * @type {string}
     * @memberof LabelResponse
     */
    'id': string;
    /**
     * Name of the label
     * @type {string}
     * @memberof LabelResponse
     */
    'name': string;
    /**
     * Color of the label
     * @type {string}
     * @memberof LabelResponse
     */
    'color': string;
    /**
     * Description of the label
     * @type {string}
     * @memberof LabelResponse
     */
    'description'?: string;
    /**
     * The creation date of the label
     * @type {string}
     * @memberof LabelResponse
     */
    'createdAt': string;
    /**
     * The update date of the label
     * @type {string}
     * @memberof LabelResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ListTaskIncidencesResponse
 */
export interface ListTaskIncidencesResponse {
    /**
     * The items
     * @type {Array<TaskIncidenceResponse>}
     * @memberof ListTaskIncidencesResponse
     */
    'items': Array<TaskIncidenceResponse>;
    /**
     * The total amount of items
     * @type {number}
     * @memberof ListTaskIncidencesResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ListTasksResponse
 */
export interface ListTasksResponse {
    /**
     * The items
     * @type {Array<TaskResponse>}
     * @memberof ListTasksResponse
     */
    'items': Array<TaskResponse>;
    /**
     * The amount of returned items
     * @type {number}
     * @memberof ListTasksResponse
     */
    'quantity': number;
    /**
     * The page returned
     * @type {number}
     * @memberof ListTasksResponse
     */
    'page': number;
    /**
     * The amount of total pages
     * @type {number}
     * @memberof ListTasksResponse
     */
    'pages': number;
    /**
     * The total amount of items
     * @type {number}
     * @memberof ListTasksResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * The username
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
    /**
     * The password
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * The access token to be used for accessing the services
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken': string;
    /**
     * Whether the user has to change it\'s password
     * @type {boolean}
     * @memberof LoginResponse
     */
    'mustChangePassword': boolean;
    /**
     * The token to use for changing the password
     * @type {string}
     * @memberof LoginResponse
     */
    'recoveryToken': string;
}
/**
 * 
 * @export
 * @interface MediaResponse
 */
export interface MediaResponse {
    /**
     * Identifier of the media in the storage
     * @type {string}
     * @memberof MediaResponse
     */
    'fileId': string;
    /**
     * Mime type of the file
     * @type {string}
     * @memberof MediaResponse
     */
    'mimeType': string;
    /**
     * The metadata of the file
     * @type {object}
     * @memberof MediaResponse
     */
    'metadata'?: object;
    /**
     * The thumbnail of the media
     * @type {ThumbnailResponse}
     * @memberof MediaResponse
     */
    'thumbnail'?: ThumbnailResponse;
    /**
     * The user that uploaded the file
     * @type {string}
     * @memberof MediaResponse
     */
    'uploadedBy': string;
    /**
     * The time at which the file was uploaded
     * @type {string}
     * @memberof MediaResponse
     */
    'uploadedAt': string;
    /**
     * The tags of the application for the file
     * @type {Array<string>}
     * @memberof MediaResponse
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MileageRateDeletedResponse
 */
export interface MileageRateDeletedResponse {
    /**
     * Identifier of the mileage rate
     * @type {string}
     * @memberof MileageRateDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof MileageRateDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface MileageRateRequest
 */
export interface MileageRateRequest {
    /**
     * Name of the mileage rate
     * @type {string}
     * @memberof MileageRateRequest
     */
    'name': string;
    /**
     * 
     * @type {RateStatusType}
     * @memberof MileageRateRequest
     */
    'status': RateStatusType;
    /**
     * Description of the mileage rates
     * @type {string}
     * @memberof MileageRateRequest
     */
    'description'?: string;
    /**
     * The per Km rate
     * @type {FixedRateDto | PorcentualRateDto}
     * @memberof MileageRateRequest
     */
    'perKm': FixedRateDto | PorcentualRateDto;
}
/**
 * 
 * @export
 * @interface MileageRateResponse
 */
export interface MileageRateResponse {
    /**
     * Identifier of the mileage rate
     * @type {string}
     * @memberof MileageRateResponse
     */
    'id': string;
    /**
     * Name of the mileage rate
     * @type {string}
     * @memberof MileageRateResponse
     */
    'name': string;
    /**
     * 
     * @type {RateStatusType}
     * @memberof MileageRateResponse
     */
    'status': RateStatusType;
    /**
     * Description of the mileage rates
     * @type {string}
     * @memberof MileageRateResponse
     */
    'description'?: string;
    /**
     * The per Km rate
     * @type {FixedRateDto | PorcentualRateDto}
     * @memberof MileageRateResponse
     */
    'perKm': FixedRateDto | PorcentualRateDto;
    /**
     * The creation date of the mileage rate
     * @type {string}
     * @memberof MileageRateResponse
     */
    'createdAt': string;
    /**
     * The update date of the mileage rate
     * @type {string}
     * @memberof MileageRateResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MoneyDto
 */
export interface MoneyDto {
    /**
     * The amount of money
     * @type {string}
     * @memberof MoneyDto
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * Identifier of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'id': string;
    /**
     * The author of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'author': string;
    /**
     * The target user of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'user': string;
    /**
     * The content of the notification
     * @type {TaskAssignedNotificationContentDto | TaskReviewAssignedNotificationContentDto | TaskCommentAddedNotificationContentDto | TaskIncidenceAddedNotificationContentDto | TaskDocumentAddedNotificationContentDto}
     * @memberof NotificationResponse
     */
    'content': TaskAssignedNotificationContentDto | TaskReviewAssignedNotificationContentDto | TaskCommentAddedNotificationContentDto | TaskIncidenceAddedNotificationContentDto | TaskDocumentAddedNotificationContentDto;
    /**
     * Whether the notification has been marked as seen
     * @type {boolean}
     * @memberof NotificationResponse
     */
    'isSeen': boolean;
    /**
     * The seen at date of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'seenAt'?: string;
    /**
     * Whether the notification has been marked as read
     * @type {boolean}
     * @memberof NotificationResponse
     */
    'isRead': boolean;
    /**
     * The read at date of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'readAt'?: string;
    /**
     * The creation date of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'createdAt': string;
    /**
     * The update date of the notification
     * @type {string}
     * @memberof NotificationResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum NotificationType {
    TaskAssigned = 'taskAssigned',
    TaskReviewAssigned = 'taskReviewAssigned',
    TaskCommentAdded = 'taskCommentAdded',
    TaskIncidenceAdded = 'taskIncidenceAdded',
    TaskDocumentAdded = 'taskDocumentAdded'
}

/**
 * 
 * @export
 * @interface NotificationsPreferencesDto
 */
export interface NotificationsPreferencesDto {
    /**
     * The preference for \"task assigned\" notifications
     * @type {TaskNotificationPreferencesDto}
     * @memberof NotificationsPreferencesDto
     */
    'taskAssigned': TaskNotificationPreferencesDto;
    /**
     * The preference for \"task review assigned\" notifications
     * @type {TaskNotificationPreferencesDto}
     * @memberof NotificationsPreferencesDto
     */
    'taskReviewAssigned': TaskNotificationPreferencesDto;
    /**
     * The preference for \"task comment added\" notifications
     * @type {TaskNotificationPreferencesDto}
     * @memberof NotificationsPreferencesDto
     */
    'taskCommentAdded': TaskNotificationPreferencesDto;
    /**
     * The preference for \"task incidence added\" notifications
     * @type {TaskNotificationPreferencesDto}
     * @memberof NotificationsPreferencesDto
     */
    'taskIncidenceAdded': TaskNotificationPreferencesDto;
    /**
     * The preference for \"task document added\" notifications
     * @type {TaskDocumentNotificationPreferencesDto}
     * @memberof NotificationsPreferencesDto
     */
    'taskDocumentAdded': TaskDocumentNotificationPreferencesDto;
}
/**
 * 
 * @export
 * @interface NotificationsResponse
 */
export interface NotificationsResponse {
    /**
     * 
     * @type {OperationType}
     * @memberof NotificationsResponse
     */
    'operation': OperationType;
    /**
     * The amount of notifications affected by the operation
     * @type {number}
     * @memberof NotificationsResponse
     */
    'affected': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OperationType {
    Seen = 'seen',
    Read = 'read',
    Delete = 'delete'
}

/**
 * 
 * @export
 * @interface PorcentualRateDto
 */
export interface PorcentualRateDto {
    /**
     * 
     * @type {RateType}
     * @memberof PorcentualRateDto
     */
    'type': RateType;
    /**
     * The factor to calculate the rate
     * @type {string}
     * @memberof PorcentualRateDto
     */
    'factor': string;
}
/**
 * 
 * @export
 * @interface PreferencesRequest
 */
export interface PreferencesRequest {
    /**
     * The default report rate
     * @type {string}
     * @memberof PreferencesRequest
     */
    'defaultReportRate'?: string;
    /**
     * The default mileage rate
     * @type {string}
     * @memberof PreferencesRequest
     */
    'defaultMileageRate'?: string;
    /**
     * The day of the month to build the billing statements
     * @type {number}
     * @memberof PreferencesRequest
     */
    'billingDay'?: number;
}
/**
 * 
 * @export
 * @interface PreferencesResponse
 */
export interface PreferencesResponse {
    /**
     * The default report rate
     * @type {string}
     * @memberof PreferencesResponse
     */
    'defaultReportRate'?: string;
    /**
     * The default mileage rate
     * @type {string}
     * @memberof PreferencesResponse
     */
    'defaultMileageRate'?: string;
    /**
     * The day of the month to build the billing statements
     * @type {number}
     * @memberof PreferencesResponse
     */
    'billingDay'?: number;
    /**
     * The update date of the preferences
     * @type {string}
     * @memberof PreferencesResponse
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RateStatusType {
    Draft = 'draft',
    Active = 'active',
    Decommissioned = 'decommissioned'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum RateType {
    Fixed = 'fixed',
    Porcentual = 'porcentual'
}

/**
 * 
 * @export
 * @interface RawRouteResponse
 */
export interface RawRouteResponse {
    /**
     * Identifier of the raw route
     * @type {string}
     * @memberof RawRouteResponse
     */
    'id': string;
    /**
     * The raw route from the Google Maps service
     * @type {object}
     * @memberof RawRouteResponse
     */
    'googleMaps': object;
    /**
     * The creation date of the route
     * @type {string}
     * @memberof RawRouteResponse
     */
    'createdAt': string;
    /**
     * The update date of the route
     * @type {string}
     * @memberof RawRouteResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface RecognitionTaskContentDto
 */
export interface RecognitionTaskContentDto {
    /**
     * 
     * @type {TaskContentType}
     * @memberof RecognitionTaskContentDto
     */
    'type': TaskContentType;
    /**
     * The client of the homologation
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'client': string;
    /**
     * The brand of the car
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'brand'?: string;
    /**
     * The model of the car
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'model'?: string;
    /**
     * The commercial name of the car
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'commercialName'?: string;
    /**
     * The variant of the car
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'variant'?: string;
    /**
     * The license plate number of the car
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'plateNumber'?: string;
    /**
     * The chassis number of the car
     * @type {string}
     * @memberof RecognitionTaskContentDto
     */
    'chassisNumber'?: string;
}
/**
 * 
 * @export
 * @interface RecoverPasswordRequest
 */
export interface RecoverPasswordRequest {
    /**
     * The email of the user to recover the password.
     * @type {string}
     * @memberof RecoverPasswordRequest
     */
    'username': string;
    /**
     * The one time usage token for changing the password
     * @type {string}
     * @memberof RecoverPasswordRequest
     */
    'token': string;
    /**
     * The new password
     * @type {string}
     * @memberof RecoverPasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ReportRateDeletedResponse
 */
export interface ReportRateDeletedResponse {
    /**
     * Identifier of the report rate
     * @type {string}
     * @memberof ReportRateDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof ReportRateDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface ReportRateRequest
 */
export interface ReportRateRequest {
    /**
     * Name of the report rate
     * @type {string}
     * @memberof ReportRateRequest
     */
    'name': string;
    /**
     * 
     * @type {RateStatusType}
     * @memberof ReportRateRequest
     */
    'status': RateStatusType;
    /**
     * Description of the report rates
     * @type {string}
     * @memberof ReportRateRequest
     */
    'description'?: string;
    /**
     * The per report rate
     * @type {FixedRateDto | PorcentualRateDto}
     * @memberof ReportRateRequest
     */
    'perReport': FixedRateDto | PorcentualRateDto;
    /**
     * The per reopen rate
     * @type {FixedRateDto | PorcentualRateDto}
     * @memberof ReportRateRequest
     */
    'perReopen': FixedRateDto | PorcentualRateDto;
}
/**
 * 
 * @export
 * @interface ReportRateResponse
 */
export interface ReportRateResponse {
    /**
     * Identifier of the report rate
     * @type {string}
     * @memberof ReportRateResponse
     */
    'id': string;
    /**
     * Name of the report rate
     * @type {string}
     * @memberof ReportRateResponse
     */
    'name': string;
    /**
     * 
     * @type {RateStatusType}
     * @memberof ReportRateResponse
     */
    'status': RateStatusType;
    /**
     * Description of the report rates
     * @type {string}
     * @memberof ReportRateResponse
     */
    'description'?: string;
    /**
     * The per report rate
     * @type {FixedRateDto | PorcentualRateDto}
     * @memberof ReportRateResponse
     */
    'perReport': FixedRateDto | PorcentualRateDto;
    /**
     * The per reopen rate
     * @type {FixedRateDto | PorcentualRateDto}
     * @memberof ReportRateResponse
     */
    'perReopen': FixedRateDto | PorcentualRateDto;
    /**
     * The creation date of the report rate
     * @type {string}
     * @memberof ReportRateResponse
     */
    'createdAt': string;
    /**
     * The update date of the report rate
     * @type {string}
     * @memberof ReportRateResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ReportTaskContentDto
 */
export interface ReportTaskContentDto {
    /**
     * 
     * @type {TaskContentType}
     * @memberof ReportTaskContentDto
     */
    'type': TaskContentType;
    /**
     * The company of the report
     * @type {string}
     * @memberof ReportTaskContentDto
     */
    'company': string;
    /**
     * The base of the report
     * @type {string}
     * @memberof ReportTaskContentDto
     */
    'base': string;
    /**
     * The zone of the report
     * @type {string}
     * @memberof ReportTaskContentDto
     */
    'zone': string;
    /**
     * The rate of the report
     * @type {string}
     * @memberof ReportTaskContentDto
     */
    'rate': string;
    /**
     * 
     * @type {AppraisalType}
     * @memberof ReportTaskContentDto
     */
    'appraisalType': AppraisalType;
    /**
     * The price of the report
     * @type {MoneyDto}
     * @memberof ReportTaskContentDto
     */
    'price'?: MoneyDto;
}
/**
 * 
 * @export
 * @interface ReviewerTaskActivityDataDto
 */
export interface ReviewerTaskActivityDataDto {
    /**
     * The previous reviewer user ID
     * @type {string}
     * @memberof ReviewerTaskActivityDataDto
     */
    'previous'?: string;
    /**
     * The current reviewer user ID
     * @type {string}
     * @memberof ReviewerTaskActivityDataDto
     */
    'current': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RoleType {
    Admin = 'admin',
    User = 'user'
}

/**
 * 
 * @export
 * @interface RouteDeletedResponse
 */
export interface RouteDeletedResponse {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RouteDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof RouteDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface RouteInfoResponse
 */
export interface RouteInfoResponse {
    /**
     * The total distance of the route plan in km
     * @type {number}
     * @memberof RouteInfoResponse
     */
    'distance': number;
    /**
     * The total duration of the route plan in seconds
     * @type {number}
     * @memberof RouteInfoResponse
     */
    'duration': number;
}
/**
 * 
 * @export
 * @interface RoutePaginatedResponse
 */
export interface RoutePaginatedResponse {
    /**
     * The items
     * @type {Array<RouteResponse>}
     * @memberof RoutePaginatedResponse
     */
    'items': Array<RouteResponse>;
    /**
     * The amount of returned items
     * @type {number}
     * @memberof RoutePaginatedResponse
     */
    'quantity': number;
    /**
     * The page returned
     * @type {number}
     * @memberof RoutePaginatedResponse
     */
    'page': number;
    /**
     * The amount of total pages
     * @type {number}
     * @memberof RoutePaginatedResponse
     */
    'pages': number;
    /**
     * The total amount of items
     * @type {number}
     * @memberof RoutePaginatedResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface RoutePlanRawRouteResponse
 */
export interface RoutePlanRawRouteResponse {
    /**
     * The ID of the raw route from the Google Maps service
     * @type {string}
     * @memberof RoutePlanRawRouteResponse
     */
    'googleMapsId': string;
}
/**
 * 
 * @export
 * @interface RoutePlanResponse
 */
export interface RoutePlanResponse {
    /**
     * Title of the route plan
     * @type {string}
     * @memberof RoutePlanResponse
     */
    'title': string;
    /**
     * The origin point for the route plan
     * @type {RoutePointResponse}
     * @memberof RoutePlanResponse
     */
    'origin': RoutePointResponse;
    /**
     * The destination point for the route plan
     * @type {RoutePointResponse}
     * @memberof RoutePlanResponse
     */
    'destination': RoutePointResponse;
    /**
     * The waypoints of the route plan
     * @type {Array<RoutePointResponse>}
     * @memberof RoutePlanResponse
     */
    'waypoints': Array<RoutePointResponse>;
    /**
     * The info of the route plan
     * @type {RouteInfoResponse}
     * @memberof RoutePlanResponse
     */
    'info': RouteInfoResponse;
    /**
     * The URL to the route in the maps\' provider
     * @type {string}
     * @memberof RoutePlanResponse
     */
    'url': string;
    /**
     * The raw route from the external service
     * @type {RoutePlanRawRouteResponse}
     * @memberof RoutePlanResponse
     */
    'raw': RoutePlanRawRouteResponse;
}
/**
 * 
 * @export
 * @interface RoutePointResponse
 */
export interface RoutePointResponse {
    /**
     * 
     * @type {RoutePointType}
     * @memberof RoutePointResponse
     */
    'type': RoutePointType;
    /**
     * The referred entity in the route point
     * @type {string}
     * @memberof RoutePointResponse
     */
    'referredId': string;
    /**
     * The label of the entity in the route point
     * @type {string}
     * @memberof RoutePointResponse
     */
    'label': string;
    /**
     * Coordinates of the route point
     * @type {string}
     * @memberof RoutePointResponse
     */
    'coordinates': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RoutePointType {
    Base = 'base',
    Task = 'task',
    Expert = 'expert'
}

/**
 * 
 * @export
 * @interface RouteResponse
 */
export interface RouteResponse {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'id': string;
    /**
     * The scheduled date of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'date': string;
    /**
     * 
     * @type {RouteStatusType}
     * @memberof RouteResponse
     */
    'status': RouteStatusType;
    /**
     * Assignee of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'assignee': string;
    /**
     * The route for the expert
     * @type {RoutePlanResponse}
     * @memberof RouteResponse
     */
    'route': RoutePlanResponse;
    /**
     * The routes for the companies. Only available for admins.
     * @type {Array<RoutePlanResponse>}
     * @memberof RouteResponse
     */
    'routes'?: Array<RoutePlanResponse>;
    /**
     * Tasks for the route
     * @type {Array<RouteTaskResponse>}
     * @memberof RouteResponse
     */
    'tasks': Array<RouteTaskResponse>;
    /**
     * Rate to apply to the route
     * @type {string}
     * @memberof RouteResponse
     */
    'rate': string;
    /**
     * Title of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'title'?: string;
    /**
     * Notes of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'notes'?: string;
    /**
     * The result of the route
     * @type {RouteResultResponse}
     * @memberof RouteResponse
     */
    'result'?: RouteResultResponse;
    /**
     * The creation date of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'createdAt': string;
    /**
     * The update date of the route
     * @type {string}
     * @memberof RouteResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface RouteResultResponse
 */
export interface RouteResultResponse {
    /**
     * The total distance done for the route in km
     * @type {number}
     * @memberof RouteResultResponse
     */
    'distance': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RouteSortType {
    Id = 'id',
    Deadline = 'deadline',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum RouteStatusType {
    PendingApproval = 'pendingApproval',
    Ready = 'ready',
    Completed = 'completed',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @interface RouteTaskResponse
 */
export interface RouteTaskResponse {
    /**
     * The task ID in the route
     * @type {string}
     * @memberof RouteTaskResponse
     */
    'id': string;
    /**
     * 
     * @type {RouteTaskStatusType}
     * @memberof RouteTaskResponse
     */
    'status': RouteTaskStatusType;
    /**
     * The visit date
     * @type {string}
     * @memberof RouteTaskResponse
     */
    'visitedAt'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RouteTaskStatusType {
    Pending = 'pending',
    Visited = 'visited',
    Failed = 'failed',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SortDir {
    Asc = 'asc',
    Desc = 'desc'
}

/**
 * 
 * @export
 * @interface StateChangedTaskActivityDataDto
 */
export interface StateChangedTaskActivityDataDto {
    /**
     * 
     * @type {TaskState}
     * @memberof StateChangedTaskActivityDataDto
     */
    'previous': TaskState;
    /**
     * 
     * @type {TaskState}
     * @memberof StateChangedTaskActivityDataDto
     */
    'current': TaskState;
}
/**
 * 
 * @export
 * @interface StorageMediaResponse
 */
export interface StorageMediaResponse {
    /**
     * Identifier of the media in the storage
     * @type {string}
     * @memberof StorageMediaResponse
     */
    'fileId': string;
    /**
     * Mime type of the file
     * @type {string}
     * @memberof StorageMediaResponse
     */
    'mimeType': string;
    /**
     * The metadata of the file
     * @type {object}
     * @memberof StorageMediaResponse
     */
    'metadata'?: object;
    /**
     * The thumbnail of the media
     * @type {ThumbnailResponse}
     * @memberof StorageMediaResponse
     */
    'thumbnail'?: ThumbnailResponse;
}
/**
 * 
 * @export
 * @interface TaskActivityDto
 */
export interface TaskActivityDto {
    /**
     * The entries of the activity section
     * @type {Array<TaskActivityEntryDto>}
     * @memberof TaskActivityDto
     */
    'entries': Array<TaskActivityEntryDto>;
}
/**
 * 
 * @export
 * @interface TaskActivityEntryDto
 */
export interface TaskActivityEntryDto {
    /**
     * Identifier of the activity entry
     * @type {string}
     * @memberof TaskActivityEntryDto
     */
    'id': string;
    /**
     * 
     * @type {TaskActivityType}
     * @memberof TaskActivityEntryDto
     */
    'type': TaskActivityType;
    /**
     * The activity data
     * @type {any}
     * @memberof TaskActivityEntryDto
     */
    'data': any;
    /**
     * The author that triggered the event
     * @type {string}
     * @memberof TaskActivityEntryDto
     */
    'author': string;
    /**
     * The timestamp of the comment
     * @type {string}
     * @memberof TaskActivityEntryDto
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskActivityType {
    Created = 'created',
    Deleted = 'deleted',
    AssigneeChanged = 'assigneeChanged',
    ReviewerChanged = 'reviewerChanged',
    StateChanged = 'stateChanged'
}

/**
 * 
 * @export
 * @interface TaskAssignedNotificationContentDto
 */
export interface TaskAssignedNotificationContentDto {
    /**
     * 
     * @type {NotificationType}
     * @memberof TaskAssignedNotificationContentDto
     */
    'type': NotificationType;
    /**
     * The task related to the notification
     * @type {string}
     * @memberof TaskAssignedNotificationContentDto
     */
    'task': string;
    /**
     * The reference of the task related to the notification
     * @type {string}
     * @memberof TaskAssignedNotificationContentDto
     */
    'reference': string;
    /**
     * The assignee to the task related to the notification
     * @type {string}
     * @memberof TaskAssignedNotificationContentDto
     */
    'assignee': string;
}
/**
 * 
 * @export
 * @interface TaskCommentAddedNotificationContentDto
 */
export interface TaskCommentAddedNotificationContentDto {
    /**
     * 
     * @type {NotificationType}
     * @memberof TaskCommentAddedNotificationContentDto
     */
    'type': NotificationType;
    /**
     * The task related to the notification
     * @type {string}
     * @memberof TaskCommentAddedNotificationContentDto
     */
    'task': string;
    /**
     * The reference of the task related to the notification
     * @type {string}
     * @memberof TaskCommentAddedNotificationContentDto
     */
    'reference': string;
    /**
     * The comment of the task related to the notification
     * @type {string}
     * @memberof TaskCommentAddedNotificationContentDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface TaskCommentEntryDto
 */
export interface TaskCommentEntryDto {
    /**
     * Identifier of the comment entry
     * @type {string}
     * @memberof TaskCommentEntryDto
     */
    'id': string;
    /**
     * The comment text
     * @type {string}
     * @memberof TaskCommentEntryDto
     */
    'comment': string;
    /**
     * The author of the comment
     * @type {string}
     * @memberof TaskCommentEntryDto
     */
    'author': string;
    /**
     * The timestamp of the comment
     * @type {string}
     * @memberof TaskCommentEntryDto
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface TaskCommentsDto
 */
export interface TaskCommentsDto {
    /**
     * The entries of the comments section
     * @type {Array<TaskCommentEntryDto>}
     * @memberof TaskCommentsDto
     */
    'entries': Array<TaskCommentEntryDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskContentType {
    Report = 'report',
    Homologation = 'homologation',
    Recognition = 'recognition'
}

/**
 * 
 * @export
 * @interface TaskDeletedResponse
 */
export interface TaskDeletedResponse {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TaskDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof TaskDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface TaskDocumentAddedNotificationContentDto
 */
export interface TaskDocumentAddedNotificationContentDto {
    /**
     * 
     * @type {NotificationType}
     * @memberof TaskDocumentAddedNotificationContentDto
     */
    'type': NotificationType;
    /**
     * The task related to the notification
     * @type {string}
     * @memberof TaskDocumentAddedNotificationContentDto
     */
    'task': string;
    /**
     * The reference of the task related to the notification
     * @type {string}
     * @memberof TaskDocumentAddedNotificationContentDto
     */
    'reference': string;
    /**
     * The document of the task related to the notification
     * @type {string}
     * @memberof TaskDocumentAddedNotificationContentDto
     */
    'document': string;
}
/**
 * 
 * @export
 * @interface TaskDocumentNotificationPreferencesDto
 */
export interface TaskDocumentNotificationPreferencesDto {
    /**
     * 
     * @type {TaskNotificationsReceiverPreferenceType}
     * @memberof TaskDocumentNotificationPreferencesDto
     */
    'receiver': TaskNotificationsReceiverPreferenceType;
    /**
     * The document preferences for tasks notifications
     * @type {Array<TaskDocumentTagType>}
     * @memberof TaskDocumentNotificationPreferencesDto
     */
    'tags': Array<TaskDocumentTagType>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskDocumentTagType {
    Document = 'document',
    Report = 'report',
    Photo = 'photo'
}

/**
 * 
 * @export
 * @interface TaskFiltersDto
 */
export interface TaskFiltersDto {
    /**
     * Filter the tasks that match any of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'ids'?: Array<string>;
    /**
     * Filter the tasks which reference matches the given string
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'reference'?: string;
    /**
     * Filter the tasks which text fields match the given terms
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'contains'?: string;
    /**
     * Filter the tasks that match any of the give states
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'states'?: Array<TaskFiltersDtoStatesEnum>;
    /**
     * Filter the tasks that have a deadline
     * @type {boolean}
     * @memberof TaskFiltersDto
     */
    'hasDeadline'?: boolean;
    /**
     * Filter the tasks that have a deadline from the given date onwards
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'deadlineFrom'?: string;
    /**
     * Filter the tasks that have a deadline until the given date
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'deadlineTo'?: string;
    /**
     * Filter the tasks that have an assigned user
     * @type {boolean}
     * @memberof TaskFiltersDto
     */
    'isAssigned'?: boolean;
    /**
     * Filter the tasks that have an assigned which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'assigneeIds'?: Array<string>;
    /**
     * Filter the tasks that have a reviewer user
     * @type {boolean}
     * @memberof TaskFiltersDto
     */
    'hasReviewer'?: boolean;
    /**
     * Filter the tasks that have a reviewer which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'reviewerIds'?: Array<string>;
    /**
     * Filter the tasks that have a label which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'labelIds'?: Array<string>;
    /**
     * Filter the tasks that were created from the given date onwards
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'createdFrom'?: string;
    /**
     * Filter the tasks that were created until the given date
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'createdTo'?: string;
    /**
     * Filter the tasks that were updated from the given date onwards
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'updatedFrom'?: string;
    /**
     * Filter the tasks that were updated until the given date
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'updatedTo'?: string;
    /**
     * Filter the tasks that were closed from the given date onwards
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'closedFrom'?: string;
    /**
     * Filter the tasks that were closed until the given date
     * @type {string}
     * @memberof TaskFiltersDto
     */
    'closedTo'?: string;
    /**
     * Filter the tasks that match any of the give types
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'types'?: Array<TaskFiltersDtoTypesEnum>;
    /**
     * Filter the tasks that have any company associated
     * @type {boolean}
     * @memberof TaskFiltersDto
     */
    'hasCompany'?: boolean;
    /**
     * Filter the tasks that have a company which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'companyIds'?: Array<string>;
    /**
     * Filter the tasks that have a base which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'baseIds'?: Array<string>;
    /**
     * Filter the tasks that have any zone associated
     * @type {boolean}
     * @memberof TaskFiltersDto
     */
    'hasZone'?: boolean;
    /**
     * Filter the tasks that have a zone which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'zoneIds'?: Array<string>;
    /**
     * Filter the tasks that match any of the give appraisal types
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'appraisalTypes'?: Array<TaskFiltersDtoAppraisalTypesEnum>;
    /**
     * Filter the tasks that have any client associated
     * @type {boolean}
     * @memberof TaskFiltersDto
     */
    'hasClient'?: boolean;
    /**
     * Filter the tasks that have a client which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TaskFiltersDto
     */
    'clientIds'?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum TaskFiltersDtoStatesEnum {
    Draft = 'draft',
    Open = 'open',
    Contact = 'contact',
    Visit = 'visit',
    BuildReport = 'buildReport',
    ReviewReport = 'reviewReport',
    InLaboratory = 'inLaboratory',
    Accepted = 'accepted',
    InInspection = 'inInspection',
    Close = 'close',
    Cancelled = 'cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskFiltersDtoTypesEnum {
    Report = 'report',
    Homologation = 'homologation',
    Recognition = 'recognition'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskFiltersDtoAppraisalTypesEnum {
    InPerson = 'inPerson',
    Remote = 'remote'
}

/**
 * 
 * @export
 * @interface TaskIncidenceAddedNotificationContentDto
 */
export interface TaskIncidenceAddedNotificationContentDto {
    /**
     * 
     * @type {NotificationType}
     * @memberof TaskIncidenceAddedNotificationContentDto
     */
    'type': NotificationType;
    /**
     * The task related to the notification
     * @type {string}
     * @memberof TaskIncidenceAddedNotificationContentDto
     */
    'task': string;
    /**
     * The reference of the task related to the notification
     * @type {string}
     * @memberof TaskIncidenceAddedNotificationContentDto
     */
    'reference': string;
    /**
     * The incidence of the task related to the notification
     * @type {string}
     * @memberof TaskIncidenceAddedNotificationContentDto
     */
    'incidence': string;
}
/**
 * 
 * @export
 * @interface TaskIncidenceDto
 */
export interface TaskIncidenceDto {
    /**
     * Identifier of the incidence entry
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'id': string;
    /**
     * The incidence title
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'title': string;
    /**
     * 
     * @type {TaskIncidenceState}
     * @memberof TaskIncidenceDto
     */
    'state': TaskIncidenceState;
    /**
     * The incidence description
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'description'?: string;
    /**
     * The deadline of the incidence
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'deadline'?: string;
    /**
     * The close date of the incidence
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'closedAt'?: string;
    /**
     * The author of the comment
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'author': string;
    /**
     * The creation date of the incidence
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'createdAt': string;
    /**
     * The update date of the incidence
     * @type {string}
     * @memberof TaskIncidenceDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TaskIncidenceResponse
 */
export interface TaskIncidenceResponse {
    /**
     * The task updated with the new incidence
     * @type {TaskResponse}
     * @memberof TaskIncidenceResponse
     */
    'task': TaskResponse;
    /**
     * The new incidence
     * @type {TaskIncidenceDto}
     * @memberof TaskIncidenceResponse
     */
    'incidence': TaskIncidenceDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskIncidenceState {
    Pending = 'pending',
    Done = 'done'
}

/**
 * 
 * @export
 * @interface TaskIncidencesDto
 */
export interface TaskIncidencesDto {
    /**
     * The entries of the incidences section
     * @type {Array<TaskIncidenceDto>}
     * @memberof TaskIncidencesDto
     */
    'entries': Array<TaskIncidenceDto>;
}
/**
 * 
 * @export
 * @interface TaskInvoiceDto
 */
export interface TaskInvoiceDto {
    /**
     * Whether the invoice was collected
     * @type {boolean}
     * @memberof TaskInvoiceDto
     */
    'collected': boolean;
}
/**
 * 
 * @export
 * @interface TaskNotificationPreferencesDto
 */
export interface TaskNotificationPreferencesDto {
    /**
     * 
     * @type {TaskNotificationsReceiverPreferenceType}
     * @memberof TaskNotificationPreferencesDto
     */
    'receiver': TaskNotificationsReceiverPreferenceType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskNotificationsReceiverPreferenceType {
    Always = 'always',
    IfAssignee = 'ifAssignee',
    IfReviewer = 'ifReviewer',
    Never = 'never'
}

/**
 * 
 * @export
 * @interface TaskResponse
 */
export interface TaskResponse {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'id': string;
    /**
     * The reference of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'reference': string;
    /**
     * Title of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'title'?: string;
    /**
     * The description of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'description'?: string;
    /**
     * 
     * @type {TaskState}
     * @memberof TaskResponse
     */
    'state': TaskState;
    /**
     * The deadline of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'deadline'?: string;
    /**
     * The assignee of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'assignee'?: string;
    /**
     * The reviewer of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'reviewer'?: string;
    /**
     * The date when the task was assigned
     * @type {string}
     * @memberof TaskResponse
     */
    'assignedAt'?: string;
    /**
     * The labels of the task
     * @type {Array<string>}
     * @memberof TaskResponse
     */
    'labels'?: Array<string>;
    /**
     * The documents of the task
     * @type {Array<MediaResponse>}
     * @memberof TaskResponse
     */
    'documents': Array<MediaResponse>;
    /**
     * The activity of the task
     * @type {TaskActivityDto}
     * @memberof TaskResponse
     */
    'activity': TaskActivityDto;
    /**
     * The comments of the task
     * @type {TaskCommentsDto}
     * @memberof TaskResponse
     */
    'comments': TaskCommentsDto;
    /**
     * The incidences of the task
     * @type {TaskIncidencesDto}
     * @memberof TaskResponse
     */
    'incidences': TaskIncidencesDto;
    /**
     * The current version of the task
     * @type {number}
     * @memberof TaskResponse
     */
    'currentVersion': number;
    /**
     * The versions of the task
     * @type {Array<TaskVersionDto>}
     * @memberof TaskResponse
     */
    'versions': Array<TaskVersionDto>;
    /**
     * The content of the task
     * @type {ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto}
     * @memberof TaskResponse
     */
    'content': ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto;
    /**
     * The creation date of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'createdAt': string;
    /**
     * The update date of the task
     * @type {string}
     * @memberof TaskResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TaskReviewAssignedNotificationContentDto
 */
export interface TaskReviewAssignedNotificationContentDto {
    /**
     * 
     * @type {NotificationType}
     * @memberof TaskReviewAssignedNotificationContentDto
     */
    'type': NotificationType;
    /**
     * The task related to the notification
     * @type {string}
     * @memberof TaskReviewAssignedNotificationContentDto
     */
    'task': string;
    /**
     * The reference of the task related to the notification
     * @type {string}
     * @memberof TaskReviewAssignedNotificationContentDto
     */
    'reference': string;
    /**
     * The reviewer of the task related to the notification
     * @type {string}
     * @memberof TaskReviewAssignedNotificationContentDto
     */
    'reviewer': string;
}
/**
 * 
 * @export
 * @interface TaskSearchDeletedResponse
 */
export interface TaskSearchDeletedResponse {
    /**
     * Identifier of the task search
     * @type {string}
     * @memberof TaskSearchDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof TaskSearchDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface TaskSearchRequest
 */
export interface TaskSearchRequest {
    /**
     * Title of the task search
     * @type {string}
     * @memberof TaskSearchRequest
     */
    'title': string;
    /**
     * Filters of the search
     * @type {TaskFiltersDto}
     * @memberof TaskSearchRequest
     */
    'filters'?: TaskFiltersDto;
    /**
     * Order of the search
     * @type {TaskSortDto}
     * @memberof TaskSearchRequest
     */
    'sort'?: TaskSortDto;
    /**
     * Position of the of the search
     * @type {number}
     * @memberof TaskSearchRequest
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface TaskSearchResponse
 */
export interface TaskSearchResponse {
    /**
     * Identifier of the task search
     * @type {string}
     * @memberof TaskSearchResponse
     */
    'id': string;
    /**
     * Title of the task search
     * @type {string}
     * @memberof TaskSearchResponse
     */
    'title': string;
    /**
     * Owner of the task search
     * @type {string}
     * @memberof TaskSearchResponse
     */
    'owner'?: string;
    /**
     * Filters of the search
     * @type {TaskFiltersDto}
     * @memberof TaskSearchResponse
     */
    'filters': TaskFiltersDto;
    /**
     * Order of the search
     * @type {TaskSortDto}
     * @memberof TaskSearchResponse
     */
    'sort': TaskSortDto;
    /**
     * Position of the of the search
     * @type {number}
     * @memberof TaskSearchResponse
     */
    'position': number;
    /**
     * The creation date of the search
     * @type {string}
     * @memberof TaskSearchResponse
     */
    'createdAt': string;
    /**
     * The update date of the search
     * @type {string}
     * @memberof TaskSearchResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TaskSortDto
 */
export interface TaskSortDto {
    /**
     * 
     * @type {TaskSortType}
     * @memberof TaskSortDto
     */
    'sortBy'?: TaskSortType;
    /**
     * 
     * @type {SortDir}
     * @memberof TaskSortDto
     */
    'sortDir'?: SortDir;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskSortType {
    Id = 'id',
    Reference = 'reference',
    State = 'state',
    Deadline = 'deadline',
    Assignee = 'assignee',
    Reviewer = 'reviewer',
    Labels = 'labels',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Type = 'type',
    Company = 'company',
    Zone = 'zone'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TaskState {
    Draft = 'draft',
    Open = 'open',
    Contact = 'contact',
    Visit = 'visit',
    BuildReport = 'buildReport',
    ReviewReport = 'reviewReport',
    InLaboratory = 'inLaboratory',
    Accepted = 'accepted',
    InInspection = 'inInspection',
    Close = 'close',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @interface TaskStateCountResponse
 */
export interface TaskStateCountResponse {
    /**
     * The amount of open tasks for the given user
     * @type {number}
     * @memberof TaskStateCountResponse
     */
    'open': number;
    /**
     * The amount of on going tasks for the given user
     * @type {number}
     * @memberof TaskStateCountResponse
     */
    'onGoing': number;
    /**
     * The amount of reviewing tasks for the given user
     * @type {number}
     * @memberof TaskStateCountResponse
     */
    'reviewing': number;
}
/**
 * 
 * @export
 * @interface TaskVersionDto
 */
export interface TaskVersionDto {
    /**
     * The version of the task
     * @type {number}
     * @memberof TaskVersionDto
     */
    'version': number;
    /**
     * The timestamp of the moment a task was closed in a given version
     * @type {string}
     * @memberof TaskVersionDto
     */
    'closedAt'?: string;
    /**
     * The invoice out of the task
     * @type {TaskInvoiceDto}
     * @memberof TaskVersionDto
     */
    'invoice': TaskInvoiceDto;
}
/**
 * 
 * @export
 * @interface ThumbnailResponse
 */
export interface ThumbnailResponse {
    /**
     * The ID of the file
     * @type {string}
     * @memberof ThumbnailResponse
     */
    'fileId': string;
    /**
     * Whether this file has a thumbnail
     * @type {boolean}
     * @memberof ThumbnailResponse
     */
    'hasThumbnail': boolean;
    /**
     * A short-lived link to the file\'s thumbnail, if available. Typically lasts on the order of hours
     * @type {string}
     * @memberof ThumbnailResponse
     */
    'thumbnailLink'?: string;
    /**
     * The thumbnail version for use in thumbnail cache invalidation
     * @type {string}
     * @memberof ThumbnailResponse
     */
    'thumbnailVersion'?: string;
}
/**
 * 
 * @export
 * @interface TodayDashboardResponse
 */
export interface TodayDashboardResponse {
    /**
     * The counters on the ongoing work
     * @type {TaskStateCountResponse}
     * @memberof TodayDashboardResponse
     */
    'amount': TaskStateCountResponse;
    /**
     * The tasks assigned to the user which due date ends today
     * @type {ListTasksResponse}
     * @memberof TodayDashboardResponse
     */
    'tasksForToday': ListTasksResponse;
    /**
     * The tasks for review of the user which due date ends today
     * @type {ListTasksResponse}
     * @memberof TodayDashboardResponse
     */
    'reviewsForToday': ListTasksResponse;
}
/**
 * 
 * @export
 * @interface UpdateCompanyRequest
 */
export interface UpdateCompanyRequest {
    /**
     * Name of the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {CompanyType}
     * @memberof UpdateCompanyRequest
     */
    'type': CompanyType;
    /**
     * Email of the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'email'?: string;
    /**
     * Phone of the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'phone'?: string;
    /**
     * Note for the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'note'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'taxName'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'taxNumber'?: string;
    /**
     * Tax name of the company
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'taxAddress'?: string;
    /**
     * The settings of the company
     * @type {InsuranceCompanySettingsRequest | CompanySettingsRequest}
     * @memberof UpdateCompanyRequest
     */
    'settings': InsuranceCompanySettingsRequest | CompanySettingsRequest;
}
/**
 * 
 * @export
 * @interface UpdateNotificationsRequest
 */
export interface UpdateNotificationsRequest {
    /**
     * Identifiers of the notifications
     * @type {Array<string>}
     * @memberof UpdateNotificationsRequest
     */
    'ids': Array<string>;
    /**
     * 
     * @type {UpdateOperationType}
     * @memberof UpdateNotificationsRequest
     */
    'operation': UpdateOperationType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UpdateOperationType {
    Seen = 'seen',
    Read = 'read'
}

/**
 * 
 * @export
 * @interface UpdateRouteRequest
 */
export interface UpdateRouteRequest {
    /**
     * The scheduled date of the route
     * @type {string}
     * @memberof UpdateRouteRequest
     */
    'date': string;
    /**
     * Rate to apply to the route
     * @type {string}
     * @memberof UpdateRouteRequest
     */
    'rate': string;
    /**
     * Title of the route
     * @type {string}
     * @memberof UpdateRouteRequest
     */
    'title'?: string;
    /**
     * Notes of the route
     * @type {string}
     * @memberof UpdateRouteRequest
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRouteTaskRequest
 */
export interface UpdateRouteTaskRequest {
    /**
     * 
     * @type {RouteTaskStatusType}
     * @memberof UpdateRouteTaskRequest
     */
    'status': RouteTaskStatusType;
}
/**
 * 
 * @export
 * @interface UpdateRouteTasksRequest
 */
export interface UpdateRouteTasksRequest {
    /**
     * Tasks for the route
     * @type {Array<string>}
     * @memberof UpdateRouteTasksRequest
     */
    'tasks': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSelfUserRequest
 */
export interface UpdateSelfUserRequest {
    /**
     * Name of the user
     * @type {string}
     * @memberof UpdateSelfUserRequest
     */
    'name': string;
    /**
     * Language of the user
     * @type {string}
     * @memberof UpdateSelfUserRequest
     */
    'language': string;
    /**
     * Phone of the user
     * @type {string}
     * @memberof UpdateSelfUserRequest
     */
    'phone'?: string;
    /**
     * Location of the user. Used to calculate the distance to the incidences.
     * @type {string}
     * @memberof UpdateSelfUserRequest
     */
    'location': string;
    /**
     * Location name of the user.
     * @type {string}
     * @memberof UpdateSelfUserRequest
     */
    'locationName': string;
}
/**
 * 
 * @export
 * @interface UpdateTaskIncidenceRequest
 */
export interface UpdateTaskIncidenceRequest {
    /**
     * The incidence title
     * @type {string}
     * @memberof UpdateTaskIncidenceRequest
     */
    'title': string;
    /**
     * 
     * @type {TaskIncidenceState}
     * @memberof UpdateTaskIncidenceRequest
     */
    'state': TaskIncidenceState;
    /**
     * The incidence description
     * @type {string}
     * @memberof UpdateTaskIncidenceRequest
     */
    'description'?: string;
    /**
     * The deadline of the incidence
     * @type {string}
     * @memberof UpdateTaskIncidenceRequest
     */
    'deadline'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTaskRequest
 */
export interface UpdateTaskRequest {
    /**
     * The reference of the task
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'reference': string;
    /**
     * Title of the task
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'title'?: string;
    /**
     * The description of the task
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'state': string;
    /**
     * The deadline of the task
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'deadline'?: string;
    /**
     * The assignee of the task
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'assignee'?: string;
    /**
     * The reviewer of the task
     * @type {string}
     * @memberof UpdateTaskRequest
     */
    'reviewer'?: string;
    /**
     * The labels of the task
     * @type {Array<string>}
     * @memberof UpdateTaskRequest
     */
    'labels'?: Array<string>;
    /**
     * The content of the task
     * @type {ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto}
     * @memberof UpdateTaskRequest
     */
    'content': ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto;
}
/**
 * 
 * @export
 * @interface UpdateTaskSearchRequest
 */
export interface UpdateTaskSearchRequest {
    /**
     * The ID of the task search
     * @type {string}
     * @memberof UpdateTaskSearchRequest
     */
    'id': string;
    /**
     * The position of the task search
     * @type {number}
     * @memberof UpdateTaskSearchRequest
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface UpdateTaskSearchesRequest
 */
export interface UpdateTaskSearchesRequest {
    /**
     * 
     * @type {Array<UpdateTaskSearchRequest>}
     * @memberof UpdateTaskSearchesRequest
     */
    'searches': Array<UpdateTaskSearchRequest>;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * Name of the user
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'name': string;
    /**
     * 
     * @type {RoleType}
     * @memberof UpdateUserRequest
     */
    'role': RoleType;
    /**
     * Claims of the user
     * @type {Array<ClaimType>}
     * @memberof UpdateUserRequest
     */
    'claims': Array<ClaimType>;
    /**
     * Language of the user
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'language': string;
    /**
     * Location of the user. Used to calculate the distance to the incidences.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'location': string;
    /**
     * Location name of the user.
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'locationName': string;
    /**
     * 
     * @type {ContractType}
     * @memberof UpdateUserRequest
     */
    'contract': ContractType;
    /**
     * Phone of the user
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'phone'?: string;
    /**
     * Whether the user has been disabled
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'disabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateWorkspaceRequest
 */
export interface UpdateWorkspaceRequest {
    /**
     * Title of the workspace
     * @type {string}
     * @memberof UpdateWorkspaceRequest
     */
    'title': string;
    /**
     * Color of the workspace
     * @type {string}
     * @memberof UpdateWorkspaceRequest
     */
    'color'?: string;
    /**
     * Order of the columns
     * @type {Array<ColumnOrderDto>}
     * @memberof UpdateWorkspaceRequest
     */
    'order': Array<ColumnOrderDto>;
}
/**
 * 
 * @export
 * @interface UserDeletedResponse
 */
export interface UserDeletedResponse {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UserDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof UserDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UserResponse
     */
    'id': string;
    /**
     * The name of the user for login purposes. Unique across the application
     * @type {string}
     * @memberof UserResponse
     */
    'username': string;
    /**
     * Name of the user
     * @type {string}
     * @memberof UserResponse
     */
    'name': string;
    /**
     * 
     * @type {RoleType}
     * @memberof UserResponse
     */
    'role': RoleType;
    /**
     * Claims of the user
     * @type {Array<ClaimType>}
     * @memberof UserResponse
     */
    'claims': Array<ClaimType>;
    /**
     * Language of the user
     * @type {string}
     * @memberof UserResponse
     */
    'language': string;
    /**
     * Location of the user. Used to calculate the distance to the incidences.
     * @type {string}
     * @memberof UserResponse
     */
    'location': string;
    /**
     * Location name of the user.
     * @type {string}
     * @memberof UserResponse
     */
    'locationName': string;
    /**
     * 
     * @type {ContractType}
     * @memberof UserResponse
     */
    'contract': ContractType;
    /**
     * The avatar of the user
     * @type {MediaResponse}
     * @memberof UserResponse
     */
    'avatar'?: MediaResponse;
    /**
     * Phone of the user
     * @type {string}
     * @memberof UserResponse
     */
    'phone'?: string;
    /**
     * Whether the user has been disabled
     * @type {boolean}
     * @memberof UserResponse
     */
    'disabled'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkspaceDeletedResponse
 */
export interface WorkspaceDeletedResponse {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspaceDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof WorkspaceDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface WorkspaceResponse
 */
export interface WorkspaceResponse {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'id': string;
    /**
     * Title of the workspace
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'title': string;
    /**
     * Color of the workspace
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'color'?: string;
    /**
     * Owner of the workspace
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'owner'?: string;
    /**
     * Columns of the workspace
     * @type {Array<ColumnResponse>}
     * @memberof WorkspaceResponse
     */
    'columns': Array<ColumnResponse>;
    /**
     * Order of the columns
     * @type {Array<ColumnOrderDto>}
     * @memberof WorkspaceResponse
     */
    'order': Array<ColumnOrderDto>;
    /**
     * The creation date of the workspace
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'createdAt': string;
    /**
     * The update date of the workspace
     * @type {string}
     * @memberof WorkspaceResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ZoneDeletedResponse
 */
export interface ZoneDeletedResponse {
    /**
     * Identifier of the zone
     * @type {string}
     * @memberof ZoneDeletedResponse
     */
    'id': string;
    /**
     * The boolean that states if the entity with the given ID has been deleted.
     * @type {boolean}
     * @memberof ZoneDeletedResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface ZoneRequest
 */
export interface ZoneRequest {
    /**
     * Name of the zone
     * @type {string}
     * @memberof ZoneRequest
     */
    'name': string;
    /**
     * Description of the zone
     * @type {string}
     * @memberof ZoneRequest
     */
    'description'?: string;
    /**
     * Coordinates of the zone
     * @type {string}
     * @memberof ZoneRequest
     */
    'coordinates': string;
}
/**
 * 
 * @export
 * @interface ZoneResponse
 */
export interface ZoneResponse {
    /**
     * Identifier of the zone
     * @type {string}
     * @memberof ZoneResponse
     */
    'id': string;
    /**
     * Name of the zone
     * @type {string}
     * @memberof ZoneResponse
     */
    'name': string;
    /**
     * Description of the zone
     * @type {string}
     * @memberof ZoneResponse
     */
    'description'?: string;
    /**
     * Coordinates of the zone
     * @type {string}
     * @memberof ZoneResponse
     */
    'coordinates': string;
    /**
     * The creation date of the zone
     * @type {string}
     * @memberof ZoneResponse
     */
    'createdAt': string;
    /**
     * The update date of the zone
     * @type {string}
     * @memberof ZoneResponse
     */
    'updatedAt': string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends en email to the user to recover the password
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest: ForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log in the system using the user credentials
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the new password for the user
         * @param {RecoverPasswordRequest} recoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPassword: async (recoverPasswordRequest: RecoverPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recoverPasswordRequest' is not null or undefined
            assertParamExists('recoverPassword', 'recoverPasswordRequest', recoverPasswordRequest)
            const localVarPath = `/auth/recover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recoverPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sends en email to the user to recover the password
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log in the system using the user credentials
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the new password for the user
         * @param {RecoverPasswordRequest} recoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverPassword(recoverPasswordRequest: RecoverPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverPassword(recoverPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Sends en email to the user to recover the password
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log in the system using the user credentials
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the new password for the user
         * @param {RecoverPasswordRequest} recoverPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPassword(recoverPasswordRequest: RecoverPasswordRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.recoverPassword(recoverPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forgotPassword operation in AuthApi.
 * @export
 * @interface AuthApiForgotPasswordRequest
 */
export interface AuthApiForgotPasswordRequest {
    /**
     * 
     * @type {ForgotPasswordRequest}
     * @memberof AuthApiForgotPassword
     */
    readonly forgotPasswordRequest: ForgotPasswordRequest
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * 
     * @type {LoginRequest}
     * @memberof AuthApiLogin
     */
    readonly loginRequest: LoginRequest
}

/**
 * Request parameters for recoverPassword operation in AuthApi.
 * @export
 * @interface AuthApiRecoverPasswordRequest
 */
export interface AuthApiRecoverPasswordRequest {
    /**
     * 
     * @type {RecoverPasswordRequest}
     * @memberof AuthApiRecoverPassword
     */
    readonly recoverPasswordRequest: RecoverPasswordRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Sends en email to the user to recover the password
     * @param {AuthApiForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(requestParameters: AuthApiForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).forgotPassword(requestParameters.forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log in the system using the user credentials
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(requestParameters.loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the new password for the user
     * @param {AuthApiRecoverPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public recoverPassword(requestParameters: AuthApiRecoverPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).recoverPassword(requestParameters.recoverPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new client with the given data
         * @param {ClientRequest} clientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient: async (clientRequest: ClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientRequest' is not null or undefined
            assertParamExists('createClient', 'clientRequest', clientRequest)
            const localVarPath = `/api/v1/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the client matching the given ID
         * @param {string} id Identifier of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClient', 'id', id)
            const localVarPath = `/api/v1/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the client matching the given ID
         * @param {string} id Identifier of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClient', 'id', id)
            const localVarPath = `/api/v1/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the client with the given data
         * @param {string} id Identifier of the client
         * @param {ClientRequest} clientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (id: string, clientRequest: ClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClient', 'id', id)
            // verify required parameter 'clientRequest' is not null or undefined
            assertParamExists('updateClient', 'clientRequest', clientRequest)
            const localVarPath = `/api/v1/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new client with the given data
         * @param {ClientRequest} clientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClient(clientRequest: ClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(clientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the client matching the given ID
         * @param {string} id Identifier of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the client matching the given ID
         * @param {string} id Identifier of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the client with the given data
         * @param {string} id Identifier of the client
         * @param {ClientRequest} clientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(id: string, clientRequest: ClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(id, clientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new client with the given data
         * @param {ClientRequest} clientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(clientRequest: ClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.createClient(clientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the client matching the given ID
         * @param {string} id Identifier of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(id: string, options?: any): AxiosPromise<ClientDeletedResponse> {
            return localVarFp.deleteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients(options?: any): AxiosPromise<Array<ClientResponse>> {
            return localVarFp.getAllClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the client matching the given ID
         * @param {string} id Identifier of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient(id: string, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.getClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the client with the given data
         * @param {string} id Identifier of the client
         * @param {ClientRequest} clientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(id: string, clientRequest: ClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.updateClient(id, clientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createClient operation in ClientsApi.
 * @export
 * @interface ClientsApiCreateClientRequest
 */
export interface ClientsApiCreateClientRequest {
    /**
     * 
     * @type {ClientRequest}
     * @memberof ClientsApiCreateClient
     */
    readonly clientRequest: ClientRequest
}

/**
 * Request parameters for deleteClient operation in ClientsApi.
 * @export
 * @interface ClientsApiDeleteClientRequest
 */
export interface ClientsApiDeleteClientRequest {
    /**
     * Identifier of the client
     * @type {string}
     * @memberof ClientsApiDeleteClient
     */
    readonly id: string
}

/**
 * Request parameters for getClient operation in ClientsApi.
 * @export
 * @interface ClientsApiGetClientRequest
 */
export interface ClientsApiGetClientRequest {
    /**
     * Identifier of the client
     * @type {string}
     * @memberof ClientsApiGetClient
     */
    readonly id: string
}

/**
 * Request parameters for updateClient operation in ClientsApi.
 * @export
 * @interface ClientsApiUpdateClientRequest
 */
export interface ClientsApiUpdateClientRequest {
    /**
     * Identifier of the client
     * @type {string}
     * @memberof ClientsApiUpdateClient
     */
    readonly id: string

    /**
     * 
     * @type {ClientRequest}
     * @memberof ClientsApiUpdateClient
     */
    readonly clientRequest: ClientRequest
}

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new client with the given data
     * @param {ClientsApiCreateClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public createClient(requestParameters: ClientsApiCreateClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).createClient(requestParameters.clientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the client matching the given ID
     * @param {ClientsApiDeleteClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public deleteClient(requestParameters: ClientsApiDeleteClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).deleteClient(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getAllClients(options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getAllClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the client matching the given ID
     * @param {ClientsApiGetClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getClient(requestParameters: ClientsApiGetClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getClient(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the client with the given data
     * @param {ClientsApiUpdateClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public updateClient(requestParameters: ClientsApiUpdateClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).updateClient(requestParameters.id, requestParameters.clientRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new base to the company
         * @param {string} id Identifier of the company
         * @param {BaseRequest} baseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyBase: async (id: string, baseRequest: BaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addCompanyBase', 'id', id)
            // verify required parameter 'baseRequest' is not null or undefined
            assertParamExists('addCompanyBase', 'baseRequest', baseRequest)
            const localVarPath = `/api/v1/companies/{id}/bases`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new company with the given data
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (createCompanyRequest: CreateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompany', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the company matching the given ID
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompany', 'id', id)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a base from the company
         * @param {string} baseId Identifier of the base
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyBase: async (baseId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseId' is not null or undefined
            assertParamExists('deleteCompanyBase', 'baseId', baseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompanyBase', 'id', id)
            const localVarPath = `/api/v1/companies/{id}/bases/{baseId}`
                .replace(`{${"baseId"}}`, encodeURIComponent(String(baseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the logo of the company
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyLogo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompanyLogo', 'id', id)
            const localVarPath = `/api/v1/companies/{id}/logo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the company matching the given ID
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompany', 'id', id)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the logo of the company
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyLogo', 'id', id)
            const localVarPath = `/api/v1/companies/{id}/logo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the company with the given data
         * @param {string} id Identifier of the company
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (id: string, updateCompanyRequest: UpdateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompany', 'id', id)
            // verify required parameter 'updateCompanyRequest' is not null or undefined
            assertParamExists('updateCompany', 'updateCompanyRequest', updateCompanyRequest)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a base from the company
         * @param {string} baseId Identifier of the base
         * @param {string} id Identifier of the company
         * @param {BaseRequest} baseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyBase: async (baseId: string, id: string, baseRequest: BaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseId' is not null or undefined
            assertParamExists('updateCompanyBase', 'baseId', baseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyBase', 'id', id)
            // verify required parameter 'baseRequest' is not null or undefined
            assertParamExists('updateCompanyBase', 'baseRequest', baseRequest)
            const localVarPath = `/api/v1/companies/{id}/bases/{baseId}`
                .replace(`{${"baseId"}}`, encodeURIComponent(String(baseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the logo of the company
         * @param {string} id Identifier of the company
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyLogo: async (id: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyLogo', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateCompanyLogo', 'file', file)
            const localVarPath = `/api/v1/companies/{id}/logo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new base to the company
         * @param {string} id Identifier of the company
         * @param {BaseRequest} baseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyBase(id: string, baseRequest: BaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyBase(id, baseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new company with the given data
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(createCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the company matching the given ID
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a base from the company
         * @param {string} baseId Identifier of the base
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyBase(baseId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyBase(baseId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the logo of the company
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyLogo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyLogo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the company matching the given ID
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the logo of the company
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLogo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLogo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the company with the given data
         * @param {string} id Identifier of the company
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(id: string, updateCompanyRequest: UpdateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(id, updateCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a base from the company
         * @param {string} baseId Identifier of the base
         * @param {string} id Identifier of the company
         * @param {BaseRequest} baseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyBase(baseId: string, id: string, baseRequest: BaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyBase(baseId, id, baseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the logo of the company
         * @param {string} id Identifier of the company
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyLogo(id: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyLogo(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new base to the company
         * @param {string} id Identifier of the company
         * @param {BaseRequest} baseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyBase(id: string, baseRequest: BaseRequest, options?: any): AxiosPromise<CompanyBaseResponse> {
            return localVarFp.addCompanyBase(id, baseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new company with the given data
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.createCompany(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the company matching the given ID
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options?: any): AxiosPromise<CompanyDeletedResponse> {
            return localVarFp.deleteCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a base from the company
         * @param {string} baseId Identifier of the base
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyBase(baseId: string, id: string, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.deleteCompanyBase(baseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the logo of the company
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyLogo(id: string, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.deleteCompanyLogo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies(options?: any): AxiosPromise<Array<CompanyResponse>> {
            return localVarFp.getAllCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the company matching the given ID
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(id: string, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.getCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the logo of the company
         * @param {string} id Identifier of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLogo(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCompanyLogo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the company with the given data
         * @param {string} id Identifier of the company
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(id: string, updateCompanyRequest: UpdateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.updateCompany(id, updateCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a base from the company
         * @param {string} baseId Identifier of the base
         * @param {string} id Identifier of the company
         * @param {BaseRequest} baseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyBase(baseId: string, id: string, baseRequest: BaseRequest, options?: any): AxiosPromise<CompanyBaseResponse> {
            return localVarFp.updateCompanyBase(baseId, id, baseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the logo of the company
         * @param {string} id Identifier of the company
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyLogo(id: string, file: any, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.updateCompanyLogo(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addCompanyBase operation in CompaniesApi.
 * @export
 * @interface CompaniesApiAddCompanyBaseRequest
 */
export interface CompaniesApiAddCompanyBaseRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiAddCompanyBase
     */
    readonly id: string

    /**
     * 
     * @type {BaseRequest}
     * @memberof CompaniesApiAddCompanyBase
     */
    readonly baseRequest: BaseRequest
}

/**
 * Request parameters for createCompany operation in CompaniesApi.
 * @export
 * @interface CompaniesApiCreateCompanyRequest
 */
export interface CompaniesApiCreateCompanyRequest {
    /**
     * 
     * @type {CreateCompanyRequest}
     * @memberof CompaniesApiCreateCompany
     */
    readonly createCompanyRequest: CreateCompanyRequest
}

/**
 * Request parameters for deleteCompany operation in CompaniesApi.
 * @export
 * @interface CompaniesApiDeleteCompanyRequest
 */
export interface CompaniesApiDeleteCompanyRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiDeleteCompany
     */
    readonly id: string
}

/**
 * Request parameters for deleteCompanyBase operation in CompaniesApi.
 * @export
 * @interface CompaniesApiDeleteCompanyBaseRequest
 */
export interface CompaniesApiDeleteCompanyBaseRequest {
    /**
     * Identifier of the base
     * @type {string}
     * @memberof CompaniesApiDeleteCompanyBase
     */
    readonly baseId: string

    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiDeleteCompanyBase
     */
    readonly id: string
}

/**
 * Request parameters for deleteCompanyLogo operation in CompaniesApi.
 * @export
 * @interface CompaniesApiDeleteCompanyLogoRequest
 */
export interface CompaniesApiDeleteCompanyLogoRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiDeleteCompanyLogo
     */
    readonly id: string
}

/**
 * Request parameters for getCompany operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetCompanyRequest
 */
export interface CompaniesApiGetCompanyRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiGetCompany
     */
    readonly id: string
}

/**
 * Request parameters for getCompanyLogo operation in CompaniesApi.
 * @export
 * @interface CompaniesApiGetCompanyLogoRequest
 */
export interface CompaniesApiGetCompanyLogoRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiGetCompanyLogo
     */
    readonly id: string
}

/**
 * Request parameters for updateCompany operation in CompaniesApi.
 * @export
 * @interface CompaniesApiUpdateCompanyRequest
 */
export interface CompaniesApiUpdateCompanyRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiUpdateCompany
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCompanyRequest}
     * @memberof CompaniesApiUpdateCompany
     */
    readonly updateCompanyRequest: UpdateCompanyRequest
}

/**
 * Request parameters for updateCompanyBase operation in CompaniesApi.
 * @export
 * @interface CompaniesApiUpdateCompanyBaseRequest
 */
export interface CompaniesApiUpdateCompanyBaseRequest {
    /**
     * Identifier of the base
     * @type {string}
     * @memberof CompaniesApiUpdateCompanyBase
     */
    readonly baseId: string

    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiUpdateCompanyBase
     */
    readonly id: string

    /**
     * 
     * @type {BaseRequest}
     * @memberof CompaniesApiUpdateCompanyBase
     */
    readonly baseRequest: BaseRequest
}

/**
 * Request parameters for updateCompanyLogo operation in CompaniesApi.
 * @export
 * @interface CompaniesApiUpdateCompanyLogoRequest
 */
export interface CompaniesApiUpdateCompanyLogoRequest {
    /**
     * Identifier of the company
     * @type {string}
     * @memberof CompaniesApiUpdateCompanyLogo
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof CompaniesApiUpdateCompanyLogo
     */
    readonly file: any
}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new base to the company
     * @param {CompaniesApiAddCompanyBaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public addCompanyBase(requestParameters: CompaniesApiAddCompanyBaseRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).addCompanyBase(requestParameters.id, requestParameters.baseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new company with the given data
     * @param {CompaniesApiCreateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public createCompany(requestParameters: CompaniesApiCreateCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).createCompany(requestParameters.createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the company matching the given ID
     * @param {CompaniesApiDeleteCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteCompany(requestParameters: CompaniesApiDeleteCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a base from the company
     * @param {CompaniesApiDeleteCompanyBaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteCompanyBase(requestParameters: CompaniesApiDeleteCompanyBaseRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteCompanyBase(requestParameters.baseId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the logo of the company
     * @param {CompaniesApiDeleteCompanyLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteCompanyLogo(requestParameters: CompaniesApiDeleteCompanyLogoRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteCompanyLogo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getAllCompanies(options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getAllCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the company matching the given ID
     * @param {CompaniesApiGetCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompany(requestParameters: CompaniesApiGetCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the logo of the company
     * @param {CompaniesApiGetCompanyLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanyLogo(requestParameters: CompaniesApiGetCompanyLogoRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompanyLogo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the company with the given data
     * @param {CompaniesApiUpdateCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompany(requestParameters: CompaniesApiUpdateCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompany(requestParameters.id, requestParameters.updateCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a base from the company
     * @param {CompaniesApiUpdateCompanyBaseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompanyBase(requestParameters: CompaniesApiUpdateCompanyBaseRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompanyBase(requestParameters.baseId, requestParameters.id, requestParameters.baseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the logo of the company
     * @param {CompaniesApiUpdateCompanyLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompanyLogo(requestParameters: CompaniesApiUpdateCompanyLogoRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompanyLogo(requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardsApi - axios parameter creator
 * @export
 */
export const DashboardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all the companies
         * @param {DashboardType} type The type of the dashboard to get data from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard: async (type: DashboardType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getDashboard', 'type', type)
            const localVarPath = `/api/v1/dashboards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardsApi - functional programming interface
 * @export
 */
export const DashboardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns all the companies
         * @param {DashboardType} type The type of the dashboard to get data from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboard(type: DashboardType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TodayDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardsApi - factory interface
 * @export
 */
export const DashboardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns all the companies
         * @param {DashboardType} type The type of the dashboard to get data from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard(type: DashboardType, options?: any): AxiosPromise<TodayDashboardResponse> {
            return localVarFp.getDashboard(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDashboard operation in DashboardsApi.
 * @export
 * @interface DashboardsApiGetDashboardRequest
 */
export interface DashboardsApiGetDashboardRequest {
    /**
     * The type of the dashboard to get data from
     * @type {DashboardType}
     * @memberof DashboardsApiGetDashboard
     */
    readonly type: DashboardType
}

/**
 * DashboardsApi - object-oriented interface
 * @export
 * @class DashboardsApi
 * @extends {BaseAPI}
 */
export class DashboardsApi extends BaseAPI {
    /**
     * 
     * @summary Returns all the companies
     * @param {DashboardsApiGetDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public getDashboard(requestParameters: DashboardsApiGetDashboardRequest, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).getDashboard(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        liveness: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readiness: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.check(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async liveness(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.liveness(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readiness(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readiness(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.check(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        liveness(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.liveness(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readiness(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.readiness(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public check(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).check(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public liveness(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).liveness(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public readiness(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).readiness(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LabelsApi - axios parameter creator
 * @export
 */
export const LabelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new label with the given data
         * @param {LabelRequest} labelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: async (labelRequest: LabelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'labelRequest' is not null or undefined
            assertParamExists('createLabel', 'labelRequest', labelRequest)
            const localVarPath = `/api/v1/labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the label matching the given ID
         * @param {string} id Identifier of the label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLabel', 'id', id)
            const localVarPath = `/api/v1/labels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLabels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the label matching the given ID
         * @param {string} id Identifier of the label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLabel', 'id', id)
            const localVarPath = `/api/v1/labels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the label with the given data
         * @param {string} id Identifier of the label
         * @param {LabelRequest} labelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel: async (id: string, labelRequest: LabelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLabel', 'id', id)
            // verify required parameter 'labelRequest' is not null or undefined
            assertParamExists('updateLabel', 'labelRequest', labelRequest)
            const localVarPath = `/api/v1/labels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabelsApi - functional programming interface
 * @export
 */
export const LabelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LabelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new label with the given data
         * @param {LabelRequest} labelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLabel(labelRequest: LabelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLabel(labelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the label matching the given ID
         * @param {string} id Identifier of the label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLabel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLabel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLabels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabelResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLabels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the label matching the given ID
         * @param {string} id Identifier of the label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the label with the given data
         * @param {string} id Identifier of the label
         * @param {LabelRequest} labelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLabel(id: string, labelRequest: LabelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLabel(id, labelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LabelsApi - factory interface
 * @export
 */
export const LabelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabelsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new label with the given data
         * @param {LabelRequest} labelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(labelRequest: LabelRequest, options?: any): AxiosPromise<LabelResponse> {
            return localVarFp.createLabel(labelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the label matching the given ID
         * @param {string} id Identifier of the label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel(id: string, options?: any): AxiosPromise<LabelDeletedResponse> {
            return localVarFp.deleteLabel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLabels(options?: any): AxiosPromise<Array<LabelResponse>> {
            return localVarFp.getAllLabels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the label matching the given ID
         * @param {string} id Identifier of the label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel(id: string, options?: any): AxiosPromise<LabelResponse> {
            return localVarFp.getLabel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the label with the given data
         * @param {string} id Identifier of the label
         * @param {LabelRequest} labelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel(id: string, labelRequest: LabelRequest, options?: any): AxiosPromise<LabelResponse> {
            return localVarFp.updateLabel(id, labelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLabel operation in LabelsApi.
 * @export
 * @interface LabelsApiCreateLabelRequest
 */
export interface LabelsApiCreateLabelRequest {
    /**
     * 
     * @type {LabelRequest}
     * @memberof LabelsApiCreateLabel
     */
    readonly labelRequest: LabelRequest
}

/**
 * Request parameters for deleteLabel operation in LabelsApi.
 * @export
 * @interface LabelsApiDeleteLabelRequest
 */
export interface LabelsApiDeleteLabelRequest {
    /**
     * Identifier of the label
     * @type {string}
     * @memberof LabelsApiDeleteLabel
     */
    readonly id: string
}

/**
 * Request parameters for getLabel operation in LabelsApi.
 * @export
 * @interface LabelsApiGetLabelRequest
 */
export interface LabelsApiGetLabelRequest {
    /**
     * Identifier of the label
     * @type {string}
     * @memberof LabelsApiGetLabel
     */
    readonly id: string
}

/**
 * Request parameters for updateLabel operation in LabelsApi.
 * @export
 * @interface LabelsApiUpdateLabelRequest
 */
export interface LabelsApiUpdateLabelRequest {
    /**
     * Identifier of the label
     * @type {string}
     * @memberof LabelsApiUpdateLabel
     */
    readonly id: string

    /**
     * 
     * @type {LabelRequest}
     * @memberof LabelsApiUpdateLabel
     */
    readonly labelRequest: LabelRequest
}

/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
export class LabelsApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new label with the given data
     * @param {LabelsApiCreateLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    public createLabel(requestParameters: LabelsApiCreateLabelRequest, options?: AxiosRequestConfig) {
        return LabelsApiFp(this.configuration).createLabel(requestParameters.labelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the label matching the given ID
     * @param {LabelsApiDeleteLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    public deleteLabel(requestParameters: LabelsApiDeleteLabelRequest, options?: AxiosRequestConfig) {
        return LabelsApiFp(this.configuration).deleteLabel(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    public getAllLabels(options?: AxiosRequestConfig) {
        return LabelsApiFp(this.configuration).getAllLabels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the label matching the given ID
     * @param {LabelsApiGetLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    public getLabel(requestParameters: LabelsApiGetLabelRequest, options?: AxiosRequestConfig) {
        return LabelsApiFp(this.configuration).getLabel(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the label with the given data
     * @param {LabelsApiUpdateLabelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    public updateLabel(requestParameters: LabelsApiUpdateLabelRequest, options?: AxiosRequestConfig) {
        return LabelsApiFp(this.configuration).updateLabel(requestParameters.id, requestParameters.labelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeApi - axios parameter creator
 * @export
 */
export const MeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes the password of the user that is logged in
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordRequest: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('changePassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/api/v1/me/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the avatar of the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeAvatar: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the avatar of the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAvatar: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates certain fields of the user that is logged in
         * @param {UpdateSelfUserRequest} updateSelfUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe: async (updateSelfUserRequest: UpdateSelfUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSelfUserRequest' is not null or undefined
            assertParamExists('updateMe', 'updateSelfUserRequest', updateSelfUserRequest)
            const localVarPath = `/api/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSelfUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the avatar of the user that is logged in
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeAvatar: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateMeAvatar', 'file', file)
            const localVarPath = `/api/v1/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeApi - functional programming interface
 * @export
 */
export const MeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Changes the password of the user that is logged in
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the avatar of the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeAvatar(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeAvatar(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the avatar of the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeAvatar(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeAvatar(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates certain fields of the user that is logged in
         * @param {UpdateSelfUserRequest} updateSelfUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMe(updateSelfUserRequest: UpdateSelfUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMe(updateSelfUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the avatar of the user that is logged in
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeAvatar(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeAvatar(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeApi - factory interface
 * @export
 */
export const MeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeApiFp(configuration)
    return {
        /**
         * 
         * @summary Changes the password of the user that is logged in
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.changePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the avatar of the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeAvatar(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.deleteMeAvatar(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the avatar of the user that is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAvatar(options?: any): AxiosPromise<any> {
            return localVarFp.getMeAvatar(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates certain fields of the user that is logged in
         * @param {UpdateSelfUserRequest} updateSelfUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(updateSelfUserRequest: UpdateSelfUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateMe(updateSelfUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the avatar of the user that is logged in
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeAvatar(file: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateMeAvatar(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changePassword operation in MeApi.
 * @export
 * @interface MeApiChangePasswordRequest
 */
export interface MeApiChangePasswordRequest {
    /**
     * 
     * @type {ChangePasswordRequest}
     * @memberof MeApiChangePassword
     */
    readonly changePasswordRequest: ChangePasswordRequest
}

/**
 * Request parameters for updateMe operation in MeApi.
 * @export
 * @interface MeApiUpdateMeRequest
 */
export interface MeApiUpdateMeRequest {
    /**
     * 
     * @type {UpdateSelfUserRequest}
     * @memberof MeApiUpdateMe
     */
    readonly updateSelfUserRequest: UpdateSelfUserRequest
}

/**
 * Request parameters for updateMeAvatar operation in MeApi.
 * @export
 * @interface MeApiUpdateMeAvatarRequest
 */
export interface MeApiUpdateMeAvatarRequest {
    /**
     * 
     * @type {any}
     * @memberof MeApiUpdateMeAvatar
     */
    readonly file: any
}

/**
 * MeApi - object-oriented interface
 * @export
 * @class MeApi
 * @extends {BaseAPI}
 */
export class MeApi extends BaseAPI {
    /**
     * 
     * @summary Changes the password of the user that is logged in
     * @param {MeApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public changePassword(requestParameters: MeApiChangePasswordRequest, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).changePassword(requestParameters.changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the avatar of the user that is logged in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public deleteMeAvatar(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).deleteMeAvatar(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user that is logged in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public getMe(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the avatar of the user that is logged in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public getMeAvatar(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).getMeAvatar(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates certain fields of the user that is logged in
     * @param {MeApiUpdateMeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public updateMe(requestParameters: MeApiUpdateMeRequest, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).updateMe(requestParameters.updateSelfUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the avatar of the user that is logged in
     * @param {MeApiUpdateMeAvatarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public updateMeAvatar(requestParameters: MeApiUpdateMeAvatarRequest, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).updateMeAvatar(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the given notifications
         * @param {DeleteNotificationsRequest} deleteNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications: async (deleteNotificationsRequest: DeleteNotificationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteNotificationsRequest' is not null or undefined
            assertParamExists('deleteNotifications', 'deleteNotificationsRequest', deleteNotificationsRequest)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the notifications preferences for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks the given notifications
         * @param {UpdateNotificationsRequest} updateNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications: async (updateNotificationsRequest: UpdateNotificationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationsRequest' is not null or undefined
            assertParamExists('updateNotifications', 'updateNotificationsRequest', updateNotificationsRequest)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the notifications preferences for the logged in user
         * @param {NotificationsPreferencesDto} notificationsPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsPreferences: async (notificationsPreferencesDto: NotificationsPreferencesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationsPreferencesDto' is not null or undefined
            assertParamExists('updateNotificationsPreferences', 'notificationsPreferencesDto', notificationsPreferencesDto)
            const localVarPath = `/api/v1/notifications-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationsPreferencesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the given notifications
         * @param {DeleteNotificationsRequest} deleteNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotifications(deleteNotificationsRequest: DeleteNotificationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotifications(deleteNotificationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the notifications preferences for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks the given notifications
         * @param {UpdateNotificationsRequest} updateNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(updateNotificationsRequest: UpdateNotificationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifications(updateNotificationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the notifications preferences for the logged in user
         * @param {NotificationsPreferencesDto} notificationsPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationsPreferences(notificationsPreferencesDto: NotificationsPreferencesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsPreferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationsPreferences(notificationsPreferencesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the given notifications
         * @param {DeleteNotificationsRequest} deleteNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(deleteNotificationsRequest: DeleteNotificationsRequest, options?: any): AxiosPromise<NotificationsResponse> {
            return localVarFp.deleteNotifications(deleteNotificationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(options?: any): AxiosPromise<Array<NotificationResponse>> {
            return localVarFp.getNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the notifications preferences for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsPreferences(options?: any): AxiosPromise<NotificationsPreferencesDto> {
            return localVarFp.getNotificationsPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks the given notifications
         * @param {UpdateNotificationsRequest} updateNotificationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(updateNotificationsRequest: UpdateNotificationsRequest, options?: any): AxiosPromise<NotificationsResponse> {
            return localVarFp.updateNotifications(updateNotificationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the notifications preferences for the logged in user
         * @param {NotificationsPreferencesDto} notificationsPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsPreferences(notificationsPreferencesDto: NotificationsPreferencesDto, options?: any): AxiosPromise<NotificationsPreferencesDto> {
            return localVarFp.updateNotificationsPreferences(notificationsPreferencesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiDeleteNotificationsRequest
 */
export interface NotificationsApiDeleteNotificationsRequest {
    /**
     * 
     * @type {DeleteNotificationsRequest}
     * @memberof NotificationsApiDeleteNotifications
     */
    readonly deleteNotificationsRequest: DeleteNotificationsRequest
}

/**
 * Request parameters for updateNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationsRequest
 */
export interface NotificationsApiUpdateNotificationsRequest {
    /**
     * 
     * @type {UpdateNotificationsRequest}
     * @memberof NotificationsApiUpdateNotifications
     */
    readonly updateNotificationsRequest: UpdateNotificationsRequest
}

/**
 * Request parameters for updateNotificationsPreferences operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationsPreferencesRequest
 */
export interface NotificationsApiUpdateNotificationsPreferencesRequest {
    /**
     * 
     * @type {NotificationsPreferencesDto}
     * @memberof NotificationsApiUpdateNotificationsPreferences
     */
    readonly notificationsPreferencesDto: NotificationsPreferencesDto
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Deletes the given notifications
     * @param {NotificationsApiDeleteNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public deleteNotifications(requestParameters: NotificationsApiDeleteNotificationsRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).deleteNotifications(requestParameters.deleteNotificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the notifications for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the notifications preferences for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationsPreferences(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationsPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks the given notifications
     * @param {NotificationsApiUpdateNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotifications(requestParameters: NotificationsApiUpdateNotificationsRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotifications(requestParameters.updateNotificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the notifications preferences for the logged in user
     * @param {NotificationsApiUpdateNotificationsPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationsPreferences(requestParameters: NotificationsApiUpdateNotificationsPreferencesRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationsPreferences(requestParameters.notificationsPreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PreferencesApi - axios parameter creator
 * @export
 */
export const PreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the global preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the global preferences
         * @param {PreferencesRequest} preferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGlobalPreferences: async (preferencesRequest: PreferencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferencesRequest' is not null or undefined
            assertParamExists('updateGlobalPreferences', 'preferencesRequest', preferencesRequest)
            const localVarPath = `/api/v1/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preferencesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreferencesApi - functional programming interface
 * @export
 */
export const PreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the global preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the global preferences
         * @param {PreferencesRequest} preferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGlobalPreferences(preferencesRequest: PreferencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGlobalPreferences(preferencesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreferencesApi - factory interface
 * @export
 */
export const PreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the global preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalPreferences(options?: any): AxiosPromise<PreferencesResponse> {
            return localVarFp.getGlobalPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the global preferences
         * @param {PreferencesRequest} preferencesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGlobalPreferences(preferencesRequest: PreferencesRequest, options?: any): AxiosPromise<PreferencesResponse> {
            return localVarFp.updateGlobalPreferences(preferencesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateGlobalPreferences operation in PreferencesApi.
 * @export
 * @interface PreferencesApiUpdateGlobalPreferencesRequest
 */
export interface PreferencesApiUpdateGlobalPreferencesRequest {
    /**
     * 
     * @type {PreferencesRequest}
     * @memberof PreferencesApiUpdateGlobalPreferences
     */
    readonly preferencesRequest: PreferencesRequest
}

/**
 * PreferencesApi - object-oriented interface
 * @export
 * @class PreferencesApi
 * @extends {BaseAPI}
 */
export class PreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Returns the global preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public getGlobalPreferences(options?: AxiosRequestConfig) {
        return PreferencesApiFp(this.configuration).getGlobalPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the global preferences
     * @param {PreferencesApiUpdateGlobalPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public updateGlobalPreferences(requestParameters: PreferencesApiUpdateGlobalPreferencesRequest, options?: AxiosRequestConfig) {
        return PreferencesApiFp(this.configuration).updateGlobalPreferences(requestParameters.preferencesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RatesApi - axios parameter creator
 * @export
 */
export const RatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new mileage rate with the given data
         * @param {MileageRateRequest} mileageRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMileageRate: async (mileageRateRequest: MileageRateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mileageRateRequest' is not null or undefined
            assertParamExists('createMileageRate', 'mileageRateRequest', mileageRateRequest)
            const localVarPath = `/api/v1/rates/mileage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mileageRateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new report rate with the given data
         * @param {ReportRateRequest} reportRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportRate: async (reportRateRequest: ReportRateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportRateRequest' is not null or undefined
            assertParamExists('createReportRate', 'reportRateRequest', reportRateRequest)
            const localVarPath = `/api/v1/rates/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the mileage rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMileageRate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMileageRate', 'id', id)
            const localVarPath = `/api/v1/rates/mileage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the report rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportRate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReportRate', 'id', id)
            const localVarPath = `/api/v1/rates/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the mileage rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMileageRates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/rates/mileage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the report rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReportRates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/rates/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the mileage rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMileageRate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMileageRate', 'id', id)
            const localVarPath = `/api/v1/rates/mileage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the report rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportRate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportRate', 'id', id)
            const localVarPath = `/api/v1/rates/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the mileage rate with the given data
         * @param {string} id Identifier of the rate
         * @param {MileageRateRequest} mileageRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMileageRate: async (id: string, mileageRateRequest: MileageRateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMileageRate', 'id', id)
            // verify required parameter 'mileageRateRequest' is not null or undefined
            assertParamExists('updateMileageRate', 'mileageRateRequest', mileageRateRequest)
            const localVarPath = `/api/v1/rates/mileage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mileageRateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the report rate with the given data
         * @param {string} id Identifier of the rate
         * @param {ReportRateRequest} reportRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportRate: async (id: string, reportRateRequest: ReportRateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReportRate', 'id', id)
            // verify required parameter 'reportRateRequest' is not null or undefined
            assertParamExists('updateReportRate', 'reportRateRequest', reportRateRequest)
            const localVarPath = `/api/v1/rates/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RatesApi - functional programming interface
 * @export
 */
export const RatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new mileage rate with the given data
         * @param {MileageRateRequest} mileageRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMileageRate(mileageRateRequest: MileageRateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MileageRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMileageRate(mileageRateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new report rate with the given data
         * @param {ReportRateRequest} reportRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportRate(reportRateRequest: ReportRateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportRate(reportRateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the mileage rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMileageRate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MileageRateDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMileageRate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the report rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportRate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportRateDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportRate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the mileage rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMileageRates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MileageRateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMileageRates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the report rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReportRates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportRateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReportRates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the mileage rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMileageRate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MileageRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMileageRate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the report rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportRate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportRate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the mileage rate with the given data
         * @param {string} id Identifier of the rate
         * @param {MileageRateRequest} mileageRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMileageRate(id: string, mileageRateRequest: MileageRateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MileageRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMileageRate(id, mileageRateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the report rate with the given data
         * @param {string} id Identifier of the rate
         * @param {ReportRateRequest} reportRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportRate(id: string, reportRateRequest: ReportRateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportRate(id, reportRateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RatesApi - factory interface
 * @export
 */
export const RatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new mileage rate with the given data
         * @param {MileageRateRequest} mileageRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMileageRate(mileageRateRequest: MileageRateRequest, options?: any): AxiosPromise<MileageRateResponse> {
            return localVarFp.createMileageRate(mileageRateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new report rate with the given data
         * @param {ReportRateRequest} reportRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportRate(reportRateRequest: ReportRateRequest, options?: any): AxiosPromise<ReportRateResponse> {
            return localVarFp.createReportRate(reportRateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the mileage rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMileageRate(id: string, options?: any): AxiosPromise<MileageRateDeletedResponse> {
            return localVarFp.deleteMileageRate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the report rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportRate(id: string, options?: any): AxiosPromise<ReportRateDeletedResponse> {
            return localVarFp.deleteReportRate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the mileage rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMileageRates(options?: any): AxiosPromise<Array<MileageRateResponse>> {
            return localVarFp.getAllMileageRates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the report rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReportRates(options?: any): AxiosPromise<Array<ReportRateResponse>> {
            return localVarFp.getAllReportRates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the mileage rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMileageRate(id: string, options?: any): AxiosPromise<MileageRateResponse> {
            return localVarFp.getMileageRate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the report rate matching the given ID
         * @param {string} id Identifier of the rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportRate(id: string, options?: any): AxiosPromise<ReportRateResponse> {
            return localVarFp.getReportRate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the mileage rate with the given data
         * @param {string} id Identifier of the rate
         * @param {MileageRateRequest} mileageRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMileageRate(id: string, mileageRateRequest: MileageRateRequest, options?: any): AxiosPromise<MileageRateResponse> {
            return localVarFp.updateMileageRate(id, mileageRateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the report rate with the given data
         * @param {string} id Identifier of the rate
         * @param {ReportRateRequest} reportRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportRate(id: string, reportRateRequest: ReportRateRequest, options?: any): AxiosPromise<ReportRateResponse> {
            return localVarFp.updateReportRate(id, reportRateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMileageRate operation in RatesApi.
 * @export
 * @interface RatesApiCreateMileageRateRequest
 */
export interface RatesApiCreateMileageRateRequest {
    /**
     * 
     * @type {MileageRateRequest}
     * @memberof RatesApiCreateMileageRate
     */
    readonly mileageRateRequest: MileageRateRequest
}

/**
 * Request parameters for createReportRate operation in RatesApi.
 * @export
 * @interface RatesApiCreateReportRateRequest
 */
export interface RatesApiCreateReportRateRequest {
    /**
     * 
     * @type {ReportRateRequest}
     * @memberof RatesApiCreateReportRate
     */
    readonly reportRateRequest: ReportRateRequest
}

/**
 * Request parameters for deleteMileageRate operation in RatesApi.
 * @export
 * @interface RatesApiDeleteMileageRateRequest
 */
export interface RatesApiDeleteMileageRateRequest {
    /**
     * Identifier of the rate
     * @type {string}
     * @memberof RatesApiDeleteMileageRate
     */
    readonly id: string
}

/**
 * Request parameters for deleteReportRate operation in RatesApi.
 * @export
 * @interface RatesApiDeleteReportRateRequest
 */
export interface RatesApiDeleteReportRateRequest {
    /**
     * Identifier of the rate
     * @type {string}
     * @memberof RatesApiDeleteReportRate
     */
    readonly id: string
}

/**
 * Request parameters for getMileageRate operation in RatesApi.
 * @export
 * @interface RatesApiGetMileageRateRequest
 */
export interface RatesApiGetMileageRateRequest {
    /**
     * Identifier of the rate
     * @type {string}
     * @memberof RatesApiGetMileageRate
     */
    readonly id: string
}

/**
 * Request parameters for getReportRate operation in RatesApi.
 * @export
 * @interface RatesApiGetReportRateRequest
 */
export interface RatesApiGetReportRateRequest {
    /**
     * Identifier of the rate
     * @type {string}
     * @memberof RatesApiGetReportRate
     */
    readonly id: string
}

/**
 * Request parameters for updateMileageRate operation in RatesApi.
 * @export
 * @interface RatesApiUpdateMileageRateRequest
 */
export interface RatesApiUpdateMileageRateRequest {
    /**
     * Identifier of the rate
     * @type {string}
     * @memberof RatesApiUpdateMileageRate
     */
    readonly id: string

    /**
     * 
     * @type {MileageRateRequest}
     * @memberof RatesApiUpdateMileageRate
     */
    readonly mileageRateRequest: MileageRateRequest
}

/**
 * Request parameters for updateReportRate operation in RatesApi.
 * @export
 * @interface RatesApiUpdateReportRateRequest
 */
export interface RatesApiUpdateReportRateRequest {
    /**
     * Identifier of the rate
     * @type {string}
     * @memberof RatesApiUpdateReportRate
     */
    readonly id: string

    /**
     * 
     * @type {ReportRateRequest}
     * @memberof RatesApiUpdateReportRate
     */
    readonly reportRateRequest: ReportRateRequest
}

/**
 * RatesApi - object-oriented interface
 * @export
 * @class RatesApi
 * @extends {BaseAPI}
 */
export class RatesApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new mileage rate with the given data
     * @param {RatesApiCreateMileageRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public createMileageRate(requestParameters: RatesApiCreateMileageRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).createMileageRate(requestParameters.mileageRateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new report rate with the given data
     * @param {RatesApiCreateReportRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public createReportRate(requestParameters: RatesApiCreateReportRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).createReportRate(requestParameters.reportRateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the mileage rate matching the given ID
     * @param {RatesApiDeleteMileageRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public deleteMileageRate(requestParameters: RatesApiDeleteMileageRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).deleteMileageRate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the report rate matching the given ID
     * @param {RatesApiDeleteReportRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public deleteReportRate(requestParameters: RatesApiDeleteReportRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).deleteReportRate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the mileage rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public getAllMileageRates(options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).getAllMileageRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the report rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public getAllReportRates(options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).getAllReportRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the mileage rate matching the given ID
     * @param {RatesApiGetMileageRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public getMileageRate(requestParameters: RatesApiGetMileageRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).getMileageRate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the report rate matching the given ID
     * @param {RatesApiGetReportRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public getReportRate(requestParameters: RatesApiGetReportRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).getReportRate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the mileage rate with the given data
     * @param {RatesApiUpdateMileageRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public updateMileageRate(requestParameters: RatesApiUpdateMileageRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).updateMileageRate(requestParameters.id, requestParameters.mileageRateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the report rate with the given data
     * @param {RatesApiUpdateReportRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatesApi
     */
    public updateReportRate(requestParameters: RatesApiUpdateReportRateRequest, options?: AxiosRequestConfig) {
        return RatesApiFp(this.configuration).updateReportRate(requestParameters.id, requestParameters.reportRateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoutesApi - axios parameter creator
 * @export
 */
export const RoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approves the route, so it can be started.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveRoute', 'id', id)
            const localVarPath = `/api/v1/routes/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel the route.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelRoute', 'id', id)
            const localVarPath = `/api/v1/routes/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Completes the route.
         * @param {string} id Identifier of the route
         * @param {CompleteRouteRequest} completeRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeRoute: async (id: string, completeRouteRequest: CompleteRouteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeRoute', 'id', id)
            // verify required parameter 'completeRouteRequest' is not null or undefined
            assertParamExists('completeRoute', 'completeRouteRequest', completeRouteRequest)
            const localVarPath = `/api/v1/routes/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeRouteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new route with the given data
         * @param {CreateRouteRequest} createRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoute: async (createRouteRequest: CreateRouteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRouteRequest' is not null or undefined
            assertParamExists('createRoute', 'createRouteRequest', createRouteRequest)
            const localVarPath = `/api/v1/routes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRouteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the route matching the given ID. Completed routes cannot be deleted.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoute', 'id', id)
            const localVarPath = `/api/v1/routes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the raw route for the given ID.
         * @param {string} id Identifier of the raw route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawRouteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRawRouteById', 'id', id)
            const localVarPath = `/api/v1/raw-routes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the route matching the given ID
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRoute', 'id', id)
            const localVarPath = `/api/v1/routes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reopens a cancelled route.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenRoute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reopenRoute', 'id', id)
            const localVarPath = `/api/v1/routes/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the routes matching the give criteria. Results are paginated.
         * @param {Array<string>} [ids] Filter the routes that match any of the given IDs
         * @param {Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>} [status] Filter the routes that match any of the give status
         * @param {string} [dateFrom] Filter the routes that have a date from the given date onwards
         * @param {string} [dateTo] Filter the route that have a date until the given date
         * @param {Array<string>} [assigneeIds] Filter the routes assigned to users with the given IDs
         * @param {Array<string>} [taskIds] Filter the routes that contains tasks matching the given IDs
         * @param {RouteSortType} [sortBy] The sort by field.
         * @param {SortDir} [sortDir] The sort direction. Defaults to DESC.
         * @param {number} [page] The requested page. Starting at 0.
         * @param {number} [limit] The requested page size. Maximum items: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoutes: async (ids?: Array<string>, status?: Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>, dateFrom?: string, dateTo?: string, assigneeIds?: Array<string>, taskIds?: Array<string>, sortBy?: RouteSortType, sortDir?: SortDir, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/routes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (assigneeIds) {
                localVarQueryParameter['assigneeIds'] = assigneeIds;
            }

            if (taskIds) {
                localVarQueryParameter['taskIds'] = taskIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the route with the given data. Completed routes cannot be changed.
         * @param {string} id Identifier of the route
         * @param {UpdateRouteRequest} updateRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoute: async (id: string, updateRouteRequest: UpdateRouteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoute', 'id', id)
            // verify required parameter 'updateRouteRequest' is not null or undefined
            assertParamExists('updateRoute', 'updateRouteRequest', updateRouteRequest)
            const localVarPath = `/api/v1/routes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRouteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific task of the route with the given data. Completed routes cannot be changed.
         * @param {string} taskId Identifier of the task
         * @param {string} id Identifier of the route
         * @param {UpdateRouteTaskRequest} updateRouteTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteTask: async (taskId: string, id: string, updateRouteTaskRequest: UpdateRouteTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateRouteTask', 'taskId', taskId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRouteTask', 'id', id)
            // verify required parameter 'updateRouteTaskRequest' is not null or undefined
            assertParamExists('updateRouteTask', 'updateRouteTaskRequest', updateRouteTaskRequest)
            const localVarPath = `/api/v1/routes/{id}/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRouteTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the tasks of the route with the given data. Completed routes cannot be changed.
         * @param {string} id Identifier of the route
         * @param {UpdateRouteTasksRequest} updateRouteTasksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteTasks: async (id: string, updateRouteTasksRequest: UpdateRouteTasksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRouteTasks', 'id', id)
            // verify required parameter 'updateRouteTasksRequest' is not null or undefined
            assertParamExists('updateRouteTasks', 'updateRouteTasksRequest', updateRouteTasksRequest)
            const localVarPath = `/api/v1/routes/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRouteTasksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutesApi - functional programming interface
 * @export
 */
export const RoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approves the route, so it can be started.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel the route.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Completes the route.
         * @param {string} id Identifier of the route
         * @param {CompleteRouteRequest} completeRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeRoute(id: string, completeRouteRequest: CompleteRouteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeRoute(id, completeRouteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new route with the given data
         * @param {CreateRouteRequest} createRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoute(createRouteRequest: CreateRouteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoute(createRouteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the route matching the given ID. Completed routes cannot be deleted.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the raw route for the given ID.
         * @param {string} id Identifier of the raw route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawRouteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RawRouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawRouteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the route matching the given ID
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reopens a cancelled route.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reopenRoute(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reopenRoute(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the routes matching the give criteria. Results are paginated.
         * @param {Array<string>} [ids] Filter the routes that match any of the given IDs
         * @param {Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>} [status] Filter the routes that match any of the give status
         * @param {string} [dateFrom] Filter the routes that have a date from the given date onwards
         * @param {string} [dateTo] Filter the route that have a date until the given date
         * @param {Array<string>} [assigneeIds] Filter the routes assigned to users with the given IDs
         * @param {Array<string>} [taskIds] Filter the routes that contains tasks matching the given IDs
         * @param {RouteSortType} [sortBy] The sort by field.
         * @param {SortDir} [sortDir] The sort direction. Defaults to DESC.
         * @param {number} [page] The requested page. Starting at 0.
         * @param {number} [limit] The requested page size. Maximum items: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRoutes(ids?: Array<string>, status?: Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>, dateFrom?: string, dateTo?: string, assigneeIds?: Array<string>, taskIds?: Array<string>, sortBy?: RouteSortType, sortDir?: SortDir, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutePaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRoutes(ids, status, dateFrom, dateTo, assigneeIds, taskIds, sortBy, sortDir, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the route with the given data. Completed routes cannot be changed.
         * @param {string} id Identifier of the route
         * @param {UpdateRouteRequest} updateRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoute(id: string, updateRouteRequest: UpdateRouteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoute(id, updateRouteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific task of the route with the given data. Completed routes cannot be changed.
         * @param {string} taskId Identifier of the task
         * @param {string} id Identifier of the route
         * @param {UpdateRouteTaskRequest} updateRouteTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRouteTask(taskId: string, id: string, updateRouteTaskRequest: UpdateRouteTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRouteTask(taskId, id, updateRouteTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the tasks of the route with the given data. Completed routes cannot be changed.
         * @param {string} id Identifier of the route
         * @param {UpdateRouteTasksRequest} updateRouteTasksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRouteTasks(id: string, updateRouteTasksRequest: UpdateRouteTasksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRouteTasks(id, updateRouteTasksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoutesApi - factory interface
 * @export
 */
export const RoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Approves the route, so it can be started.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveRoute(id: string, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.approveRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel the route.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRoute(id: string, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.cancelRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Completes the route.
         * @param {string} id Identifier of the route
         * @param {CompleteRouteRequest} completeRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeRoute(id: string, completeRouteRequest: CompleteRouteRequest, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.completeRoute(id, completeRouteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new route with the given data
         * @param {CreateRouteRequest} createRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoute(createRouteRequest: CreateRouteRequest, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.createRoute(createRouteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the route matching the given ID. Completed routes cannot be deleted.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoute(id: string, options?: any): AxiosPromise<RouteDeletedResponse> {
            return localVarFp.deleteRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the raw route for the given ID.
         * @param {string} id Identifier of the raw route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawRouteById(id: string, options?: any): AxiosPromise<RawRouteResponse> {
            return localVarFp.getRawRouteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the route matching the given ID
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoute(id: string, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.getRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reopens a cancelled route.
         * @param {string} id Identifier of the route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenRoute(id: string, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.reopenRoute(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the routes matching the give criteria. Results are paginated.
         * @param {Array<string>} [ids] Filter the routes that match any of the given IDs
         * @param {Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>} [status] Filter the routes that match any of the give status
         * @param {string} [dateFrom] Filter the routes that have a date from the given date onwards
         * @param {string} [dateTo] Filter the route that have a date until the given date
         * @param {Array<string>} [assigneeIds] Filter the routes assigned to users with the given IDs
         * @param {Array<string>} [taskIds] Filter the routes that contains tasks matching the given IDs
         * @param {RouteSortType} [sortBy] The sort by field.
         * @param {SortDir} [sortDir] The sort direction. Defaults to DESC.
         * @param {number} [page] The requested page. Starting at 0.
         * @param {number} [limit] The requested page size. Maximum items: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoutes(ids?: Array<string>, status?: Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>, dateFrom?: string, dateTo?: string, assigneeIds?: Array<string>, taskIds?: Array<string>, sortBy?: RouteSortType, sortDir?: SortDir, page?: number, limit?: number, options?: any): AxiosPromise<RoutePaginatedResponse> {
            return localVarFp.searchRoutes(ids, status, dateFrom, dateTo, assigneeIds, taskIds, sortBy, sortDir, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the route with the given data. Completed routes cannot be changed.
         * @param {string} id Identifier of the route
         * @param {UpdateRouteRequest} updateRouteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoute(id: string, updateRouteRequest: UpdateRouteRequest, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.updateRoute(id, updateRouteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific task of the route with the given data. Completed routes cannot be changed.
         * @param {string} taskId Identifier of the task
         * @param {string} id Identifier of the route
         * @param {UpdateRouteTaskRequest} updateRouteTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteTask(taskId: string, id: string, updateRouteTaskRequest: UpdateRouteTaskRequest, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.updateRouteTask(taskId, id, updateRouteTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the tasks of the route with the given data. Completed routes cannot be changed.
         * @param {string} id Identifier of the route
         * @param {UpdateRouteTasksRequest} updateRouteTasksRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRouteTasks(id: string, updateRouteTasksRequest: UpdateRouteTasksRequest, options?: any): AxiosPromise<RouteResponse> {
            return localVarFp.updateRouteTasks(id, updateRouteTasksRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for approveRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiApproveRouteRequest
 */
export interface RoutesApiApproveRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiApproveRoute
     */
    readonly id: string
}

/**
 * Request parameters for cancelRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiCancelRouteRequest
 */
export interface RoutesApiCancelRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiCancelRoute
     */
    readonly id: string
}

/**
 * Request parameters for completeRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiCompleteRouteRequest
 */
export interface RoutesApiCompleteRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiCompleteRoute
     */
    readonly id: string

    /**
     * 
     * @type {CompleteRouteRequest}
     * @memberof RoutesApiCompleteRoute
     */
    readonly completeRouteRequest: CompleteRouteRequest
}

/**
 * Request parameters for createRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiCreateRouteRequest
 */
export interface RoutesApiCreateRouteRequest {
    /**
     * 
     * @type {CreateRouteRequest}
     * @memberof RoutesApiCreateRoute
     */
    readonly createRouteRequest: CreateRouteRequest
}

/**
 * Request parameters for deleteRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiDeleteRouteRequest
 */
export interface RoutesApiDeleteRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiDeleteRoute
     */
    readonly id: string
}

/**
 * Request parameters for getRawRouteById operation in RoutesApi.
 * @export
 * @interface RoutesApiGetRawRouteByIdRequest
 */
export interface RoutesApiGetRawRouteByIdRequest {
    /**
     * Identifier of the raw route
     * @type {string}
     * @memberof RoutesApiGetRawRouteById
     */
    readonly id: string
}

/**
 * Request parameters for getRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiGetRouteRequest
 */
export interface RoutesApiGetRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiGetRoute
     */
    readonly id: string
}

/**
 * Request parameters for reopenRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiReopenRouteRequest
 */
export interface RoutesApiReopenRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiReopenRoute
     */
    readonly id: string
}

/**
 * Request parameters for searchRoutes operation in RoutesApi.
 * @export
 * @interface RoutesApiSearchRoutesRequest
 */
export interface RoutesApiSearchRoutesRequest {
    /**
     * Filter the routes that match any of the given IDs
     * @type {Array<string>}
     * @memberof RoutesApiSearchRoutes
     */
    readonly ids?: Array<string>

    /**
     * Filter the routes that match any of the give status
     * @type {Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>}
     * @memberof RoutesApiSearchRoutes
     */
    readonly status?: Array<'pendingApproval' | 'ready' | 'completed' | 'cancelled'>

    /**
     * Filter the routes that have a date from the given date onwards
     * @type {string}
     * @memberof RoutesApiSearchRoutes
     */
    readonly dateFrom?: string

    /**
     * Filter the route that have a date until the given date
     * @type {string}
     * @memberof RoutesApiSearchRoutes
     */
    readonly dateTo?: string

    /**
     * Filter the routes assigned to users with the given IDs
     * @type {Array<string>}
     * @memberof RoutesApiSearchRoutes
     */
    readonly assigneeIds?: Array<string>

    /**
     * Filter the routes that contains tasks matching the given IDs
     * @type {Array<string>}
     * @memberof RoutesApiSearchRoutes
     */
    readonly taskIds?: Array<string>

    /**
     * The sort by field.
     * @type {RouteSortType}
     * @memberof RoutesApiSearchRoutes
     */
    readonly sortBy?: RouteSortType

    /**
     * The sort direction. Defaults to DESC.
     * @type {SortDir}
     * @memberof RoutesApiSearchRoutes
     */
    readonly sortDir?: SortDir

    /**
     * The requested page. Starting at 0.
     * @type {number}
     * @memberof RoutesApiSearchRoutes
     */
    readonly page?: number

    /**
     * The requested page size. Maximum items: 1000.
     * @type {number}
     * @memberof RoutesApiSearchRoutes
     */
    readonly limit?: number
}

/**
 * Request parameters for updateRoute operation in RoutesApi.
 * @export
 * @interface RoutesApiUpdateRouteRequest
 */
export interface RoutesApiUpdateRouteRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiUpdateRoute
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRouteRequest}
     * @memberof RoutesApiUpdateRoute
     */
    readonly updateRouteRequest: UpdateRouteRequest
}

/**
 * Request parameters for updateRouteTask operation in RoutesApi.
 * @export
 * @interface RoutesApiUpdateRouteTaskRequest
 */
export interface RoutesApiUpdateRouteTaskRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof RoutesApiUpdateRouteTask
     */
    readonly taskId: string

    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiUpdateRouteTask
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRouteTaskRequest}
     * @memberof RoutesApiUpdateRouteTask
     */
    readonly updateRouteTaskRequest: UpdateRouteTaskRequest
}

/**
 * Request parameters for updateRouteTasks operation in RoutesApi.
 * @export
 * @interface RoutesApiUpdateRouteTasksRequest
 */
export interface RoutesApiUpdateRouteTasksRequest {
    /**
     * Identifier of the route
     * @type {string}
     * @memberof RoutesApiUpdateRouteTasks
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRouteTasksRequest}
     * @memberof RoutesApiUpdateRouteTasks
     */
    readonly updateRouteTasksRequest: UpdateRouteTasksRequest
}

/**
 * RoutesApi - object-oriented interface
 * @export
 * @class RoutesApi
 * @extends {BaseAPI}
 */
export class RoutesApi extends BaseAPI {
    /**
     * 
     * @summary Approves the route, so it can be started.
     * @param {RoutesApiApproveRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public approveRoute(requestParameters: RoutesApiApproveRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).approveRoute(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel the route.
     * @param {RoutesApiCancelRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public cancelRoute(requestParameters: RoutesApiCancelRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).cancelRoute(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Completes the route.
     * @param {RoutesApiCompleteRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public completeRoute(requestParameters: RoutesApiCompleteRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).completeRoute(requestParameters.id, requestParameters.completeRouteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new route with the given data
     * @param {RoutesApiCreateRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public createRoute(requestParameters: RoutesApiCreateRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).createRoute(requestParameters.createRouteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the route matching the given ID. Completed routes cannot be deleted.
     * @param {RoutesApiDeleteRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public deleteRoute(requestParameters: RoutesApiDeleteRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).deleteRoute(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the raw route for the given ID.
     * @param {RoutesApiGetRawRouteByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public getRawRouteById(requestParameters: RoutesApiGetRawRouteByIdRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).getRawRouteById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the route matching the given ID
     * @param {RoutesApiGetRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public getRoute(requestParameters: RoutesApiGetRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).getRoute(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reopens a cancelled route.
     * @param {RoutesApiReopenRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public reopenRoute(requestParameters: RoutesApiReopenRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).reopenRoute(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the routes matching the give criteria. Results are paginated.
     * @param {RoutesApiSearchRoutesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public searchRoutes(requestParameters: RoutesApiSearchRoutesRequest = {}, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).searchRoutes(requestParameters.ids, requestParameters.status, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.assigneeIds, requestParameters.taskIds, requestParameters.sortBy, requestParameters.sortDir, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the route with the given data. Completed routes cannot be changed.
     * @param {RoutesApiUpdateRouteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public updateRoute(requestParameters: RoutesApiUpdateRouteRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).updateRoute(requestParameters.id, requestParameters.updateRouteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific task of the route with the given data. Completed routes cannot be changed.
     * @param {RoutesApiUpdateRouteTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public updateRouteTask(requestParameters: RoutesApiUpdateRouteTaskRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).updateRouteTask(requestParameters.taskId, requestParameters.id, requestParameters.updateRouteTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the tasks of the route with the given data. Completed routes cannot be changed.
     * @param {RoutesApiUpdateRouteTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutesApi
     */
    public updateRouteTasks(requestParameters: RoutesApiUpdateRouteTasksRequest, options?: AxiosRequestConfig) {
        return RoutesApiFp(this.configuration).updateRouteTasks(requestParameters.id, requestParameters.updateRouteTasksRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id Identifier of the media in the storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/v1/storage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all the uploaded files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMedia: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all the uploaded files
         * @param {string} id Identifier of the media in the storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMedia', 'id', id)
            const localVarPath = `/api/v1/storage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id Identifier of the media in the storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletedMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists all the uploaded files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMedia(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorageMediaResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMedia(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists all the uploaded files
         * @param {string} id Identifier of the media in the storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedia(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedia(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id Identifier of the media in the storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<DeletedMediaResponse> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all the uploaded files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMedia(options?: any): AxiosPromise<Array<StorageMediaResponse>> {
            return localVarFp.getAllMedia(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all the uploaded files
         * @param {string} id Identifier of the media in the storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: string, options?: any): AxiosPromise<StorageMediaResponse> {
            return localVarFp.getMedia(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in StorageApi.
 * @export
 * @interface StorageApiDeleteRequest
 */
export interface StorageApiDeleteRequest {
    /**
     * Identifier of the media in the storage
     * @type {string}
     * @memberof StorageApiDelete
     */
    readonly id: string
}

/**
 * Request parameters for getMedia operation in StorageApi.
 * @export
 * @interface StorageApiGetMediaRequest
 */
export interface StorageApiGetMediaRequest {
    /**
     * Identifier of the media in the storage
     * @type {string}
     * @memberof StorageApiGetMedia
     */
    readonly id: string
}

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @param {StorageApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public _delete(requestParameters: StorageApiDeleteRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all the uploaded files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getAllMedia(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).getAllMedia(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all the uploaded files
     * @param {StorageApiGetMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getMedia(requestParameters: StorageApiGetMediaRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).getMedia(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a new comment to the task
         * @param {string} id Identifier of the task
         * @param {AddTaskCommentRequest} addTaskCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskComment: async (id: string, addTaskCommentRequest: AddTaskCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTaskComment', 'id', id)
            // verify required parameter 'addTaskCommentRequest' is not null or undefined
            assertParamExists('addTaskComment', 'addTaskCommentRequest', addTaskCommentRequest)
            const localVarPath = `/api/v1/tasks/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTaskCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new document to the task
         * @param {string} id Identifier of the task
         * @param {any} file 
         * @param {Array<string>} [tags] The comma separated list of tags of the application for the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskDocument: async (id: string, file: any, tags?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTaskDocument', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addTaskDocument', 'file', file)
            const localVarPath = `/api/v1/tasks/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a new incidence to the task
         * @param {string} id Identifier of the task
         * @param {AddTaskIncidenceRequest} addTaskIncidenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskIncidence: async (id: string, addTaskIncidenceRequest: AddTaskIncidenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTaskIncidence', 'id', id)
            // verify required parameter 'addTaskIncidenceRequest' is not null or undefined
            assertParamExists('addTaskIncidence', 'addTaskIncidenceRequest', addTaskIncidenceRequest)
            const localVarPath = `/api/v1/tasks/{id}/incidences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTaskIncidenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set a certain version of the task as collected
         * @param {string} id Identifier of the task
         * @param {CollectTaskVersionRequest} collectTaskVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectTaskVersion: async (id: string, collectTaskVersionRequest: CollectTaskVersionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('collectTaskVersion', 'id', id)
            // verify required parameter 'collectTaskVersionRequest' is not null or undefined
            assertParamExists('collectTaskVersion', 'collectTaskVersionRequest', collectTaskVersionRequest)
            const localVarPath = `/api/v1/tasks/{id}/versions/collect`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectTaskVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new task with the given data
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (createTaskRequest: CreateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskRequest' is not null or undefined
            assertParamExists('createTask', 'createTaskRequest', createTaskRequest)
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new task search with the given data
         * @param {TaskSearchRequest} taskSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskSearch: async (taskSearchRequest: TaskSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskSearchRequest' is not null or undefined
            assertParamExists('createTaskSearch', 'taskSearchRequest', taskSearchRequest)
            const localVarPath = `/api/v1/tasks-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the task matching the given ID
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTask', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a comment from the task
         * @param {string} commentId Identifier of the comment
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskComment: async (commentId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteTaskComment', 'commentId', commentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskComment', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the document of the task
         * @param {string} documentId Identifier of the document
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskDocument: async (documentId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteTaskDocument', 'documentId', documentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskDocument', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes an incidence from the task
         * @param {string} incidenceId Identifier of the incidence
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskIncidence: async (incidenceId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidenceId' is not null or undefined
            assertParamExists('deleteTaskIncidence', 'incidenceId', incidenceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskIncidence', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/incidences/{incidenceId}`
                .replace(`{${"incidenceId"}}`, encodeURIComponent(String(incidenceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the task search matching the given ID
         * @param {string} id Identifier of the task search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskSearch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskSearch', 'id', id)
            const localVarPath = `/api/v1/tasks-searches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the task searches for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTasksSearches: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the pending task incidences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingIncidences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks-incidences/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the task matching the given ID
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTask', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the task document
         * @param {string} documentId Identifier of the document
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskDocument: async (documentId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getTaskDocument', 'documentId', documentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskDocument', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the task document thumbnails
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskDocumentsThumbnails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskDocumentsThumbnails', 'id', id)
            const localVarPath = `/api/v1/tasks/{id}/documents-thumbnails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the tasks matching the give criteria. Results are paginated.
         * @param {Array<string>} [ids] Filter the tasks that match any of the given IDs
         * @param {string} [reference] Filter the tasks which reference matches the given string
         * @param {string} [contains] Filter the tasks which text fields match the given terms
         * @param {Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>} [states] Filter the tasks that match any of the give states
         * @param {boolean} [hasDeadline] Filter the tasks that have a deadline
         * @param {string} [deadlineFrom] Filter the tasks that have a deadline from the given date onwards
         * @param {string} [deadlineTo] Filter the tasks that have a deadline until the given date
         * @param {boolean} [isAssigned] Filter the tasks that have an assigned user
         * @param {Array<string>} [assigneeIds] Filter the tasks that have an assigned which ID matches one of the given IDs
         * @param {boolean} [hasReviewer] Filter the tasks that have a reviewer user
         * @param {Array<string>} [reviewerIds] Filter the tasks that have a reviewer which ID matches one of the given IDs
         * @param {Array<string>} [labelIds] Filter the tasks that have a label which ID matches one of the given IDs
         * @param {string} [createdFrom] Filter the tasks that were created from the given date onwards
         * @param {string} [createdTo] Filter the tasks that were created until the given date
         * @param {string} [updatedFrom] Filter the tasks that were updated from the given date onwards
         * @param {string} [updatedTo] Filter the tasks that were updated until the given date
         * @param {string} [closedFrom] Filter the tasks that were closed from the given date onwards
         * @param {string} [closedTo] Filter the tasks that were closed until the given date
         * @param {Array<'report' | 'homologation' | 'recognition'>} [types] Filter the tasks that match any of the give types
         * @param {boolean} [hasCompany] Filter the tasks that have any company associated
         * @param {Array<string>} [companyIds] Filter the tasks that have a company which ID matches one of the given IDs
         * @param {Array<string>} [baseIds] Filter the tasks that have a base which ID matches one of the given IDs
         * @param {boolean} [hasZone] Filter the tasks that have any zone associated
         * @param {Array<string>} [zoneIds] Filter the tasks that have a zone which ID matches one of the given IDs
         * @param {Array<'inPerson' | 'remote'>} [appraisalTypes] Filter the tasks that match any of the give appraisal types
         * @param {boolean} [hasClient] Filter the tasks that have any client associated
         * @param {Array<string>} [clientIds] Filter the tasks that have a client which ID matches one of the given IDs
         * @param {TaskSortType} [sortBy] The sort by field.
         * @param {SortDir} [sortDir] The sort direction. Defaults to DESC.
         * @param {number} [page] The requested page. Starting at 0.
         * @param {number} [limit] The requested page size. Maximum items: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTasks: async (ids?: Array<string>, reference?: string, contains?: string, states?: Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>, hasDeadline?: boolean, deadlineFrom?: string, deadlineTo?: string, isAssigned?: boolean, assigneeIds?: Array<string>, hasReviewer?: boolean, reviewerIds?: Array<string>, labelIds?: Array<string>, createdFrom?: string, createdTo?: string, updatedFrom?: string, updatedTo?: string, closedFrom?: string, closedTo?: string, types?: Array<'report' | 'homologation' | 'recognition'>, hasCompany?: boolean, companyIds?: Array<string>, baseIds?: Array<string>, hasZone?: boolean, zoneIds?: Array<string>, appraisalTypes?: Array<'inPerson' | 'remote'>, hasClient?: boolean, clientIds?: Array<string>, sortBy?: TaskSortType, sortDir?: SortDir, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }

            if (contains !== undefined) {
                localVarQueryParameter['contains'] = contains;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            if (hasDeadline !== undefined) {
                localVarQueryParameter['hasDeadline'] = hasDeadline;
            }

            if (deadlineFrom !== undefined) {
                localVarQueryParameter['deadlineFrom'] = (deadlineFrom as any instanceof Date) ?
                    (deadlineFrom as any).toISOString() :
                    deadlineFrom;
            }

            if (deadlineTo !== undefined) {
                localVarQueryParameter['deadlineTo'] = (deadlineTo as any instanceof Date) ?
                    (deadlineTo as any).toISOString() :
                    deadlineTo;
            }

            if (isAssigned !== undefined) {
                localVarQueryParameter['isAssigned'] = isAssigned;
            }

            if (assigneeIds) {
                localVarQueryParameter['assigneeIds'] = assigneeIds;
            }

            if (hasReviewer !== undefined) {
                localVarQueryParameter['hasReviewer'] = hasReviewer;
            }

            if (reviewerIds) {
                localVarQueryParameter['reviewerIds'] = reviewerIds;
            }

            if (labelIds) {
                localVarQueryParameter['labelIds'] = labelIds;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['createdFrom'] = (createdFrom as any instanceof Date) ?
                    (createdFrom as any).toISOString() :
                    createdFrom;
            }

            if (createdTo !== undefined) {
                localVarQueryParameter['createdTo'] = (createdTo as any instanceof Date) ?
                    (createdTo as any).toISOString() :
                    createdTo;
            }

            if (updatedFrom !== undefined) {
                localVarQueryParameter['updatedFrom'] = (updatedFrom as any instanceof Date) ?
                    (updatedFrom as any).toISOString() :
                    updatedFrom;
            }

            if (updatedTo !== undefined) {
                localVarQueryParameter['updatedTo'] = (updatedTo as any instanceof Date) ?
                    (updatedTo as any).toISOString() :
                    updatedTo;
            }

            if (closedFrom !== undefined) {
                localVarQueryParameter['closedFrom'] = (closedFrom as any instanceof Date) ?
                    (closedFrom as any).toISOString() :
                    closedFrom;
            }

            if (closedTo !== undefined) {
                localVarQueryParameter['closedTo'] = (closedTo as any instanceof Date) ?
                    (closedTo as any).toISOString() :
                    closedTo;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (hasCompany !== undefined) {
                localVarQueryParameter['hasCompany'] = hasCompany;
            }

            if (companyIds) {
                localVarQueryParameter['companyIds'] = companyIds;
            }

            if (baseIds) {
                localVarQueryParameter['baseIds'] = baseIds;
            }

            if (hasZone !== undefined) {
                localVarQueryParameter['hasZone'] = hasZone;
            }

            if (zoneIds) {
                localVarQueryParameter['zoneIds'] = zoneIds;
            }

            if (appraisalTypes) {
                localVarQueryParameter['appraisalTypes'] = appraisalTypes;
            }

            if (hasClient !== undefined) {
                localVarQueryParameter['hasClient'] = hasClient;
            }

            if (clientIds) {
                localVarQueryParameter['clientIds'] = clientIds;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the task with the given data
         * @param {string} id Identifier of the task
         * @param {UpdateTaskRequest} updateTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (id: string, updateTaskRequest: UpdateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTask', 'id', id)
            // verify required parameter 'updateTaskRequest' is not null or undefined
            assertParamExists('updateTask', 'updateTaskRequest', updateTaskRequest)
            const localVarPath = `/api/v1/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an incidence to the task
         * @param {string} incidenceId Identifier of the incidence
         * @param {string} id Identifier of the task
         * @param {UpdateTaskIncidenceRequest} updateTaskIncidenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskIncidence: async (incidenceId: string, id: string, updateTaskIncidenceRequest: UpdateTaskIncidenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'incidenceId' is not null or undefined
            assertParamExists('updateTaskIncidence', 'incidenceId', incidenceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskIncidence', 'id', id)
            // verify required parameter 'updateTaskIncidenceRequest' is not null or undefined
            assertParamExists('updateTaskIncidence', 'updateTaskIncidenceRequest', updateTaskIncidenceRequest)
            const localVarPath = `/api/v1/tasks/{id}/incidences/{incidenceId}`
                .replace(`{${"incidenceId"}}`, encodeURIComponent(String(incidenceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskIncidenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the task search with the given data
         * @param {string} id Identifier of the task search
         * @param {TaskSearchRequest} taskSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskSearch: async (id: string, taskSearchRequest: TaskSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskSearch', 'id', id)
            // verify required parameter 'taskSearchRequest' is not null or undefined
            assertParamExists('updateTaskSearch', 'taskSearchRequest', taskSearchRequest)
            const localVarPath = `/api/v1/tasks-searches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the task searches with the given data
         * @param {UpdateTaskSearchesRequest} updateTaskSearchesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskSearches: async (updateTaskSearchesRequest: UpdateTaskSearchesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTaskSearchesRequest' is not null or undefined
            assertParamExists('updateTaskSearches', 'updateTaskSearchesRequest', updateTaskSearchesRequest)
            const localVarPath = `/api/v1/tasks-searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskSearchesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a new comment to the task
         * @param {string} id Identifier of the task
         * @param {AddTaskCommentRequest} addTaskCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaskComment(id: string, addTaskCommentRequest: AddTaskCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddTaskCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaskComment(id, addTaskCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a new document to the task
         * @param {string} id Identifier of the task
         * @param {any} file 
         * @param {Array<string>} [tags] The comma separated list of tags of the application for the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaskDocument(id: string, file: any, tags?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddTaskDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaskDocument(id, file, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a new incidence to the task
         * @param {string} id Identifier of the task
         * @param {AddTaskIncidenceRequest} addTaskIncidenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTaskIncidence(id: string, addTaskIncidenceRequest: AddTaskIncidenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIncidenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTaskIncidence(id, addTaskIncidenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set a certain version of the task as collected
         * @param {string} id Identifier of the task
         * @param {CollectTaskVersionRequest} collectTaskVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectTaskVersion(id: string, collectTaskVersionRequest: CollectTaskVersionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectTaskVersion(id, collectTaskVersionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new task with the given data
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(createTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new task search with the given data
         * @param {TaskSearchRequest} taskSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskSearch(taskSearchRequest: TaskSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskSearch(taskSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the task matching the given ID
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a comment from the task
         * @param {string} commentId Identifier of the comment
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskComment(commentId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskComment(commentId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the document of the task
         * @param {string} documentId Identifier of the document
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskDocument(documentId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskDocument(documentId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes an incidence from the task
         * @param {string} incidenceId Identifier of the incidence
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskIncidence(incidenceId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskIncidence(incidenceId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the task search matching the given ID
         * @param {string} id Identifier of the task search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskSearch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSearchDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskSearch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the task searches for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTasksSearches(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskSearchResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyTasksSearches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the pending task incidences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingIncidences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTaskIncidencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingIncidences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the task matching the given ID
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the task document
         * @param {string} documentId Identifier of the document
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskDocument(documentId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskDocument(documentId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the task document thumbnails
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskDocumentsThumbnails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThumbnailResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskDocumentsThumbnails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the tasks matching the give criteria. Results are paginated.
         * @param {Array<string>} [ids] Filter the tasks that match any of the given IDs
         * @param {string} [reference] Filter the tasks which reference matches the given string
         * @param {string} [contains] Filter the tasks which text fields match the given terms
         * @param {Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>} [states] Filter the tasks that match any of the give states
         * @param {boolean} [hasDeadline] Filter the tasks that have a deadline
         * @param {string} [deadlineFrom] Filter the tasks that have a deadline from the given date onwards
         * @param {string} [deadlineTo] Filter the tasks that have a deadline until the given date
         * @param {boolean} [isAssigned] Filter the tasks that have an assigned user
         * @param {Array<string>} [assigneeIds] Filter the tasks that have an assigned which ID matches one of the given IDs
         * @param {boolean} [hasReviewer] Filter the tasks that have a reviewer user
         * @param {Array<string>} [reviewerIds] Filter the tasks that have a reviewer which ID matches one of the given IDs
         * @param {Array<string>} [labelIds] Filter the tasks that have a label which ID matches one of the given IDs
         * @param {string} [createdFrom] Filter the tasks that were created from the given date onwards
         * @param {string} [createdTo] Filter the tasks that were created until the given date
         * @param {string} [updatedFrom] Filter the tasks that were updated from the given date onwards
         * @param {string} [updatedTo] Filter the tasks that were updated until the given date
         * @param {string} [closedFrom] Filter the tasks that were closed from the given date onwards
         * @param {string} [closedTo] Filter the tasks that were closed until the given date
         * @param {Array<'report' | 'homologation' | 'recognition'>} [types] Filter the tasks that match any of the give types
         * @param {boolean} [hasCompany] Filter the tasks that have any company associated
         * @param {Array<string>} [companyIds] Filter the tasks that have a company which ID matches one of the given IDs
         * @param {Array<string>} [baseIds] Filter the tasks that have a base which ID matches one of the given IDs
         * @param {boolean} [hasZone] Filter the tasks that have any zone associated
         * @param {Array<string>} [zoneIds] Filter the tasks that have a zone which ID matches one of the given IDs
         * @param {Array<'inPerson' | 'remote'>} [appraisalTypes] Filter the tasks that match any of the give appraisal types
         * @param {boolean} [hasClient] Filter the tasks that have any client associated
         * @param {Array<string>} [clientIds] Filter the tasks that have a client which ID matches one of the given IDs
         * @param {TaskSortType} [sortBy] The sort by field.
         * @param {SortDir} [sortDir] The sort direction. Defaults to DESC.
         * @param {number} [page] The requested page. Starting at 0.
         * @param {number} [limit] The requested page size. Maximum items: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTasks(ids?: Array<string>, reference?: string, contains?: string, states?: Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>, hasDeadline?: boolean, deadlineFrom?: string, deadlineTo?: string, isAssigned?: boolean, assigneeIds?: Array<string>, hasReviewer?: boolean, reviewerIds?: Array<string>, labelIds?: Array<string>, createdFrom?: string, createdTo?: string, updatedFrom?: string, updatedTo?: string, closedFrom?: string, closedTo?: string, types?: Array<'report' | 'homologation' | 'recognition'>, hasCompany?: boolean, companyIds?: Array<string>, baseIds?: Array<string>, hasZone?: boolean, zoneIds?: Array<string>, appraisalTypes?: Array<'inPerson' | 'remote'>, hasClient?: boolean, clientIds?: Array<string>, sortBy?: TaskSortType, sortDir?: SortDir, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTasks(ids, reference, contains, states, hasDeadline, deadlineFrom, deadlineTo, isAssigned, assigneeIds, hasReviewer, reviewerIds, labelIds, createdFrom, createdTo, updatedFrom, updatedTo, closedFrom, closedTo, types, hasCompany, companyIds, baseIds, hasZone, zoneIds, appraisalTypes, hasClient, clientIds, sortBy, sortDir, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the task with the given data
         * @param {string} id Identifier of the task
         * @param {UpdateTaskRequest} updateTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(id: string, updateTaskRequest: UpdateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(id, updateTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an incidence to the task
         * @param {string} incidenceId Identifier of the incidence
         * @param {string} id Identifier of the task
         * @param {UpdateTaskIncidenceRequest} updateTaskIncidenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskIncidence(incidenceId: string, id: string, updateTaskIncidenceRequest: UpdateTaskIncidenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIncidenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskIncidence(incidenceId, id, updateTaskIncidenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the task search with the given data
         * @param {string} id Identifier of the task search
         * @param {TaskSearchRequest} taskSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskSearch(id: string, taskSearchRequest: TaskSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskSearch(id, taskSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the task searches with the given data
         * @param {UpdateTaskSearchesRequest} updateTaskSearchesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskSearches(updateTaskSearchesRequest: UpdateTaskSearchesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskSearchResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskSearches(updateTaskSearchesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a new comment to the task
         * @param {string} id Identifier of the task
         * @param {AddTaskCommentRequest} addTaskCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskComment(id: string, addTaskCommentRequest: AddTaskCommentRequest, options?: any): AxiosPromise<AddTaskCommentResponse> {
            return localVarFp.addTaskComment(id, addTaskCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new document to the task
         * @param {string} id Identifier of the task
         * @param {any} file 
         * @param {Array<string>} [tags] The comma separated list of tags of the application for the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskDocument(id: string, file: any, tags?: Array<string>, options?: any): AxiosPromise<AddTaskDocumentResponse> {
            return localVarFp.addTaskDocument(id, file, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a new incidence to the task
         * @param {string} id Identifier of the task
         * @param {AddTaskIncidenceRequest} addTaskIncidenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTaskIncidence(id: string, addTaskIncidenceRequest: AddTaskIncidenceRequest, options?: any): AxiosPromise<TaskIncidenceResponse> {
            return localVarFp.addTaskIncidence(id, addTaskIncidenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set a certain version of the task as collected
         * @param {string} id Identifier of the task
         * @param {CollectTaskVersionRequest} collectTaskVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectTaskVersion(id: string, collectTaskVersionRequest: CollectTaskVersionRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.collectTaskVersion(id, collectTaskVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new task with the given data
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(createTaskRequest: CreateTaskRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.createTask(createTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new task search with the given data
         * @param {TaskSearchRequest} taskSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskSearch(taskSearchRequest: TaskSearchRequest, options?: any): AxiosPromise<TaskSearchResponse> {
            return localVarFp.createTaskSearch(taskSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the task matching the given ID
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask(id: string, options?: any): AxiosPromise<TaskDeletedResponse> {
            return localVarFp.deleteTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a comment from the task
         * @param {string} commentId Identifier of the comment
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskComment(commentId: string, id: string, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.deleteTaskComment(commentId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the document of the task
         * @param {string} documentId Identifier of the document
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskDocument(documentId: string, id: string, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.deleteTaskDocument(documentId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes an incidence from the task
         * @param {string} incidenceId Identifier of the incidence
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskIncidence(incidenceId: string, id: string, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.deleteTaskIncidence(incidenceId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the task search matching the given ID
         * @param {string} id Identifier of the task search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskSearch(id: string, options?: any): AxiosPromise<TaskSearchDeletedResponse> {
            return localVarFp.deleteTaskSearch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the task searches for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTasksSearches(options?: any): AxiosPromise<Array<TaskSearchResponse>> {
            return localVarFp.getMyTasksSearches(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the pending task incidences.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingIncidences(options?: any): AxiosPromise<ListTaskIncidencesResponse> {
            return localVarFp.getPendingIncidences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the task matching the given ID
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(id: string, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.getTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the task document
         * @param {string} documentId Identifier of the document
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskDocument(documentId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getTaskDocument(documentId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the task document thumbnails
         * @param {string} id Identifier of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskDocumentsThumbnails(id: string, options?: any): AxiosPromise<Array<ThumbnailResponse>> {
            return localVarFp.getTaskDocumentsThumbnails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the tasks matching the give criteria. Results are paginated.
         * @param {Array<string>} [ids] Filter the tasks that match any of the given IDs
         * @param {string} [reference] Filter the tasks which reference matches the given string
         * @param {string} [contains] Filter the tasks which text fields match the given terms
         * @param {Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>} [states] Filter the tasks that match any of the give states
         * @param {boolean} [hasDeadline] Filter the tasks that have a deadline
         * @param {string} [deadlineFrom] Filter the tasks that have a deadline from the given date onwards
         * @param {string} [deadlineTo] Filter the tasks that have a deadline until the given date
         * @param {boolean} [isAssigned] Filter the tasks that have an assigned user
         * @param {Array<string>} [assigneeIds] Filter the tasks that have an assigned which ID matches one of the given IDs
         * @param {boolean} [hasReviewer] Filter the tasks that have a reviewer user
         * @param {Array<string>} [reviewerIds] Filter the tasks that have a reviewer which ID matches one of the given IDs
         * @param {Array<string>} [labelIds] Filter the tasks that have a label which ID matches one of the given IDs
         * @param {string} [createdFrom] Filter the tasks that were created from the given date onwards
         * @param {string} [createdTo] Filter the tasks that were created until the given date
         * @param {string} [updatedFrom] Filter the tasks that were updated from the given date onwards
         * @param {string} [updatedTo] Filter the tasks that were updated until the given date
         * @param {string} [closedFrom] Filter the tasks that were closed from the given date onwards
         * @param {string} [closedTo] Filter the tasks that were closed until the given date
         * @param {Array<'report' | 'homologation' | 'recognition'>} [types] Filter the tasks that match any of the give types
         * @param {boolean} [hasCompany] Filter the tasks that have any company associated
         * @param {Array<string>} [companyIds] Filter the tasks that have a company which ID matches one of the given IDs
         * @param {Array<string>} [baseIds] Filter the tasks that have a base which ID matches one of the given IDs
         * @param {boolean} [hasZone] Filter the tasks that have any zone associated
         * @param {Array<string>} [zoneIds] Filter the tasks that have a zone which ID matches one of the given IDs
         * @param {Array<'inPerson' | 'remote'>} [appraisalTypes] Filter the tasks that match any of the give appraisal types
         * @param {boolean} [hasClient] Filter the tasks that have any client associated
         * @param {Array<string>} [clientIds] Filter the tasks that have a client which ID matches one of the given IDs
         * @param {TaskSortType} [sortBy] The sort by field.
         * @param {SortDir} [sortDir] The sort direction. Defaults to DESC.
         * @param {number} [page] The requested page. Starting at 0.
         * @param {number} [limit] The requested page size. Maximum items: 1000.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTasks(ids?: Array<string>, reference?: string, contains?: string, states?: Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>, hasDeadline?: boolean, deadlineFrom?: string, deadlineTo?: string, isAssigned?: boolean, assigneeIds?: Array<string>, hasReviewer?: boolean, reviewerIds?: Array<string>, labelIds?: Array<string>, createdFrom?: string, createdTo?: string, updatedFrom?: string, updatedTo?: string, closedFrom?: string, closedTo?: string, types?: Array<'report' | 'homologation' | 'recognition'>, hasCompany?: boolean, companyIds?: Array<string>, baseIds?: Array<string>, hasZone?: boolean, zoneIds?: Array<string>, appraisalTypes?: Array<'inPerson' | 'remote'>, hasClient?: boolean, clientIds?: Array<string>, sortBy?: TaskSortType, sortDir?: SortDir, page?: number, limit?: number, options?: any): AxiosPromise<ListTasksResponse> {
            return localVarFp.searchTasks(ids, reference, contains, states, hasDeadline, deadlineFrom, deadlineTo, isAssigned, assigneeIds, hasReviewer, reviewerIds, labelIds, createdFrom, createdTo, updatedFrom, updatedTo, closedFrom, closedTo, types, hasCompany, companyIds, baseIds, hasZone, zoneIds, appraisalTypes, hasClient, clientIds, sortBy, sortDir, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the task with the given data
         * @param {string} id Identifier of the task
         * @param {UpdateTaskRequest} updateTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(id: string, updateTaskRequest: UpdateTaskRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.updateTask(id, updateTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an incidence to the task
         * @param {string} incidenceId Identifier of the incidence
         * @param {string} id Identifier of the task
         * @param {UpdateTaskIncidenceRequest} updateTaskIncidenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskIncidence(incidenceId: string, id: string, updateTaskIncidenceRequest: UpdateTaskIncidenceRequest, options?: any): AxiosPromise<TaskIncidenceResponse> {
            return localVarFp.updateTaskIncidence(incidenceId, id, updateTaskIncidenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the task search with the given data
         * @param {string} id Identifier of the task search
         * @param {TaskSearchRequest} taskSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskSearch(id: string, taskSearchRequest: TaskSearchRequest, options?: any): AxiosPromise<TaskSearchResponse> {
            return localVarFp.updateTaskSearch(id, taskSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the task searches with the given data
         * @param {UpdateTaskSearchesRequest} updateTaskSearchesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskSearches(updateTaskSearchesRequest: UpdateTaskSearchesRequest, options?: any): AxiosPromise<Array<TaskSearchResponse>> {
            return localVarFp.updateTaskSearches(updateTaskSearchesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addTaskComment operation in TasksApi.
 * @export
 * @interface TasksApiAddTaskCommentRequest
 */
export interface TasksApiAddTaskCommentRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiAddTaskComment
     */
    readonly id: string

    /**
     * 
     * @type {AddTaskCommentRequest}
     * @memberof TasksApiAddTaskComment
     */
    readonly addTaskCommentRequest: AddTaskCommentRequest
}

/**
 * Request parameters for addTaskDocument operation in TasksApi.
 * @export
 * @interface TasksApiAddTaskDocumentRequest
 */
export interface TasksApiAddTaskDocumentRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiAddTaskDocument
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof TasksApiAddTaskDocument
     */
    readonly file: any

    /**
     * The comma separated list of tags of the application for the file
     * @type {Array<string>}
     * @memberof TasksApiAddTaskDocument
     */
    readonly tags?: Array<string>
}

/**
 * Request parameters for addTaskIncidence operation in TasksApi.
 * @export
 * @interface TasksApiAddTaskIncidenceRequest
 */
export interface TasksApiAddTaskIncidenceRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiAddTaskIncidence
     */
    readonly id: string

    /**
     * 
     * @type {AddTaskIncidenceRequest}
     * @memberof TasksApiAddTaskIncidence
     */
    readonly addTaskIncidenceRequest: AddTaskIncidenceRequest
}

/**
 * Request parameters for collectTaskVersion operation in TasksApi.
 * @export
 * @interface TasksApiCollectTaskVersionRequest
 */
export interface TasksApiCollectTaskVersionRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiCollectTaskVersion
     */
    readonly id: string

    /**
     * 
     * @type {CollectTaskVersionRequest}
     * @memberof TasksApiCollectTaskVersion
     */
    readonly collectTaskVersionRequest: CollectTaskVersionRequest
}

/**
 * Request parameters for createTask operation in TasksApi.
 * @export
 * @interface TasksApiCreateTaskRequest
 */
export interface TasksApiCreateTaskRequest {
    /**
     * 
     * @type {CreateTaskRequest}
     * @memberof TasksApiCreateTask
     */
    readonly createTaskRequest: CreateTaskRequest
}

/**
 * Request parameters for createTaskSearch operation in TasksApi.
 * @export
 * @interface TasksApiCreateTaskSearchRequest
 */
export interface TasksApiCreateTaskSearchRequest {
    /**
     * 
     * @type {TaskSearchRequest}
     * @memberof TasksApiCreateTaskSearch
     */
    readonly taskSearchRequest: TaskSearchRequest
}

/**
 * Request parameters for deleteTask operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskRequest
 */
export interface TasksApiDeleteTaskRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiDeleteTask
     */
    readonly id: string
}

/**
 * Request parameters for deleteTaskComment operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskCommentRequest
 */
export interface TasksApiDeleteTaskCommentRequest {
    /**
     * Identifier of the comment
     * @type {string}
     * @memberof TasksApiDeleteTaskComment
     */
    readonly commentId: string

    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiDeleteTaskComment
     */
    readonly id: string
}

/**
 * Request parameters for deleteTaskDocument operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskDocumentRequest
 */
export interface TasksApiDeleteTaskDocumentRequest {
    /**
     * Identifier of the document
     * @type {string}
     * @memberof TasksApiDeleteTaskDocument
     */
    readonly documentId: string

    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiDeleteTaskDocument
     */
    readonly id: string
}

/**
 * Request parameters for deleteTaskIncidence operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskIncidenceRequest
 */
export interface TasksApiDeleteTaskIncidenceRequest {
    /**
     * Identifier of the incidence
     * @type {string}
     * @memberof TasksApiDeleteTaskIncidence
     */
    readonly incidenceId: string

    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiDeleteTaskIncidence
     */
    readonly id: string
}

/**
 * Request parameters for deleteTaskSearch operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskSearchRequest
 */
export interface TasksApiDeleteTaskSearchRequest {
    /**
     * Identifier of the task search
     * @type {string}
     * @memberof TasksApiDeleteTaskSearch
     */
    readonly id: string
}

/**
 * Request parameters for getTask operation in TasksApi.
 * @export
 * @interface TasksApiGetTaskRequest
 */
export interface TasksApiGetTaskRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiGetTask
     */
    readonly id: string
}

/**
 * Request parameters for getTaskDocument operation in TasksApi.
 * @export
 * @interface TasksApiGetTaskDocumentRequest
 */
export interface TasksApiGetTaskDocumentRequest {
    /**
     * Identifier of the document
     * @type {string}
     * @memberof TasksApiGetTaskDocument
     */
    readonly documentId: string

    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiGetTaskDocument
     */
    readonly id: string
}

/**
 * Request parameters for getTaskDocumentsThumbnails operation in TasksApi.
 * @export
 * @interface TasksApiGetTaskDocumentsThumbnailsRequest
 */
export interface TasksApiGetTaskDocumentsThumbnailsRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiGetTaskDocumentsThumbnails
     */
    readonly id: string
}

/**
 * Request parameters for searchTasks operation in TasksApi.
 * @export
 * @interface TasksApiSearchTasksRequest
 */
export interface TasksApiSearchTasksRequest {
    /**
     * Filter the tasks that match any of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly ids?: Array<string>

    /**
     * Filter the tasks which reference matches the given string
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly reference?: string

    /**
     * Filter the tasks which text fields match the given terms
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly contains?: string

    /**
     * Filter the tasks that match any of the give states
     * @type {Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>}
     * @memberof TasksApiSearchTasks
     */
    readonly states?: Array<'draft' | 'open' | 'contact' | 'visit' | 'buildReport' | 'reviewReport' | 'inLaboratory' | 'accepted' | 'inInspection' | 'close' | 'cancelled'>

    /**
     * Filter the tasks that have a deadline
     * @type {boolean}
     * @memberof TasksApiSearchTasks
     */
    readonly hasDeadline?: boolean

    /**
     * Filter the tasks that have a deadline from the given date onwards
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly deadlineFrom?: string

    /**
     * Filter the tasks that have a deadline until the given date
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly deadlineTo?: string

    /**
     * Filter the tasks that have an assigned user
     * @type {boolean}
     * @memberof TasksApiSearchTasks
     */
    readonly isAssigned?: boolean

    /**
     * Filter the tasks that have an assigned which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly assigneeIds?: Array<string>

    /**
     * Filter the tasks that have a reviewer user
     * @type {boolean}
     * @memberof TasksApiSearchTasks
     */
    readonly hasReviewer?: boolean

    /**
     * Filter the tasks that have a reviewer which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly reviewerIds?: Array<string>

    /**
     * Filter the tasks that have a label which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly labelIds?: Array<string>

    /**
     * Filter the tasks that were created from the given date onwards
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly createdFrom?: string

    /**
     * Filter the tasks that were created until the given date
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly createdTo?: string

    /**
     * Filter the tasks that were updated from the given date onwards
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly updatedFrom?: string

    /**
     * Filter the tasks that were updated until the given date
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly updatedTo?: string

    /**
     * Filter the tasks that were closed from the given date onwards
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly closedFrom?: string

    /**
     * Filter the tasks that were closed until the given date
     * @type {string}
     * @memberof TasksApiSearchTasks
     */
    readonly closedTo?: string

    /**
     * Filter the tasks that match any of the give types
     * @type {Array<'report' | 'homologation' | 'recognition'>}
     * @memberof TasksApiSearchTasks
     */
    readonly types?: Array<'report' | 'homologation' | 'recognition'>

    /**
     * Filter the tasks that have any company associated
     * @type {boolean}
     * @memberof TasksApiSearchTasks
     */
    readonly hasCompany?: boolean

    /**
     * Filter the tasks that have a company which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly companyIds?: Array<string>

    /**
     * Filter the tasks that have a base which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly baseIds?: Array<string>

    /**
     * Filter the tasks that have any zone associated
     * @type {boolean}
     * @memberof TasksApiSearchTasks
     */
    readonly hasZone?: boolean

    /**
     * Filter the tasks that have a zone which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly zoneIds?: Array<string>

    /**
     * Filter the tasks that match any of the give appraisal types
     * @type {Array<'inPerson' | 'remote'>}
     * @memberof TasksApiSearchTasks
     */
    readonly appraisalTypes?: Array<'inPerson' | 'remote'>

    /**
     * Filter the tasks that have any client associated
     * @type {boolean}
     * @memberof TasksApiSearchTasks
     */
    readonly hasClient?: boolean

    /**
     * Filter the tasks that have a client which ID matches one of the given IDs
     * @type {Array<string>}
     * @memberof TasksApiSearchTasks
     */
    readonly clientIds?: Array<string>

    /**
     * The sort by field.
     * @type {TaskSortType}
     * @memberof TasksApiSearchTasks
     */
    readonly sortBy?: TaskSortType

    /**
     * The sort direction. Defaults to DESC.
     * @type {SortDir}
     * @memberof TasksApiSearchTasks
     */
    readonly sortDir?: SortDir

    /**
     * The requested page. Starting at 0.
     * @type {number}
     * @memberof TasksApiSearchTasks
     */
    readonly page?: number

    /**
     * The requested page size. Maximum items: 1000.
     * @type {number}
     * @memberof TasksApiSearchTasks
     */
    readonly limit?: number
}

/**
 * Request parameters for updateTask operation in TasksApi.
 * @export
 * @interface TasksApiUpdateTaskRequest
 */
export interface TasksApiUpdateTaskRequest {
    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiUpdateTask
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTaskRequest}
     * @memberof TasksApiUpdateTask
     */
    readonly updateTaskRequest: UpdateTaskRequest
}

/**
 * Request parameters for updateTaskIncidence operation in TasksApi.
 * @export
 * @interface TasksApiUpdateTaskIncidenceRequest
 */
export interface TasksApiUpdateTaskIncidenceRequest {
    /**
     * Identifier of the incidence
     * @type {string}
     * @memberof TasksApiUpdateTaskIncidence
     */
    readonly incidenceId: string

    /**
     * Identifier of the task
     * @type {string}
     * @memberof TasksApiUpdateTaskIncidence
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTaskIncidenceRequest}
     * @memberof TasksApiUpdateTaskIncidence
     */
    readonly updateTaskIncidenceRequest: UpdateTaskIncidenceRequest
}

/**
 * Request parameters for updateTaskSearch operation in TasksApi.
 * @export
 * @interface TasksApiUpdateTaskSearchRequest
 */
export interface TasksApiUpdateTaskSearchRequest {
    /**
     * Identifier of the task search
     * @type {string}
     * @memberof TasksApiUpdateTaskSearch
     */
    readonly id: string

    /**
     * 
     * @type {TaskSearchRequest}
     * @memberof TasksApiUpdateTaskSearch
     */
    readonly taskSearchRequest: TaskSearchRequest
}

/**
 * Request parameters for updateTaskSearches operation in TasksApi.
 * @export
 * @interface TasksApiUpdateTaskSearchesRequest
 */
export interface TasksApiUpdateTaskSearchesRequest {
    /**
     * 
     * @type {UpdateTaskSearchesRequest}
     * @memberof TasksApiUpdateTaskSearches
     */
    readonly updateTaskSearchesRequest: UpdateTaskSearchesRequest
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary Adds a new comment to the task
     * @param {TasksApiAddTaskCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTaskComment(requestParameters: TasksApiAddTaskCommentRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTaskComment(requestParameters.id, requestParameters.addTaskCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new document to the task
     * @param {TasksApiAddTaskDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTaskDocument(requestParameters: TasksApiAddTaskDocumentRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTaskDocument(requestParameters.id, requestParameters.file, requestParameters.tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a new incidence to the task
     * @param {TasksApiAddTaskIncidenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTaskIncidence(requestParameters: TasksApiAddTaskIncidenceRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTaskIncidence(requestParameters.id, requestParameters.addTaskIncidenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set a certain version of the task as collected
     * @param {TasksApiCollectTaskVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public collectTaskVersion(requestParameters: TasksApiCollectTaskVersionRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).collectTaskVersion(requestParameters.id, requestParameters.collectTaskVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new task with the given data
     * @param {TasksApiCreateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createTask(requestParameters: TasksApiCreateTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).createTask(requestParameters.createTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new task search with the given data
     * @param {TasksApiCreateTaskSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createTaskSearch(requestParameters: TasksApiCreateTaskSearchRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).createTaskSearch(requestParameters.taskSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the task matching the given ID
     * @param {TasksApiDeleteTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTask(requestParameters: TasksApiDeleteTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a comment from the task
     * @param {TasksApiDeleteTaskCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTaskComment(requestParameters: TasksApiDeleteTaskCommentRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTaskComment(requestParameters.commentId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the document of the task
     * @param {TasksApiDeleteTaskDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTaskDocument(requestParameters: TasksApiDeleteTaskDocumentRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTaskDocument(requestParameters.documentId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes an incidence from the task
     * @param {TasksApiDeleteTaskIncidenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTaskIncidence(requestParameters: TasksApiDeleteTaskIncidenceRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTaskIncidence(requestParameters.incidenceId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the task search matching the given ID
     * @param {TasksApiDeleteTaskSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTaskSearch(requestParameters: TasksApiDeleteTaskSearchRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTaskSearch(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the task searches for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getMyTasksSearches(options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).getMyTasksSearches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the pending task incidences.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getPendingIncidences(options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).getPendingIncidences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the task matching the given ID
     * @param {TasksApiGetTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTask(requestParameters: TasksApiGetTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the task document
     * @param {TasksApiGetTaskDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTaskDocument(requestParameters: TasksApiGetTaskDocumentRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTaskDocument(requestParameters.documentId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the task document thumbnails
     * @param {TasksApiGetTaskDocumentsThumbnailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTaskDocumentsThumbnails(requestParameters: TasksApiGetTaskDocumentsThumbnailsRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTaskDocumentsThumbnails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the tasks matching the give criteria. Results are paginated.
     * @param {TasksApiSearchTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public searchTasks(requestParameters: TasksApiSearchTasksRequest = {}, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).searchTasks(requestParameters.ids, requestParameters.reference, requestParameters.contains, requestParameters.states, requestParameters.hasDeadline, requestParameters.deadlineFrom, requestParameters.deadlineTo, requestParameters.isAssigned, requestParameters.assigneeIds, requestParameters.hasReviewer, requestParameters.reviewerIds, requestParameters.labelIds, requestParameters.createdFrom, requestParameters.createdTo, requestParameters.updatedFrom, requestParameters.updatedTo, requestParameters.closedFrom, requestParameters.closedTo, requestParameters.types, requestParameters.hasCompany, requestParameters.companyIds, requestParameters.baseIds, requestParameters.hasZone, requestParameters.zoneIds, requestParameters.appraisalTypes, requestParameters.hasClient, requestParameters.clientIds, requestParameters.sortBy, requestParameters.sortDir, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the task with the given data
     * @param {TasksApiUpdateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTask(requestParameters: TasksApiUpdateTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTask(requestParameters.id, requestParameters.updateTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an incidence to the task
     * @param {TasksApiUpdateTaskIncidenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTaskIncidence(requestParameters: TasksApiUpdateTaskIncidenceRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTaskIncidence(requestParameters.incidenceId, requestParameters.id, requestParameters.updateTaskIncidenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the task search with the given data
     * @param {TasksApiUpdateTaskSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTaskSearch(requestParameters: TasksApiUpdateTaskSearchRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTaskSearch(requestParameters.id, requestParameters.taskSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the task searches with the given data
     * @param {TasksApiUpdateTaskSearchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTaskSearches(requestParameters: TasksApiUpdateTaskSearchesRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTaskSearches(requestParameters.updateTaskSearchesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new user with the given data
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the avatar of the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAvatar: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserAvatar', 'id', id)
            const localVarPath = `/api/v1/users/{id}/avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user with the given data
         * @param {string} id Identifier of the user
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the avatar of a given user
         * @param {string} id Identifier of the user
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAvatar: async (id: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateUserAvatar', 'file', file)
            const localVarPath = `/api/v1/users/{id}/avatar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new user with the given data
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the avatar of the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAvatar(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAvatar(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user with the given data
         * @param {string} id Identifier of the user
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the avatar of a given user
         * @param {string} id Identifier of the user
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAvatar(id: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAvatar(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new user with the given data
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<UserDeletedResponse> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the avatar of the user matching the given ID
         * @param {string} id Identifier of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAvatar(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getUserAvatar(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user with the given data
         * @param {string} id Identifier of the user
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUser(id, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the avatar of a given user
         * @param {string} id Identifier of the user
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAvatar(id: string, file: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserAvatar(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserRequest
 */
export interface UsersApiCreateUserRequest {
    /**
     * 
     * @type {CreateUserRequest}
     * @memberof UsersApiCreateUser
     */
    readonly createUserRequest: CreateUserRequest
}

/**
 * Request parameters for deleteUser operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserRequest
 */
export interface UsersApiDeleteUserRequest {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UsersApiDeleteUser
     */
    readonly id: string
}

/**
 * Request parameters for getUser operation in UsersApi.
 * @export
 * @interface UsersApiGetUserRequest
 */
export interface UsersApiGetUserRequest {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UsersApiGetUser
     */
    readonly id: string
}

/**
 * Request parameters for getUserAvatar operation in UsersApi.
 * @export
 * @interface UsersApiGetUserAvatarRequest
 */
export interface UsersApiGetUserAvatarRequest {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UsersApiGetUserAvatar
     */
    readonly id: string
}

/**
 * Request parameters for updateUser operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserRequest
 */
export interface UsersApiUpdateUserRequest {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UsersApiUpdateUser
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserRequest}
     * @memberof UsersApiUpdateUser
     */
    readonly updateUserRequest: UpdateUserRequest
}

/**
 * Request parameters for updateUserAvatar operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserAvatarRequest
 */
export interface UsersApiUpdateUserAvatarRequest {
    /**
     * Identifier of the user
     * @type {string}
     * @memberof UsersApiUpdateUserAvatar
     */
    readonly id: string

    /**
     * 
     * @type {any}
     * @memberof UsersApiUpdateUserAvatar
     */
    readonly file: any
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new user with the given data
     * @param {UsersApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(requestParameters: UsersApiCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(requestParameters.createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the user matching the given ID
     * @param {UsersApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(requestParameters: UsersApiDeleteUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user matching the given ID
     * @param {UsersApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(requestParameters: UsersApiGetUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the avatar of the user matching the given ID
     * @param {UsersApiGetUserAvatarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserAvatar(requestParameters: UsersApiGetUserAvatarRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserAvatar(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user with the given data
     * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(requestParameters: UsersApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(requestParameters.id, requestParameters.updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the avatar of a given user
     * @param {UsersApiUpdateUserAvatarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserAvatar(requestParameters: UsersApiUpdateUserAvatarRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserAvatar(requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspacesApi - axios parameter creator
 * @export
 */
export const WorkspacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new column in the given workspace with the given data
         * @param {string} id Identifier of the workspace
         * @param {ColumnRequest} columnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createColumn: async (id: string, columnRequest: ColumnRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createColumn', 'id', id)
            // verify required parameter 'columnRequest' is not null or undefined
            assertParamExists('createColumn', 'columnRequest', columnRequest)
            const localVarPath = `/api/v1/workspaces/{id}/columns`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new workspace with the given data
         * @param {CreateWorkspaceRequest} createWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace: async (createWorkspaceRequest: CreateWorkspaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWorkspaceRequest' is not null or undefined
            assertParamExists('createWorkspace', 'createWorkspaceRequest', createWorkspaceRequest)
            const localVarPath = `/api/v1/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkspaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the column in the given workspace matching the given ID
         * @param {string} columnId Identifier of the column
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColumn: async (columnId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnId' is not null or undefined
            assertParamExists('deleteColumn', 'columnId', columnId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteColumn', 'id', id)
            const localVarPath = `/api/v1/workspaces/{id}/columns/{columnId}`
                .replace(`{${"columnId"}}`, encodeURIComponent(String(columnId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the workspace matching the given ID
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkspace', 'id', id)
            const localVarPath = `/api/v1/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the workspaces for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWorkspaces: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workspaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the workspace matching the given ID
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkspace', 'id', id)
            const localVarPath = `/api/v1/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a column in the given workspace with the given data
         * @param {string} columnId Identifier of the column
         * @param {string} id Identifier of the workspace
         * @param {ColumnRequest} columnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColumn: async (columnId: string, id: string, columnRequest: ColumnRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnId' is not null or undefined
            assertParamExists('updateColumn', 'columnId', columnId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateColumn', 'id', id)
            // verify required parameter 'columnRequest' is not null or undefined
            assertParamExists('updateColumn', 'columnRequest', columnRequest)
            const localVarPath = `/api/v1/workspaces/{id}/columns/{columnId}`
                .replace(`{${"columnId"}}`, encodeURIComponent(String(columnId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the workspace with the given data
         * @param {string} id Identifier of the workspace
         * @param {UpdateWorkspaceRequest} updateWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspace: async (id: string, updateWorkspaceRequest: UpdateWorkspaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkspace', 'id', id)
            // verify required parameter 'updateWorkspaceRequest' is not null or undefined
            assertParamExists('updateWorkspace', 'updateWorkspaceRequest', updateWorkspaceRequest)
            const localVarPath = `/api/v1/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkspaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspacesApi - functional programming interface
 * @export
 */
export const WorkspacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new column in the given workspace with the given data
         * @param {string} id Identifier of the workspace
         * @param {ColumnRequest} columnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createColumn(id: string, columnRequest: ColumnRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createColumn(id, columnRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new workspace with the given data
         * @param {CreateWorkspaceRequest} createWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkspace(createWorkspaceRequest: CreateWorkspaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkspace(createWorkspaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the column in the given workspace matching the given ID
         * @param {string} columnId Identifier of the column
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColumn(columnId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColumn(columnId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the workspace matching the given ID
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkspace(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkspace(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the workspaces for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyWorkspaces(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspaceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyWorkspaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the workspace matching the given ID
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspace(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspace(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a column in the given workspace with the given data
         * @param {string} columnId Identifier of the column
         * @param {string} id Identifier of the workspace
         * @param {ColumnRequest} columnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateColumn(columnId: string, id: string, columnRequest: ColumnRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateColumn(columnId, id, columnRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the workspace with the given data
         * @param {string} id Identifier of the workspace
         * @param {UpdateWorkspaceRequest} updateWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspace(id: string, updateWorkspaceRequest: UpdateWorkspaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspace(id, updateWorkspaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspacesApi - factory interface
 * @export
 */
export const WorkspacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspacesApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new column in the given workspace with the given data
         * @param {string} id Identifier of the workspace
         * @param {ColumnRequest} columnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createColumn(id: string, columnRequest: ColumnRequest, options?: any): AxiosPromise<WorkspaceResponse> {
            return localVarFp.createColumn(id, columnRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new workspace with the given data
         * @param {CreateWorkspaceRequest} createWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace(createWorkspaceRequest: CreateWorkspaceRequest, options?: any): AxiosPromise<WorkspaceResponse> {
            return localVarFp.createWorkspace(createWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the column in the given workspace matching the given ID
         * @param {string} columnId Identifier of the column
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColumn(columnId: string, id: string, options?: any): AxiosPromise<WorkspaceResponse> {
            return localVarFp.deleteColumn(columnId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the workspace matching the given ID
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(id: string, options?: any): AxiosPromise<WorkspaceDeletedResponse> {
            return localVarFp.deleteWorkspace(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the workspaces for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWorkspaces(options?: any): AxiosPromise<Array<WorkspaceResponse>> {
            return localVarFp.getMyWorkspaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the workspace matching the given ID
         * @param {string} id Identifier of the workspace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspace(id: string, options?: any): AxiosPromise<WorkspaceResponse> {
            return localVarFp.getWorkspace(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a column in the given workspace with the given data
         * @param {string} columnId Identifier of the column
         * @param {string} id Identifier of the workspace
         * @param {ColumnRequest} columnRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColumn(columnId: string, id: string, columnRequest: ColumnRequest, options?: any): AxiosPromise<WorkspaceResponse> {
            return localVarFp.updateColumn(columnId, id, columnRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the workspace with the given data
         * @param {string} id Identifier of the workspace
         * @param {UpdateWorkspaceRequest} updateWorkspaceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspace(id: string, updateWorkspaceRequest: UpdateWorkspaceRequest, options?: any): AxiosPromise<WorkspaceResponse> {
            return localVarFp.updateWorkspace(id, updateWorkspaceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createColumn operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiCreateColumnRequest
 */
export interface WorkspacesApiCreateColumnRequest {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspacesApiCreateColumn
     */
    readonly id: string

    /**
     * 
     * @type {ColumnRequest}
     * @memberof WorkspacesApiCreateColumn
     */
    readonly columnRequest: ColumnRequest
}

/**
 * Request parameters for createWorkspace operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiCreateWorkspaceRequest
 */
export interface WorkspacesApiCreateWorkspaceRequest {
    /**
     * 
     * @type {CreateWorkspaceRequest}
     * @memberof WorkspacesApiCreateWorkspace
     */
    readonly createWorkspaceRequest: CreateWorkspaceRequest
}

/**
 * Request parameters for deleteColumn operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiDeleteColumnRequest
 */
export interface WorkspacesApiDeleteColumnRequest {
    /**
     * Identifier of the column
     * @type {string}
     * @memberof WorkspacesApiDeleteColumn
     */
    readonly columnId: string

    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspacesApiDeleteColumn
     */
    readonly id: string
}

/**
 * Request parameters for deleteWorkspace operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiDeleteWorkspaceRequest
 */
export interface WorkspacesApiDeleteWorkspaceRequest {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspacesApiDeleteWorkspace
     */
    readonly id: string
}

/**
 * Request parameters for getWorkspace operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiGetWorkspaceRequest
 */
export interface WorkspacesApiGetWorkspaceRequest {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspacesApiGetWorkspace
     */
    readonly id: string
}

/**
 * Request parameters for updateColumn operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiUpdateColumnRequest
 */
export interface WorkspacesApiUpdateColumnRequest {
    /**
     * Identifier of the column
     * @type {string}
     * @memberof WorkspacesApiUpdateColumn
     */
    readonly columnId: string

    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspacesApiUpdateColumn
     */
    readonly id: string

    /**
     * 
     * @type {ColumnRequest}
     * @memberof WorkspacesApiUpdateColumn
     */
    readonly columnRequest: ColumnRequest
}

/**
 * Request parameters for updateWorkspace operation in WorkspacesApi.
 * @export
 * @interface WorkspacesApiUpdateWorkspaceRequest
 */
export interface WorkspacesApiUpdateWorkspaceRequest {
    /**
     * Identifier of the workspace
     * @type {string}
     * @memberof WorkspacesApiUpdateWorkspace
     */
    readonly id: string

    /**
     * 
     * @type {UpdateWorkspaceRequest}
     * @memberof WorkspacesApiUpdateWorkspace
     */
    readonly updateWorkspaceRequest: UpdateWorkspaceRequest
}

/**
 * WorkspacesApi - object-oriented interface
 * @export
 * @class WorkspacesApi
 * @extends {BaseAPI}
 */
export class WorkspacesApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new column in the given workspace with the given data
     * @param {WorkspacesApiCreateColumnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public createColumn(requestParameters: WorkspacesApiCreateColumnRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).createColumn(requestParameters.id, requestParameters.columnRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new workspace with the given data
     * @param {WorkspacesApiCreateWorkspaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public createWorkspace(requestParameters: WorkspacesApiCreateWorkspaceRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).createWorkspace(requestParameters.createWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the column in the given workspace matching the given ID
     * @param {WorkspacesApiDeleteColumnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public deleteColumn(requestParameters: WorkspacesApiDeleteColumnRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).deleteColumn(requestParameters.columnId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the workspace matching the given ID
     * @param {WorkspacesApiDeleteWorkspaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public deleteWorkspace(requestParameters: WorkspacesApiDeleteWorkspaceRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).deleteWorkspace(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the workspaces for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getMyWorkspaces(options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getMyWorkspaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the workspace matching the given ID
     * @param {WorkspacesApiGetWorkspaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public getWorkspace(requestParameters: WorkspacesApiGetWorkspaceRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).getWorkspace(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a column in the given workspace with the given data
     * @param {WorkspacesApiUpdateColumnRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public updateColumn(requestParameters: WorkspacesApiUpdateColumnRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).updateColumn(requestParameters.columnId, requestParameters.id, requestParameters.columnRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the workspace with the given data
     * @param {WorkspacesApiUpdateWorkspaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspacesApi
     */
    public updateWorkspace(requestParameters: WorkspacesApiUpdateWorkspaceRequest, options?: AxiosRequestConfig) {
        return WorkspacesApiFp(this.configuration).updateWorkspace(requestParameters.id, requestParameters.updateWorkspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZonesApi - axios parameter creator
 * @export
 */
export const ZonesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new zone with the given data
         * @param {ZoneRequest} zoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZone: async (zoneRequest: ZoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneRequest' is not null or undefined
            assertParamExists('createZone', 'zoneRequest', zoneRequest)
            const localVarPath = `/api/v1/zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the zone matching the given ID
         * @param {string} id Identifier of the zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZone: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteZone', 'id', id)
            const localVarPath = `/api/v1/zones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all the zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllZones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the zone matching the given ID
         * @param {string} id Identifier of the zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZone: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getZone', 'id', id)
            const localVarPath = `/api/v1/zones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the zone with the given data
         * @param {string} id Identifier of the zone
         * @param {ZoneRequest} zoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZone: async (id: string, zoneRequest: ZoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateZone', 'id', id)
            // verify required parameter 'zoneRequest' is not null or undefined
            assertParamExists('updateZone', 'zoneRequest', zoneRequest)
            const localVarPath = `/api/v1/zones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZonesApi - functional programming interface
 * @export
 */
export const ZonesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZonesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new zone with the given data
         * @param {ZoneRequest} zoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createZone(zoneRequest: ZoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createZone(zoneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the zone matching the given ID
         * @param {string} id Identifier of the zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteZone(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneDeletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteZone(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all the zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllZones(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllZones(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the zone matching the given ID
         * @param {string} id Identifier of the zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZone(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZone(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the zone with the given data
         * @param {string} id Identifier of the zone
         * @param {ZoneRequest} zoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateZone(id: string, zoneRequest: ZoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateZone(id, zoneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZonesApi - factory interface
 * @export
 */
export const ZonesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZonesApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new zone with the given data
         * @param {ZoneRequest} zoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZone(zoneRequest: ZoneRequest, options?: any): AxiosPromise<ZoneResponse> {
            return localVarFp.createZone(zoneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the zone matching the given ID
         * @param {string} id Identifier of the zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZone(id: string, options?: any): AxiosPromise<ZoneDeletedResponse> {
            return localVarFp.deleteZone(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all the zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllZones(options?: any): AxiosPromise<Array<ZoneResponse>> {
            return localVarFp.getAllZones(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the zone matching the given ID
         * @param {string} id Identifier of the zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZone(id: string, options?: any): AxiosPromise<ZoneResponse> {
            return localVarFp.getZone(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the zone with the given data
         * @param {string} id Identifier of the zone
         * @param {ZoneRequest} zoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZone(id: string, zoneRequest: ZoneRequest, options?: any): AxiosPromise<ZoneResponse> {
            return localVarFp.updateZone(id, zoneRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createZone operation in ZonesApi.
 * @export
 * @interface ZonesApiCreateZoneRequest
 */
export interface ZonesApiCreateZoneRequest {
    /**
     * 
     * @type {ZoneRequest}
     * @memberof ZonesApiCreateZone
     */
    readonly zoneRequest: ZoneRequest
}

/**
 * Request parameters for deleteZone operation in ZonesApi.
 * @export
 * @interface ZonesApiDeleteZoneRequest
 */
export interface ZonesApiDeleteZoneRequest {
    /**
     * Identifier of the zone
     * @type {string}
     * @memberof ZonesApiDeleteZone
     */
    readonly id: string
}

/**
 * Request parameters for getZone operation in ZonesApi.
 * @export
 * @interface ZonesApiGetZoneRequest
 */
export interface ZonesApiGetZoneRequest {
    /**
     * Identifier of the zone
     * @type {string}
     * @memberof ZonesApiGetZone
     */
    readonly id: string
}

/**
 * Request parameters for updateZone operation in ZonesApi.
 * @export
 * @interface ZonesApiUpdateZoneRequest
 */
export interface ZonesApiUpdateZoneRequest {
    /**
     * Identifier of the zone
     * @type {string}
     * @memberof ZonesApiUpdateZone
     */
    readonly id: string

    /**
     * 
     * @type {ZoneRequest}
     * @memberof ZonesApiUpdateZone
     */
    readonly zoneRequest: ZoneRequest
}

/**
 * ZonesApi - object-oriented interface
 * @export
 * @class ZonesApi
 * @extends {BaseAPI}
 */
export class ZonesApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new zone with the given data
     * @param {ZonesApiCreateZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZonesApi
     */
    public createZone(requestParameters: ZonesApiCreateZoneRequest, options?: AxiosRequestConfig) {
        return ZonesApiFp(this.configuration).createZone(requestParameters.zoneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the zone matching the given ID
     * @param {ZonesApiDeleteZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZonesApi
     */
    public deleteZone(requestParameters: ZonesApiDeleteZoneRequest, options?: AxiosRequestConfig) {
        return ZonesApiFp(this.configuration).deleteZone(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all the zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZonesApi
     */
    public getAllZones(options?: AxiosRequestConfig) {
        return ZonesApiFp(this.configuration).getAllZones(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the zone matching the given ID
     * @param {ZonesApiGetZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZonesApi
     */
    public getZone(requestParameters: ZonesApiGetZoneRequest, options?: AxiosRequestConfig) {
        return ZonesApiFp(this.configuration).getZone(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the zone with the given data
     * @param {ZonesApiUpdateZoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZonesApi
     */
    public updateZone(requestParameters: ZonesApiUpdateZoneRequest, options?: AxiosRequestConfig) {
        return ZonesApiFp(this.configuration).updateZone(requestParameters.id, requestParameters.zoneRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


