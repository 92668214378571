import { MileageRateRequest, MileageRateResponse } from '../../client'
import { transformRate, transformRateRequest } from './rate.transformer'

/**
 * Transforms a mileage rate. Transforms perKm rate
 * @param rate
 * @returns transformed rate
 */
export function transformMileageRate(rate: MileageRateResponse): MileageRateResponse {
  return { ...rate, perKm: transformRate(rate.perKm) }
}

/**
 * Transforms a mileage rate request. Transforms perKm rate
 * @param rate
 * @returns transformed rate
 */
export function transformMileageRateRequest(request: MileageRateRequest): MileageRateRequest {
  if (request.perKm) {
    request.perKm = transformRateRequest(request.perKm)
  }

  return request
}
