import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import {
  SortDir,
  TaskSearchRequest,
  TaskSearchResponse,
  TaskSortType,
  UpdateTaskSearchRequest,
} from '../../client'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'

export function useTaskSearchesStore(auth: AuthStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [currentSearchId, setCurrentSearchId] = useState<string | undefined>()
  const [searchesMap, dispatchSearches] = useReducer(
    entityReducer as EntityReducer<string, TaskSearchResponse>,
    {},
  )
  const [localSearch, setLocalSearch] = useState<Omit<TaskSearchRequest, 'title'>>({
    filters: {},
    sort: { sortBy: TaskSortType.CreatedAt, sortDir: SortDir.Desc },
  })

  // Getters

  const searches = useMemo(
    () => Object.values(searchesMap).sort((a, b) => a.position - b.position),
    [searchesMap],
  )
  const currentSearch = useMemo(() => searchById(currentSearchId), [searches, currentSearchId])

  function searchById(id?: string) {
    return id ? searchesMap[id] : undefined
  }

  const numFilters = useMemo(
    () =>
      Object.values(localSearch?.filters ?? {}).filter(
        (value) => value !== undefined && value !== null && value?.length !== 0,
      ).length,
    [localSearch],
  )

  // Actions

  const fetch = operation(async () => {
    const response = await api.tasks.getMyTasksSearches()

    dispatchSearches({ type: 'addMany', items: response.data })
  })

  const createTaskSearch = operation(async (params: TaskSearchRequest) => {
    const response = await api.tasks.createTaskSearch({ taskSearchRequest: params })

    dispatchSearches({ type: 'addOne', item: response.data })
    setCurrentSearchId(response.data.id)
  })

  const updateTaskSearch = operation(async (id: string, params: TaskSearchRequest) => {
    const response = await api.tasks.updateTaskSearch({ id, taskSearchRequest: params })

    dispatchSearches({ type: 'addOne', item: response.data })
  })

  const updateTaskSearchesOrder = operation(async (positions: UpdateTaskSearchRequest[]) => {
    const response = await api.tasks.updateTaskSearches({
      updateTaskSearchesRequest: { searches: positions },
    })

    dispatchSearches({ type: 'addMany', items: response.data })
  })

  const deleteTaskSearch = operation(async (id: string) => {
    await api.tasks.deleteTaskSearch({ id })

    dispatchSearches({ type: 'removeOne', id })
  })

  useEffect(() => {
    if (currentSearch) {
      setLocalSearch({
        filters: { ...currentSearch.filters },
        sort: { ...currentSearch.sort },
      })
    } else {
      setLocalSearch({
        filters: {},
        sort: { sortBy: TaskSortType.CreatedAt, sortDir: SortDir.Desc },
      })
    }
  }, [currentSearch])

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetch()
    } else {
      dispatchSearches({ type: 'clear' })
    }
  }, [auth.isAuthenticated])

  return {
    isLoading,
    isLoadingKey,
    searches,
    searchById,
    currentSearchId,
    currentSearch,
    localSearch,
    numFilters,

    setCurrentSearchId,
    setLocalSearch,

    createTaskSearch,
    updateTaskSearch,
    updateTaskSearchesOrder,
    deleteTaskSearch,
  }
}

export type TaskSearchesStore = ReturnType<typeof useTaskSearchesStore>

export const TaskSearchesStoreContext = createContext<TaskSearchesStore>({
  isLoading: true,
} as any)

export function getTaskSearchesStore() {
  return useContext(TaskSearchesStoreContext)
}
