import { Writeable } from './types'

/**
 * Gets all the keys from an object, including sub fields
 * @param object
 */
export function getDeepKeys(object: Record<string, any>): string[] {
  let keys: string[] = []
  for (const key in object) {
    let value = object[key]
    if (typeof value?.toDate === 'function') {
      value = value.toDate()
    }
    if (value instanceof Date) {
      value = value.toISOString()
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      const subKeys = getDeepKeys(value as Record<string, unknown>)
      keys = keys.concat(
        subKeys.map(function (subKey) {
          return key + '.' + subKey
        }),
      )
    } else {
      keys.push(key)
    }
  }

  return keys
}

export function deepCopy<T = any>(object: T): Writeable<T> {
  if (!object) {
    return {} as T
  }

  return JSON.parse(JSON.stringify(object))
}

export function objectsAreEqual<T = any>(objectA: T, objectB: T): boolean {
  return JSON.stringify(objectA) === JSON.stringify(objectB)
}

/**
 * Returns a map of the objects by one of the field
 * @param objects
 * @param key
 * @returns map
 */
export function objectsByKey<T extends Record<string, any>>(
  objects: T[] | undefined,
  key: keyof T,
): Record<string, T> {
  if (!objects?.length) {
    return {}
  }

  return objects.reduce((result, object) => {
    if (typeof object[key] === 'string') {
      result[object[key] as string] = object
    }

    return result
  }, {} as Record<string, T>)
}

/**
 * Returns a map containing true for all the ids present
 * @param array
 * @returns map
 */
export function idsArrayToMap(array?: string[]): Record<string, true> {
  if (!array) {
    return {}
  }

  return array.reduce((result, id) => {
    result[id] = true
    return result
  }, {} as Record<string, true>)
}

/** Checks whether two elements are deep equal */
export function areDeepEqual(x?: any, y?: any): boolean {
  if (typeof x !== typeof y) {
    return false
  }
  if (typeof x === 'object') {
    const keysX = Object.keys(x).filter((key) => x[key] !== undefined)
    const keysY = Object.keys(y).filter((key) => y[key] !== undefined)
    if (keysX.length !== keysY.length) {
      return false
    }

    for (const key of keysX) {
      if (!areDeepEqual(x[key], y[key])) {
        return false
      }
    }

    return true
  }

  return x === y
}
