import diacritics from 'diacritics'
import Fuse from 'fuse.js'

import { Company } from './company.interface'

/**
 * Creates an index to look for companies
 * @param companies
 */
export function createCompaniesIndex(companies: Company[]) {
  return new Fuse(companies, {
    keys: ['name', 'email', 'phone', 'taxName', 'taxNumber'],
    threshold: 0.25,
    getFn(object: any, path: string | string[]) {
      path = Array.isArray(path) ? path[0] : path
      const value = object[path]
      if (!value) {
        return ''
      }

      return diacritics.remove(object[path])
    },
  })
}
