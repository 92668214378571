import moment from 'moment'

import {
  ColumnRequest,
  ColumnResponse,
  CreateWorkspaceRequest,
  UpdateWorkspaceRequest,
  WorkspaceResponse,
} from '../../client'
import {
  Workspace,
  WorkspaceColumn,
  WorkspaceColumnParams,
  WorkspaceParams,
} from './workspace.interface'

export function transformWorkspaceResponse(response: WorkspaceResponse): Workspace {
  return {
    id: response.id,
    title: response.title,
    color: response.color,
    columns: response.columns.map(transformWorkspaceColumnResponse),
    createdAt: moment(response.createdAt).toDate(),
    updatedAt: moment(response.updatedAt).toDate(),
  }
}

function transformWorkspaceColumnResponse(response: ColumnResponse): WorkspaceColumn {
  return {
    id: response.id,
    title: response.title,
    color: response.color,
    filters: {
      types: (response.filters.types?.length ?? 0) > 0 ? response.filters.types : undefined,
      states: (response.filters.states?.length ?? 0) > 0 ? response.filters.states : undefined,
      assigneeIds:
        (response.filters.assigneeIds?.length ?? 0) > 0 ? response.filters.assigneeIds : undefined,
      reviewerIds:
        (response.filters.reviewerIds?.length ?? 0) > 0 ? response.filters.reviewerIds : undefined,
      labelIds:
        (response.filters.labelIds?.length ?? 0) > 0 ? response.filters.labelIds : undefined,
      companyIds:
        (response.filters.companyIds?.length ?? 0) > 0 ? response.filters.companyIds : undefined,
      clientIds:
        (response.filters.clientIds?.length ?? 0) > 0 ? response.filters.clientIds : undefined,
      zoneIds: (response.filters.zoneIds?.length ?? 0) > 0 ? response.filters.zoneIds : undefined,
    },
    sort: response.sort,
    createdAt: moment(response.createdAt).toDate(),
    updatedAt: moment(response.updatedAt).toDate(),
  }
}

export function transformToCreateWorkspaceRequest(params: WorkspaceParams): CreateWorkspaceRequest {
  return {
    title: params.title,
    color: params.color,
    columns: params.columns,
  }
}

export function transformToUpdateWorkspaceRequest(params: WorkspaceParams): UpdateWorkspaceRequest {
  return {
    title: params.title,
    color: params.color,
    order: [],
  }
}

export function transformToWorkspaceColumnRequest(params: WorkspaceColumnParams): ColumnRequest {
  return {
    title: params.title,
    color: params.color,
    filters: params.filters,
    sort: params.sort,
  }
}
