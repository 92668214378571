import { ClientRequest, ClientResponse } from '../../client'
import { Client, ClientParams } from './client.interface'

export function transformClientResponse(response: ClientResponse): Client {
  return {
    id: response.id,
    name: response.name,
    email: response.email,
    phone: response.phone,
    note: response.note,
    taxName: response.taxName,
    taxNumber: response.taxNumber,
    taxAddress: response.taxAddress,
  }
}

export function transformToCreateClientRequest(params: ClientParams): ClientRequest {
  return {
    name: params.name,
    email: params.email,
    phone: params.phone,
    note: params.note,
    taxName: params.taxName,
    taxNumber: params.taxNumber,
    taxAddress: params.taxAddress,
  }
}

export function transformToUpdateClientRequest(params: ClientParams): ClientRequest {
  return {
    name: params.name,
    email: params.email,
    phone: params.phone,
    note: params.note,
    taxName: params.taxName,
    taxNumber: params.taxNumber,
    taxAddress: params.taxAddress,
  }
}
