import {
  blue,
  cyan,
  geekblue,
  gold,
  green,
  grey,
  lime,
  magenta,
  orange,
  purple,
  red,
  volcano,
  yellow,
} from '@ant-design/colors'

import { TaskState, TaskType } from '../modules/tasks/task.interface'

export const Colors = {
  // Primary
  lightestPrimary: purple[0],
  lightPrimary: purple[3],
  primary: purple[4],
  darkPrimary: purple[5],
  white: '#ffffff',
  black: '#333333',
  danger: red[4],
  success: green[4],

  disabled: '#2C4A4180',
  lightestGrey: '#fafafa',
  lighterGrey: '#EDEDED',
  lightGrey: '#D4D4D4',
  mediumGrey: '#c0c0c0',
  darkGrey: '#727272',
  darkerGrey: '#939393',
  darkestGrey: '#1F1F1F',

  // Theme colors
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
}

export const TaskStateColor: Record<TaskState, string> = {
  [TaskState.Draft]: orange[2],
  [TaskState.Open]: grey[2],

  // Report
  [TaskState.Contact]: blue[2],
  [TaskState.Visit]: blue[2],
  [TaskState.BuildReport]: blue[2],
  [TaskState.ReviewReport]: blue[2],
  // Homologagation
  [TaskState.InLaboratory]: blue[2],
  [TaskState.Accepted]: blue[2],
  [TaskState.InInspection]: blue[2],

  [TaskState.Close]: green[2],
  [TaskState.Cancelled]: red[3],
}

export const TaskStateColorDark: Record<TaskState, string> = {
  [TaskState.Draft]: orange[6],
  [TaskState.Open]: grey[6],

  // Report
  [TaskState.Contact]: blue[6],
  [TaskState.Visit]: blue[6],
  [TaskState.BuildReport]: blue[6],
  [TaskState.ReviewReport]: blue[6],
  // Homologagation
  [TaskState.InLaboratory]: blue[6],
  [TaskState.Accepted]: blue[6],
  [TaskState.InInspection]: blue[6],

  [TaskState.Close]: green[6],
  [TaskState.Cancelled]: red[6],
}

export const TaskTypeColor: Record<TaskType, string> = {
  [TaskType.Report]: Colors.cyan[6],
  [TaskType.Homologation]: Colors.volcano[6],
  [TaskType.Recognition]: Colors.gold[6],
}

export const ColorPalette: string[] = [
  // Primary
  Colors.darkPrimary,
  Colors.primary,

  // Colors
  magenta[6],
  red[6],
  volcano[6],
  orange[6],
  gold[6],
  yellow[6],
  green[6],
  lime[6],
  cyan[6],
  blue[6],
  geekblue[6],

  // Greyscale
  grey[0],
  grey[3],
  grey[6],
  grey[9],
]

export const ColorPaletteWithWhite = ['#ffffff', ...ColorPalette]
