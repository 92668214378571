import diacritics from 'diacritics';
export function sanitize(input) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (!input) {
    return '';
  }

  var sanitized = input; // Trim the input

  if (options.trim) {
    sanitized = sanitized.trim();
  } // Transform all to lower case


  if (options.toLowerCase) {
    sanitized = sanitized.toLowerCase();
  } // Remove chars with tildes and such


  if (options.diacritics) {
    sanitized = diacritics.remove(sanitized);
  }

  return sanitized;
}
export function sanitizeAll(input) {
  return sanitize(input, {
    trim: true,
    toLowerCase: true,
    diacritics: true
  });
}