import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import { api } from '../../api/clients'
import { UserResponse } from '../../client'
import { EntityReducer, entityReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { AuthStore } from '../auth/auth.store'
import { MediaStore } from '../media/media.store'
import { createUsersIndex } from './user.filter'
import { User, UserParams } from './user.interface'
import {
  transformToCreateUserRequest,
  transformToUpdateMeRequest,
  transformToUpdateUserRequest,
  transformUserResponse,
} from './user.transformer'

export function useUsersStore(auth: AuthStore, media: MediaStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State
  const [isReady, setReady] = useState(false)
  const [usersMap, dispatchUsers] = useReducer(entityReducer as EntityReducer<string, User>, {})

  // Getters
  const users = Object.values(usersMap)
  const getUserById = (id?: string): User | undefined => (id ? usersMap[id] : undefined)

  const currentUser = getUserById(auth.user?.id)

  const usersIndex = useMemo(() => createUsersIndex(users), [users])

  // Actions

  const getUsers = operation(async () => {
    setReady(false)

    try {
      const response = await api.users.getAllUsers()
      const newUsers = response.data.map(transformUserResponse)
      for (const user of newUsers) {
        if (user.hasAvatar) {
          media.fetchUserAvatar(user)
        }
      }

      dispatchUsers({ type: 'addMany', items: newUsers })
    } finally {
      setReady(true)
    }
  })

  const onUserAvatarUpdated = (response: UserResponse) => {
    const updatedUser = transformUserResponse(response)
    media.fetchUserAvatar(updatedUser)
  }

  const createUser = operation(async (params: UserParams): Promise<User | undefined> => {
    const response = await api.users.createUser({
      createUserRequest: transformToCreateUserRequest(params),
    })
    const user = transformUserResponse(response.data)
    dispatchUsers({ type: 'addOne', item: user })

    return user
  })

  const updateUser = operation(async (id: string, params: UserParams) => {
    const response = await api.users.updateUser({
      id,
      updateUserRequest: transformToUpdateUserRequest(params),
    })
    const user = transformUserResponse(response.data)
    dispatchUsers({ type: 'addOne', item: user })

    return user
  })

  const updateCurrentUser = operation(async (params: UserParams) => {
    const response = await api.me.updateMe({
      updateSelfUserRequest: transformToUpdateMeRequest(params),
    })
    const user = transformUserResponse(response.data)
    auth.setUser(user)
    dispatchUsers({ type: 'addOne', item: user })

    return user
  })

  const updatePassword = operation(async (previousPassword: string, newPassword: string) => {
    const response = await api.me.changePassword({
      changePasswordRequest: { previousPassword, newPassword },
    })
    const user = transformUserResponse(response.data)
    dispatchUsers({ type: 'addOne', item: user })

    return user
  })

  const deleteUser = operation(async (id: string) => {
    if (id === auth.user?.id) {
      throw new Error('Deleting own user')
    }

    await api.users.deleteUser({ id })

    dispatchUsers({ type: 'removeOne', id })
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getUsers()
    } else {
      dispatchUsers({ type: 'clear' })
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    users,
    currentUser,
    getUserById,
    usersIndex,

    getUsers,
    onUserAvatarUpdated,
    createUser,
    updateUser,
    updateCurrentUser,
    updatePassword,
    deleteUser,
  }
}

export type UsersStore = ReturnType<typeof useUsersStore>

export const UsersStoreContext = createContext<UsersStore>({
  isReady: false,
  isLoading: true,
  users: [],
} as any)

export function getUserStore() {
  return useContext(UsersStoreContext)
}
