export function arraysEqual(a: Array<any>, b: Array<any>) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false
    }
  }
  return true
}

/**
 * Removes the duplicate in a given array
 * @param array
 * @returns array without duplicates
 */
export function removeDuplicates<T extends string | number>(array: T[]): T[] {
  return Array.from(new Set(array))
}

/**
 * Removes nullish elements from an array
 * @param items
 * @returns array without nullish items
 */
export function removeNullish<T>(items?: (T | undefined | null)[]): T[] {
  return items?.filter(isTruthy) ?? []
}

/**
 * Whether a value is truthy
 * @param value
 * @returns whether a value is truthy
 */
export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value
}
