import { createContext, useContext, useReducer } from 'react'

import { api } from '../../api/clients'
import { Logger } from '../../utils/logger'
import { MapReducer, mapReducer } from '../../utils/store'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { Company } from '../companies/company.interface'
import { SettingsStore } from '../settings/settings.store'
import { Task } from '../tasks/task.interface'
import { User } from '../users/user.interface'
import { MediaInfo, MediaParams } from './media.types'

const USER_PREFIX = 'user_'
const COMPANY_PREFIX = 'company_'
const TASK_DOCUMENT_PREFIX = 'task-document_'

const logger = new Logger('media')

export function useMediaStore(settings: SettingsStore) {
  // State
  const { isLoadingKey, startLoading, stopLoading } = useLoadingStore()
  const [thumbnailMap, dispatchThumbnail] = useReducer(
    mapReducer as MapReducer<string, string | false>,
    {},
  )
  const [mediaMap, dispatchMedia] = useReducer(mapReducer as MapReducer<string, MediaInfo>, {})

  // Getters
  const media = Object.values(mediaMap)

  // - User
  const getUserKey = (userId: string): string => `${USER_PREFIX}${userId}`
  const getUserMediaParams = (userId?: string): MediaParams => ({
    uri: `${settings.config.api.baseUrl}/api/v1/users/${userId}/avatar`,
    headers: {
      Authorization: `bearer ${settings.jwt}`,
    },
  })
  const getUserMediaInfo = (userId: string): MediaInfo | undefined => mediaMap[getUserKey(userId)]
  const getUserMediaSrc = (userId: string): string | undefined => getUserMediaInfo(userId)?.src
  const isLoadingUserMediaInfo = (userId: string) => isLoadingKey(getUserKey(userId))

  // - Company
  const getCompanyKey = (companyId: string): string => `${COMPANY_PREFIX}${companyId}`
  const getCompanyMediaInfo = (companyId: string): MediaInfo | undefined =>
    mediaMap[getCompanyKey(companyId)]
  const getCompanyMediaSrc = (companyId: string): string | undefined =>
    getCompanyMediaInfo(companyId)?.src
  const isLoadingCompanyMediaInfo = (companyId: string) => isLoadingKey(getCompanyKey(companyId))

  // - Task document
  const getTaskDocumentKey = (taskId: string, documentId: string): string =>
    `${TASK_DOCUMENT_PREFIX}${taskId}_${documentId}`
  const getTaskDocumentThumbnail = (
    taskId: string,
    documentId: string,
  ): string | false | undefined => thumbnailMap[getTaskDocumentKey(taskId, documentId)]
  const getTskDocumentMediaParams = (taskId: string, documentId: string): MediaParams => ({
    uri: `${settings.config.api.baseUrl}/api/v1/tasks/${taskId}/documents/${documentId}`,
    headers: {
      Authorization: `bearer ${settings.jwt}`,
    },
  })
  const getTaskDocumentMediaInfo = (taskId: string, documentId: string): MediaInfo | undefined =>
    mediaMap[getTaskDocumentKey(taskId, documentId)]
  const getTaskDocumentMediaSrc = (taskId: string, documentId: string): string | undefined =>
    getTaskDocumentMediaInfo(taskId, documentId)?.src
  const isLoadingTaskDocumentMediaInfo = (taskId: string, documentId: string) =>
    isLoadingKey(getTaskDocumentKey(taskId, documentId))

  // Actions
  // - User
  const fetchUserAvatar = async (user: User) => {
    if (getUserMediaInfo(user.id)) {
      return
    }

    const key = getUserKey(user.id)
    startLoading(key)

    try {
      const response = await api.users.getUserAvatar({ id: user.id }, { responseType: 'blob' })

      dispatchMedia({
        type: 'addOne',
        key,
        value: { src: URL.createObjectURL(response.data) },
      })
    } catch (error) {
      dispatchMedia({
        type: 'addOne',
        key,
        value: { hasFailed: true },
      })
    }

    stopLoading(key)
  }

  // - Company
  const fetchCompanyLogo = async (company: Company) => {
    if (getCompanyMediaInfo(company.id)) {
      return
    }

    const key = getCompanyKey(company.id)
    startLoading(key)

    try {
      const response = await api.companies.getCompanyLogo(
        { id: company.id },
        { responseType: 'blob' },
      )

      dispatchMedia({
        type: 'addOne',
        key,
        value: { src: URL.createObjectURL(response.data) },
      })
    } catch (error) {
      dispatchMedia({
        type: 'addOne',
        key,
        value: { hasFailed: true },
      })
    }

    stopLoading(key)
  }

  // - Task
  const fetchTaskDocumentThumbnails = async (task: Task) => {
    if (isLoadingKey(task.id)) {
      return
    }

    startLoading(task.id)

    try {
      const response = await api.tasks.getTaskDocumentsThumbnails({ id: task.id })
      const thumbs: Record<string, string | false> = {}
      for (const thumbnail of response.data) {
        thumbs[getTaskDocumentKey(task.id, thumbnail.fileId)] =
          thumbnail.hasThumbnail && thumbnail.thumbnailLink ? thumbnail.thumbnailLink : false
      }

      dispatchThumbnail({ type: 'addMany', items: thumbs })
    } catch (error) {
      logger.error(error)
    } finally {
      stopLoading(task.id)
    }
  }

  // Fetch file
  const fetchTaskDocument = async (task: Task, documentId: string): Promise<string | undefined> => {
    if (getTaskDocumentMediaInfo(task.id, documentId)) {
      return
    }

    const key = getTaskDocumentKey(task.id, documentId)
    startLoading(key)

    try {
      const response = await api.tasks.getTaskDocument(
        { id: task.id, documentId },
        { responseType: 'blob' },
      )
      const src = URL.createObjectURL(response.data)

      dispatchMedia({
        type: 'addOne',
        key,
        value: { src },
      })

      return src
    } catch (error) {
      dispatchMedia({
        type: 'addOne',
        key,
        value: { hasFailed: true },
      })
    }

    stopLoading(key)
  }

  return {
    media,

    // Users
    isLoadingUserMediaInfo,
    getUserMediaInfo,
    getUserMediaParams,
    getUserMediaSrc,
    fetchUserAvatar,

    // Companies
    isLoadingCompanyMediaInfo,
    getCompanyMediaInfo,
    getCompanyMediaSrc,
    fetchCompanyLogo,

    // Tasks
    isLoadingTaskDocumentMediaInfo,
    getTaskDocumentThumbnail,
    getTskDocumentMediaParams,
    getTaskDocumentMediaInfo,
    getTaskDocumentMediaSrc,
    fetchTaskDocumentThumbnails,
    fetchTaskDocument,
  }
}

export type MediaStore = ReturnType<typeof useMediaStore>

export const MediaStoreContext = createContext<MediaStore>({
  isLoading: true,
} as any)

export function getMediaStore() {
  return useContext(MediaStoreContext)
}
