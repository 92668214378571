import { getDeepKeys } from 'core/utils/object';
/**
 * Returns whether an antd form has fields with errors.
 * @param form
 */

export function formHasErrors(form) {
  return form.getFieldsError().filter(function (field) {
    return field.errors.length > 0;
  }).length > 0;
}
/**
 * Extracts all the field keys from a form.
 * @param form
 */

export function formExtractFieldKeys(form) {
  return getDeepKeys(form.getFieldsValue()).map(function (item) {
    return item.split('.');
  }).flatMap(function (item) {
    return item.length === 1 ? item[0] : [item, item[0]];
  });
}
/**
 * Returns whether all the required field have a value.
 * @param form
 * @param fields
 */

export function formHasValueForRequiredFields(form) {
  for (var _len = arguments.length, fields = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    fields[_key - 1] = arguments[_key];
  }

  var normalizedFields = fields.map(function (item) {
    return item.split('.');
  });
  return !normalizedFields.some(function (field) {
    return !form.getFieldValue(field);
  });
}
/**
 * Returns whether the form is touched (any field is touched).
 * @param form
 */

export function formIsTouched(form) {
  var keys = formExtractFieldKeys(form);
  return keys.some(function (key) {
    return form.isFieldTouched(key);
  });
}
/**
 * Returns fields that are touched
 * @param form
 */

export function formTouchedFields(form) {
  var keys = formExtractFieldKeys(form);
  return keys.filter(function (key) {
    return form.isFieldTouched(key);
  }).flat();
}