import diacritics from 'diacritics'
import Fuse from 'fuse.js'

import { ReportRateResponse } from '../../client'

/**
 * Creates an index to look for report rates
 * @param mileageRates
 */
export function createReportRatesIndex(mileageRates: ReportRateResponse[]) {
  return new Fuse(mileageRates, {
    keys: ['name'],
    threshold: 0.25,
    getFn(object: any, path: string | string[]) {
      path = Array.isArray(path) ? path[0] : path
      const value = object[path]
      if (!value) {
        return ''
      }

      return diacritics.remove(object[path])
    },
  })
}
