import diacritics from 'diacritics'
import Fuse from 'fuse.js'

import { Label } from './label.interface'

/**
 * Creates an index to look for labels
 * @param labels
 */
export function createLabelsIndex(labels: Label[]) {
  return new Fuse(labels, {
    keys: ['name', 'color'],
    threshold: 0.25,
    getFn(object: any, path: string | string[]) {
      path = Array.isArray(path) ? path[0] : path
      const value = object[path]
      if (!value) {
        return ''
      }

      return diacritics.remove(object[path])
    },
  })
}
