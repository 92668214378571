import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '../../api/clients'
import { Config } from '../../interfaces/config.interface'
import { StorageRepository } from '../../interfaces/storage-repository.interface'
import { Logger } from '../../utils/logger'
import { parseString } from '../../utils/parser'
import { TaskColumn, TaskFilters } from '../tasks/task.interface'

// Keys

const JWT_KEY = 'auth.jwt'
const TASK_COLUMNS_KEY = 'task.columns'
const TASK_FILTERS_KEY = 'tasks.filters'

// Default values

const DEFAULT_TASK_COLUMNS: Partial<Record<TaskColumn, boolean>> = {
  type: true,
  state: true,
  company: true,
  deadline: true,
  assignee: true,
  reviewer: true,
  reference: true,
  labels: true,

  // Types
  reportZone: true,
  homologationPlateNumber: true,
  homologationChassisNumber: true,
  recognitionPlateNumber: true,
  recognitionChassisNumber: true,
}

const logger = new Logger('settings.store')

export function useSettingsStore(
  config: Config,
  storage: StorageRepository,
  onUnauthorized: () => void,
) {
  // State

  const [taskColumns, setTaskColumns] =
    useState<Partial<Record<TaskColumn, boolean>>>(DEFAULT_TASK_COLUMNS)
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({})
  const [isReady, setReady] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [jwt, setJwt] = useState<string | undefined>()

  // Actions

  const saveJwt = async (newJwt: string) => {
    setLoading(true)

    try {
      api.init(config, newJwt, onUnauthorized)
      setJwt(newJwt)

      await storage.set(JWT_KEY, newJwt)
    } catch (error) {
      logger.error(error)
    }

    setLoading(false)
  }

  const removeJwt = async () => {
    setLoading(true)

    try {
      setJwt(undefined)
      api.init(config, undefined, onUnauthorized)

      await storage.set(JWT_KEY, null)
    } catch (error) {
      logger.error(error)
    }

    setLoading(false)
  }

  const init = async () => {
    setReady(false)
    setLoading(true)

    await loadJwt()
    await loadTaskColumns()
    await loadTaskFilters()

    setLoading(false)
    setReady(true)
  }

  const loadJwt = async () => {
    try {
      let newJwt = parseString(await storage.get(JWT_KEY))
      if (newJwt === 'undefined' || newJwt === 'null') {
        newJwt = undefined
      }
      setJwt(newJwt)

      api.init(config, newJwt, onUnauthorized)
    } catch (error) {
      logger.error(error)
    }
  }

  const loadTaskColumns = async () => {
    try {
      const content = await storage.get(TASK_COLUMNS_KEY)
      if (
        typeof content !== 'string' ||
        !content ||
        content === 'undefined' ||
        content === 'null'
      ) {
        setTaskColumns(DEFAULT_TASK_COLUMNS)
      } else {
        const taskColumns = JSON.parse(content) ?? DEFAULT_TASK_COLUMNS
        setTaskColumns(taskColumns)
      }
    } catch (error) {
      logger.error(error)
    }
  }

  const saveTaskColumns = async (newTaskColumns: Partial<Record<TaskColumn, boolean>>) => {
    try {
      await storage.set(TASK_COLUMNS_KEY, JSON.stringify(newTaskColumns))
      setTaskColumns(newTaskColumns)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  const loadTaskFilters = async () => {
    try {
      const content = await storage.get(TASK_FILTERS_KEY)
      if (
        typeof content !== 'string' ||
        !content ||
        content === 'undefined' ||
        content === 'null'
      ) {
        setTaskFilters({})
      } else {
        const taskFilters = JSON.parse(content) ?? {}
        setTaskFilters(taskFilters)
      }
    } catch (error) {
      logger.error(error)
    }
  }

  const saveTaskFilters = async (newTaskFilters: TaskFilters) => {
    try {
      await storage.set(TASK_FILTERS_KEY, JSON.stringify(newTaskFilters))
      setTaskFilters(newTaskFilters)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  // Effects

  useEffect(() => {
    init()
  }, [])

  return {
    isReady,
    isLoading,
    config,
    jwt,
    taskColumns,
    taskFilters,

    saveJwt,
    removeJwt,

    saveTaskColumns,
    saveTaskFilters,
  }
}

export type SettingsStore = ReturnType<typeof useSettingsStore>

export const SettingsStoreContext = createContext<SettingsStore>({
  isReady: false,
  isLoading: true,
} as any)

export function getSettingsStore() {
  return useContext(SettingsStoreContext)
}
