import {
  AppraisalType,
  ClaimType,
  CompanyType,
  ContractType,
  NotificationType,
  RateStatusType,
  RouteStatusType,
  RouteTaskStatusType,
  TaskNotificationsReceiverPreferenceType,
} from '../../client'
import { TaskState, TaskType } from '../../modules/tasks/task.interface'
import es from '../es'

const translations: typeof es = {
  changesSaved: 'Changes saved successfully',
  unknownError: 'Unknown error occurred, please try again later',

  create: 'Create',
  modify: 'Modify',
  delete: 'Delete',
  remove: 'Remove',
  clear: 'Clear',
  search: 'Search',

  copy: 'Copy',
  continue: 'Continue',
  confirm: 'Confirm',
  close: 'Close',
  cancel: 'Cancel',
  edit: 'Edit',
  quit: 'Exit',
  pass: 'Pass',
  save: 'Save',
  download: 'Download',
  submit: 'Submit',
  yes: 'Yes',
  no: 'No',

  areYouSure: 'Are you sure?',
  cannotBeUndone: 'This action cannot be undone.',

  warning: 'Warning!',
  createMore: {
    label: 'Create another',
    help: 'Check this to create multiple items.',
  },

  copiedToClipboard: 'Text copied to clipboard.',
  language: {
    es: 'Spanish',
    en: 'English',
  },

  map: {
    search: 'Search',
    sectionTitle: 'Location',
    coordinates: { label: 'Coordinates', placeholder: 'Coordinates in the map...' },
    address: { label: 'Address', placeholder: 'Address in the map...' },
    error: {
      emptyCoordinates: 'Coordinates are required',
      invalidCoordinates: 'The coordinate are invalid. Example: 38.597222,-0.195833',
    },
  },

  form: {
    user: {
      disabled: {
        label: 'Disabled',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Insert your e-mail...',
        error: {
          required: 'E-mail is required',
          invalid: 'Insert a valid e-mail',
        },
      },
      name: {
        label: 'Name',
        placeholder: 'Insert your name...',
        error: {
          required: 'The name is required.',
        },
      },
      language: {
        label: 'Language',
        placeholder: 'Select your language',
        error: {
          required: 'Language is required.',
        },
      },
      phone: {
        label: 'Phone',
        placeholder: 'Insert your phone...',
        error: {
          invalid: 'Phone is invalid.',
        },
      },
      password: {
        label: 'Password',
        placeholder: 'Insert your password...',
        error: {
          required: 'Password is required',
          invalidLength: 'Password must be at least 8 characters long.',
          incorrect: 'Password is incorrect.',
        },
      },
      newPassword: {
        label: 'New password',
        placeholder: 'Insert your new password...',
        error: {
          required: 'New password is required.',
        },
      },
      confirmation: {
        label: 'Confirmation',
        placeholder: 'Confirm your password...',
        error: {
          incorrect: "Passwords don't match",
        },
      },
      contractType: {
        label: 'Contract type',
        placeholder: 'Select the contract type...',
        values: {
          [ContractType.Employee]: 'Employee',
          [ContractType.Contractor]: 'Contractor',
        },
      },
    },

    company: {
      name: {
        label: 'Name',
        placeholder: 'Insert the name of the company...',
        error: {
          required: 'The name of the company is mandatory',
        },
      },
      type: {
        label: 'Type',
        placeholder: 'Enter the type of the company...',
        error: {
          required: 'The type of company is required',
        },
        values: {
          [CompanyType.Insurance]: 'Insurance',
          [CompanyType.Laboratory]: 'Laboratory',
        },
      },
      phone: {
        label: 'Phone',
        placeholder: "Insert the company's phone...",
        error: {
          invalid: 'Phone is invalid.',
        },
      },
      email: {
        label: 'E-mail',
        placeholder: 'Enter the E-Mail of the company...',
        error: {
          invalid: 'Please enter a valid e-mail',
        },
      },
      logo: {
        label: 'Logo',
        message: {
          uploaded: 'Logo of the company updated correctly.',
        },
      },
      taxName: {
        label: 'Fiscal name',
        labelShort: 'Name',
        placeholder: 'Insert the fiscal name of the company ...',
        error: {
          required: 'The fiscal name of the company is mandatory',
          max: 'The fiscal name can not exceed 500 characters.',
        },
      },
      taxNumber: {
        label: 'Fiscal number',
        labelShort: 'nº',
        placeholder: 'Insert the fiscal number of the company ...',
        error: {
          required: 'The fiscal number of the company is mandatory',
          max: 'The fiscal number can not exceed 500 characters.',
        },
      },
      taxAddress: {
        label: 'Fiscal address',
        labelShort: 'address',
        placeholder: 'Insert the fiscal address of the company ...',
        error: {
          required: 'The fiscal address of the company is mandatory',
          max: 'The tax address can not exceed 500 characters.',
        },
      },
      note: {
        label: 'Notes',
        placeholder: 'Add notes about the company ...',
        error: {
          max: 'The notes can not exceed 10000 characters.',
        },
      },
    },

    client: {
      name: {
        label: 'Name',
        placeholder: 'Enter the name of the client...',
        error: {
          required: 'The name of the client is mandatory',
        },
      },
      phone: {
        label: 'Phone',
        placeholder: "Enter the client's phone...",
        error: {
          invalid: 'The introduced phone is invalid',
        },
      },
      email: {
        label: 'E-mail',
        placeholder: 'Enter the E-mail of the client...',
        error: {
          invalid: 'Enter a valid E-mail',
        },
      },
      note: {
        label: 'Notes',
        placeholder: 'Add notes about the client ...',
        error: {
          max: 'The notes can not exceed 10000 characters.',
        },
      },
      taxName: {
        label: 'Fiscal name',
        labelShort: 'Name',
        placeholder: 'Insert the fiscal name of the client ...',
        error: {
          required: 'The fiscal name of the client is mandatory',
          max: 'The fiscal name can not exceed 500 characters.',
        },
      },
      taxNumber: {
        label: 'Fiscal number',
        labelShort: 'nº',
        placeholder: 'Insert the fiscal number of the client ...',
        error: {
          required: 'The fiscal number of the client is mandatory',
          max: 'The fiscal number can not exceed 500 characters.',
        },
      },
      taxAddress: {
        label: 'Fiscal address',
        labelShort: 'address',
        placeholder: 'Insert the fiscal address of the client ...',
        error: {
          required: 'The fiscal address of the client is mandatory',
          max: 'The tax address can not exceed 500 characters.',
        },
      },
    },

    rate: {
      name: {
        label: 'Name',
        placeholder: 'Insert the name of the rate...',
        error: {
          required: 'The name is mandatory',
        },
      },
      status: {
        label: 'Status',
        placeholder: 'Select the status...',
        error: {
          required: 'The status is mandatory',
        },
        values: {
          [RateStatusType.Draft]: 'Draft',
          [RateStatusType.Active]: 'Active',
          [RateStatusType.Decommissioned]: 'Decommissioned',
        },
      },
      description: {
        label: 'Description',
        placeholder: 'Insert the description of the rate...',
        error: {
          max: 'The description cannot be more than 1000 characters.',
        },
      },
    },

    reportRate: {
      perReport: {
        label: 'Per report',
        placeholder: 'Insert the rate per report...',
        error: {
          required: 'The rate per report is mandatory',
        },
      },
      perReopen: {
        label: 'Per reopen',
        placeholder: 'Insert the rate per reopen...',
        error: {
          required: 'The rate per reopen is mandatory',
        },
      },
    },

    mileageRate: {
      perKm: {
        label: 'Per KM.',
        placeholder: 'Insert the rate per KM....',
        error: {
          required: 'The rate per KM. is mandatory',
        },
      },
    },

    task: {
      type: {
        label: 'Type',
        placeholder: 'Enter the type of task...',
        filter: 'Filter by type',
        error: {
          required: 'The type of task is mandatory',
        },
        values: {
          [TaskType.Report]: 'Report',
          [TaskType.Homologation]: 'Homologation',
          [TaskType.Recognition]: 'Recognition',
        },
      },
      state: {
        label: 'State',
        filter: 'Filter by state...',
      },
      title: {
        label: 'Title',
        placeholder: 'Insert the title...',
        empty: 'No title',
        error: {
          required: 'Title of the task is required.',
        },
      },
      description: {
        label: 'Description',
        placeholder: 'Write a description for the task...',
        empty: 'No description',
      },
      deadline: {
        label: 'Deadline',
        placeholder: 'Select the deadline...',
        placeholderFrom: 'Deadline from',
        placeholderTo: 'Deadline to',
        filter: 'Filter by deadline...',
        empty: 'No deadline',
        with: 'With deadline',
        without: 'Without deadline',
      },
      assignee: {
        label: 'Assignee',
        placeholder: 'Assign an expert...',
        filter: 'Filter by assignee...',
        empty: 'No assignee',
      },
      reviewer: {
        label: 'Reviewer',
        placeholder: 'Assign an expert to review the task...',
        filter: 'Filter by reviewer...',
        empty: 'No reviewer',
      },
      labels: {
        label: 'Labels',
        placeholder: 'Add labels to the task...',
        filter: 'Filter by labels...',
        empty: 'No labels',
      },
      reference: {
        label: 'Reference',
        placeholder: 'Insert the reference...',
        filter: 'Filter by reference...',
        error: {
          required: 'Reference is required.',
        },
      },
      content: {
        label: 'Content',
        filter: 'Filter by content...',
      },
      company: {
        label: 'Company',
        placeholder: 'Select the company...',
        filter: 'Filter by companies...',
        empty: 'No company',
        error: {
          required: 'Company is required.',
        },
      },
      base: {
        label: 'Base',
        placeholder: 'Select the base of the company...',
        filter: 'Filter by bases...',
        empty: 'No base',
        error: {
          required: 'Base is required.',
        },
      },
      client: {
        label: 'Client',
        placeholder: 'Select the client...',
        filter: 'Filter by clients...',
        empty: 'No company',
        error: {
          required: 'Client is mandatory',
        },
      },
      laboratory: {
        label: 'Laboratory',
        placeholder: 'Select the laboratory...',
        error: {
          required: 'The laboratory is required',
        },
      },
      zone: {
        label: 'Zone',
        placeholder: 'Select the zone...',
        filter: 'Filter by zones...',
        error: {
          required: 'Zone is required.',
        },
      },
      rate: {
        label: 'Rate',
        placeholder: 'Select the report rate...',
        error: {
          required: 'The rate is required',
        },
      },
      appraisalType: {
        type: {
          [AppraisalType.InPerson]: 'In person',
          [AppraisalType.Remote]: 'Remote',
        },
        label: 'Appraisal type',
        placeholder: 'Select the appraisal type',
        empty: 'No appraisal type',
        error: {
          required: 'Appraisal type is required',
        },
      },
      price: {
        label: 'Price',
        placeholder: 'Insert the price of the report...',
      },
      documents: {
        empty: 'No documents',
      },
      createdAt: {
        label: 'Created at',
        placeholderFrom: 'Created from',
        placeholderTo: 'Created to',
      },
      updatedAt: {
        label: 'Updated at',
        placeholderFrom: 'Updated from',
        placeholderTo: 'Updated to',
      },
      closedAt: {
        label: 'Closed at',
        placeholderFrom: 'Closed from',
        placeholderTo: 'Closed to',
      },
    },

    incidence: {
      title: {
        label: 'Title',
        placeholder: 'Insert the title...',
        empty: 'No title',
        error: {
          required: 'Title of the task is required.',
        },
      },
      description: {
        label: 'Description',
        placeholder: 'Write a description for the task...',
        empty: 'No description',
      },
      deadline: {
        label: 'Deadline',
        placeholder: 'Select the deadline...',
        empty: 'No deadline',
      },
      solved: {
        label: 'Solved',
      },
    },

    route: {
      title: { label: 'Title', placeholder: 'Write a title for the route...' },
      status: { label: 'Status', filter: 'Filter by the status of the route...' },
      notes: {
        label: 'Notes',
        placeholder: 'Add notes to the route...',
      },
      date: {
        label: 'Date',
        placeholder: 'Enter the date of the route...',
        filter: 'Filter by the date of the route...',
      },
      assignee: {
        label: 'Assignee',
        placeholder: 'Select the assigned expert...',
        filter: 'Filter by the assigned expert...',
      },
      rate: {
        label: 'Rate',
        placeholder: 'Select the rate...',
        error: {
          required: 'The rate is mandatory',
        },
      },
      tasks: {
        status: { label: 'Status' },
        notes: {
          label: 'Notes',
          placeholder: 'Add notes to explain the status of the visit...',
        },
      },

      preferences: {
        defaultReportRate: {
          label: 'Default report rate',
          placeholder: 'Select the default report rate...',
          error: {
            required: 'The default report rate is mandatory',
          },
        },
        defaultMileageRate: {
          label: 'Default mileage rate',
          placeholder: 'Select default mileage rate...',
          error: {
            required: 'The default mileage rate is mandatory',
          },
        },
        billingDay: {
          label: 'Billing day',
          placeholder: '1, 2, 31',
          error: {
            required: 'The billing day is mandatory',
          },
        },
      },
    },

    carDetails: {
      brand: {
        label: 'Brand',
        placeholder: 'Enter the brand...',
        empty: 'No brand',
      },
      model: {
        label: 'Model',
        placeholder: 'Enter the model...',
        empty: 'No model',
      },
      commercialName: {
        label: 'Commercial name',
        placeholder: 'Enter the commercial name...',
        empty: 'No commercial name',
      },
      variant: {
        label: 'Variant',
        placeholder: 'Enter the variant...',
        empty: 'No variant',
      },
      plateNumber: {
        label: 'Plate number',
        placeholder: 'Enter the plate number...',
        empty: 'No plate number',
      },
      chassisNumber: {
        label: 'Chassis number',
        placeholder: 'Enter the chassis number...',
        empty: 'No chassis number',
      },
    },

    workspace: {
      title: {
        label: 'Title',
        placeholder: 'Insert the title',
        error: {
          required: 'Title is required.',
        },
      },
      color: {
        label: 'Color',
      },
    },

    column: {
      title: {
        label: 'Title',
        placeholder: 'Insert the title...',
        error: {
          required: 'Title is required.',
        },
      },
      color: {
        label: 'Color',
      },
      types: {
        label: 'Types',
        placeholder: 'Filter by types...',
      },
      states: {
        label: 'States',
        placeholder: 'Filter by states...',
      },
      assignees: {
        label: 'Assignee',
        placeholder: 'Filter by experts...',
      },
      reviewers: {
        label: 'Reviewers',
        placeholder: 'Filter by reviewers...',
      },
      labels: {
        label: 'Labels',
        placeholder: 'Filter by labels...',
      },
      companies: {
        label: 'Companies',
        placeholder: 'Filter by companies...',
      },
      clients: {
        label: 'Clients',
        placeholder: 'Filter by clients...',
      },
      zones: {
        label: 'Zones',
        placeholder: 'Filter by zones...',
      },

      sortBy: {
        title: 'Order',
        label: 'Field',
        placeholder: 'Select the field you want to order by...',
        state: 'State',
        deadline: 'Deadline',
        createdAt: 'Creation date',
        error: {
          required: 'Field is required.',
        },
      },
      sortDir: {
        label: 'Direction',
        asc: 'Ascending',
        desc: 'Descending',
        error: {
          required: 'Direction is required.',
        },
      },
    },
  },

  auth: {
    login: {
      title: 'Sign In',
      subtitle: 'Connect to your profile',
      submit: 'Connect',
    },

    forgotPassword: {
      title: 'Remember password',
      cta: 'Remember password',
      alert: 'Insert your e-mail and will send you the instructions to recover your password.',
      submit: 'Send',
      success: 'Message sent successfully. Check your e-mail!',
    },

    recover: {
      title: 'Recover password',
      change: 'Change password',

      email: 'E-mail',
      password: 'Password',
      confirmation: 'Confirmation',
      submit: 'Change password',
      success: 'Password changed successfully.',

      errors: {
        confirmationMatch: "Passwords don't match.",
      },
    },
  },

  // Sections
  work: {
    new: {
      title: 'New workspace',
      subtitle: '',
      submit: 'Create workspace',

      custom: {
        title: 'Custom',
        subtitle: 'Create a new workspace from scratch.',
        create: 'Custom',
      },

      template: {
        title: 'Template',
        subtitle: 'Create a new workspace from a template.',

        byType: {
          title: 'By type',
        },
        byState: {
          title: 'By state',
        },
        byAssigned: {
          title: 'By assignee',
        },
        byCompany: {
          title: 'By company',
        },
        byClient: {
          title: 'By client',
        },
        byZone: {
          title: 'By zone',
        },
        byLabel: {
          title: 'By label',
        },
      },
    },

    settings: {
      filters: 'Filter',
    },
  },

  column: {
    new: 'New column',
    created: 'Column created successfully.',
    updated: 'Column updated successfully.',
  },

  tasksList: {
    title: 'Tasks',
    filter: 'Filter reference...',
    settings: 'Configure filters',
    add: 'Add task',

    changeOrder: 'Rearrange filters',
    columnsSettings: 'Manage the columns that are visible',
    noColumnsError: 'You must select at least one column',

    filtersSettings: 'Manage the active filters',
    filtersTitlePlaceholder: 'Insert the name of the filter...',

    columns: {
      id: 'ID',
      type: 'Type',
      state: 'State',
      reference: 'Reference',
      title: 'Title',
      description: 'Description',
      creator: 'Creator',
      assignee: 'Assignee',
      reviewer: 'Reviewer',
      company: 'Company',
      labels: 'Labels',
      documents: 'Documents',
      comments: 'Comments',
      incidences: 'Incidences',
      zone: 'Zone',
      deadline: 'Deadline',
      details: 'Details',
      plateNumber: 'Plate number',
      chassisNumber: 'Chassis number',
      client: 'Client',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      closedAt: 'Closed at',
    },

    documentsTooltip: 'Documents: {{numDocuments}}. Photos: {{numPhotos}}. Reports: {{numReports}}',
    commentsTooltip: '{{numComments}} comment(s)',
  },

  // Sections
  tasks: {
    title: 'Tasks',
    filter: 'Filter tasks...',
    new: 'New task',
    open: 'Open task {{reference}}',

    filters: 'Filters',
    created: 'Task created successfully.',
    updated: 'Task updated successfully.',
    newComment: 'Comment added successfully.',

    newIncidence: 'Incidence added successfully.',
    incidenceUpdated: 'Incidence updated successfully.',
    incidenceDeleted: 'Incidence deleted successfully.',

    references: {
      new: 'New reference',
      newHelp: 'There are no other tasks that use this reference.',
      existingHelp: 'There is another task with this reference. Click to see that task.',
    },

    state: {
      [TaskState.Draft]: 'Draft',
      [TaskState.Open]: 'Open',
      [TaskState.Contact]: 'Pending contact',
      [TaskState.Visit]: 'Pending visit',
      [TaskState.BuildReport]: 'Build report',
      [TaskState.ReviewReport]: 'Review report',
      [TaskState.InLaboratory]: 'Laboratory',
      [TaskState.InInspection]: 'Inspection',
      [TaskState.Accepted]: 'Accepted',
      [TaskState.Close]: 'Closed',
      [TaskState.Cancelled]: 'Cancelled',
    },

    changeState: {
      message: 'Do you want to change the state to {{state}}?',
      deadlineAlert:
        'This company has a deadline associated to this state. The deadline of the task will change when moving to the new state.',

      error: {
        requirePrice: 'You must set the price of the report',
        requireAssignee: 'The assignee is required',
      },
    },

    sections: {
      general: 'General',
      report: 'Report',
      homologation: 'Homologation',
      recognition: 'Recognition',
      versions: 'Version',
      comments: 'Comments',
      incidences: 'Incidences',
      activity: 'Activity',
      documents: 'Documents',
      photos: 'Photos',
      reports: 'Reports',
    },

    multiple: {
      delete: {
        title: 'Delete',
      },
      labels: {
        title: 'Change labels',
        cta: 'Change',
      },
      state: {
        label: 'Change state',
        title: 'Change the state of {{ quantity }} tasks to {{ state }}',
        cta: 'Change the state of {{ quantity }} tasks',
        info: 'You are about to change hte state of <1>{{ quantity }}</1> tasks to the new state <2>{{ state }}</2>.',
        warning:
          'There are tasks that cannot be changed to the selected state. Only the tasks that can be changes will be affected.',
        success: 'Tasks have been updated successfully.',
        error: 'Not all the tasks were updated successfully.',
      },
    },
  },

  task: {
    form: {
      submit: 'Save',
    },

    reopen: 'This task was closed previously.',
    reopenLabel: 'Reopen',

    version: 'Version',
    versionNumber: 'Version #{{version}}',
    createdAt: 'Created',
    updatedAt: 'Updated',
    closedAt: 'Closed',
    invoices: 'Invoices',
    versions: {
      invoice: {
        confirmCheck: 'Do you want to mark the invoice for the version {{version}} as collected?',
        confirmUncheck: 'Do you want to mark the invoice for the version {{version}} as pending?',
        collected: 'Collected',
        pending: 'Pending',
      },
    },

    progress: {
      assignee: 'Assignee',
      created: 'Task created',
      deleted: 'Task deleted',
      newState: 'New state',
    },

    documents: {
      title: 'Documents',
      addSuccess: 'Document added successfully.',
      deleteSuccess: 'Document deleted successfully.',
    },

    comments: {
      add: 'Add comments',
      text: {
        placeholder: 'Add a comment...',
        error: {
          required: 'Comment is required.',
        },
      },
    },
  },

  incidences: {
    title: 'Pending incidences',
    tooltip: '{{ count }} pending incidences',
  },

  route: {
    filters: 'Filters',
    pendingTooltip: '{{ count }} pending routes',
    form: 'Route details',
    status: {
      types: {
        [RouteStatusType.PendingApproval]: 'Pending approval',
        [RouteStatusType.Ready]: 'Ready',
        [RouteStatusType.Completed]: 'Completed',
        [RouteStatusType.Cancelled]: 'Cancelled',
      },
    },

    summary: {
      title: 'Summary of the route',
      expertDistance: 'Distance for the expert',
      companiesDistance: 'Distance for the companies',

      profitable: {
        message: 'The route is profitable.',
        description:
          'The distance billable for the companies is greater than the distance for the assigned expert.',
      },
      notProfitable: {
        message: 'The route could not be profitable.',
        description:
          'The distance billable for the company is lower to the distance for the assigned expert. Review that the tasks and their order is right.',
      },
      finalDistanceWarning: {
        notProfitable:
          'This route has required more kilometers of the estimation. Is possible that the route will be checked. Add the notes you consider appropriate to explain the reason.',
      },
    },

    maps: {
      title: 'Maps for the route',
      expert: 'Expert',
      distance: 'Distance',
      finalDistance: 'Distance traveled',
      duration: 'Duration',
      open: 'Open route',
      copy: 'Copy route',
    },

    tasks: {
      status: {
        label: 'Status',
        types: {
          [RouteTaskStatusType.Pending]: {
            title: 'Pending',
            description: "Visit didn't happen yet",
          },
          [RouteTaskStatusType.Cancelled]: {
            title: 'Cancelled',
            description: 'The client has cancelled the appointment',
          },
          [RouteTaskStatusType.Failed]: {
            title: 'Failed',
            description: 'The client was not present when the expert visited',
          },
          [RouteTaskStatusType.Visited]: {
            title: 'Visited',
            description: 'The client was visited successfully',
          },
        },
      },
      notes: 'Notes',
      actions: 'Actions',
      changeStatus: 'Mark as...',
      rearrange: {
        cta: 'Modify tasks',
        title: 'Modify tasks',
        addMore: 'Add more tasks',
        addMoreReassign:
          'You cannot add the selected tasks because they are not assigned to the expert assigned to the route.\nPlease reassign the tasks to the assignee by clicking the button in the table.',
        reassign: 'Reassign',
        help: 'Sort the tasks of the route.',
        helpDescription:
          'Notice that the order that you chose will be used to generate the car routes.',
      },

      addNote: {
        cta: 'Add note',
        title: 'Add note',
        template: 'Note in the route "{{- routeTitle }}" the {{- date }}:\n\n{{ note }}',
      },
    },
    approve: 'Approve route',
    approveTip: 'This route has to be approved by an administrator.',
    restore: 'Restore route',
    needsApproval: {
      message: 'The approval for this route is pending.',
      description: 'The route must be accepted by an administrator before it can be initiated.',
      descriptionAdmin:
        'Check that the details of the route are correct and approve it by clicking the button.',
    },

    cancelled: {
      message: 'This route has been cancelled.',
      description: 'Check the notes or ask an admin to know more.',
      descriptionAdmin: 'The assigned expert will not be able to continue with this route.',
    },

    complete: {
      cta: 'Complete route',
      title: 'Complete route',
      message: 'Please insert the distance you have traveled.',
      description: 'Notice that the distance cannot be modified afterwards.',
    },

    create: {
      title: 'Create new route',
      selectTasks: 'Select the tasks for the route',
      addMore: 'Add more tasks',
    },
  },

  settings: {
    users: {
      title: 'Users',
      filter: 'Filter users...',
      add: 'Add user',
      deleteConfirmation: 'Are you sure you want to delete this user?',
      deleteMany: {
        title: 'Delete {{quantity}} users',
        warning: 'You are about to delete the following users, this action cannot be undone:',
        success: 'Users deleted successfully.',
        error: 'Error while trying to delete users.',
      },

      roles: {
        user: 'User',
        admin: 'Admin',
        god: 'God',
      },
      claims: {
        [ClaimType.ManageUsers]: {
          label: 'Users',
          tip: 'Allows management of other users (create, edit, delete).',
        },
        [ClaimType.ManageSettings]: {
          label: 'Settings',
          tip: 'Allows managing the configuration of the system (labels, zones, companies, rates and clients).',
        },
        [ClaimType.ManageTasks]: {
          label: 'Tasks',
          tip: 'Allows management of tasks (create, modify, assign).',
        },
        [ClaimType.SeeAllTasks]: {
          label: 'See all tasks',
          tip: 'Allows seeing all the tasks.',
        },
        [ClaimType.ManageFinances]: {
          label: 'Invoices',
          tip: 'Allows to mark versions of tasks as collected.',
        },
      },

      form: {
        create: 'Create user',
        edit: 'Edit user',

        name: 'Name',
        namePlaceholder: 'Name of the user...',
        avatar: 'Avatar',
        email: 'E-mail',
        emailPlaceholder: 'E-mail of the user...',
        phone: 'Phone',
        phonePlaceholder: 'Phone of the user...',
        language: 'Language',
        role: 'Role',
        admin: 'Admin',
        claims: 'Claims',
        claimsInfo: 'Manage the sections the user has access to.',

        submit: 'Save',

        updated: 'User updated successfully.',
        created: 'User created successfully.',
        avatarUpdated: 'Avatar updated successfully.',
      },
    },

    labels: {
      title: 'Labels',
      filter: 'Filter labels...',
      add: 'Add label',
      deleteConfirmation: 'Are you sure you want to delete this label?',
      deleteMany: {
        title: 'Delete {{quantity}} labels',
        warning: 'You are about to delete the following labels, this action cannot be undone:',
        success: 'Labels deleted successfully.',
        error: 'Error while trying to delete labels.',
      },

      form: {
        create: 'Crete label',
        edit: 'Edit label',

        name: 'Name',
        namePlaceholder: 'Name of the label...',
        nameError: 'Name of the label is required.',
        color: 'Color',
        colorPlaceholder: 'Color of the label...',
        description: 'Description',
        descriptionPlaceholder: 'Description of the label...',

        submit: 'Save',

        updated: 'Label updated successfully.',
        created: 'Label created successfully.',
      },
    },

    zones: {
      title: 'Zones',
      filter: 'Filter zones...',
      add: 'Add zone',
      deleteConfirmation: 'Are you sure you want to delete this zone?',
      deleteMany: {
        title: 'Delete {{quantity}} zones',
        warning: 'You are about to delete the following zones, this action cannot be undone:',
        success: 'Zones deleted successfully.',
        error: 'Error while trying to delete zones.',
      },

      form: {
        create: 'Create zone',
        edit: 'Edit zone',

        name: 'Name',
        namePlaceholder: 'Name of the zone...',
        nameError: 'Name of the zone is required.',
        description: 'Description',
        descriptionPlaceholder: 'Description of the zone...',
        coordinates: { label: 'Coordinates', placeholder: 'Map coordinates...' },

        submit: 'Save',

        updated: 'Zone updated successfully.',
        created: 'Zone created successfully.',
      },
    },

    companies: {
      title: 'Companies',
      filter: 'Filter companies...',
      add: 'Add company',

      deleteConfirmation: 'Are you sure you want to delete this company?',
      deleteMany: {
        title: 'Delete {{quantity}} companies',
        warning: 'You are about to delete the following companies, this action cannot be undone:',
        success: 'Companies deleted successfully.',
        error: 'Error while trying to delete companies.',
      },

      tax: 'Fiscal Information',
      taxInfoCopied: 'Fiscal information copied successfully',

      form: {
        create: 'Create company',
        edit: 'Edit company',
        bases: {
          title: 'Bases',
          info: 'Manage the bases',

          add: 'Add new base',
          new: 'New base',
        },

        settings: {
          deadlines: {
            add: 'Add deadline',

            unit: {
              hour: 'hours',
              day: 'days',
              week: 'weeks',
              month: 'months',
            },

            info: 'Manage the deadline for each of the states a task created for this company can be',
            invalid: 'Deadline is required.',
          },
        },

        submit: 'Save',

        created: 'Company created successfully.',
        updated: 'Company updated successfully.',
      },
    },

    reportRates: {
      title: 'Report rates',
      filter: 'Filter rates...',
      add: 'Add rate',
      unknown: 'Unknown rate',

      deleteConfirmation: 'Are you sure you want to delete this rate?',
      deleteMany: {
        title: 'Delete {{quantity}} rates',
        warning: 'You are about to delete the following rates, this action cannot be undone:',
        success: 'rates deleted successfully.',
        error: 'Error while trying to delete rates.',
      },
      form: {
        create: 'Create rate',
        edit: 'Edit rate',
        submit: 'Save',
        created: 'Rate created successfully.',
        updated: 'Rate updated successfully.',
      },
    },

    mileageRates: {
      title: 'Mileage rates',
      filter: 'Filter rates...',
      add: 'Add rate',
      unknown: 'Unknown rate',

      deleteConfirmation: 'Are you sure you want to delete this rate?',
      deleteMany: {
        title: 'Delete {{quantity}} rates',
        warning: 'You are about to delete the following rates, this action cannot be undone:',
        success: 'rates deleted successfully.',
        error: 'Error while trying to delete rates.',
      },
      form: {
        create: 'Create rate',
        edit: 'Edit rate',
        submit: 'Save',
        created: 'Rate created successfully.',
        updated: 'Rate updated successfully.',
      },
    },

    clients: {
      title: 'Clients',
      filter: 'Filter clients...',
      add: 'Add client',

      deleteConfirmation: 'Are you sure you want to delete this client?',
      deleteMany: {
        title: 'Delete {{quantity}} clients',
        warning: 'You are about to delete the following clients, this action cannot be undone:',
        success: 'Clients have been deleted successfully.',
        error: 'All customers have not been deleted.',
      },

      tax: 'Fiscal Information',
      taxInfoCopied: 'Fiscal information copied successfully',

      form: {
        create: 'Create client',
        edit: 'Edit client',

        submit: 'Save',

        created: 'Client created successfully.',
        updated: 'Client updated successfully.',
      },
    },

    task: {
      deleteMany: {
        title: 'Delete {{quantity}} tasks',
        warning: 'You are about to delete the following tasks, this action cannot be undone:',
        success: 'Tasks have been deleted successfully.',
        error: 'All tasks have not been deleted.',
      },
    },

    preferences: {
      title: 'Preferences',
      general: 'General',
      submit: 'Save',
      success: 'Preferences saved successfully.',
    },
  },

  userProfile: {
    title: 'User profile',

    update: {
      title: 'Change profile',
      submit: 'Update info',
      success: 'User info updated successfully.',
    },

    notifications: {
      title: 'Notifications',
    },

    changePassword: {
      title: 'Change password',
      submit: 'Update password',
      success: 'Password updated successfully.',
    },
  },

  nav: {
    home: 'Home',

    // Work pages
    work: 'Workspace',
    workNew: 'New',

    // Tasks pages
    tasks: 'Tasks',
    tasksList: 'List',
    tasksColumns: 'Columns',
    tasksCalendar: 'Calendar',
    tasksAssignees: 'Assignees',
    routes: 'Routes',
    incidences: 'Incidences',

    invoices: 'Invoices',

    settings: 'Settings',
    // Settings pages
    users: 'Users',
    labels: 'Labels',
    zones: 'Zones',
    companies: 'Companies',
    insurances: 'Insurances',
    laboratories: 'Laboratories',
    rates: 'Rates',
    reportRates: 'Reports',
    mileageRates: 'Mileage',
    clients: 'Clients',
    preferences: 'Preferences',

    profile: 'Profile',
    logout: 'Logout',
  },

  notifications: {
    label: 'Notifications',
    tooltip: '{{ count }} pending notifications',
    read: 'Read',
    empty: 'No pending notification',
    markAllAsRead: 'Mark all as read',
    deleteAll: 'Delete all',

    receiver: {
      [TaskNotificationsReceiverPreferenceType.Always]: 'Always',
      [TaskNotificationsReceiverPreferenceType.IfAssignee]: 'My tasks',
      [TaskNotificationsReceiverPreferenceType.IfReviewer]: 'Tasks I review',
      [TaskNotificationsReceiverPreferenceType.Never]: 'Never',
    },

    [NotificationType.TaskAssigned]: {
      title: 'You have been assigned to a task',
      details: '<1>{{ author }}</1> has assigned you to the task <2>{{ task }}</2>.',
    },
    [NotificationType.TaskReviewAssigned]: {
      title: 'You are the reviewer of a task',
      details: '<1>{{ author }}</1> has assigned you as reviewer of the task <2>{{ task }}</2>',
    },
    [NotificationType.TaskDocumentAdded]: {
      title: 'New document',
      details: '<1>{{ author }}</1> has added a new document to the task <2>{{ task }}</2>',
    },
    [NotificationType.TaskIncidenceAdded]: {
      title: 'New incidence',
      details: '<1>{{ author }}</1> has added a new incidence to the task <2>{{ task }}</2>',
    },
    [NotificationType.TaskCommentAdded]: {
      title: 'New comment',
      details: '<1>{{ author }}</1> has commented the task <2>{{ task }}</2>',
    },
  },

  confirmClose: {
    title: 'Warning!',
    description:
      'If you close the panel, the changes that you have not saved will be lost. Are you sure you want to continue?',
    discard: 'Discard changes',
  },

  errors: {
    403: 'You do not have access to this section',
    404: 'The section you are looking for is not available',
    500: 'Oh no, an unexpected error has occurred',
    sessionExpired: 'Session has expired, please log in again.',

    reload: 'Reload',
    goBack: 'Go back',
  },
}

export default translations
