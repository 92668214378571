import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '../../api/clients'
import { BaseRequest, BaseResponse, CompanyResponse, CompanyType } from '../../client'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { useRepositoryStore } from '../../utils/stores/repository.store'
import { AuthStore } from '../auth/auth.store'
import { MediaStore } from '../media/media.store'
import { createCompaniesIndex } from './company.filter'
import { Company, CompanyParams } from './company.interface'
import {
  transformCompanyResponse,
  transformToCreateCompanyRequest,
  transformToUpdateCompanyRequest,
} from './company.transformer'

export function useCompaniesStore(auth: AuthStore, media: MediaStore) {
  const { isLoading, isLoadingKey, operation } = useLoadingStore()

  // State

  const [isReady, setReady] = useState(false)
  const repository = useRepositoryStore<Company>()

  // Getters

  const companiesIndex = useMemo(() => createCompaniesIndex(repository.items), [repository.items])

  const insurances = useMemo(
    () => repository.items.filter((company) => company.type === CompanyType.Insurance),
    [repository.items],
  )
  const insurancesIndex = useMemo(() => createCompaniesIndex(insurances), [insurances])

  const laboratories = useMemo(
    () => repository.items.filter((company) => company.type === CompanyType.Laboratory),
    [repository.items],
  )
  const laboratoriesIndex = useMemo(() => createCompaniesIndex(laboratories), [laboratories])

  const basesMap = useMemo(
    () =>
      repository.items.reduce((result, it) => {
        for (const base of it.bases ?? []) {
          result[base.id] = { ...base, company: it }
        }

        return result
      }, {} as Record<string, BaseResponse & { company: Company }>),
    [repository.items],
  )

  const baseById = useCallback((id?: string) => (id ? basesMap[id] : undefined), [basesMap])

  // Actions

  // Get companies for the current user
  const getCompanies = operation(async () => {
    setReady(false)

    try {
      const response = await api.companies.getAllCompanies()
      const newCompanies = response.data.map(transformCompanyResponse)
      for (const company of newCompanies) {
        if (company.hasLogo) {
          media.fetchCompanyLogo(company)
        }
      }

      repository.addMany(newCompanies)
    } finally {
      setReady(true)
    }
  })

  // On company logo is updated
  const onCompanyLogoUpdated = operation(async (response: CompanyResponse) => {
    const updatedCompany = transformCompanyResponse(response)
    media.fetchCompanyLogo(updatedCompany)
  })

  // Creates a new company
  const createCompany = operation(async (params: CompanyParams): Promise<Company | undefined> => {
    if (params.email === '') {
      params.email = undefined
    }

    const response = await api.companies.createCompany({
      createCompanyRequest: transformToCreateCompanyRequest(params),
    })
    const createdCompany = transformCompanyResponse(response.data)
    repository.addOne(createdCompany)

    return createdCompany
  })

  // Updates a company
  const updateCompany = operation(async (id: string, params: CompanyParams) => {
    if (params.email === '') {
      params.email = undefined
    }
    const response = await api.companies.updateCompany({
      id,
      updateCompanyRequest: transformToUpdateCompanyRequest(params),
    })
    const updatedCompany = transformCompanyResponse(response.data)
    repository.addOne(updatedCompany)

    return updatedCompany
  })

  // Creates a base in a given company
  const createCompanyBase = operation(async (companyId: string, params: BaseRequest) => {
    const response = await api.companies.addCompanyBase({ id: companyId, baseRequest: params })

    repository.addOne(transformCompanyResponse(response.data.company))
  })

  // Updates a base in a given company
  const updateCompanyBase = operation(
    async (companyId: string, baseId: string, params: BaseRequest) => {
      const response = await api.companies.updateCompanyBase({
        id: companyId,
        baseId,
        baseRequest: params,
      })

      repository.addOne(transformCompanyResponse(response.data.company))
    },
  )

  // Deletes a company base
  const deleteCompanyBase = operation(async (companyId: string, baseId: string) => {
    const response = await api.companies.deleteCompanyBase({ id: companyId, baseId })

    repository.addOne(transformCompanyResponse(response.data))
  })

  // Deletes a company
  const deleteCompany = operation(async (id: string) => {
    await api.companies.deleteCompany({ id })

    repository.removeOne(id)
  })

  // Effects

  useEffect(() => {
    if (auth.isAuthenticated) {
      getCompanies()
    } else {
      repository.clear()
      setReady(true)
    }
  }, [auth.isAuthenticated])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    repository,
    companies: repository.items,
    getCompanyById: repository.itemById,
    companiesByIds: repository.itemsByIds,
    companiesIndex,
    insurances,
    insurancesIndex,
    laboratories,
    laboratoriesIndex,
    baseById,

    getCompanies,
    onCompanyLogoUpdated,
    createCompany,
    updateCompany,
    createCompanyBase,
    updateCompanyBase,
    deleteCompanyBase,
    deleteCompany,
  }
}

export type CompaniesStore = ReturnType<typeof useCompaniesStore>

export const CompaniesStoreContext = createContext<CompaniesStore>({
  isReady: false,
  isLoading: true,
  companies: [],
} as any)

export function getCompanyStore() {
  return useContext(CompaniesStoreContext)
}
