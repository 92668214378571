import { Task, TASK_STATE_TRANSITIONS, TASK_STATUS_ORDER, TaskState } from './task.interface'

/**
 * Whether a task can change to a certain state
 * @param task
 * @param newState
 * @returns whether the task can change to the given state
 */
export function taskCanChangeState(task: Task, newState: TaskState): boolean {
  const transactions = TASK_STATE_TRANSITIONS[task.content.type][task.state] ?? []

  return transactions.includes(newState)
}

export function taskPossibleStates(task: Task): TaskState[] {
  return (Object.keys(TASK_STATE_TRANSITIONS[task.content.type]) as TaskState[]).sort(
    (stateA, stateB) => {
      const orderA = TASK_STATUS_ORDER[stateA]
      const orderB = TASK_STATUS_ORDER[stateB]

      return orderA - orderB
    },
  )
}
