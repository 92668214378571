import moment from 'moment'

import {
  CreateTaskRequest,
  HomologationTaskContentDto,
  MediaResponse,
  RecognitionTaskContentDto,
  ReportTaskContentDto,
  SortDir,
  TaskContentType,
  TaskIncidenceDto,
  TaskResponse,
  TasksApiSearchTasksRequest,
  TaskSortType,
  UpdateTaskRequest,
} from '../../client'
import {
  Task,
  TaskActivity,
  TaskActivityType,
  TaskComment,
  TaskContent,
  TaskDocument,
  TaskDocumentType,
  TaskFilters,
  TaskIncidence,
  TaskParams,
  TaskState,
  TaskType,
} from './task.interface'

const IMAGE_REGEX = /^image\/.*/
const VIDEO_REGEX = /^video\/.*/

/**
 * Transforms a task coming from the API.
 * @param task
 * @returns transformed task
 */
export function transformTask(task: TaskResponse): Task {
  let closedAt: Date | undefined
  if (task.versions.length > 0 && task.versions[task.versions.length - 1].closedAt) {
    closedAt = moment(task.versions[task.versions.length - 1].closedAt).toDate()
  }

  return {
    id: task.id,
    type: task.content.type as unknown as TaskType,
    state: task.state as unknown as TaskState,
    reference: task.reference,
    version: task.currentVersion,
    title: task.title,
    description: task.description,
    // Missing!
    createdByUserId: '',
    assignedToUserId: task.assignee,
    reviewerId: task.reviewer,
    content: transformTaskContent(task),
    labelIds: task.labels ?? [],
    createdAt: moment(task.createdAt).toDate(),
    updatedAt: moment(task.updatedAt).toDate(),
    closedAt,
    deadline: task.deadline ? moment(task.deadline).toDate() : undefined,
    comments: task.comments.entries.map(
      (entry): TaskComment => ({
        id: entry.id,
        authorId: entry.author,
        text: entry.comment,
        createdAt: moment(entry.timestamp).toDate(),
      }),
    ),
    incidences:
      task.incidences?.entries?.map((incidence) => transformTaskIncidence(incidence, task.id)) ??
      [],
    activity: task.activity.entries.map(
      (entry): TaskActivity => ({
        type: entry.type as unknown as TaskActivityType,
        data: entry.data,
        authorId: entry.author,
        createdAt: moment(entry.timestamp).toDate(),
      }),
    ),
    versions: task.versions,
    documents: task.documents?.map(transformMediaResponseToTaskDocument) ?? [],
  }
}

export function transformTaskIncidence(response: TaskIncidenceDto, taskId?: string): TaskIncidence {
  return {
    id: response.id,
    taskId,
    title: response.title,
    state: response.state,
    description: response.description,
    deadline: response.deadline ? moment(response.deadline).toDate() : undefined,
    closedAt: response.closedAt ? moment(response.closedAt).toDate() : undefined,
    authorId: response.author,
    createdAt: moment(response.createdAt).toDate(),
    updatedAt: moment(response.updatedAt).toDate(),
  }
}

export function transformMediaResponseToTaskDocument(response: MediaResponse): TaskDocument {
  const tags = response.tags ?? []

  return {
    id: response.fileId,
    mimeType: response.mimeType,
    type: getDocumentTypeFromMimeType(response.mimeType),
    metadata: response.metadata,
    uploadedBy: response.uploadedAt,
    uploadedAt: response.uploadedAt,
    tags,
    isDocument: tags.includes('document'),
    isPhoto: tags.includes('photo'),
    isReport: tags.includes('report'),
  }
}

export function getDocumentTypeFromMimeType(mimeType: string): TaskDocumentType {
  if (IMAGE_REGEX.test(mimeType)) {
    return TaskDocumentType.Image
  }
  if (VIDEO_REGEX.test(mimeType)) {
    return TaskDocumentType.Video
  }

  return TaskDocumentType.Document
}

export function transformTaskContent(task: TaskResponse): TaskContent {
  if (task.content.type === TaskContentType.Report) {
    const content = task.content as ReportTaskContentDto

    return {
      type: TaskType.Report,
      companyId: content.company,
      baseId: content.base,
      zoneId: content.zone,
      appraisalType: content.appraisalType,
      rate: content.rate,
      price: content.price,
    }
  }

  if (task.content.type === TaskContentType.Homologation) {
    const content = task.content as HomologationTaskContentDto

    return {
      type: TaskType.Homologation,
      clientId: content.client,
      laboratoryId: content.company,
      companyId: content.company,
      brand: content.brand,
      model: content.model,
      commercialName: content.commercialName,
      variant: content.variant,
      plateNumber: content.plateNumber,
      chassisNumber: content.chassisNumber,
    }
  }

  if (task.content.type === TaskContentType.Recognition) {
    const content = task.content as RecognitionTaskContentDto

    return {
      type: TaskType.Recognition,
      clientId: content.client,
      brand: content.brand,
      model: content.model,
      commercialName: content.commercialName,
      variant: content.variant,
      plateNumber: content.plateNumber,
      chassisNumber: content.chassisNumber,
    }
  }

  throw new Error('Invalid content type')
}

export function transformToTaskCreateRequest(params: TaskParams): CreateTaskRequest {
  return {
    reference: params.reference,
    title: params.title,
    description: params.description,
    assignee: params.assignedToUserId,
    reviewer: params.reviewerId,
    deadline: params.deadline?.toISOString(),
    labels: params.labelIds,
    content: transformToTaskContentResponse(params),
  }
}

export function transformToTaskContentResponse(
  params: TaskParams,
): ReportTaskContentDto | HomologationTaskContentDto | RecognitionTaskContentDto {
  if (params.content.type === TaskType.Report) {
    return {
      type: params.content.type as any,
      company: params.content.companyId,
      base: params.content.baseId,
      zone: params.content.zoneId,
      appraisalType: params.content.appraisalType,
      rate: params.content.rate,
      price: params.content.price,
    }
  }

  if (params.content.type === TaskType.Homologation) {
    return {
      type: params.content.type as any,
      client: params.content.clientId,
      company: params.content.companyId,
      brand: params.content.brand,
      model: params.content.model,
      commercialName: params.content.commercialName,
      variant: params.content.variant,
      plateNumber: params.content.plateNumber,
      chassisNumber: params.content.chassisNumber,
    }
  }

  if (params.content.type === TaskType.Recognition) {
    return {
      type: params.content.type as any,
      client: params.content.clientId,
      brand: params.content.brand,
      model: params.content.model,
      commercialName: params.content.commercialName,
      variant: params.content.variant,
      plateNumber: params.content.plateNumber,
      chassisNumber: params.content.chassisNumber,
    }
  }

  throw new Error('Invalid content type')
}

export function transformToTaskUpdateRequest(params: TaskParams): UpdateTaskRequest {
  return {
    reference: params.reference,
    title: params.title,
    description: params.description,
    state: params.state,
    assignee: params.assignedToUserId,
    reviewer: params.reviewerId,
    deadline: params.deadline?.toISOString(),
    labels: params.labelIds,
    content: transformToTaskContentResponse(params),
  }
}

export function transformToSearchRequest(
  page: number,
  limit: number,
  filters: TaskFilters,
  sortBy?: TaskSortType,
  sortDir?: SortDir,
): TasksApiSearchTasksRequest {
  return {
    page: page,
    limit,

    // Filters
    ids: filters.ids,
    reference: filters.reference,
    contains: filters.contains,
    types: filters.types,
    states: filters.states,
    hasCompany: filters.hasCompany,
    companyIds: filters.companyIds,
    clientIds: filters.clientIds,
    hasDeadline: filters.hasDeadline,
    deadlineFrom: filters.deadlineFrom,
    deadlineTo: filters.deadlineTo,

    isAssigned: filters.isAssigned,
    assigneeIds: filters.assigneeIds,
    hasReviewer: filters.hasReviewer,
    reviewerIds: filters.reviewerIds,
    labelIds: filters.labelIds,
    hasZone: filters.hasZone,
    zoneIds: filters.zoneIds,

    createdFrom: filters.createdFrom,
    createdTo: filters.createdTo,
    updatedFrom: filters.updatedFrom,
    updatedTo: filters.updatedTo,
    closedFrom: filters.closedFrom,
    closedTo: filters.closedTo,

    // Sort
    sortBy,
    sortDir,
  }
}
