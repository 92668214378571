import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '../../api/clients'
import { ClaimType, LoginResponse, NotificationsPreferencesDto, RoleType } from '../../client'
import { Logger } from '../../utils/logger'
import { useLoadingStore } from '../../utils/stores/loading.store'
import { SettingsStore } from '../settings/settings.store'
import { User } from '../users/user.interface'
import { transformUserResponse } from '../users/user.transformer'

export enum AuthLoader {
  Init = 'init',
  Login = 'login',
  Logout = 'logout',
  ForgotPassword = 'forgotPassword',
  RecoverPassword = 'recoverPassword',
  GetCurrentUser = 'getCurrentUser',
}

const logger = new Logger('auth.store')

export function useAuthStore(settings: SettingsStore) {
  // State

  const [isReady, setReady] = useState(false)
  const { isLoading, isLoadingKey, operation } = useLoadingStore()
  const [user, setUser] = useState<User | null>(null)
  const [notificationsPreferences, setNotificationsPreferences] =
    useState<NotificationsPreferencesDto>()

  // Getters

  const isAuthenticated = useMemo(() => !!user && !!settings.jwt, [user, settings.jwt])
  const isGod = useMemo(() => user?.role === RoleType.Admin, [user?.role])
  const isAdmin = useMemo(
    () => user?.role === RoleType.Admin || (user?.role as any) === 'god',
    [user?.role],
  )
  const canManageUsers = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageUsers],
    [isAdmin, user?.claimsMap],
  )
  const canManageLabels = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageSettings],
    [isAdmin, user?.claimsMap],
  )
  const canManageZones = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageSettings],
    [isAdmin, user?.claimsMap],
  )
  const canManageCompanies = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageSettings],
    [isAdmin, user?.claimsMap],
  )
  const canManageRates = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageSettings],
    [isAdmin, user?.claimsMap],
  )
  const canManageClients = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageSettings],
    [isAdmin, user?.claimsMap],
  )
  const canManagePreferences = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageSettings],
    [isAdmin, user?.claimsMap],
  )
  const canManageSettings = useMemo(
    () =>
      canManageUsers || canManageLabels || canManageZones || canManageCompanies || canManageClients,
    [canManageUsers, canManageLabels, canManageZones, canManageCompanies, canManageClients],
  )
  const canManageTasks = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageTasks],
    [isAdmin, user?.claimsMap],
  )
  const canManageRoutes = useMemo(() => isAdmin, [isAdmin])
  const canManageFinances = useMemo(
    () => isAdmin || user?.claimsMap[ClaimType.ManageFinances],
    [isAdmin, user?.claimsMap],
  )

  // Actions

  // Initializes the store. Tries to get the current user if three is a saved JWT.
  // If so, saves the new JWT.
  const init = operation(async () => {
    try {
      setReady(false)

      if (!settings.jwt) {
        return
      }

      await getCurrentUser()
    } finally {
      setReady(true)
    }
  })

  // Tries to login using some credentials
  const login = operation(async (username: string, password: string): Promise<LoginResponse> => {
    const response = await api.auth.login({ loginRequest: { username, password } })
    if (!response.data.mustChangePassword) {
      await settings.saveJwt(response.data.accessToken)
      await getCurrentUser()
    }

    return response.data
  })

  // Logs out
  const logout = operation(async () => {
    settings.removeJwt()
  })

  // Sends an email with instructions to recover the password, in case the
  // email is present in the system.
  const forgotPassword = operation(async (email: string) => {
    await api.auth.forgotPassword({ forgotPasswordRequest: { username: email } })
  })

  // Recovers the password (changes to the new password).
  const recoverPassword = operation(async (token: string, email: string, password: string) => {
    const response = await api.auth.recoverPassword({
      recoverPasswordRequest: { token, username: email, password },
    })
    const jwt = response.data.accessToken

    if (jwt) {
      await settings.saveJwt(jwt)
      await getCurrentUser()
    }
  })

  // Gets the current user out of thw JWT
  const getCurrentUser = operation(async () => {
    const response = await api.me.getMe()
    const user = transformUserResponse(response.data)

    setUser(user)
    logger.registerUser(user, 'current-user')
  })

  // Gets the notifications preferences
  const getNotificationsPreferences = operation(async () => {
    const response = await api.notifications.getNotificationsPreferences()

    setNotificationsPreferences(response.data)
  })

  // Updates the user preferences
  const updateNotificationsPreferences = operation(async (params: NotificationsPreferencesDto) => {
    const response = await api.notifications.updateNotificationsPreferences({
      notificationsPreferencesDto: params,
    })

    setNotificationsPreferences(response.data)
  })

  // Effects

  useEffect(() => {
    if (settings.isReady) {
      init()
    }
  }, [settings.isReady])

  useEffect(() => {
    if (user) {
      getNotificationsPreferences()
    }
  }, [user?.id])

  return {
    isReady,
    isLoading,
    isLoadingKey,
    jwt: settings.jwt,
    user,
    setUser,
    isAuthenticated,
    isGod,
    isAdmin,
    notificationsPreferences,
    canManageUsers,
    canManageLabels,
    canManageZones,
    canManageCompanies,
    canManageClients,
    canManageSettings,
    canManageRates,
    canManagePreferences,
    canManageTasks,
    canManageRoutes,
    canManageFinances,

    login,
    logout,
    forgotPassword,
    recoverPassword,
    updateNotificationsPreferences,
  }
}

export type AuthStore = ReturnType<typeof useAuthStore>

export const AuthStoreContext = createContext<AuthStore>({
  isReady: false,
  isLoading: true,
  isAuthenticated: false,
} as any)

export function getAuthStore() {
  return useContext(AuthStoreContext)
}
